import { ThemeType } from 'theme/jss-types';

export const editHeadersStyles = (theme: ThemeType) => ({
    modal: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
            borderRadius: '4px 4px 0 0',
            padding: '16px 24px',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: 24,
        },
        '& .ant-btn-primary': {
            fontSize: 12,
            fontWeight: 600,
        },
    },
    row: {
        display: 'flex',
        width: '100%',
        marginBottom: 12,
        alignItems: 'center',
    },
    btnRow: {
        display: 'flex',
        marginTop: 12,
        marginBottom: 12,
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: 16,
        fontWeight: 'bold',
        color: theme.primaryColor,
    },
    column: {
        width: '20%',
        fontSize: 14,
        color: theme.primaryColor,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    clear: {
        textDecoration: `underline ${theme.primaryColor} solid`,
        fontWeight: 500,
        letterSpacing: 0.4,
        textAlign: 'left',
        cursor: 'pointer',
        marginLeft: 12,
    },
    mandatory: {
        width: '15%',
        marginLeft: '5%',
        fontSize: 14,
        color: theme.primaryColor,
        fontWeight: 'bold',
    },
    defaultValues: {
        width: '31%',
        fontSize: 14,
        color: theme.primaryColor,
        fontWeight: 'bold',
    },
    name: {
        width: '31%',
        fontSize: 14,
        color: theme.primaryColor,
        fontWeight: 'bold',
    },
    values: {
        width: '100%',
        overflow: 'scroll',
        maxHeight: '55vh',
    },
    valuerow: {
        display: 'flex',
        width: '100%',
        marginTop: 6,
        color: '#4a4a4a',
        '& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
            minHeight: 26,
            paddingBottom: 1,
            alignItems: 'center',
            borderBottomStyle: 'solid',
            boxSizing: 'content-box',
            padding: '2px 2px 1px 0',
            marginTop: 2,
            borderRadius: 0,
            height: 30,
            borderWidth: '0 0 1px 0',
            borderRight: 'none',
        },
        '& .ant-select': {
            width: '20%',
        },
        '& .ant-checkbox': {
            marginTop: 10,
            borderWidth: 0,
            display: 'flex',
            justifyContent: 'space-around',
            borderColor: '#FF4081',
            height: 18,
            width: 18,
        },
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: '#FF4081',
            borderColor: '#FF4081',
            borderStyle: 'solid',
            height: 18,
            width: 18,
        },
        '& .ant-input, .ant-input:hover, .ant-input:focus, .ant-input-focused': {
            marginTop: 2,
            background: 'none',
            borderRadius: 0,
            boxSizing: 'border-box',
            display: 'block',
            float: 'left',
            height: 30,
            borderWidth: '0 0 1px 0',
            borderRight: 'none',
            padding: '2px 2px 2px 0px',
            transition: 'border-color .4s cubic-bezier(.25,.8,.25,1)',
            boxShadow: 'none',
            outline: 'none',
            width: '80%',
            borderRightStyle: 'none',
            marginRight: '10%',
            borderStyle: 'dashed',
        },
        '& .ant-form-item-has-error .ant-input:focus': {
            marginTop: 2,
            background: 'none',
            borderRadius: 0,
            boxSizing: 'border-box',
            display: 'block',
            float: 'left',
            height: 30,
            borderWidth: '0 0 1px 0',
            borderRight: 'none',
            padding: '2px 2px 2px 0px',
            transition: 'border-color .4s cubic-bezier(.25,.8,.25,1)',
            boxShadow: 'none',
            outline: 'none',
            width: '80%',
            borderStyle: 'dashed',
            borderRightStyle: 'none',
            marginRight: '10%',
        },
    },
    columnCol: {
        width: '20%',
        fontSize: 14,
    },
    mandatoryCol: {
        width: '15%',
        marginLeft: '5%',
        display: 'flex',
        justifyContent: 'space-around',
    },
    defaultRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '31%',
        fontSize: 14,
    },
    nameCol: {
        width: '31%',
        fontSize: 14,
        letterSpacing: '-0.1px',
    },
    duplicate: {
        color: 'red',
    },
});
