import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from 'theme/jss-types';
import {
    Button,
    Checkbox,
    Input,
    message,
    Modal,
    Select,
} from 'antd';
import { Header, PositionOption } from './settings.types';
import { updateHeaders, updatePieceHeaders } from 'network/settings-product';
import { editHeadersStyles } from './edit-headers.styles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

interface ResetHeadersProps extends StylesProps<ReturnType<typeof editHeadersStyles>> {
    headers: Header[];
    orgMandatoryHeaders: Header[];
    onClose: (reload?: boolean) => void;
    positionOptions: PositionOption[];
    freezeDefaultMandatoryHeaders?: boolean;
    pieceHeaders?: boolean;
}
const ResetHeaders = (props: ResetHeadersProps) => {
    const {
        classes,
        onClose,
        headers,
        orgMandatoryHeaders,
        positionOptions,
        freezeDefaultMandatoryHeaders,
        pieceHeaders,
    } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState<boolean>(false);

    const sortHeaders = () => {
        return headers.sort((a, b) => {
            return a.position - b.position;
        });
    };

    const [editHeaders, setEditHeaders] = React.useState<Header[]>(sortHeaders());

    const handleClearAll = () => {
        let newHeaders = [...editHeaders];
        newHeaders = newHeaders.map((item) => {
            return {
                ...item,
                position: -1,
                positionAlpha: 'Not Present',
            };
        });
        setEditHeaders(newHeaders);
    };

    const renderColumns = () => {
        return (
            <div className={classes.row}>
                <div className={classes.name}>{t('header_name')}</div>
                <div className={classes.column}>
                    <span>{t('column')}</span>
                    <span
                        className={classes.clear}
                        onClick={() => handleClearAll()}
                    >
                        {t('clear_all')}
                    </span>
                </div>
                <div className={classes.mandatory}>{t('mandatory')}</div>
                <div className={classes.defaultValues}>{t('default_values')}</div>
            </div>
        );
    };

    const renderColumnName = (header: Header) => {
        return (
            <div className={classes.nameCol}>
                {header.name}
                &nbsp;
                {header.mandatory && '*'}
            </div>
        );
    };

    const handlePositionChange = (option: any, header: Header) => {
        let newHeaders = [...editHeaders];
        const duplicates = [...editHeaders].filter((item) => {
            return (
                item.positionAlpha === option.value
                && item.position !== -1
            );
        });
        const duplicateKeys = duplicates.map((item) => (item.id));

        const oldDuplicates = [...editHeaders].filter((item) => {
            return (
                item.positionAlpha === header.positionAlpha
                && item.id !== header.id
                && item.position !== -1
            );
        });
        const oldDuplicateKeys = oldDuplicates.map((item) => (item.id));

        newHeaders = newHeaders.map((item) => {
            if (oldDuplicateKeys.length < 2 && oldDuplicateKeys.includes(item.id)) {
                return {
                    ...item,
                    duplicate: false,
                };
            }
            if (duplicateKeys.includes(item.id)) {
                return {
                    ...item,
                    duplicate: true,
                };
            }
            if (item.id === header.id) {
                return {
                    ...item,
                    position: Number(option.key),
                    positionAlpha: option.value,
                    duplicate: duplicates.length > 0,
                };
            }
            return item;
        });
        setEditHeaders(newHeaders);
    };

    const renderPositionDropdown = (header: Header) => {
        return (
            <Select
                className={classes.columnCol}
                value={header.positionAlpha || 'Not Present'}
                onChange={(value, option) => handlePositionChange(option, header)}
            >
                {positionOptions.map((item) => {
                    return (
                        <Select.Option
                            key={item.position}
                            value={item.positionAlpha}
                        >
                            {item.positionAlpha}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };

    const handleMandatoryChange = (e: CheckboxChangeEvent, header: Header) => {
        let newHeaders = [...editHeaders];
        newHeaders = newHeaders.map((item) => {
            return {
                ...item,
                mandatory: item.id === header.id ? e.target.checked : item.mandatory,
            };
        });
        setEditHeaders(newHeaders);
    };


    const renderMandatory = (header: Header) => {
        let orgMandatoryHeadersState;

        if (freezeDefaultMandatoryHeaders) {
            orgMandatoryHeadersState = orgMandatoryHeaders.find((orgMandatoryHeader: any) => {
                return header.id === orgMandatoryHeader.id;
            });
        }

        return (
            <Checkbox
                className={classes.mandatoryCol}
                onChange={(e) => handleMandatoryChange(e, header)}
                checked={header.mandatory || (freezeDefaultMandatoryHeaders && orgMandatoryHeadersState?.mandatory)}
                disabled={freezeDefaultMandatoryHeaders && orgMandatoryHeadersState?.mandatory}
            />
        );
    };

    const handleDefaultValueChange = (e: React.ChangeEvent<HTMLInputElement>, header: Header) => {
        let newHeaders = [...editHeaders];
        newHeaders = newHeaders.map((item) => {
            return {
                ...item,
                default: item.id === header.id ? e.target.value : item.default,
            };
        });
        setEditHeaders(newHeaders);
    };

    const handleIsDefaultValueChange = (e: CheckboxChangeEvent, header: Header) => {
        let newHeaders = [...editHeaders];
        newHeaders = newHeaders.map((item) => {
            return {
                ...item,
                useDefault: item.id === header.id ? e.target.checked : item.useDefault,
            };
        });
        setEditHeaders(newHeaders);
    };

    const renderDuplicate = () => {
        return (
            <span className={classes.duplicate}>{t('duplicate')}</span>
        );
    };

    const renderDefaultValue = (header: Header) => {
        return (
            <div className={classes.defaultRow}>
                {header.duplicate && renderDuplicate()}
                {header.position === -1 && !header.duplicate && (
                    <Checkbox
                        onChange={(e) => handleIsDefaultValueChange(e, header)}
                        disabled={header.position !== -1}
                        checked={header.useDefault}
                    />
                )}
                {header.position === -1 && !header.duplicate && (
                    <Input
                        defaultValue={header?.default}
                        onChange={(e) => handleDefaultValueChange(e, header)}
                        disabled={header.position !== -1 || !header.useDefault}
                        placeholder={`Default Value For ${header.name}`}
                    />
                )}
            </div>
        );
    };

    const renderValues = () => {
        return (
            <div className={classes.values}>
                {editHeaders.map((header: Header) => {
                    return (
                        <div
                            className={classes.valuerow}
                            key={header.id}
                        >
                            {renderColumnName(header)}
                            {renderPositionDropdown(header)}
                            {renderMandatory(header)}
                            {renderDefaultValue(header)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const createHeaderArray = () => {
        const headerArray = editHeaders.map((item) => {
            return {
                ...item,
                pos: {
                    position: item.position,
                    positionAlpha: item.positionAlpha,
                },
            };
        });
        if (headerArray.find((item) => !!item.duplicate)) {
            return [];
        }
        return headerArray;
    };

    const saveHeaders = async () => {
        const apiBody = createHeaderArray();
        if (!apiBody.length) {
            message.error(t('duplicates_error'));
            return;
        }
        setSaving(true);
        const response = pieceHeaders ? await updatePieceHeaders(apiBody) : await updateHeaders(apiBody);
        setSaving(false);
        if (response.isSuccess) {
            message.success(t('header_mapping_update_success'));
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
    };

    const renderFooter = () => {
        return (
            <div className={classes.btnRow}>
                <Button
                    type="primary"
                    loading={saving}
                    onClick={() => saveHeaders()}
                >
                    {t('save').toUpperCase()}
                </Button>
            </div>
        );
    };

    return (
        <Modal
            className={classes.modal}
            closable
            visible
            title={t('edit_mapping')}
            width="70%"
            footer={renderFooter()}
            onCancel={() => onClose()}
        >
            {renderColumns()}
            {renderValues()}
        </Modal>
    );
};

export default withStyles(editHeadersStyles)(ResetHeaders);
