/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const VeryUnhappyColored = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Very unhappy</title>
        <defs>
            <polygon id="path-1" points="0 0 36.00006 0 36.00006 36 0 36" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-90.000000, -293.000000)">
                <g id="Group-14" transform="translate(90.000000, 293.000000)">
                    <g id="Group-3">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g id="Clip-2" />
                        <path d="M36.00006,19.220312 C36.00006,29.9614099 28.7411279,36.00006 18.00003,36.00006 C7.2589321,36.00006 0,29.9614099 0,19.220312 C0,8.07241345 6.85885143,0 18.00003,0 C29.1412086,0 36.00006,8.07241345 36.00006,19.220312" id="Fill-1" fill="#F55151" mask="url(#mask-2)" />
                    </g>
                    <path d="M9.56088393,14.2260957 C9.56088393,14.9020568 9.62856405,15.5626579 9.75780426,16.201899 C10.2376851,18.576943 13.6840908,18.576943 14.1640916,16.201899 C14.2932118,15.5626579 14.3608919,14.9020568 14.3608919,14.2260957 C14.3608919,13.2897341 14.2310517,12.3827726 13.9878113,11.5214112 C13.4098903,9.47516779 10.5120055,9.47516779 9.93408456,11.5214112 C9.69084415,12.3827726 9.56088393,13.2897341 9.56088393,14.2260957" id="Fill-4" fill="#802D21" />
                    <path d="M26.3610439,14.2260957 C26.3610439,14.9020568 26.2933638,15.5626579 26.1641236,16.201899 C25.6842428,18.576943 22.2378371,18.576943 21.7578363,16.201899 C21.628716,15.5626579 21.5610359,14.9020568 21.5610359,14.2260957 C21.5610359,13.2897341 21.6908762,12.3827726 21.9341166,11.5214112 C22.5120375,9.47516779 25.4100424,9.47516779 25.9878433,11.5214112 C26.2310837,12.3827726 26.3610439,13.2897341 26.3610439,14.2260957" id="Fill-6" fill="#802D21" />
                    <path d="M6.97741163,26.6643324 C7.72705288,24.5646889 9.1815753,22.5884056 11.2096987,21.2640834 C13.2417821,19.9372412 15.7163062,19.4133204 18.0347101,19.4667204 C20.1829536,19.5161605 22.9560383,20.0423614 24.8078813,21.2194434 C26.8991248,22.5486856 28.2930472,24.573689 29.0252884,26.6642124 C29.1365286,26.981613 28.9692483,27.3290135 28.6518478,27.4402537 C28.4054873,27.5265339 28.1411269,27.4451737 27.9830866,27.2561734 L27.9775666,27.2492134 C26.6407644,25.6427707 25.199922,24.2736885 23.5419992,23.5175672 C21.9853566,22.807646 19.5276325,22.5082455 17.9597099,22.4603654 C16.0340667,22.4018053 14.1704636,22.6988058 12.4880608,23.5001672 C10.803378,24.3027285 9.33973557,25.6377307 8.02513338,27.2494534 C7.81273302,27.5098538 7.42597238,27.5530539 7.16557194,27.3407736 C6.9607316,27.1737333 6.89329149,26.8997728 6.97741163,26.6643324" id="Fill-8" fill="#802D21" />
                    <path d="M29.4310331,6.76017527 C28.183151,6.05349409 26.6116284,6.25677443 25.2301861,6.86577544 C23.8498237,7.47429646 22.6883418,8.53581823 21.7320602,9.51105985 C21.0846591,10.171301 20.1353376,10.1177809 19.5654566,9.55905993 C18.9685756,8.97381896 18.9590956,8.01549736 19.5442166,7.41861636 C20.0528974,6.8998555 22.6578618,5.48697314 24.7254652,5.13801256 C26.5441882,4.83093205 28.5208315,5.06229244 29.8002737,6.28725448 C29.9205139,6.40233467 29.9245939,6.59313499 29.8095137,6.71325519 C29.7181935,6.80865535 29.5732333,6.8406954 29.4310331,6.76017527" id="Fill-10" fill="#802D21" />
                    <path d="M6.13197422,6.76017527 C7.3799763,6.05349409 8.95137892,6.25677443 10.3328212,6.86577544 C11.7131835,7.47429646 12.8746655,8.53581823 13.8309471,9.51105985 C14.4783481,10.171301 15.4276697,10.1177809 15.9975507,9.55905993 C16.5944317,8.97381896 16.6039117,8.01549736 16.0187907,7.41861636 C15.5102299,6.8998555 12.9051455,5.48697314 10.8375421,5.13801256 C9.01881903,4.83093205 7.04217574,5.06229244 5.7627336,6.28725448 C5.6424934,6.40233467 5.6384134,6.59313499 5.75349359,6.71325519 C5.84481374,6.80865535 5.98977398,6.8406954 6.13197422,6.76017527" id="Fill-12" fill="#802D21" />
                </g>
            </g>
        </g>
    </svg>
);

const VeryUnhappy = (props: any) => (
    <Icon component={(innerProps: any) => {
        return VeryUnhappyColored({ ...innerProps, ...props });
    }}
    />
);

export default VeryUnhappy;
