/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Cross = (props: any) => (
    <svg fill="current" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>FIlter Blue</title>
        <desc>Created with Sketch.</desc>
        <g id="FIlter-Blue" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="funnel" transform="translate(3.000000, 2.000000)" fillRule="nonzero">
                <path d="M3.90677966,6.54409938 C3.96440678,6.61118012 3.99830508,6.69689441 3.99830508,6.79006211 L3.99830508,11.9962733 L6.03220339,10.084472 L6.03220339,6.79006211 C6.03220339,6.69689441 6.06610169,6.61118012 6.12372881,6.54409938 L9.56440678,2.42236025 L0.466101695,2.42236025 L3.90677966,6.54409938 Z" id="Path" />
                <rect id="Rectangle" x="0.0322033898" y="0" width="9.96610169" height="1.67701863" />
            </g>
        </g>
    </svg>
);

const CrossIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Cross({ ...innerProps, ...props });
    }}
    />
);

export default CrossIcon;
