import { SearchOutlined } from '@ant-design/icons';
import { Collapse, Input, Modal } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import CrossIcon from 'assets/cross';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from 'theme/jss-types';
import { walletFaqList } from './faq-list';

const styles = {
    modal: {
        '& .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            height: 36,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            margin: '16px 24px 0px 24px',
            width: '93%',
        },
        '& .ant-collapse > .ant-collapse-item > .ant-collapse-header': {
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-collapse-item': {
            padding: 4,
        },
        '& .ant-collapse': {
            backgroundColor: '#FFF',
            padding: '0px 12px',
        },
        '& .ant-modal-body': {
            padding: 0,
        },
        '& .ant-collapse-content': {
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    closeIcon: {
        marginRight: 16,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 12,
        maxHeight: '40vh',
        overflow: 'scroll',
    },
};

interface FAQProps extends StylesProps<typeof styles> {
    onCancel: () => void;
}

const FAQModal = (props: FAQProps) => {
    const {
        classes,
        onCancel,
    } = props;

    const [searchText, setSearchText] = React.useState<string | undefined>();

    const renderFAQ = (faq: { question: string, answer: any, answerText: string }) => {
        const {
            question,
            answer,
        } = faq;

        return (
            <CollapsePanel
                header={question}
                key={question}
            >
                {answer}
            </CollapsePanel>
        );
    };

    const renderFAQList = () => {
        const filteredList = walletFaqList.filter((item) => {
            if (!searchText) {
                return true;
            }

            const {
                question,
                answerText,
            } = item;

            return (
                question.toUpperCase().includes(searchText?.toUpperCase())
                || answerText?.toUpperCase().includes(searchText?.toUpperCase())
            );
        });
        return (
            <Collapse
                className={classes.flexCol}
                expandIconPosition="right"
                bordered={false}
            >
                {filteredList.map((faq) => renderFAQ(faq))}
            </Collapse>
        );
    };

    const renderHeader = () => {
        const storage = window.localStorage;
        const logoUrl = storage.getItem('logoUrl');
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <CrossIcon
                        onClick={() => onCancel()}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>Wallet FAQs</span>
                </div>
                <img
                    style={{
                        height: 36,
                    }}
                    src={logoUrl as string}
                    alt="logo"
                />
            </div>
        );
    };

    const renderFAQSearch = () => {
        return (
            <Input
                type="search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                autoFocus={false}
                suffix={<SearchOutlined />}
                placeholder="Search Frequently Asked Questions"
            />
        );
    };

    return (
        <Modal
            className={classes.modal}
            visible
            title={renderHeader()}
            onCancel={() => onCancel()}
            width="45%"
            closable={false}
            footer={false}
        >
            {renderFAQSearch()}
            {renderFAQList()}
        </Modal>
    );
};

export default withStyles(styles, { injectTheme: true })(FAQModal);
