/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Ecommplugin = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        fill="none"
        {...props}
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M13.875 1H2.125C1.50313 1 1 1.50313 1 2.125V5.30469C1 6.80156 2.21406 8.01562 3.71094 8.01562C4.58594 8.01562 5.36406 7.60156 5.85938 6.95781C6.35469 7.60156 7.13281 8.01562 8.00781 8.01562C8.87813 8.01562 9.65312 7.60625 10.1484 6.96875C10.6438 7.60625 11.4187 8.01562 12.2891 8.01562C13.7859 8.01562 15 6.80156 15 5.30469V2.125C15 1.50313 14.4969 1 13.875 1ZM13.4375 9.4375C13.1266 9.4375 12.875 9.68906 12.875 10V13.875H3.125V10C3.125 9.68906 2.87344 9.4375 2.5625 9.4375C2.25156 9.4375 2 9.68906 2 10V13.875C2 14.4969 2.50312 15 3.125 15H12.875C13.4969 15 14 14.4969 14 13.875V10C14 9.68906 13.7484 9.4375 13.4375 9.4375ZM5.29688 5.30469V3.71094V2.125H2.125V5.30469C2.125 6.18125 2.83438 6.89062 3.71094 6.89062C4.5875 6.89062 5.29688 6.18125 5.29688 5.30469ZM8.00781 6.89062C7.13125 6.89062 6.42188 6.18125 6.42188 5.30469V2.125H9.57812V5.30469C9.57812 5.36406 9.57969 5.42344 9.58438 5.48281C9.49531 6.275 8.82344 6.89062 8.00781 6.89062ZM12.2891 6.89062C13.1656 6.89062 13.875 6.18125 13.875 5.30469V2.125H10.7031V5.30469C10.7031 6.18125 11.4125 6.89062 12.2891 6.89062Z" fill="#333333" />
    </svg>
);

const EcompluginIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Ecommplugin({ ...innerProps, ...props });
    }}
    />
);

export default EcompluginIcon;

