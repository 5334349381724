/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const RTODashboard = (props: any) => (
    <svg width={16} height={14} viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <clipPath id="a">
                <path d="M14.621 0c.231 0 .418.187.418.418v7.604c.595.635.961 1.488.961 2.426A3.556 3.556 0 0 1 12.45 14a3.542 3.542 0 0 1-2.669-1.212H.418A.418.418 0 0 1 0 12.37V.418C0 .188.187 0 .418 0H14.62ZM12.45 7.732a2.719 2.719 0 0 0-2.715 2.716 2.719 2.719 0 0 0 2.715 2.716 2.719 2.719 0 0 0 2.715-2.716 2.719 2.719 0 0 0-2.715-2.716ZM5.566.836H.836v11.116h8.397a3.53 3.53 0 0 1-.335-1.504 3.556 3.556 0 0 1 3.551-3.552c.616 0 1.22.16 1.755.466V.836H9.569V4.93c0 .23-.187.418-.418.418H5.984a.418.418 0 0 1-.418-.418V.836Zm6.13 8.037v.196h1.463c.76 0 1.379.619 1.379 1.379a1.38 1.38 0 0 1-1.379 1.379h-1.88a.418.418 0 0 1 0-.836h1.88a.544.544 0 0 0 0-1.086h-1.462v.228a.084.084 0 0 1-.13.069l-.928-.63a.084.084 0 0 1 0-.138l.927-.63a.084.084 0 0 1 .13.069Zm-5.808-1c.23 0 .418.186.418.417v2.496c0 .231-.187.418-.418.418H2.001a.418.418 0 0 1-.418-.418V8.29c0-.23.188-.418.418-.418h3.887Zm-.418.835H2.42v1.66H5.47v-1.66ZM8.733.836H6.402v3.676h2.331V.836Z" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)">
            <path d="M0 0h16v14H0V0z" />
        </g>
    </svg>
);


const RTODashboardIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return RTODashboard({ ...innerProps, ...props });
    }}
    />
);

export default RTODashboardIcon;
