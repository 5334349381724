/* eslint-disable max-len */

import Icon from '@ant-design/icons';
import * as React from 'react';

const Invoice = (props: any) => (
    <svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Invoice</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Nav-Bar---Inventory" transform="translate(-25.000000, -348.000000)" fill="#333333" fillRule="nonzero">
                <g id="Invoice" transform="translate(25.000000, 348.000000)">
                    <path d="M10.2307691,6.99999984 C10.2298821,7.29701573 9.98932346,7.53757435 9.69230757,7.53846137 L5.38461532,7.53846137 C5.08723123,7.53846137 4.84615379,7.29738393 4.84615379,6.99999984 C4.84615379,6.70261575 5.08723123,6.46153832 5.38461532,6.46153832 L9.69230757,6.46153832 C9.98932346,6.4624254 10.2298821,6.70298395 10.2307691,6.99999984 L10.2307691,6.99999984 Z" id="Path" />
                    <path d="M10.2307691,9.69230751 C10.2298821,9.9893234 9.98932346,10.229882 9.69230757,10.230769 L5.38461532,10.230769 C5.08723123,10.230769 4.84615379,9.9896916 4.84615379,9.69230751 C4.84615379,9.39492342 5.08723123,9.15384599 5.38461532,9.15384599 L9.69230757,9.15384599 C9.98932346,9.15473306 10.2298821,9.39529162 10.2307691,9.69230751 L10.2307691,9.69230751 Z" id="Path" />
                    <path d="M9.15384605,4.3076922 C9.15295897,4.60470808 8.91240042,4.84526667 8.61538453,4.84615373 L5.38461532,4.84615373 C5.08723123,4.84615373 4.84615379,4.60507629 4.84615379,4.3076922 C4.84615379,4.0103081 5.08723123,3.76923067 5.38461532,3.76923067 L8.61538453,3.76923067 C8.91240042,3.77011773 9.15295897,4.01067631 9.15384605,4.3076922 L9.15384605,4.3076922 Z" id="Path" />
                    <path d="M12.6699999,0.0807691694 C12.5097307,-0.0174055802 12.3100446,-0.0255560328 12.1423076,0.0592307079 L10.2199999,1.01769224 L8.02846142,0.0484614773 C7.88837386,-0.0161538258 7.72701056,-0.0161538258 7.58692301,0.0484614773 L5.39538456,1.01769224 L3.47307688,0.0592307079 C3.30653819,-0.026291345 3.10744371,-0.0187131839 2.94788794,0.0792210482 C2.78833216,0.17715528 2.69144394,0.351249261 2.69230766,0.538461472 L2.69230766,7.53846137 L0.538461532,7.53846137 C0.241445649,7.53934844 0.000887061829,7.77990706 0,8.07692295 L0,12.1153844 C0.00114548499,13.1557539 0.844245886,13.9988543 1.88461536,14 L10.7692307,14 C11.3405139,14.0001555 11.8884436,13.7732831 12.2924018,13.3693248 C12.6963601,12.9653666 12.9232325,12.4174369 12.9230806,11.8461537 L12.9230806,0.538461472 C12.9235744,0.352390037 12.8278551,0.17928077 12.6699999,0.0807691694 Z M2.69230766,12.1153845 C2.69230766,12.5614606 2.3306915,12.9230767 1.88461536,12.9230767 C1.43853923,12.9230767 1.07692307,12.5614606 1.07692307,12.1153845 L1.07692307,8.61538447 L2.69230766,8.61538447 L2.69230766,12.1153845 Z M11.8461537,11.8461537 C11.8444178,12.4402013 11.3632783,12.9213408 10.7692307,12.9230767 L3.58076919,12.9230767 C3.70679933,12.6726179 3.77140032,12.3957565 3.76923072,12.1153844 L3.76923072,1.41076915 L5.14230763,2.0946153 C5.28641561,2.17070687 5.45789513,2.17469477 5.60538455,2.10538453 L7.80769221,1.12538454 L10.0099999,2.10538453 C10.157487,2.17470578 10.3289717,2.17071777 10.4730768,2.0946153 L11.8461537,1.41076915 L11.8461537,11.8461537 Z" id="Shape" />
                </g>
            </g>
        </g>
    </svg>
);

const InvoiceIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Invoice({ ...innerProps, ...props });
    }}
    />
);

export default InvoiceIcon;
