import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import Paginator from '../common/paginator';
import {
    DatePicker,
    Table,
    Dropdown,
    Menu,
    message,
} from 'antd';
import {
    DownloadOutlined,
} from '@ant-design/icons';
import authHelper from '../../auth/auth-helper';
import {
    getExcels,
} from '../../network/excel-upload';
import {
    fetchCsvHistory,
} from '../../network/asn.api';
import moment from 'moment';
import { NAVBAR_HEIGHT, BUSINESS_BOOKING_API_URL } from 'library/globals';
import { excelColumnsChild, excelColumnsParent } from './advanceShippingNote.constants';
import {
    EXCEL_DOWNLOAD,
    EXCEL_DOWNLOAD_ERRORS,
    EXCEL_NON_INITIATED,
    EXCEL_PENDING,
} from '../../network/api.constants';

const menuStyle: React.CSSProperties = {
    padding: 12,
    borderBottom: '1px solid #CCCCCC',
    fontWeight: 'bold',
    fontSize: 12,
};

const styles = {
    main: {
        '& .ant-table-tbody > tr > td': {
            padding: '14px 0px',
        },
        '& .ant-picker': {
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 20,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    extraFilters: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        marginBottom: 4,
        justifyContent: 'space-between',
    },
    dateFilters: {
        display: 'flex',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
};


const AsnExcelUpload = (props: any) => {
    const { classes } = props;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [excels, setExcels] = React.useState<any>([]);
    const [dates, setDates] = React.useState({
        startDate: moment().subtract(1, 'M'),
        endDate: moment(),
    });
    const [pagination, setPagination] = React.useState<any>({
        nextOrPrev: 'first',
        currentPageNumber: 1,
        resultPerPage: 5,
        isNextPresent: false,
        lastUpdatedAt: null,
    });

    const loadData = async () => {
        setLoading(true);
        const isParentCustomer = JSON.parse(window.localStorage.getItem('parentId') as string);
        let response;
        const payload: any = {
            startDate: dates.startDate.format('YYYY-MM-DD'),
            endDate: dates.endDate.format('YYYY-MM-DD'),
            currentPage: pagination.currentPageNumber,
            nextOrPrev: pagination.nextOrPrev,
            resultPerPage: pagination.resultPerPage,
            lastUpdatedAt: pagination.lastUpdatedAt,
        };
        if (isParentCustomer) {
            response = await getExcels(payload);
        } else {
            response = await fetchCsvHistory(payload);
        }

        if (response.isSuccess) {
            setExcels(response?.data?.csvUploaded || []);
            setPagination({
                ...pagination,
                isNextPresent: response?.data?.nextPageExist,
            });
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadData();
    }, [dates]);

    const handlePagination = (nextOrPrev: any) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        let newUpdatedAt = null;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
            newUpdatedAt = null;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
            const lastExcel = excels[excels.length - 1];
            newUpdatedAt = lastExcel.createdAt;
        } else {
            newPageNumber = currentPageNumber - 1;
            const firstExcel = excels[0];
            newUpdatedAt = firstExcel.createdAt;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            lastUpdatedAt: newUpdatedAt,
            currentPageNumber: newPageNumber,
        };
        setPagination(newPagination);
    };

    const renderDateField = (key: 'startDate' | 'endDate') => {
        const name = key === 'startDate' ? 'From' : 'To';
        const inputName = `${name} Date`;
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {inputName}
                </span>
                <DatePicker
                    value={dates[key]}
                    placeholder={`Select ${inputName}`}
                    allowClear={false}
                    onChange={(value) => {
                        setDates({
                            ...dates,
                            [key]: moment(value),
                        });
                    }}
                />
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                <div className={classes.dateFilters}>
                    {renderDateField('startDate')}
                    {renderDateField('endDate')}
                </div>
                <Paginator
                    currentPageNumber={1}
                    isNextPresent={false}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                    onFirstClick={() => handlePagination('first')}
                />
            </div>
        );
    };

    const getDownloadLink = (feature: string, row: any) => {
        const headers = authHelper.getAuthenticationHeaders();
        const linkArray = [`${BUSINESS_BOOKING_API_URL}${feature}?`];
        linkArray.push(`csvId=${row.csvId}`);
        linkArray.push(`header-access-token=${headers['access-token']}`);
        linkArray.push(`header-user-id=${headers['user-id']}`);
        linkArray.push(`header-organisation-id=${headers['organisation-id']}`);

        return linkArray.join('&');
    };

    const renderDownloadOptions = (row: any) => {
        return (
            <Menu>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_DOWNLOAD, row)}>Download Data</a>
                </Menu.Item>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_DOWNLOAD_ERRORS, row)}>Download Errors</a>
                </Menu.Item>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_PENDING, row)}>Download Pending Softdata</a>
                </Menu.Item>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_NON_INITIATED, row)}> Download Not Initiated Bookings</a>
                </Menu.Item>
            </Menu>
        );
    };

    const renderText = (text: string) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const renderColumn = (text: string, column: any, row: any) => {
        const isChild = JSON.parse(window.localStorage.getItem('parentId') as string);
        if (column.column_id === 'createdAt' && !isChild) {
            return <div>{ text ? moment(text).format('lll') : 'NA'}</div>;
        }
        if (column.column_id === 'actions') {
            return (
                <div className={classes.row}>
                    <Dropdown
                        overlay={renderDownloadOptions(row)}
                    >
                        <DownloadOutlined />
                    </Dropdown>
                </div>
            );
        }
        if (text === null) {
            return <div className={classes.cellNa}>-NA-</div>;
        }
        return renderText(text);
    };

    const getColumns = (): any[] => {
        const excelColumns = JSON.parse(window.localStorage.getItem('parentId') as string)
            ? excelColumnsChild : excelColumnsParent;
        const columns: any = excelColumns.map((column: any) => {
            return {
                key: column.column_id,
                title: column.pretty_name,
                dataIndex: column.column_id,
                ellipsis: true,
                bordered: false,
                width: column.width,
                fixed: column.column_id === 'actions' ? 'right' : undefined,
                render: (text: string, row: any) => renderColumn(text, column, row),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                className={classes.table}
                loading={loading}
                rowKey={(row: any) => row.csvId}
                columns={getColumns()}
                dataSource={excels}
                locale={{
                    emptyText: <div className={classes.cellNa}>—NA—</div>,
                }}
                rowSelection={{
                    onSelect: (row) => (row),
                    onSelectAll: (rows) => (rows),
                }}
                scroll={{
                    y: `calc((100vh - ${NAVBAR_HEIGHT}px) - 160px)`,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderExtraFilters()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(AsnExcelUpload);
