import {
    Button, Checkbox, Drawer, Form, Image, Input, message, Popover, Select, Upload,
} from 'antd';
import { updateCustomer } from 'network/settings-product';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from 'theme/jss-types';
import { uniq } from 'library/lodash-helper';
import { Customer, ProductInput } from './settings.types';
import { accountStyles } from './settings.styles';
import PrimaryIcon from 'components/create-consignment/single-consignment-icons/primary';
import CrossIcon from 'assets/cross';
import BankIcon from 'assets/bank-details-icon';
import ConsignorIcon from 'assets/consignor-icon';
import { CustomerPortalConfig } from 'types/master-data-types';
import Helper from 'library/Helper';
import { CloseOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import { getS3Urls, uploadImageToS3 } from 'network/common.api';
import { useTranslation } from 'react-i18next';
import PasswordRules from 'routing/PasswordRules';
import { rules, isRuleSatified } from './password-rule-helper';

const accountFields: Record<string, ProductInput> = {
    username: {
        key: 'username',
        pretty_name: 'Username',
        placeholder: 'Username',
        required: true,
        errormsg: 'Enter Username.',
        disabled: true,
    },
    name: {
        key: 'name',
        pretty_name: 'Name',
        placeholder: 'Name',
        required: true,
        errormsg: 'Enter Name.',
        disabled: true,
    },
    phone_list: {
        key: 'phone_list',
        pretty_name: 'Phone',
        placeholder: 'Phone',
        required: true,
        errormsg: 'Enter Phone.',
    },
    status: {
        key: 'status',
        pretty_name: 'Status',
        placeholder: 'Status',
        required: true,
        errormsg: 'Select Status.',
    },
    email_list: {
        key: 'email_list',
        pretty_name: 'Email',
        placeholder: 'Email',
        required: true,
        errormsg: 'Enter Email.',
    },
    password: {
        key: 'password',
        pretty_name: 'Password',
        placeholder: 'Password',
        required: true,
        errormsg: 'Enter Password.',
    },
    comPassword: {
        key: 'comPassword',
        pretty_name: 'Confirm Password',
        placeholder: 'Confirm Password',
        required: true,
        errormsg: 'Enter Confirm Password.',
    },
    account_number: {
        key: 'account_number',
        pretty_name: 'Bank Account No.',
        placeholder: 'Bank Account No.',
        required: false,
    },
    bank_code: {
        key: 'bank_code',
        pretty_name: 'Bank AD Code',
        placeholder: 'Bank AD Code',
        required: false,
    },
    branch_code: {
        key: 'branch_code',
        pretty_name: 'Bank IFSC Code',
        placeholder: 'Bank IFSC Code',
        required: false,
    },
    bank_name: {
        key: 'bank_name',
        pretty_name: 'Bank Name',
        placeholder: 'Bank Name',
        required: false,
    },
    kyc_doc_type: {
        key: 'kyc_doc_type',
        pretty_name: 'KYC Doc Type',
        placeholder: 'KYC Doc Type',
        required: false,
        options: [
            {
                label: 'PAN CARD',
                value: 'PAN CARD',
            },
            {
                label: 'AADHAAR CARD',
                value: 'AADHAAR CARD',
            },
            {
                label: 'VOTER ID',
                value: 'VOTER ID',
            },
            {
                label: 'PASSPORT',
                value: 'PASSPORT',
            },
        ],
    },
    customer_gstin_number: {
        key: 'customer_gstin_number',
        pretty_name: 'GSTIN No.',
        placeholder: 'GSTIN No.',
        required: false,
    },
    customer_iec_number: {
        key: 'customer_iec_number',
        pretty_name: 'IEC No.',
        placeholder: 'IEC No.',
        required: false,
    },
    kyc_doc_number: {
        key: 'kyc_doc_number',
        pretty_name: 'KYC Doc No.',
        placeholder: 'KYC Doc No.',
        required: false,
    },
    kyc_doc: {
        key: 'kyc_doc',
        pretty_name: 'Attachments',
        placeholder: '-',
        required: false,
    },
};

interface IProps extends StylesProps<ReturnType<typeof accountStyles>> {
    customerDetails: Partial<Customer>;
    onClose: (reload?: boolean) => any;
    config: CustomerPortalConfig;
    phone_regex: RegExp;
    changePassword: boolean;
    password_policy: any;
}

const EditAccount = (props: IProps) => {
    const {
        classes,
        onClose,
        customerDetails,
        config,
        phone_regex,
        changePassword,
        password_policy,
    } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [passwordInput, showPasswordInput] = React.useState<boolean>(changePassword);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [customer, setCustomer] = React.useState<Partial<Customer>>(customerDetails);
    const [password, setPassword] = React.useState<string | undefined>();
    const [comPassword, setComPassword] = React.useState<string | undefined>();
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(false);
    const [docsUrls, setDocUrls] = React.useState<Record<string, string | undefined>>({
        signatureUrl: customer.signature_url,
        stampUrl: customer.stamp_url,
        kycUrl: customer.kyc_details?.kyc_url,
        kycUrlBack: customer.kyc_details?.kyc_back_url,
    });

    const [docsUploading, setDocsUploading] = React.useState<Record<string, boolean | undefined>>({});
    const rulesList = rules(t, password_policy) as Record<string, any>;


    const [docVisible, setDocVisible] = React.useState<Record<string, boolean>>({
        signatureUrl: Boolean(customer.show_invoice_options && customer.signature_url),
        stampUrl: Boolean(customer.show_invoice_options && customer.stamp_url),
        kycUrl: Boolean(customer.show_invoice_options && customer.kyc_details?.kyc_url),
        kycUrlBack: Boolean(customer.show_invoice_options && customer.kyc_details?.kyc_back_url),
    });

    React.useEffect(() => {
        form.setFieldsValue({
            username: customer.username,
            name: customer.name,
            email_list: customer.email_list?.join(', '),
            phone_list: customer.phone_list?.join(', '),
            status: customer.is_active ? 'active' : 'inactive',
            account_number: customer.bank_details?.account_number,
            branch_code: customer.bank_details?.branch_code,
            bank_code: customer.bank_details?.bank_code,
            bank_name: customer.bank_details?.bank_name,
            kyc_doc_number: customer.kyc_details?.kyc_doc_number,
            kyc_doc_type: customer.kyc_details?.kyc_doc_type,
            customer_gstin_number: customer.customer_gstin_number,
            customer_iec_number: customer.customer_iec_number,
        });

        setDocUrls({ ...docsUrls });
    }, []);

    React.useEffect(() => {
        if (!password) {
            return;
        }
        const incorrect = Object.keys(password_policy || {}).some((key: any) => {
            const isRuleActive = Boolean(password_policy[key]?.val);

            const rule = rulesList[key];

            if (!isRuleActive || !rule) {
                return false;
            }

            const isSatisfied = isRuleSatified(rule, password);
            return !isSatisfied;
        });
        if (!incorrect && comPassword === password && password) {
            setIsPasswordCorrect(true);
        } else {
            setIsPasswordCorrect(false);
        }
    }, [password, comPassword]);


    const createApiBody = async () => {
        const formValues = await form.validateFields();
        if (formValues.errorFields?.length) {
            return null;
        }
        setCustomer(customer);
        const apiBody = form.getFieldsValue();

        const bankDetails = {
            ...customer?.bank_details,
            account_number: apiBody.account_number,
            bank_code: apiBody.bank_code,
            branch_code: apiBody.branch_code,
            bank_name: apiBody.bank_name,
        };
        const bank_details = config?.show_bank_details ? bankDetails : null;

        const kycDetails = {
            kyc_doc_number: apiBody.kyc_doc_number,
            kyc_doc_type: apiBody.kyc_doc_type,
            kyc_url: docsUrls.kycUrl,
            kyc_back_url: docsUrls.kycUrlBack,
        };
        const kyc_details = config?.show_kyc_details ? kycDetails : null;

        return {
            bank_details,
            kyc_details,
            code: customer.code,
            id: customer.id,
            is_active: apiBody.status === 'active',
            parent_code: customer.parent_code,
            parent_id: customer.parent_id,
            parent_name: customer.parent_name,
            phone_list: uniq([...apiBody.phone_list.split(',').map((phone_number: string) => phone_number.trim())]),
            email_list: uniq([...apiBody.email_list.split(',')]),
            status: {
                name: apiBody.status.toUpperCase(),
                is_active: apiBody.status === 'active',
            },
            username: apiBody.username,
            name: apiBody.name,
            password: apiBody.password,
            comPassword: apiBody.comPassword,
            signature_url: docsUrls.signatureUrl || null,
            stamp_url: docsUrls.stampUrl || null,
            customer_gstin_number: apiBody.customer_gstin_number,
            customer_iec_number: apiBody.customer_iec_number,
        };
    };

    const saveCustomer = async () => {
        const apiBody = await createApiBody();
        if (!apiBody) {
            return;
        }
        setSaving(true);
        const response = await updateCustomer(apiBody);
        setSaving(false);
        if (response.isSuccess) {
            onClose(true);
            showPasswordInput(false);
        } else {
            message.error(response.errorMessage);
        }
    };

    const renderPasswordRules = () => {
        return (
            <PasswordRules
                password_policy={password_policy}
                password={password}
                conPassword={comPassword}
                enableSubmit={() => setIsPasswordCorrect(true)}
            />
        );
    };

    const renderRules = (key: string) => {
        if (key !== accountFields.password.key || Object.keys(password_policy || {}).length === 0) {
            return null;
        }
        return (
            <Popover
                content={renderPasswordRules()}
                title="Password Rules"
                trigger={['click', 'hover']}
            >
                <InfoCircleFilled style={{ marginLeft: 4 }} />
            </Popover>
        );
    };

    const renderLabel = (key: string) => {
        const required = accountFields[key].required;
        return (
            <div
                className={classes.label}
            >
                <span>{t(key)}</span>
                {required && <span>*</span>}
                {renderRules(key)}
            </div>
        );
    };

    const validateEmail = (value: string, callback: any) => {
        const emailList = value.split(',');
        const error = emailList.some((email: string) => {
            const isEmailValid = Helper.validateEmail(email);
            return !isEmailValid;
        });
        if (error) {
            return callback('Email list contains invalid emails');
        }
        return callback();
    };

    const validateConPassword = (value: string, callback: any) => {
        if (password !== value) {
            return callback('Confirm password should match with password');
        }
        return callback();
    };

    const validatePhone = (value: string, callback: any) => {
        if (!phone_regex) {
            return callback();
        }
        const isValid = !String(value).split(',').some((phone_number) => {
            return !phone_number.trim().match(new RegExp(phone_regex.toString().replace(/^\//, '').replace(/\/$/, '')));
        });
        if (!isValid) {
            return callback('Invalid phone');
        }
        return callback();
    };

    const validateInput = (key: string, value: any, callback: any) => {
        if (!value && accountFields[key].required) {
            return callback(`You've missed this field. ${accountFields[key].errormsg}`);
        }

        switch (key) {
            case 'email': return validateEmail(value, callback);
            case 'comPassword': return validateConPassword(value, callback);
            case 'phone_list': return validatePhone(value, callback);
            default: return callback();
        }
    };

    const renderPasswordInput = (key: string) => {
        return (
            <div className={classes.formParent}>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateInput(key, value, cb),
                    }]}
                >
                    <Input
                        name={key}
                        max={50}
                        type="password"
                        disabled={accountFields[key].disabled}
                        placeholder={t(key)}
                        onChange={(e) => {
                            if (key === accountFields.password.key) {
                                setPassword(e.target.value);
                            } else {
                                setComPassword(e.target.value);
                            }
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderPassword = () => {
        if (!passwordInput) {
            return (
                <div className={classes.changePassword}>
                    {renderLabel(accountFields.password.key)}
                    <div
                        className={classes.changePasswordText}
                        onClick={() => showPasswordInput(true)}
                    >
                        {t('change_password')}
                    </div>
                </div>
            );
        }
        return null;
    };

    const handleShowImageUpload = (e: any, key: string) => {
        setDocVisible({
            ...docVisible,
            [key]: e.target.checked,
        });

        if (!e.target.checked) {
            setDocUrls({
                ...docsUrls,
                [key]: undefined,
            });
        }
    };

    const handleFileUpload = async (key: string, imageType: string, file: any) => {
        const extention = Helper.getFileExtension(file?.name);

        if (!['JPG', 'JPEG', 'PNG'].includes(extention.toUpperCase())) {
            message.error('only images are allowed');
            return;
        }

        if (['signatureUrl', 'stampUrl'].includes(key) && file?.size > 5242880) {
            message.error('Maximum file size allowed 5MB');
            return;
        }

        setDocsUploading({
            ...docsUploading,
            [key]: true,
        });

        const storage = window.localStorage;
        const clientId = storage.getItem('userId');

        const response = await getS3Urls({
            clientId,
            imageType,
            contentType: file?.type,
        });

        if (response.isSuccess) {
            const upload = await uploadImageToS3(file, response?.data?.result?.imageSignedUrl, file?.type);

            if (upload.isSuccess) {
                setDocUrls({
                    ...docsUrls,
                    [key]: response?.data?.result?.imageUrl,
                });
            } else {
                message.error(upload.errorMessage);
            }
        } else {
            message.error(response.errorMessage);
        }

        setDocsUploading({
            ...docsUploading,
            [key]: false,
        });
    };

    const handleRemoveFile = (key: string) => {
        setDocUrls({
            ...docsUrls,
            [key]: undefined,
        });
    };

    const renderImage = (key: string) => {
        if (!docsUrls[key]) {
            return null;
        }
        return (
            <div
                className={classes.image}
            >
                <Image
                    height={32}
                    width={100}
                    src={docsUrls[key]}
                    alt={key}
                />
                <CloseOutlined
                    onClick={() => handleRemoveFile(key)}
                    style={{
                        color: 'red',
                        marginLeft: 5,
                        marginTop: -5,
                        cursor: 'pointer',
                    }}
                />
            </div>
        );
    };

    const renderUpload = (key: string, imageType: string) => {
        if (!docVisible[key] && !(key === 'kycUrl' || key === 'kycUrlBack')) {
            return null;
        }

        return (
            <Button
                style={{
                    borderRadius: 4,
                    fontSize: 12,
                }}
            >
                <Upload
                    showUploadList={false}
                    disabled={Boolean(docsUploading[key])}
                    customRequest={(upload) => handleFileUpload(key, imageType, upload?.file)}
                >
                    <UploadOutlined />
                    <span style={{ fontSize: 12, marginLeft: 6 }}>
                        Upload&nbsp;
                        {key === 'kycUrl' ? '(Front)' : null}
                        {key === 'kycUrlBack' ? <>(Back)&nbsp;</> : null}
                    </span>
                </Upload>
            </Button>
        );
    };

    const renderSignature = () => {
        if (customer.show_invoice_options) {
            return (
                <div className={classes.embedImage}>
                    <Checkbox
                        checked={Boolean(docVisible.signatureUrl)}
                        className={classes.checkbox}
                        onChange={(e) => handleShowImageUpload(e, 'signatureUrl')}
                    >
                        {t('embed_signature')}
                    </Checkbox>
                    {renderUpload('signatureUrl', 'signature')}
                    {renderImage('signatureUrl')}
                </div>
            );
        }
        return null;
    };

    const renderInformationUpload = () => {
        if (customer.show_invoice_options) {
            return (
                <div className={classes.uploadRules}>
                    {t('supported_file_text')}
                    <br />
                    {t('file_size_text')}
                </div>
            );
        }
        return null;
    };

    const renderStamp = () => {
        if (customer.show_invoice_options) {
            return (
                <div className={classes.embedImage}>
                    <Checkbox
                        checked={Boolean(docVisible.stampUrl)}
                        className={classes.checkbox}
                        onChange={(e) => handleShowImageUpload(e, 'stampUrl')}
                    >
                        {t('embed_seal')}
                    </Checkbox>
                    {renderUpload('stampUrl', 'stamp')}
                    {renderImage('stampUrl')}
                </div>
            );
        }
        return null;
    };

    const renderKycUpload = (key: string) => {
        return (
            <div className={classes.embedImage}>
                {renderUpload(key, 'kyc')}
                {renderImage(key)}
            </div>
        );
    };

    const renderInput = (key: string) => {
        return (
            <div className={classes.formParent}>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateInput(key, value, cb),
                    }]}
                >
                    <Input
                        name={key}
                        max={50}
                        disabled={accountFields[key].disabled}
                        placeholder={t(key)}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <CrossIcon onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('edit_account_heading')}
                    </span>
                </div>
                <Button
                    type="primary"
                    loading={saving}
                    disabled={passwordInput && !isPasswordCorrect}
                    onClick={() => saveCustomer()}
                    className={classes.newButton}
                >
                    {t('save')}
                </Button>
            </div>
        );
    };

    const renderSelect = (key: string) => {
        return (
            <div className={classes.formParent}>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateInput(key, value, cb),
                    }]}
                >
                    <Select
                        placeholder={accountFields[key].placeholder}
                    >
                        <Select.Option
                            key="active"
                            value="active"
                        >
                            ACTIVE
                        </Select.Option>
                        <Select.Option
                            key="inactive"
                            value="inactive"
                        >
                            INACTIVE
                        </Select.Option>
                    </Select>
                </Form.Item>
            </div>
        );
    };

    const renderFormBasicUtil = () => {
        return (
            <div className={classes.formFields}>
                <div className={classes.flexRow}>
                    {renderInput(accountFields.name.key)}
                    {renderInput(accountFields.phone_list.key)}
                </div>
                <div className={classes.flexRow}>
                    {renderSelect(accountFields.status.key)}
                    {renderInput(accountFields.username.key)}
                </div>
                <div className={classes.flexRow}>
                    {renderInput(accountFields.email_list.key)}
                    {renderPassword()}
                </div>
                {passwordInput && (
                    <div className={classes.flexRow}>
                        {renderPasswordInput(accountFields.password.key)}
                        {renderPasswordInput(accountFields.comPassword.key)}
                    </div>
                )}
                <div className={classes.flexCol}>
                    {renderSignature()}
                    {renderStamp()}
                    {renderInformationUpload()}
                </div>
                <div className={classes.flexRow}>
                    {config?.show_gst_details ? renderInput(accountFields.customer_gstin_number.key) : null}
                    {config?.show_customer_iec_number ? renderInput(accountFields.customer_iec_number.key) : null}
                </div>
            </div>
        );
    };

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const renderFormBasic = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, t('primary_details'))}
                {renderFormBasicUtil()}
            </div>
        );
    };

    const renderBankForm = () => {
        return (
            <div className={classes.formFields}>
                <div className={classes.flexRow}>
                    {renderInput(accountFields.account_number.key)}
                    {renderInput(accountFields.bank_code.key)}
                </div>
                <div className={classes.flexRow}>
                    {renderInput(accountFields.branch_code.key)}
                    {renderInput(accountFields.bank_name.key)}
                </div>
            </div>
        );
    };

    const renderConsignorForm = () => {
        return (
            <div className={classes.formFields}>
                <div className={classes.flexRow}>
                    <div className={classes.formParent}>
                        {renderLabel(accountFields.kyc_doc_type.key)}
                        <Form.Item
                            name={accountFields.kyc_doc_type.key}
                            className={classes.formItem}
                            rules={[{
                                validator: (rule, value, cb) => validateInput(accountFields.kyc_doc_type.key, value, cb),
                            }]}
                        >
                            <Select
                                placeholder={t(accountFields.kyc_doc_type.key)}
                                options={accountFields.kyc_doc_type.options}
                            />
                        </Form.Item>
                    </div>
                    {renderInput(accountFields.kyc_doc_number.key)}
                </div>
                <div className={classes.flexCol}>
                    {renderLabel(accountFields.kyc_doc.key)}
                    {renderKycUpload('kycUrl')}
                    {renderKycUpload('kycUrlBack')}
                </div>
            </div>
        );
    };

    const renderBankDetails = () => {
        if (!config?.show_bank_details) {
            return null;
        }
        return (
            <div className={classes.box}>
                {renderRowDescription(BankIcon, t('bank_details_heading'))}
                {renderBankForm()}
            </div>
        );
    };

    const renderConsignorDetails = () => {
        if (!config?.show_kyc_details) {
            return null;
        }
        return (
            <div className={classes.box}>
                {renderRowDescription(ConsignorIcon, t('consignor_details'))}
                {renderConsignorForm()}
            </div>
        );
    };

    return (
        <Drawer
            visible
            title={renderHeader()}
            onClose={() => onClose()}
            width="50%"
            closable={false}
            className={classes.drawerBody}
        >
            <div className={classes.main}>
                <Form
                    form={form}
                    className={classes.form}
                >
                    {renderFormBasic()}
                    {renderBankDetails()}
                    {renderConsignorDetails()}
                </Form>
            </div>
        </Drawer>
    );
};

export default withStyles(accountStyles)(EditAccount);
