import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input } from 'antd';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const CustomerReferenceNumberField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const { classes, params } = props;
    const { isRequired } = params;
    const { CustomerReference, ConsignmentCategory } = formFields;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    return (
        <Col span={12}>
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(CustomerReference.key)}
                    {isRequired(false, CustomerReference.key) ? '*' : null}
                </div>
                <Form.Item
                    name={CustomerReference.key}
                    className={classes.contentType}
                    valuePropName={CustomerReference.valuePropName}
                    rules={isRequired(false, CustomerReference.key) ? [formRuleRequired] : undefined}
                    dependencies={[ConsignmentCategory.key]}
                >
                    <Input
                        placeholder={t('customer_reference_number_input')}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CustomerReferenceNumberField);
