import React from 'react';
import withStyles from 'react-jss';
import { loginStyles } from '../components/sign-in/sign-in.styles';
import { StylesProps } from '../theme/jss-types';
import background from '../assets/images/background-customer-portal.jpg';
import authHelper from 'auth/auth-helper';

interface CarouselProps extends StylesProps<ReturnType<typeof loginStyles>> {
    background_url: any;
}
const CarouselPage = (props: CarouselProps): React.ReactElement => {
    const {
        classes,
        background_url,
    } = props;

    // const renderText = () => {
    //     return (
    //         <div className={classes.carouselText}>
    //             One of India&apos;s leading Integrated express logistics player
    //         </div>
    //     );
    // };

    // const renderSmallText = () => {
    //     return (
    //         <div className={classes.smallText}>
    //             One Stop Solution For All Your Shipping Needs
    //         </div>
    //     );
    // };

    const renderCarousel = () => {
        if (authHelper.getOrganisationId() === '1') {
            return (
                <div className={classes.carouselContent}>
                    {/* {renderText()}
                    {renderSmallText()} */}
                </div>
            );
        }
        return null;
    };

    return (
        <div
            style={{
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                backgroundImage: `url('${background_url || background}')`,
            }}
        >
            {renderCarousel()}
        </div>
    );
};

const Carousels = withStyles(loginStyles)(CarouselPage);
export default Carousels;
