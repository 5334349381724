export const PageOptions = Object.freeze({
    Asn: 'ASN Uploaded',
    ExcelUpload: 'Excel Uploaded',
});

export const ASN_PAGE_KEY = 'Asn';

export const AsnBucketChild: any = {
    customer_portal_asn_assigned: {
        name: 'ASN Assigned',
    },
    customer_portal_asn_converted_to_cn_child: {
        name: 'Converted to CN',
    },
    customer_portal_asn_cancelled_child: {
        name: 'ASN Cancelled',
    },
};

export const AsnBucketParent: any = {
    customer_portal_asn_uploaded: {
        name: 'ASN Uploaded',
    },
    customer_portal_asn_converted_to_cn_parent: {
        name: 'Converted to CN',
    },
    customer_portal_asn_cancelled_parent: {
        name: 'ASN Cancelled',
    },
};

export const getAsnBucket = (isParent:any) => {
    return isParent ? AsnBucketParent : AsnBucketChild;
};

export const dateKeys = ['asn_created_at', 'asn_date'];
export const epoachDates = ['asn_canceled_at', 'asn_converted_to_cn_at'];

export const asnChildColumns = [{
    column_id: 'asn_number',
    is_sortable: false,
    pretty_name: 'ASN No',
}, {
    column_id: 'asn_date',
    is_sortable: false,
    pretty_name: 'ASN Date',
}, {
    column_id: 'asn_created_at',
    is_sortable: false,
    pretty_name: 'ASN Uploaded Date',
}, {
    column_id: 'status',
    is_sortable: false,
    pretty_name: 'Status',
}, {
    column_id: 'service_type',
    is_sortable: false,
    pretty_name: 'Service Type',
}, {
    column_id: 'origin_address',
    is_sortable: false,
    pretty_name: 'Origin Address',
}, {
    column_id: 'origin_code',
    is_sortable: false,
    pretty_name: 'Origin Code',
}, {
    column_id: 'destination_address',
    is_sortable: false,
    pretty_name: 'Destination Address',
}, {
    column_id: 'destination_code',
    is_sortable: false,
    pretty_name: 'Destination Code',
}, {
    column_id: 'allocation_tag',
    is_sortable: false,
    pretty_name: 'Allocation Tag',
}, {
    column_id: 'actions',
    is_sortable: false,
    pretty_name: 'Actions',
},
];

export const asnParentColumns = [{
    column_id: 'asn_number',
    is_sortable: false,
    pretty_name: 'ASN No',
}, {
    column_id: 'created_at',
    is_sortable: false,
    pretty_name: 'ASN Date',
}, {
    column_id: 'vendor_code',
    is_sortable: false,
    pretty_name: 'Vendor Code',
}, {
    column_id: 'vendor_name',
    is_sortable: false,
    pretty_name: 'Vendor Name',
}, {
    column_id: 'business_id',
    is_sortable: false,
    pretty_name: 'Business ID',
}, {
    column_id: 'service_type',
    is_sortable: false,
    pretty_name: 'Service Type',
}, {
    column_id: 'destination_location_code',
    is_sortable: false,
    pretty_name: 'Destination Location Code',
}, {
    column_id: 'destination_address',
    is_sortable: false,
    pretty_name: 'Destination Address',
}, {
    column_id: 'allocation_tag',
    is_sortable: false,
    pretty_name: 'Allocation Tag',
}, {
    column_id: 'num_of_hus',
    is_sortable: false,
    pretty_name: 'Number of HUs',
}];


export const excelColumnsParent: any = [
    {
        column_id: 'csvId',
        pretty_name: 'Excel ID',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'createdAt',
        pretty_name: 'Uploaded Date',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'totalCount',
        pretty_name: 'Total Rows',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'successCount',
        pretty_name: 'Upload Success',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'failureCount',
        pretty_name: 'Upload Failed',
        is_sortable: false,
        width: 80,
    },
];

export const excelColumnsChild: any = [
    {
        column_id: 'csvId',
        pretty_name: 'Excel ID',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'createdAt',
        pretty_name: 'Uploaded Date',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'numberOfConsignments',
        pretty_name: 'Total',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'softDataSuccess',
        pretty_name: 'Softdata Success',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'softDataFailure',
        pretty_name: 'Softdata Failed',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'softDataPending',
        pretty_name: 'Softdata Pending',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'actions',
        pretty_name: 'Action',
        is_sortable: false,
        width: 50,
    },
];

export const cancelAsnText = 'This will cancel the ASN and remove the entry from “Child’s Name” as well.'
 + ' Do you wish to continue, this can’t be undone?';
