/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Wallet = (props: any) => (
    <svg fill="current" width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>noun_Wallet_4025943 Copy</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="Nav-Bar---Inventory" transform="translate(-25.000000, -308.000000)" fillRule="nonzero">
                <g id="noun_Wallet_4025943-Copy" transform="translate(25.000000, 308.000000)">
                    <path d="M1.75,0 C0.7891486,0 0,0.7891485 0,1.75 C0.0055545,5.129451 0,8.5963075 0,12 C0,13.0993515 0.9006486,14 2,14 L12,14 C13.099351,14 14,13.0993515 14,12 C14,9.5 14,7 14,4.5 C14,3.4006485 13.099351,2.5 12,2.5 L11.5,2.5 L11.5,1.25 C11.5,0.5655665 10.9344335,0 10.25,0 L1.75,0 Z M1.75,1 L10.25,1 C10.3939935,1 10.5,1.1060065 10.5,1.25 L10.5,2.5 L1.75,2.5 C1.3258514,2.5 1,2.1741485 1,1.75 C1,1.3258515 1.3258514,1 1.75,1 Z M1,3.3222655 C1.22858635,3.43248 1.48092285,3.5 1.75,3.5 L12,3.5 C12.562648,3.5 13,3.9373515 13,4.5 L13,6 L9,6 C7.9006485,6 7,6.9006485 7,8 C7,9.0993515 7.9006485,10 9,10 L13,10 L13,12 C13,12.5626485 12.562648,13 12,13 L2,13 C1.4373514,13 1,12.5626485 1,12 L1,3.3222655 Z M9,7 L13,7 L13,9 L9,9 C8.4373515,9 8,8.5626485 8,8 C8,7.4373515 8.4373515,7 9,7 Z" id="Shape" />
                </g>
            </g>
        </g>
    </svg>
);

const WalletIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Wallet({ ...innerProps, ...props });
    }}
    />
);

export default WalletIcon;
