export const DetailsToShowKeysIntl: Record<string, (string | undefined)[]> = {
    primaryDetails: [
        'load_type',
        'weight',
        'num_pieces',
        'description',
        'length',
        'movement_type',
        'is_risk_surcharge_applicable',
        'width',
        'declared_value',
        'against_bond_lut',
        'height',
        'booking_service_type_id',
        'courier_partner_reference_number',
        'meis',
        'product_code',
        'is_battery',
        'freight_cost',
        'consignor_iec_number',
        'given_weight',
        'given_length',
        'given_height',
        'given_width',
        'document_list',
    ],
    consigneeDetails: [
        'destination_name',
        'destination_type',
        'destination_address_line_1',
        'destination_address_line_2',
        'destination_city',
        'destination_state',
        'destination_pincode',
        'destination_country',
        'destination_phone',
        'receiver_vat_number',
    ],
    consignorDetails: [
        'sender_name',
        'consignor_tax_id',
        'consignor_company_name',
        'consignor_pan',
        'sender_phone',
        'consignor_kyc_doc_type',
        'consignor_gstin_number',
        'consignor_kyc_doc_number',
        'total_gst_paid_amount',
        'consignor_kyc_front_image',
        'total_gst_paid_currency',
        'consignor_kyc_back_image',
        'origin_type',
        undefined,
        'sender_address_line_1',
        undefined,
        'sender_address_line_2',
        undefined,
        'sender_country',
        undefined,
        'sender_state',
        undefined,
        'sender_city',
        undefined,
        'sender_pincode',
        undefined,
        'sender_bank_account_number',
        undefined,
        'sender_bank_ad_code',
        undefined,
        'sender_bank_ifsc_code',
        undefined,
    ],
    otherDetails: [
        'billing_address_line_1',
        'currency_code',
        'billing_address_line_2',
        'billing_city',
        'billing_state',
        'international_detail_shipment_purpose',
        'billing_country',
        'ecom_shipment',
        'cod_favor_of',
        'export_invoice_date',
        'cod_amount',
        'invoice_number',
        'fob_value',
        'invoice_date',
        'nefi_flag',
        'invoice_type',
        'delivery_terms',
        'inco_terms',
        'cod_mode',
        'price_details',
        'sales_order_number',
    ],
    itemDetails: [
        'item_value',
        'piece_number',
        'item_description',
        'sku_number',
        'gst_value',
        'hsn_code',
        'gst_percentage',
        'duty_value',
        'cess_value',
        'product_dead_weight',
        'ate_number',
        'product_type',
        'composition',
        'product_url',
        'product_height',
        'rodtep_scheme',
        'product_length',
        'origin_of_goods',
        'product_width',
    ],
    pieceDetails: [
        'length',
        'number_pieces',
        'reference_number',
        'width',
        'volumetric_weight',
        'piece_number',
        'height',
        'description',
        'declared_value',
        'weight',
        'number_of_items',
        'status',
    ],
};

export const DetailsToShowKeysDomestic: Record<string, (string | undefined)[]> = {
    primaryDetails: [
        'load_type',
        'weight',
        'num_pieces',
        'description',
        'length',
        'movement_type',
        'is_risk_surcharge_applicable',
        'width',
        'declared_value',
        'height',
        'service_type',
        'product_code',
        'transport_mode',
        'courier_partner',
        'courier_partner_reference_number',
        'given_weight',
        'given_length',
        'given_height',
        'given_width',
        'document_list',
    ],
    consigneeDetails: [
        'destination_name',
        'destination_address_line_1',
        'destination_address_line_2',
        'destination_city',
        'destination_state',
        'destination_pincode',
        'destination_country',
        'destination_phone',
    ],
    consignorDetails: [
        'sender_name',
        'sender_phone',
        'sender_address_line_1',
        'sender_address_line_2',
        'sender_country',
        'sender_state',
        'sender_city',
        'sender_pincode',
    ],
    otherDetails: [
        'currency_code',
        'cod_favor_of',
        'cod_amount',
        'cod_mode',
        'price_details',
        'sales_order_number',
    ],
    pieceDetails: [
        'length',
        'number_pieces',
        'reference_number',
        'width',
        'volumetric_weight',
        'piece_number',
        'height',
        'description',
        'declared_value',
        'weight',
        'quantity',
        'status',
    ],
    itemDetails: [],
};
