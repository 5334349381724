/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const HappyColored = (props: any) => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Happy</title>
        <defs>
            <polygon id="path-1" points="0 0 24.00004 0 24.00004 24 0 24" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-138.000000, -88.000000)">
                <g id="Group-14" transform="translate(138.000000, 88.000000)">
                    <g id="Group-3">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g id="Clip-2" />
                        <path d="M24.00004,12.8135814 C24.00004,19.9743133 19.1607519,24 12.00002,24 C4.83928807,24 0,19.9743133 0,12.8135814 C0,5.38164897 4.57256762,-4.00000667e-05 12.00002,-4.00000667e-05 C19.4274724,-4.00000667e-05 24.00004,5.38164897 24.00004,12.8135814" id="Fill-1" fill="#FED132" mask="url(#mask-2)" />
                    </g>
                    <path d="M18.8209114,13.0206457 C16.7434679,12.2571244 14.1841036,12.002644 11.99914,11.999044 C9.80017633,11.992564 7.22825205,12.2600844 5.17744863,13.0206457 C4.30216717,13.3456062 4.6776878,14.1804076 4.82088803,14.5804083 C5.89536983,17.5821733 8.64601441,19.7464169 11.99914,19.7420236 C15.1315452,19.7230569 17.97619,17.9074538 19.3392322,14.1327276 C19.5016325,13.6829668 19.2704321,13.185846 18.8209114,13.0206457" id="Fill-4" fill="#802D21" />
                    <path d="M18.0915582,13.6216787 C16.4425154,13.0964778 14.2506718,12.7963173 11.972828,12.7963173 C9.65378409,12.7963173 7.50706051,13.0953578 5.85625776,13.6366387 C5.95057792,14.3103999 6.71265919,14.7963173 11.972828,14.7963173 C17.2718768,14.7963173 18.006358,14.3032798 18.0915582,13.6216787" id="Fill-6" fill="#FFFFFF" />
                    <path d="M15.1339932,18.1542463 C14.6712725,17.2504048 12.7885493,16.6910438 11.984068,17.5948053 C11.1796666,16.6910438 9.29694349,17.2504048 8.83422272,18.1542463 C10.773426,19.202488 13.18679,19.206728 15.1339932,18.1542463" id="Fill-8" fill="#F55151" />
                    <path d="M9.54581591,8.63075038 C9.21725536,8.23194972 8.29237382,6.88314747 7.44525241,6.98450764 C6.37541063,7.04842775 5.55052925,8.30306984 5.25844876,8.70307051 C5.01700836,9.03371106 4.49396749,8.84347074 4.51620753,8.43779006 C4.60020767,6.91122752 5.78188964,5.60738535 7.28949215,5.39682499 C8.90653484,5.29322482 10.1038968,6.72754721 10.2656571,8.2817098 C10.3178172,8.78251064 9.78661631,8.92307087 9.54581591,8.63075038" id="Fill-10" fill="#802D21" />
                    <path d="M14.401984,8.63075038 C14.7305446,8.23194972 15.6554261,6.88314747 16.5025475,6.98450764 C17.5723893,7.04842775 18.3972707,8.30306984 18.6893511,8.70307051 C18.9307916,9.03371106 19.4538324,8.84347074 19.4315124,8.43779006 C19.3475922,6.91122752 18.1659103,5.60738535 16.6583078,5.39682499 C15.0412651,5.29322482 13.8439031,6.72754721 13.6821428,8.2817098 C13.6299827,8.78251064 14.1611836,8.92307087 14.401984,8.63075038" id="Fill-12" fill="#802D21" />
                </g>
            </g>
        </g>
    </svg>
);

const Happy = (props: any) => (
    <Icon component={(innerProps: any) => {
        return HappyColored({ ...innerProps, ...props });
    }}
    />
);

export default Happy;
