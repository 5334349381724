import {
    IFRAME_SET_VISIBILITY,
    IFRAME_SET_DIMENSIONS,
    IFRAME_SET_LOADED,
    IFRAME_SET_RENDERED,
} from './redux-constants';
import { IFrameApp, IframeApps } from '../components/persistent-iframe/persistent-iframe.constants';

const getDefaultDimensions = () => ({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
});

const objectHasOwnProperty = (obj: any, key: string): boolean => ({}).hasOwnProperty.call(obj, key);

const getDefaultState = () => {
    const defaultState: Record<string, any> = {};
    // By default all iframes are hidden
    Object.keys(IframeApps).forEach((value: string) => {
        if (objectHasOwnProperty(IframeApps, value)) {
            defaultState[IframeApps[value as IFrameApp]] = {
                visibility: false,
                dimensions: getDefaultDimensions(),
                isLoaded: false,
                isRendered: false,
            };
        }
    });
    return defaultState;
};

const persistentIframeReducer = (state: any = getDefaultState(), action: any) => {
    const { visibility, appName } = action;
    switch (action.type) {
        case IFRAME_SET_VISIBILITY: {
        // Reset dimensions if visibility is false
            const dimensionToSet = visibility ? state[appName].dimensions : getDefaultDimensions();
            return {
                ...state,
                [appName]: {
                    ...state[appName],
                    visibility,
                    dimensions: dimensionToSet,
                },
            };
        }

        case IFRAME_SET_DIMENSIONS: {
            return {
                ...state,
                [action.appName]: {
                    ...state[action.appName],
                    dimensions: action.dimensions,
                },
            };
        }

        case IFRAME_SET_LOADED: {
            return {
                ...state,
                [action.appName]: {
                    ...state[action.appName],
                    isLoaded: action.isLoaded,
                },
            };
        }

        case IFRAME_SET_RENDERED: {
            return {
                ...state,
                [action.appName]: {
                    ...state[action.appName],
                    isRendered: action.isRendered,
                },
            };
        }

        default: return state;
    }
};

export default persistentIframeReducer;
