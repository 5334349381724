import * as React from 'react';
import withStyles from 'react-jss';
import {
    Row,
    Col,
    Divider,
    Table,
    Typography,
} from 'antd';
import { stylesOrderPreview } from './styles';
import { OrderPreviewProps } from './types';
import { previewStructure, columnsMap } from './utils';

const { Title } = Typography;

const OrderPreview = (props: OrderPreviewProps) => {
    const {
        classes,
        data,
    } = props;

    const generateColumnForTable = (key: any) => {
        const columnData: any = [];
        if (data[key] == null || data[key] === 0) {
            return columnData;
        }
        const indexList = columnsMap[key] || [];
        indexList.forEach((item: any) => {
            const itemToPush = {
                title: item.pretty_name,
                dataIndex: item.key,
                key: item.key,
                width: item.width,
                render: (text: any) => {
                    return text;
                },
            };
            columnData.push(itemToPush);
        });

        return columnData;
    };

    const renderTableData = (field: any) => {
        return (
            <div style={{ width: field.width || '100%' }}>
                <Table
                    dataSource={data[field.key]}
                    columns={generateColumnForTable(field.key)}
                    pagination={false}
                    rowKey={(record: any) => record.row_num}
                    scroll={{ y: '100px' }}
                />
            </div>
        );
    };

    const renderFieldValue = (field: any) => {
        switch (field.type) {
            case 'table':
                return renderTableData(field);
            default:
                return data[field.key] || '---';
        }
    };

    const renderFields = (fields: any) => {
        return fields.map((field: any) => (
            <Col span={field.span || 12} className={classes.orderGrid}>
                <div className={classes.orderGridLabel}>{field.label}</div>
                <div className={classes.orderGridValue}>
                    {renderFieldValue(field)}
                </div>
            </Col>
        ));
    };

    const renderDetails = () => {
        return previewStructure.map((item: any, index: any) => {
            return (
                <>
                    <Row gutter={16} className={classes.orderGridTitle}>
                        <Col span={24}>
                            <Title
                                level={4}
                                className={classes.sectionTitle}
                            >
                                {item.title}
                            </Title>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {renderFields(item.fields)}
                    </Row>
                    {index < previewStructure.length - 1
                        ? (<Divider className={classes.divider} />) : null}
                </>
            );
        });
    };

    return (
        <div className={classes.formSegment}>
            {renderDetails()}
        </div>
    );
};

export default withStyles(stylesOrderPreview)(OrderPreview);
