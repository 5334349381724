export type PageSelected = 'columns' | 'filters';

type SettingOption = {
    key: PageSelected;
    val: string;
};

export const settingOptions: SettingOption[] = [
    {
        key: 'columns',
        val: 'Columns',
    }, {
        key: 'filters',
        val: 'Filters',
    },
];
