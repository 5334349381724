import Cross from '../../assets/cross';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import Helper from '../../library/Helper';
import { asnBulkUploadStyles } from './asn-bulk-upload.styles';
import { UploadFile } from 'antd/lib/upload/interface';
import { downloadSample, createBulkAsn } from '../../network/asn.api';
import FailureArrayHandler from '../common/FailureArrayHandler';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

interface AsnBulkUploadProps extends StylesProps<ReturnType<typeof asnBulkUploadStyles >> {
    onClose: () => void;
    loadAsn: () => void;
    uiTheme: ThemeType;
}


const AsnBulkUpload = (props: AsnBulkUploadProps) => {
    const {
        classes,
        onClose,
        loadAsn,
        uiTheme,
    } = props;

    const [asnArray, setAsn] = React.useState<any>([]);
    const [uploading, setUploading] = React.useState<boolean>(false);
    const [excelErrorModalVisible, setExcelErrorModalVisible] = React.useState<boolean>(false);
    const [failureArray, setFailureArray] = React.useState<any[]>([]);
    const [fileList, setFileList] = React.useState([] as UploadFile[]);

    const handleCreate = async () => {
        setUploading(true);
        const response = await createBulkAsn({
            asn_list: asnArray,
        });
        if (response?.isSuccess) {
            if (response?.data?.failures?.length) {
                setExcelErrorModalVisible(true);
                setFailureArray(response.data.failures);
            } else {
                message.success('Excel uploaded successfully');
                onClose();
            }
            loadAsn();
        } else {
            message.error(response.errorMessage);
        }
        setUploading(false);
    };

    const handleErrorModalClose = () => {
        setExcelErrorModalVisible(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButtonDisabled;
        if (asnArray.length) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                loading={uploading}
                className={btnClass}
                disabled={!asnArray.length}
                style={{
                    border: `1px solid ${!asnArray?.length ? '#EDEDED'
                        : uiTheme.primaryColor}`,
                }}
                onClick={handleCreate}
            >
                Submit
            </Button>
        );
    };


    const mapping: Record<string, string> = {
        'ASN No': 'asn_number',
        'ASN Date': 'asn_date',
        'Vendor Code': 'vendor_code',
        'Service Type': 'service_type',
        'Destination Location Code': 'destination_location_code',
        'Allocation Tag': 'allocation_tag',
        'Number of Handling Units': 'num_of_hu',
    };

    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };

    const getAsnArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const newData: any[] = slicedData.map((item: any) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = mapping[keyUpdate];
                returnObj[keyUpdate] = item[key];
            });
            return returnObj;
        });
        return newData;
    };

    const onFileRecieved = async (file: any) => {
        const asnArr: any = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
        const data = getAsnArray(asnArr[0]);
        setAsn(data);
    };

    const handleUploadChange = (info: UploadChangeParam) => {
        const files = info.fileList;
        const filesToShow = files.length ? files.slice(-1) : [];
        setFileList(filesToShow);
        const { status } = info.file;
        if (status === 'done') {
            onFileRecieved(info.file.originFileObj);
        }
    };

    const uploadProps = {
        accept: '.xlsx, .xls, .csv',
        name: 'file',
        multiple: false,
        customRequest,
    };

    const renderUpload = () => {
        return (
            <div
                className={classes.uploadBox}
            >
                <Dragger
                    fileList={fileList}
                    onChange={handleUploadChange}
                    {...uploadProps}
                >
                    <p className={classes.uploadText}>
                        <UploadOutlined className={classes.closeIcon} />
                        Upload excel file to add ASN
                    </p>
                    <p className={classes.uploadHint}>
                        Drag and drop to upload or
                        <Button type="link">Browse</Button>
                        to choose file
                    </p>
                </Dragger>
            </div>
        );
    };


    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        Upload ASN
                    </span>
                </div>
                {renderButton()}
            </div>
        );
    };

    const handleDownload = async () => {
        const fileBuffer = await downloadSample({});
        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'sample-asn-upload.csv',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }
    };

    const renderDownload = () => {
        return (
            <Button
                type="link"
                className={classes.downloadBtn}
                onClick={handleDownload}
            >
                <DownloadOutlined />
                Download Sample File
            </Button>
        );
    };

    const renderExcelErrorModal = () => {
        if (!excelErrorModalVisible) {
            return null;
        }
        return (
            <FailureArrayHandler
                onModalClose={handleErrorModalClose}
                failureArray={failureArray}
                isVisible={excelErrorModalVisible}
            />
        );
    };

    return (
        <>
            <Drawer
                visible
                width="35%"
                title={renderHeader()}
                onClose={() => onClose()}
                className={classes.main}
                closable={false}
            >
                {renderUpload()}
                {renderDownload()}
            </Drawer>
            {renderExcelErrorModal()}
        </>

    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(asnBulkUploadStyles)(GenericHoc(hocConfig)(AsnBulkUpload));
