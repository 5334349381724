import * as React from 'react';
import Cross from '../../assets/cross';
import { Button, Drawer, message } from 'antd';
import withStyles from 'react-jss';
import { commonStyleSheet } from '../../library/common-styles';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import TextArea from 'antd/lib/input/TextArea';
import { setConsignmentNotes } from 'network/consignments.api';

const styles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 16,
            width: '100%',
            zIndex: 10,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-btn-link': {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            fontSize: 12,
            color: theme.primaryColor,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
    },
    submit: {
        ...commonStyleSheet(theme).flexRow,
    },
    heading: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        fontSize: 16,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    body: {
        padding: 16,
    },
});
interface EditNotesProps extends StylesProps<ReturnType<typeof styles>> {
    onClose: () => void;
    loadconsignments: () => void;
    referenceNumber: any;
    currentNote: any;
}

const EditNotesComponent = (props: EditNotesProps) => {
    const {
        classes,
        onClose,
        referenceNumber,
        loadconsignments,
        currentNote,
    } = props;

    const [notes, setNotes] = React.useState<string>(currentNote);
    const [creating, setCreating] = React.useState<boolean>(false);

    const onSubmit = async () => {
        setCreating(true);
        const response = await setConsignmentNotes({
            referenceNumber,
            notes,
        });
        if (response.isSuccess) {
            loadconsignments();
            onClose();
        } else {
            message.error(response.errorMessage);
        }
        setCreating(false);
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.heading}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        Edit Note
                    </span>
                </div>
                <Button
                    className={classes.submit}
                    type="primary"
                    onClick={() => onSubmit()}
                    loading={creating}
                >
                    Submit
                </Button>
            </div>
        );
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                <TextArea
                    rows={3}
                    cols={3}
                    placeholder="Write here"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="30%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderBody()}
        </Drawer>
    );
};

const EditNotes = withStyles(styles)(EditNotesComponent);
export default EditNotes;
