export const settingsStyles = {
    appModal: {
        zIndex: 500,
        background: '#fff',
        position: 'fixed',
        overflow: 'hidden',
    },
    customModalIframe: {
        border: 'none',
    },
};
