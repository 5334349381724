import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    message, Table, DatePicker, Tooltip, Button,
} from 'antd';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { getPrintHistory } from '../../network/print-history';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { ExcelColumn } from '../../types/master-data-types';
import Paginator from '../common/paginator';
import { printHistoryColumns } from './consignments.constants';
import { exportCSVFile } from './print-history.helper';
import GenericHoc from '../common/generic-hoc';
import { NAVBAR_HEIGHT } from 'library/globals';
import { useTranslation } from 'react-i18next';
import { NextOrPrev, Pagination } from '../../library/Types';

const styles = {
    main: {
        '& .ant-table-tbody > tr > td': {
            padding: '10px 0px',
        },
        '& .ant-picker': {
            width: 130,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    extraFilters: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    dateFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
};

interface PrintHistoryProps extends StylesProps<typeof styles> {
    uiTheme: ThemeType,
}

const PrintHistory = (props: PrintHistoryProps) => {
    const { classes, uiTheme } = props;
    const [prints, setPrints] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const { t } = useTranslation();
    const [dates, setDates] = React.useState({
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
    });
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [pagination, setPagination] = React.useState<Pagination>({
        nextOrPrev: 'first',
        currentPageNumber: 1,
        resultPerPage: 15,
        isNextPresent: false,
        lastUpdatedAt: null,
    });

    const loadData = async () => {
        setLoading(true);
        const response = await getPrintHistory({
            startDate: dates.startDate.format('YYYY-MM-DD'),
            endDate: dates.endDate.format('YYYY-MM-DD'),
            currentPage: pagination.currentPageNumber,
            nextOrPrev: pagination.nextOrPrev,
            resultPerPage: pagination.resultPerPage,
            lastUpdatedAt: pagination.lastUpdatedAt,
        });
        if (response.isSuccess) {
            setPrints(response?.data?.data || []);
            setPagination({
                ...pagination,
                currentPageNumber: response?.data?.currentPageNo,
                isNextPresent: response?.data?.nextPageExist,
            });
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadData();
    }, [dates, toggle]);

    const renderRefresh = () => {
        return (
            <Tooltip title={t('reload')}>
                <Button
                    size="small"
                    type="ghost"
                    style={{
                        color: uiTheme.primaryColor,
                        fontSize: 14,
                    }}
                    onClick={() => setToggle(!toggle)}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        let newUpdatedAt = null;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
            newUpdatedAt = null;
        } else if (nextOrPrev === 'next') {
            const lastPrint = prints[prints.length - 1];
            newUpdatedAt = lastPrint?.createdAt;
        } else {
            const lastPrint = prints[0];
            newUpdatedAt = lastPrint?.createdAt;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            lastUpdatedAt: newUpdatedAt,
            currentPageNumber: newPageNumber,
        };
        setPagination(newPagination);
        setToggle(!toggle);
    };

    const getDisabled = (current: Moment) => {
        return current && moment(current) >= moment();
    };

    const renderDateField = (key: 'startDate' | 'endDate') => {
        const name = key === 'startDate' ? 'From' : 'To';
        const inputName = t(`${name}_date`);
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {inputName}
                </span>
                <DatePicker
                    value={dates[key]}
                    disabledDate={(current) => getDisabled(current)}
                    placeholder={`Select ${inputName}`}
                    allowClear={false}
                    onChange={(value) => {
                        setDates({
                            ...dates,
                            [key]: moment(value),
                        });
                    }}
                />
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                <div className={classes.dateFilters}>
                    {renderDateField('startDate')}
                    {renderDateField('endDate')}
                    {renderRefresh()}
                </div>
                <Paginator
                    currentPageNumber={pagination.currentPageNumber}
                    isNextPresent={pagination.isNextPresent}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                    onFirstClick={() => handlePagination('first')}
                />
            </div>
        );
    };


    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const renderDownload = (text: any) => {
        if (text.length) {
            return (
                <a
                    className={classes.row}
                    href={text[0]}
                >
                    <DownloadOutlined />
                </a>
            );
        }
        return null;
    };

    const renderErrors = (text: any, row: any) => {
        if (text.length) {
            return (
                <div
                    className={classes.row}
                >
                    <DownloadOutlined
                        onClick={() => exportCSVFile(row.failureArray, `Errors_${row.csvId}`)}
                    />
                </div>
            );
        }
        return null;
    };

    const renderColumn = (text: any, column: ExcelColumn, row: any) => {
        if (column.column_id === 'urlArray') {
            return renderDownload(text);
        }
        if (column.column_id === 'downloadErrors') {
            return renderErrors(row.failureArray, row);
        }
        if (column.column_id === 'failureArray') {
            return renderText(text.length);
        }
        if (column.column_id === 'createdAt') {
            const date = moment(text).format('DD/MM/YYYY, hh:mm:ss');
            return renderText(date);
        }
        if (column.column_id === 'type') {
            switch (text) {
                case 'THIRD_PARTY_CONSIGNMENT_LABEL':
                    return renderText('Bulk Label');
                default:
                    return renderText(text);
            }
        }
        if (text === null) {
            return <div className={classes.cellNa}>—NA—</div>;
        }
        return renderText(text);
    };

    const getColumns = (): any[] => {
        const columns: any = printHistoryColumns.map((column: ExcelColumn) => {
            return {
                key: column.column_id,
                title: column.column_id === 'type' ? t('details') : t(column.column_id),
                dataIndex: column.column_id,
                ellipsis: true,
                bordered: false,
                width: column.width,
                render: (text: string, row: any) => renderColumn(text, column, row),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                loading={loading}
                pagination={false}
                rowKey={(row: any) => row.id}
                columns={getColumns()}
                dataSource={prints}
                className={classes.table}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                rowSelection={{}}
                scroll={{
                    y: `calc((100vh - ${NAVBAR_HEIGHT}px) - 160px)`,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderExtraFilters()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        master: state.master,
        uiTheme: state.uiTheme,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(PrintHistory);
