import Icon from '@ant-design/icons';
import * as React from 'react';
/* eslint-disable max-len */
const DetailsIconSvg = (props: any) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 30 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Add_Order-1" transform="translate(-31.000000, -168.000000)" stroke="#333333">
                <g id="Group-12" transform="translate(31.000000, 168.000000)">
                    <rect id="Rectangle-Copy-4" fill="#333333" x="0.5" y="0.5" width="29" height="6.5" rx="2" />
                    <rect id="Rectangle-Copy-2" x="0.5" y="9.75" width="29" height="6.5" rx="2" />
                    <rect id="Rectangle-Copy-3" x="0.5" y="19" width="29" height="6.5" rx="2" />
                </g>
            </g>
        </g>
    </svg>
);

const DetailsIcon = (props: any) => (
    <Icon component={DetailsIconSvg} {...props} />
);

export default DetailsIcon;
