import React, { useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
import {
    authenticateActivationCode,
    changePassword,
    // getPasswordPolicy,
} from '../../network/common.api';
import { StylesProps } from '../../theme/jss-types';
import {
    Button,
    Form,
    Input,
    message,
    Modal,
} from 'antd';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { createPasswordStyles } from './create-password.styles';
import { ReduxStore } from 'reducers/redux.types';
import { Master } from 'types/master-data-types';
import authHelper from 'auth/auth-helper';
import { CP_BASE_URL } from 'library/globals';
import { useTranslation } from 'react-i18next';
import PasswordRules from 'routing/PasswordRules';

interface CreatePasswordProps extends StylesProps<ReturnType<typeof createPasswordStyles>>, RouteChildrenProps {
    master: Master;
    passwordPolicy: any;
}

const CreatePasswordComponent = (props: CreatePasswordProps): React.ReactElement => {
    const {
        master,
        passwordPolicy,
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(true);
    const history = useHistory();
    const { classes } = props;
    const [disableButton, setDisableButton] = useState(false);
    const [isValidActivationCode, setIsValidActivationCode] = useState(false);
    const [username, setUsername] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [isUsingCustomerPortalV2, setIsUsingCustomerPortalV2] = useState(false);
    const [isPasswordSetSuccess, setIsPasswordSuccess] = useState(false);
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(false);
    const [isCorruptActivationCode, setIsCorruptActivationCode] = useState(false);
    const oldBaseURL = master?.config?.customer_portal_config?.old_cp_base_url;
    const [currPassword, setPassword] = useState<string>('');
    const [currConPassword, setConPassword] = useState<string>('');

    const { t } = useTranslation();

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values: { password: string; conPassword: string }) => {
        const { password, conPassword } = values;
        if (conPassword !== password) {
            message.error(t('password_not_match'));
            return;
        }
        setDisableButton(true);
        const body = {
            new_password: password,
            username,
            auth_token: authCode,
        };
        const result = await changePassword(body);

        // const res = await getPasswordPolicy();
        setDisableButton(false);
        if (!result?.isSuccess) {
            message.error(result?.errorMessage || t('error_message'));
        } else {
            setIsPasswordSuccess(true);
        }
    };

    const verifyActivationLink = async () => {
        const urlParams = new URLSearchParams(history.location.search);
        const params: any = urlParams.get('activationCode');
        if (!params) {
            return;
        }
        try {
            const loginInfo: any = atob(params).split(':');
            setUsername(loginInfo[1]);
            setAuthCode(loginInfo[2]);
            setIsUsingCustomerPortalV2(loginInfo[3] === 'true');
            const result = await authenticateActivationCode({
                username: loginInfo[1],
                authToken: loginInfo[2],
            });
            if (!result?.isSuccess) {
                setIsCorruptActivationCode(true);
            }
        } catch (e) {
            setIsCorruptActivationCode(true);
        }
        setIsValidActivationCode(true);
    };

    const renderPassword = () => {
        return (
            <div>
                <span className={classes.labelForInput}>
                    {t('password')}
                </span>
                <Form.Item
                    className={classes.passwordInput}
                    name="password"
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('required'),
                    }]}
                >
                    <Input.Password
                        className={classes.emailInput}
                        placeholder={t('password')}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderConfirmPassword = () => {
        return (
            <div>
                <span className={classes.labelForInput} style={{ width: 90 }}>
                    {t('comPassword')}
                </span>
                <Form.Item
                    className={classes.passwordInput}
                    name="conPassword"
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('required'),
                    }]}
                >
                    <Input.Password
                        className={classes.emailInput}
                        placeholder={t('confirm_password')}
                        onChange={(e) => {
                            setConPassword(e.target.value);
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderSubmitButton = () => {
        return (
            <Form.Item>
                <Button
                    className={classes.submitButton}
                    style={{ margin: '0 16px 16px 0' }}
                    type="primary"
                    htmlType="submit"
                    loading={disableButton}
                    disabled={!isPasswordCorrect}
                >
                    {t('set_password')}
                </Button>
            </Form.Item>
        );
    };

    const renderSetPassword = () => {
        return (
            <div className={classes.signInForm}>
                <div className={classes.welcomeMsg}>
                    {t('set_password')}
                </div>
                <Form
                    onFinish={onFinish}
                    wrapperCol={{ span: 20 }}
                    style={{ marginLeft: '15%' }}
                >
                    {renderPassword()}
                    {renderConfirmPassword()}
                    {renderSubmitButton()}
                    <PasswordRules
                        password_policy={passwordPolicy}
                        password={currPassword}
                        conPassword={currConPassword}
                        enableSubmit={() => setIsPasswordCorrect(true)}
                    />
                </Form>
            </div>
        );
    };

    const renderConfirmationModal = () => {
        return (
            <Modal
                centered
                closable={false}
                visible={isModalOpen}
                onOk={handleOk}
                footer={null}
                width={400}
                style={{ textAlign: 'center' }}
            >
                <div style={{ fontWeight: 900, fontSize: 14, margin: '10px 0' }}>{t('set_new_password')}</div>
                <p>{t('set_new_password_heading')}</p>
                <Button
                    type="primary"
                    onClick={verifyActivationLink}
                    disabled={disableButton}
                >
                    {t('set_password')}
                </Button>
            </Modal>
        );
    };

    const renderSuccessModal = () => {
        return (
            <Modal
                title={t('password_change_success_message')}
                centered
                closable={false}
                visible={isModalOpen}
                footer={null}
            >
                {t('check_mail_on_login')}
                or&nbsp;
                <Button
                    className={classes.clickHere}
                    type="link"
                    onClick={() => {
                        if (isUsingCustomerPortalV2) {
                            history.push('/login');
                            window.location.href = '/login';
                        } else if (oldBaseURL) {
                            window.location.href = oldBaseURL;
                        } else {
                            const orgId = authHelper.getOrganisationId();
                            window.location.href = CP_BASE_URL.replace('{organisationId}', orgId === '1' ? 'dtdc' : orgId);
                        }
                    }}
                >
                    {t('click_here')}
                </Button>
                &nbsp;to redirect
            </Modal>
        );
    };

    const renderInvalidActivationCode = () => {
        return (
            <Modal
                centered
                closable={false}
                visible={isModalOpen}
                footer={null}
            >
                <div style={{ fontWeight: 600, fontSize: 14, margin: '10px 0' }}>{t('invalid_activation_link')}</div>
            </Modal>
        );
    };

    if (isCorruptActivationCode) {
        return (
            <>
                <div className={classes.backgroundDesign} />
                { renderInvalidActivationCode() }
            </>
        );
    }

    if (isPasswordSetSuccess) {
        return (
            <>
                <div className={classes.backgroundDesign} />
                { renderSuccessModal() }
            </>
        );
    }

    return (
        <>
            { isValidActivationCode ? renderSetPassword() : renderConfirmationModal() }
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: createPasswordStyles,
    },
    connectRouter: true,
};

const CreatePassword = GenericHoc(hocConfig)(CreatePasswordComponent);
export default CreatePassword;
