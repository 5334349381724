import { ThemeType } from 'theme/jss-types';

export const loginStyles = (theme: ThemeType) => ({
    continueButton: {
        height: '48px',
        width: '100%',
        borderRadius: 12,
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: theme.primaryColor,
        '&:hover': {
            backgroundColor: theme.primaryColor,
        },
    },
    emailLabel: {
        '& .ant-form-item-explain': {
            height: '4px',
            minHeight: '4px',
            marginLeft: '4px',
            textAlign: 'left',
            fontFamily: 'Open Sans',
            fontSize: '10px',
            fontWeight: 600,
        },
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 600,
        marginBottom: '16px',
        width: '100%',
    },
    emailInput: {
        fontSize: '14px',
        textAlign: 'left',
        height: '42px',
        border: '1px solid #CCCCCC',
        borderRadius: '8px',
    },
    leftSide: {
        width: '48.75%',
    },
    rightSide: {
        width: '51.25%',
        display: 'flex',
        flexDirection: 'column',
    },
    formHeader: {
        textAlign: 'left',
        color: 'black',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 'bold',
        paddingBottom: '16px',
        visibility: 'hidden',
    },
    navInfo: {
        '@media screen and (max-width: 600px)': {
            display: 'none',
        },
        lineHeight: '2',
    },
    blueBar: {
        background: '#165594',
        '@media (min-width: 768px)': { display: 'none' },
        width: '100%',
        height: '80px',
        padding: '24px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    noLabelFields: {
        marginLeft: 0,
        '@media (min-width: 576px)': { marginLeft: '25%' },
    },
    responsivePadding: {
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    signUpForm: {
        width: '100%',
        padding: '22.66% 14.64% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    signInForm: {
        width: '100%',
        padding: '13.43% 14.64% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    signUpDetailsForm: {
        width: '100%',
        padding: '5.6% 9.69% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    signInWorkspaceForm: {
        width: '100%',
        padding: '28.65% 7.91% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    resetPasswordForm: {
        width: '100%',
        padding: '22.94% 6.44% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    forgotPasswordForm: {
        width: '100%',
        padding: '13.43% 14.64% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
        display: 'flex',
        flexDirection: 'column',
    },
    emailVerification: {
        width: '100%',
        padding: '31.9% 10.98% 0px 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    forgotPassDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
        '@media (min-width: 480px)': { width: '81%' },
    },
    workspaceHeader: {
        paddingBottom: '16px',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    forgotPassword: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer',
        letterSpacing: 0,
        textAlign: 'left',

    },
    carouselText: {
        padding: '20px 21.37%',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: 42,
        fontWeight: 600,
        textAlign: 'center',
    },
    smallText: {
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: 24,
        fontWeight: 600,
        textAlign: 'center',
    },
    carouselContent: {
        padding: '21.48% 0',
    },
    dots: {
        display: 'none',
    },
    logoStyle: {
        position: 'absolute',
        left: '24px',
        top: '24px',
        width: '30.08%',
    },
    carouselStyle: {
        '& .ant-carousel': {
            height: '100vh',
        },
    },
    carouselImage: {
        height: '100vh',
        width: '100%',
    },
    parentDiv: {
        display: 'flex',
        justifyContent: 'center',
    },
    welcomeMsg: {
        fontFamily: 'Open Sans',
        color: 'black',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: -0.09,
        textAlign: 'center',
    },
    labelForInput: {
        position: 'relative',
        display: 'block',
        top: 3,
        left: 20,
        height: 10,
        width: 50,
        backgroundColor: 'white',
        color: '#36393C',
        fontSize: 10,
        zIndex: 500,
    },
    otpNote: {
        width: '80%',
        marginLeft: '10%',
        padding: 16,
        bottom: 100,
        marginTop: 150,
        backgroundColor: '#FFF9EB',
        color: '#C9A835',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 12,
    },
});
