import * as React from 'react';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import withStyles from 'react-jss';
import { StylesProps } from 'theme/jss-types';
import { ServiceDocument } from 'library/Types';

const styles = {
    header: {
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    addText: {},
    modal: {
        color: '#262626',
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    docsList: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 14,
        margin: 6,
    },
    note: {
        fontStyle: 'italic',
        fontColor: 'skyblue',
        fontSize: 12,
    },
};

/* eslint-disable max-len */
const text = 'You will need to provide the mentioned additional mandatory documents in order to ship the selected contents. Please provide the completely filled up and signed documents at time of Pickup.';

interface WWSProps extends StylesProps<typeof styles> {
    onClose: () => void;
    mandatoryList?: ServiceDocument[];
    optionalList?: ServiceDocument[];
    notes?: string[];
}

const WWSPopup = (props: WWSProps) => {
    const {
        onClose,
        classes,
        mandatoryList,
        optionalList,
        notes,
    } = props;

    const renderDocument = (item: ServiceDocument, isOptional?: boolean) => {
        let documentName = item.document_name || 'Document';

        if (isOptional) {
            documentName = `${documentName}`;
        } else {
            documentName = `${documentName}`;
        }

        if (item.document_link) {
            return (
                <a
                    style={{ margin: 4 }}
                    rel="noreferrer"
                    title={documentName}
                    target="_blank"
                    href={item.document_link}
                >
                    <DownloadOutlined
                        style={{ marginRight: 2 }}
                    />
                    {documentName}
                </a>
            );
        }
        return (
            <span
                style={{ margin: 4 }}
                title={documentName}
            >
                <DownloadOutlined
                    style={{ marginRight: 2 }}
                />
                {documentName}
            </span>
        );
    };

    const renderDocs = () => {
        return (
            <div className={classes.modal}>
                <span
                    style={{
                        marginBottom: 16,
                    }}
                >
                    {text}
                </span>
                <span>
                    Download the templates from the links mentioned below:
                </span>
                <div className={classes.docsList}>
                    {(mandatoryList || []).map((item) => renderDocument(item))}
                    {(optionalList || []).map((item) => renderDocument(item, true))}
                </div>
                <span className={classes.note}>
                    <ul>

                        {(notes || []).map((note) => {
                            return (
                                <li>{note}</li>
                            );
                        })}
                    </ul>
                </span>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <InfoCircleFilled
                        style={{
                            color: '#EF4D18',
                            marginRight: 12,
                        }}
                    />
                    Required Documents
                </div>
            </div>
        );
    };

    return (
        <Modal
            visible
            onOk={() => onClose()}
            onCancel={() => onClose()}
            okText="Ok"
            width="525px"
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
            okButtonProps={{
                style: {
                    color: '#FFF',
                    borderRadius: 4,
                    marginLeft: 8,
                    textAlign: 'center',
                    fontSize: 12,
                    fontWeight: 600,
                    letterSpacing: 0,
                },
            }}
            title={renderHeader()}
        >
            {renderDocs()}
        </Modal>
    );
};

export default withStyles(styles)(WWSPopup);
