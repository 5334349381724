import * as React from 'react';
import { SEARCH_TIMEOUT } from '../library/constants';

const { useEffect, useState } = React;

export const useDebounce = (value: any, delay: number = SEARCH_TIMEOUT) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};
