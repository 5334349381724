import * as React from 'react';
import withStyles from 'react-jss';
import {
    SortableContainer,
    SortableElement,
    SortEnd,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { StylesProps } from '../../theme/jss-types';

const styles = {
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    sortableItemIcon: {
        marginRight: 10,
        color: '#595959',
        width: '10%',
    },
    sortableItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        '& .ant-switch': {
            height: 14,
            minWidth: 25,
            maxWidth: 25,
            borderRadius: 7,
            marginBottom: 7,
        },
        '& .ant-switch-handle': {
            borderRadius: 5,
            height: 10,
            width: 10,
        },
        '& .ant-switch-checked .ant-switch-handle': {
            left: 12,
        },
    },
    filterColumns: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    filterName: {
        color: '#111111',
        fontSize: 14,
        width: '80%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingRight: 10,
    },
    closeIcon: {
        marginLeft: 8,
        cursor: 'pointer',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
        padding: 6,
        borderRadius: 6,
        backgroundColor: 'rgba(7,128,254,0.15)',
        fontSize: 12,
        width: '60%',
        marginRight: '10%',
    },
    list: {
        overflow: 'auto',
        width: '100%',
        height: '73vh',
    },
};

interface IProps extends StylesProps<typeof styles> {
    selectedList: any[];
    ItemComponent: React.ComponentType<any>;
    updateSelection: (checked: any[]) => void;
}

const DragNSelect = (props: IProps) => {
    const {
        classes,
        selectedList,
        ItemComponent,
        updateSelection,
    } = props;

    const onSortEnd = (sortEnd: SortEnd) => {
        const result = arrayMoveImmutable(
            selectedList,
            sortEnd.oldIndex,
            sortEnd.newIndex,
        );
        if (sortEnd.oldIndex !== sortEnd.newIndex) {
            updateSelection(result.map((item) => (item.key)));
        }
    };

    const SortableItem = SortableElement((
        itemProps: { element: any, index: number },
    ) => (
        <ItemComponent
            {...itemProps}
        />
    ));

    const SortableList = SortableContainer(() => {
        return (
            <ul style={{ paddingInlineStart: 0 }}>
                {selectedList.map((element, idx) => (
                    <SortableItem
                        index={idx}
                        element={element}
                    />
                ))}
            </ul>
        );
    });

    return (
        <div className={classes.list}>
            <SortableList
                pressDelay={100}
                onSortEnd={onSortEnd}
            />
        </div>
    );
};

export default withStyles(styles)(DragNSelect);
