import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import { Col, Form, Radio } from 'antd';

const styles = () => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {}

const RetailTransactionField = (props: IProps) => {
    const { t } = useTranslation();
    const { classes, isCopApplicable } = props;
    const { RetailTransaction } = formFields;

    const retailTransactionTypeOptions = RetailTransaction.options;
    if (Array.isArray(retailTransactionTypeOptions) && retailTransactionTypeOptions.length > 0) {
        retailTransactionTypeOptions.forEach((item: any) => {
            /* eslint-disable no-param-reassign */
            item.label = t(item.value);
        });
    }
    if (isCopApplicable) {
        return (
            <Col span={12}>
                <div className={classes.referenceItem}>
                    <div className={classes.referenceTitle}>
                        {t(RetailTransaction.key)}
                    </div>
                    <Form.Item
                        name={RetailTransaction.key}
                        initialValue={RetailTransaction.defaultValue}
                        valuePropName={RetailTransaction.valuePropName}
                        className={classes.formItem}
                    >
                        <Radio.Group
                            options={retailTransactionTypeOptions}
                            defaultValue={RetailTransaction.defaultValue}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        />
                    </Form.Item>
                </div>
            </Col>
        );
    }
    return null;
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    return {
        isCopApplicable: parts_to_show.is_cop_applicable,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(RetailTransactionField);
