import { API_BASE_URL } from '../library/globals';
import { GET, POST } from './api-hander.service';
import {
    CREATE_SUPP_SERVICES,
    FETCH_SUPP_SERVICE_MASTER,
    FETCH_SUPP_SERVICES,
    UPDATE_SUPP_SERVICES,
} from './api.constants';

export const fetchSupplementaryServices = async (params: any) => {
    return GET(`${API_BASE_URL}${FETCH_SUPP_SERVICES}`, params);
};

export const fetchSupplementaryServiceMasterData = async () => {
    return GET(`${API_BASE_URL}${FETCH_SUPP_SERVICE_MASTER}`, {});
};

export const createSupplementaryRequest = async (params: any) => {
    return POST(`${API_BASE_URL}${CREATE_SUPP_SERVICES}`, params);
};

export const updateSupplementaryRequest = async (params: any) => {
    return POST(`${API_BASE_URL}${UPDATE_SUPP_SERVICES}`, params);
};
