import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { getAddresses } from '../network/pickup.api';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
} from './generic-action';

const bucket = Buckets.ADDRESSES;

export const loadAddresses = (
    searchString: string = '', parentCustomerId?: string,
) => async (dispatch: Dispatch<any>, store: () => ReduxStore) => {
    dispatch(startLoading(bucket));

    dispatch(setGenericData(bucket, []));

    const { generic } = store();
    const { ADDRESSES } = generic;
    const { pagination } = ADDRESSES;

    const response = await getAddresses({
        resultPerPage: pagination.resultPerPage,
        pageNumber: pagination.currentPageNumber,
        searchString,
        parentCustomerId,
    });
    const addressBookData: any[] = [];
    const currentPage = ((Number)(pagination.currentPageNumber) - 1);
    const recordsPerPage = (Number)(pagination.resultPerPage);
    const offset = currentPage * recordsPerPage;

    response?.data?.addressBook?.forEach((row: any, index: string) => {
        const address = {
            ...row,
            serialNumber: offset + index + 1,
        };
        addressBookData.push(address);
    });

    const data = {
        addressBookData,
        isNextPresent: response?.data?.isNextPresent,
    };

    if (response.isSuccess) {
        dispatch(setGenericData(bucket, data));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
