/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const BankIconUtil = (props: any) => (
    <svg version="1.1" width="26px" height="26px" viewBox="0 0 26.0 26.0" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <clipPath id="i0">
                <path d="M620,0 L620,756 L0,756 L0,0 L620,0 Z" />
            </clipPath>
            <clipPath id="i1">
                <path d="M25.983866,0 L25.983866,1.29988973 L0,1.29988973 L0,0 L25.983866,0 Z" />
            </clipPath>
            <clipPath id="i2">
                <path d="M23.8162224,0 L23.8162224,1.29988973 L0,1.29988973 L0,0 L23.8162224,0 Z" />
            </clipPath>
            <clipPath id="i3">
                <path d="M5.19671516,0 L5.19671516,1.29988973 L4.32890325,1.29988973 L4.32890325,9.68840932 L5.19671516,9.68840932 L5.19671516,10.9885312 L0,10.9885312 L0,9.68840932 L0.864445823,9.68840932 L0.864445823,1.29988973 L0,1.29988973 L0,0 L5.19671516,0 Z" />
            </clipPath>
            <clipPath id="i4">
                <path d="M11.907821,0 L23.815642,7.21851437 L23.8162224,8.51527016 L0,8.51527016 L0,7.21851437 L11.907821,0 Z" />
            </clipPath>
            <clipPath id="i5">
                <path d="M5.19671516,0 L5.19671516,1.29988973 L4.33226934,1.29988973 L4.33226934,9.68840932 L5.19671516,9.68840932 L5.19671516,10.9885312 L0,10.9885312 L0,9.68840932 L0.86781191,9.68840932 L0.86781191,1.29988973 L0,1.29988973 L0,0 L5.19671516,0 Z" />
            </clipPath>
            <clipPath id="i6">
                <path d="M5.19671516,0 L5.19671516,1.29988973 L4.33226934,1.29988973 L4.33226934,9.68840932 L5.19671516,9.68840932 L5.19671516,10.9885312 L0,10.9885312 L0,9.68840932 L0.86781191,9.68840932 L0.86781191,1.29988973 L0,1.29988973 L0,0 L5.19671516,0 Z" />
            </clipPath>
        </defs>
        <g transform="translate(-24.0 -427.0)">
            <g clipPath="url(#i0)">
                <g transform="translate(24.000000000000014 427.0)">
                    <g transform="translate(0.0 24.70011026834941)">
                        <g clipPath="url(#i1)">
                            <polygon points="0,0 25.983866,0 25.983866,1.29988973 0,1.29988973 0,0" stroke="none" fill="#000000" />
                        </g>
                    </g>
                    <g transform="translate(1.082370903441534 22.10358081955723)">
                        <g clipPath="url(#i2)">
                            <polygon points="0,0 23.8162224,0 23.8162224,1.29988973 0,1.29988973 0,0" stroke="none" fill="#000000" />
                        </g>
                    </g>
                    <g transform="translate(17.75477122665727 9.81515988910665)">
                        <g clipPath="url(#i3)">
                            <polygon points="0,0 5.19671516,0 5.19671516,10.9885312 0,10.9885312 0,0" stroke="none" fill="#000000" />
                        </g>
                    </g>
                    <g transform="translate(1.0823709034415359 0.0)">
                        <g clipPath="url(#i4)">
                            <polygon points="0,0 23.8162224,0 23.8162224,8.51527016 0,8.51527016 0,0" stroke="none" fill="#000000" />
                        </g>
                    </g>
                    <g transform="translate(10.39000620538485 9.81515988910665)">
                        <g clipPath="url(#i5)">
                            <polygon points="0,0 5.19671516,0 5.19671516,10.9885312 0,10.9885312 0,0" stroke="none" fill="#000000" />
                        </g>
                    </g>
                    <g transform="translate(3.0287233425149265 9.81515988910665)">
                        <g clipPath="url(#i6)">
                            <polygon points="0,0 5.19671516,0 5.19671516,10.9885312 0,10.9885312 0,0" stroke="none" fill="#000000" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const BankIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return BankIconUtil({ ...innerProps, ...props });
    }}
    />
);

export default BankIcon;
