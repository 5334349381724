/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line import/no-mutable-exports
let configureStore: any;

if (process.env.NODE_ENV === 'production') {
    configureStore = require('./configureStore.prod').default;
} else {
    configureStore = require('./configureStore.dev').default;
}
export default configureStore;
