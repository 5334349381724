/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const BulkCN = (props: any) => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Group 31</title>
        <g id="Uppdated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Create" transform="translate(-35.000000, -93.000000)">
                <g id="Group-31" transform="translate(36.000000, 94.000000)">
                    <polygon id="Fill-1" fill="#FFB74D" points="12.0404285 13.5873099 17.5237863 10.4172806 17.5237863 3.17011177 12.0404285 6.34134735" />
                    <polygon id="Fill-2" fill="#FFA726" points="5.46032929 3.17009272 11.0899091 0 17.5238054 3.17009272 12.0404476 6.3413283" />
                    <polygon id="Fill-3" fill="#FFCC80" points="12.0404285 13.5873099 5.46031024 10.4172806 5.46031024 3.17011177 12.0404285 6.34134735" />
                    <polyline id="Stroke-4" stroke="#000000" strokeWidth="0.634919795" strokeLinecap="round" strokeLinejoin="round" points="8.5004459 4.63524711 12.0404412 6.341341 12.0404412 13.5873035 5.46032294 10.4172743 5.46032294 3.17010542 7.35511408 4.08324755" />
                    <polygon id="Stroke-5" stroke="#000000" strokeWidth="0.634919795" strokeLinecap="round" strokeLinejoin="round" points="12.0404285 13.5873099 17.5237863 10.4172806 17.5237863 3.17011177 12.0404285 6.34134735" />
                    <polyline id="Stroke-6" stroke="#000000" strokeWidth="0.634919795" strokeLinecap="round" strokeLinejoin="round" points="8.54706806 4.65775503 12.0404603 6.34137274 17.5238181 3.17007367 11.0899218 4.44444091e-05 5.46034198 3.17007367 7.28084751 4.0474698" />
                    <polyline id="Stroke-8" stroke="#000000" strokeWidth="0.634919795" strokeLinecap="round" strokeLinejoin="round" points="8.27543032 1.58508446 14.7087551 4.75632003 14.7820884 8.37872986" />
                    <polygon id="Fill-9" fill="#FFB74D" points="6.58011828 23.936508 12.0634761 20.7664788 12.0634761 13.5193099 6.58011828 16.6905455" />
                    <polygon id="Fill-10" fill="#FFA726" points="1.90475939e-05 13.5192909 5.6295989 10.3491981 12.0634952 13.5192909 6.58013733 16.6905264" />
                    <polygon id="Fill-11" fill="#FFCC80" points="6.58011828 23.936508 0 20.7664788 0 13.5193099 6.58011828 16.6905455" />
                    <g id="Group-18" transform="translate(0.000013, 10.349243)" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.634919795">
                        <polyline id="Stroke-12" points="3.04012296 4.63520267 6.58011828 6.34129655 6.58011828 13.5872591 0 10.4172298 0 3.17006098 1.89479114 4.08320311" />
                        <polygon id="Stroke-14" points="6.58010558 13.5872654 12.0634634 10.4172362 12.0634634 3.17006733 6.58010558 6.3413029" />
                        <polyline id="Stroke-16" points="3.08674512 4.65771059 6.58013733 6.3413283 12.0634952 3.17002923 5.6295989 0 1.90475939e-05 3.17002923 1.82052458 4.04742536" />
                    </g>
                    <polyline id="Stroke-19" stroke="#000000" strokeWidth="0.634919795" strokeLinecap="round" strokeLinejoin="round" points="2.81512009 11.9342826 9.24844491 15.1055182 9.32177815 18.727928" />
                    <polygon id="Fill-20" fill="#FFB74D" points="18.5166168 24 23.9999746 20.8299708 23.9999746 13.5828019 18.5166168 16.7540375" />
                    <polygon id="Fill-21" fill="#FFA726" points="11.9365112 13.5827829 17.5660911 10.4126901 23.9999873 13.5827829 18.5166295 16.7540184" />
                    <polygon id="Fill-22" fill="#FFCC80" points="18.5166168 24 11.9364985 20.8299708 11.9364985 13.5828019 18.5166168 16.7540375" />
                    <g id="Group-29" transform="translate(11.936505, 10.412735)" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.634919795">
                        <polyline id="Stroke-23" points="3.04012296 4.63520267 6.58011828 6.34129655 6.58011828 13.5872591 0 10.4172298 0 3.17006098 1.89479114 4.08320311" />
                        <polygon id="Stroke-25" points="6.58011193 13.5872654 12.0634698 10.4172362 12.0634698 3.17006733 6.58011193 6.3413029" />
                        <polyline id="Stroke-27" points="3.08674512 4.65771059 6.58013733 6.3413283 12.0634952 3.17002923 5.6295989 0 1.90475939e-05 3.17002923 1.82052458 4.04742536" />
                    </g>
                    <polyline id="Stroke-30" stroke="#000000" strokeWidth="0.634919795" strokeLinecap="round" strokeLinejoin="round" points="14.7516122 11.9977746 21.1849371 15.1690102 21.2582703 18.79142" />
                </g>
            </g>
        </g>
    </svg>
);

const BulkCNIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return BulkCN({ ...innerProps, ...props });
    }}
    />
);

export default BulkCNIcon;
