/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Cross = (props: any) => (
    <svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>cross</title>
        <g id="New" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Add-Single-Consignment-2.2" transform="translate(-20.000000, -22.000000)">
                <g id="Group-56" transform="translate(16.000000, 18.000000)">
                    <rect id="Rectangle-36-Copy" x="0" y="0" width="24" height="24" />
                    <g id="close" transform="translate(4.429688, 4.453125)" fill="#333333" fillRule="nonzero">
                        <path d="M8.87578125,7.54453125 L14.8476563,1.57734375 C15.2085938,1.21640625 15.2085938,0.6328125 14.8476563,0.27421875 C14.4867188,-0.08671875 13.903125,-0.08671875 13.5445313,0.27421875 L7.5703125,6.2390625 L1.59609375,0.271875 C1.23515625,-0.0890625 0.6515625,-0.0890625 0.29296875,0.271875 C-0.06796875,0.6328125 -0.06796875,1.21640625 0.29296875,1.575 L6.26484375,7.5421875 L0.29296875,13.5117188 C-0.06796875,13.8726563 -0.06796875,14.45625 0.29296875,14.8148438 C0.4734375,14.9953125 0.71015625,15.084375 0.94453125,15.084375 C1.18125,15.084375 1.415625,14.9953125 1.59609375,14.8148438 L7.5703125,8.84765625 L13.5445313,14.8171875 C13.725,14.9976563 13.959375,15.0867187 14.1960938,15.0867187 C14.4328125,15.0867187 14.6671875,14.9976563 14.8476563,14.8171875 C15.2085938,14.45625 15.2085938,13.8726563 14.8476563,13.5140625 L8.87578125,7.54453125 Z" id="Shape" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const CrossIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Cross({ ...innerProps, ...props });
    }}
    />
);

export default CrossIcon;
