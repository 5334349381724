import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Form,
    FormInstance,
    InputNumber,
    Row,
} from 'antd';
import { getBoxLevelFieldFromMapping } from '../generic-sub-layout-renderer';
import { get, uniqueId } from 'lodash';
import { PieceDetails, formFields, itemFormFields } from 'components/create-consignment/create-modal.constants';
import { FormField } from 'components/create-consignment/create-consignment.types';
import ItemDetails from 'components/create-consignment/single-consignment-steps/item-details-new';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const BoxDetailsField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        form,
        params,
        boxDetailsLayout,
        mandatoryItemDetails,
        itemDetailsShow,
    } = props;
    const {
        ConsignmentCategory,
        ItemType,
        Against_Bond_LUT,
    } = formFields;
    const { countryList } = params;

    const PIECES_FORM_KEY = 'pieces';
    const pieces = Form.useWatch(PIECES_FORM_KEY, form);
    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);
    const [selectedItemData, setSelectedItemData] = React.useState({
        pieceIndex: -1,
        itemIndex: -1,
    });
    const [numberOfBoxesToAdd, setNumberOfBoxesToAdd] = React.useState(1);
    const showBoxDetails = consignmentCategory === 'international' && itemType === 'non-document';

    if (!showBoxDetails) return null;

    const addPiece = () => {
        const finalNumber = numberOfBoxesToAdd || 1;
        const newPieces = [...pieces];
        for (let i = 0; i < finalNumber; i += 1) {
            const id = uniqueId('prefixPieceId');
            newPieces.push({
                key: id,
                weightUnit: 'kg',
                dimensionsUnit: 'cm',
                isNewPiece: true,
            });
        }
        form.setFieldsValue({
            [PIECES_FORM_KEY]: newPieces,
        });
    };

    const removePiece = (index: number) => {
        const newPieces = [...pieces];
        newPieces.splice(index, 1);
        form.setFieldsValue({
            [PIECES_FORM_KEY]: newPieces,
        });
    };

    const renderAddMore = () => {
        return (
            <>
                {t('add_more_boxes')}
                <InputNumber
                    style={{ margin: '0 10px' }}
                    min={0}
                    max={10}
                    placeholder="Write here"
                    defaultValue={1}
                    value={numberOfBoxesToAdd}
                    onChange={(e) => {
                        if (e) setNumberOfBoxesToAdd(e);
                    }}
                />
                <Button
                    type="primary"
                    onClick={addPiece}
                >
                    {t('add_more_text')}
                </Button>
            </>
        );
    };

    const renderRemovePiece = (index: number) => {
        return (
            <Button danger type="link" onClick={() => removePiece(index)}>{t('remove')}</Button>
        );
    };

    const removeItem = (pieceIndex: number, itemIndex: number) => {
        const newPieces = [...pieces];
        const newItems = [...newPieces[pieceIndex].item_details];
        newItems.splice(itemIndex, 1);
        newPieces[pieceIndex].item_details = newItems;
        form.setFieldsValue({
            [PIECES_FORM_KEY]: newPieces,
        });
    };

    const renderItemDetail = (item: any, field: FormField) => {
        return (
            <span
                style={{
                    marginRight: 10,
                }}
            >
                <span style={{
                    color: '#262626',
                    fontWeight: 300,
                }}
                >
                    {t(field.key)}
                    &nbsp;
                    :
                    &nbsp;
                </span>
                <span style={{
                    color: '#111111',
                    fontWeight: 600,
                }}
                >
                    {item[field.key] || <span style={{ margin: '0 20px' }}>&nbsp;</span> }
                </span>
            </span>
        );
    };

    const addEditItem = (itemData: any, pieceIndex: number, itemIndex: number) => {
        const newPieces = [...pieces];
        const newItems = [...(newPieces[pieceIndex].item_details || [])];
        if (itemIndex === -1) {
            newItems.push(itemData);
        } else {
            newItems[itemIndex] = {
                ...itemData,
            };
        }
        newPieces[pieceIndex].item_details = newItems;
        form.setFieldsValue({
            [PIECES_FORM_KEY]: newPieces,
        });
    };

    const renderAddItemModel = () => {
        if (selectedItemData.pieceIndex > -1) {
            const selectedItem = selectedItemData.itemIndex > -1
                ? pieces[selectedItemData.pieceIndex].item_details[selectedItemData.itemIndex]
                : null;
            return (
                <ItemDetails
                    selectedItem={selectedItem}
                    onClose={(itemData?: any) => {
                        if (itemData) {
                            addEditItem(itemData, selectedItemData.pieceIndex, selectedItemData.itemIndex);
                        }
                        setSelectedItemData({
                            pieceIndex: -1,
                            itemIndex: -1,
                        });
                    }}
                    mandatoryItemDetails={
                        {
                            ...(mandatoryItemDetails || {}),
                            gstPercentage: form.getFieldValue(Against_Bond_LUT.key) === 'G',
                            gstValue: form.getFieldValue(Against_Bond_LUT.key) === 'G',
                        }
                    }
                    itemDetailsToShow={itemDetailsShow || {}}
                    countryList={countryList}
                />
            );
        }
        return <></>;
    };

    const renderItems = (pieceData: PieceDetails, pieceIndex: number) => {
        const items = get(pieceData, 'item_details', []);
        return (
            <>
                {
                    items.map((itemData: any, itemIndex: any) => {
                        return (
                            <div
                                key={itemData.key || uniqueId()}
                            >
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                    }}
                                >
                                    Item&nbsp;
                                    {itemIndex + 1}
                                    <Button
                                        onClick={() => removeItem(pieceIndex, itemIndex)}
                                        danger
                                        type="link"
                                        style={{
                                            fontWeight: '300',
                                            fontSize: 10,
                                        }}
                                    >
                                        {t('remove')}
                                    </Button>
                                </div>
                                {renderItemDetail(itemData, itemFormFields.HSNCode)}
                                {renderItemDetail(itemData, itemFormFields.Item_Rate)}
                                {renderItemDetail(itemData, itemFormFields.Gst_Value)}
                                &#62;&#62;
                                <Button
                                    onClick={() => {
                                        setSelectedItemData({
                                            pieceIndex,
                                            itemIndex,
                                        });
                                    }}
                                    type="link"
                                >
                                    {t('edit_view_more')}
                                </Button>
                            </div>
                        );
                    })
                }
            </>
        );
    };

    const renderAddItem = (pieceIndex: number) => {
        return (
            <Button
                onClick={() => {
                    setSelectedItemData({
                        pieceIndex,
                        itemIndex: -1,
                    });
                }}
                type="primary"
            >
                {t('add_item')}
            </Button>
        );
    };

    return (
        <Col span={24}>
            {
                Array.isArray(pieces) && pieces.length
                    ? pieces.map((pieceData, pieceIndex) => {
                        const boxForm = boxDetailsLayout.map(
                            (fieldKey: any) => getBoxLevelFieldFromMapping(fieldKey, form, {
                                ...params,
                                pieceIndex,
                            }),
                        );
                        return (
                            <div
                                key={(pieceData.key || uniqueId()) + (+new Date())}
                                style={{
                                    background: '#FAFAFA',
                                    padding: 4,
                                }}
                            >
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}
                                >
                                    Box&nbsp;
                                    {pieceIndex + 1}
                                    { Object.keys(pieces).length !== 1 ? renderRemovePiece(pieceIndex) : null}
                                </div>
                                <Row>
                                    {boxForm}
                                </Row>
                                {renderItems(pieceData, pieceIndex)}
                                {renderAddItem(pieceIndex)}
                            </div>
                        );
                    })
                    : <></>
            }
            {renderAddMore()}
            {renderAddItemModel()}
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.config?.customer_portal_config;
    const mandatoryItemDetails = customerPortalConfig?.mandatory_item_details;
    const itemDetailsShow = customerPortalConfig?.item_details_show;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const boxDetailsLayout = formSubLayout?.boxDetails || [];
    return {
        mandatoryItemDetails,
        itemDetailsShow,
        boxDetailsLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(BoxDetailsField);
