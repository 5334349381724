import { ProductInput } from './settings.types';

export const productFields: Record<string, ProductInput> = {
    code: {
        key: 'code',
        pretty_name: 'code',
        placeholder: 'code',
        required: true,
        errormsg: 'Enter Code.',
    },
    name: {
        key: 'name',
        pretty_name: 'product_name',
        placeholder: 'product_name',
        required: true,
        errormsg: 'Enter Name.',
    },
    description: {
        key: 'description',
        pretty_name: 'product_description',
        placeholder: 'product_description',
        required: true,
        errormsg: 'Enter Description.',
    },
    length: {
        key: 'length',
        pretty_name: 'product_length_in_cms',
        placeholder: 'product_length',
        required: true,
        errormsg: 'Enter Length.',
    },
    height: {
        key: 'height',
        pretty_name: 'product_height_in_cms',
        placeholder: 'product_height',
        required: true,
        errormsg: 'Enter Height.',
    },
    width: {
        key: 'width',
        pretty_name: 'product_width_in_cms',
        placeholder: 'product_width',
        required: true,
        errormsg: 'Enter Width.',
    },
    weight: {
        key: 'weight',
        pretty_name: 'product_weight',
        placeholder: 'product_weight',
        required: true,
        errormsg: 'Enter Weight.',
    },
    declaredValue: {
        key: 'declaredValue',
        pretty_name: 'declaredValue',
        placeholder: 'declaredValue',
        required: true,
        errormsg: 'Enter Declared Value.',
    },
};
