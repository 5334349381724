import { CP_BASE_URL, ANALYTICS_FRONTEND_BASE_URL, API_DOC_FRONTEND_BASE_URL } from '../../library/globals';
import AuthHelper from '../../auth/auth-helper';

interface IAppMetadataInfo {
    iframeId: string;
    origin: string;
    url: string;
    passCredentials?: boolean;
    basePath: string;
}

interface IAppMetaData {
    [x: string]: IAppMetadataInfo;
}
export const API_PLAYGROUND_BASE_PATH = '/apidocs';
export enum IframeApps {
    CustomerPortal = 'CustomerPortal',
    Analytics = 'GENERIC_ANALYTICS',
    APIPlayground = 'API_PLAYGROUND',
}

export type IFrameApp = 'CustomerPortal';

const authHeaders = AuthHelper.getAuthenticationHeaders();
export const AppMetadata: IAppMetaData = {
    [IframeApps.CustomerPortal]: {
        iframeId: 'persistent-iframe-customer-portal',
        origin: CP_BASE_URL,
        url: `${CP_BASE_URL}/dashboard#!/home/settings/inside_react_app`,
        basePath: '',
    },
    [IframeApps.Analytics]: {
        iframeId: 'persistent-iframe-generic-analytics',
        origin: `https://${authHeaders['organisation-id']}.${ANALYTICS_FRONTEND_BASE_URL}`,
        url: `https://${authHeaders['organisation-id']}.${ANALYTICS_FRONTEND_BASE_URL}`,
        basePath: '/analytics',
        passCredentials: true,
    },
    [IframeApps.APIPlayground]: {
        iframeId: 'persistent-iframe-generic-api-playground',
        origin: API_DOC_FRONTEND_BASE_URL,
        url: API_DOC_FRONTEND_BASE_URL,
        basePath: '/api-playground',
        passCredentials: true,
    },
};

export enum IncomingActions {
    Loaded = 'LOADED',
    ROUTING = 'ROUTING',
}

export enum OutgoingActions {
    Routing = 'ROUTING',
    CREDENTIALS_SHARING = 'CREDENTIALS_SHARING',
}
