import { AUTH_RSA_PUBLIC_KEY } from './globals';
import lodash from 'lodash';

const keyEnum = {
    authentication: AUTH_RSA_PUBLIC_KEY,
};

const generateArrayBufferFromRSA = (rsaKey: string) => {
    const replacedKey = rsaKey?.replace('-----BEGIN PUBLIC KEY-----', '')
        ?.replace('-----END PUBLIC KEY-----', '')?.replace(/\s+|\n\r|\n|\r$/gm, '');
    const binaryString = window.atob(replacedKey);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

const getPublicRSAKey = async (purpose: string) => {
    try {
        const key = lodash.get(keyEnum, purpose) || null;

        if (!key) {
            return null;
        }

        const arrayBufferKey = generateArrayBufferFromRSA(key);

        const publicKey = await window.crypto.subtle.importKey(
            'spki', arrayBufferKey,
            {
                name: 'RSA-OAEP',
                hash: { name: 'SHA-256' },
            },
            false,
            ['encrypt'],
        );

        return publicKey;
    } catch (err) {
        return null;
    }
};

const encryptRSA = async (purpose:string, password: string) => {
    try {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const publicKey = await getPublicRSAKey(purpose);

        if (!publicKey) {
            return null;
        }

        const encrypted = await window.crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP',
            },
            publicKey,
            data,
        );

        const encryptedArray = Array.from(new Uint8Array(encrypted));
        return window.btoa(String.fromCharCode.apply(null, encryptedArray));
    } catch (err) {
        return null;
    }
};

const hexStringToArrayBuffer = (hexString: string) => {
    const bytes = new Uint8Array(Math.ceil(hexString.length / 2));
    for (let i = 0; i < bytes.length; i += 1) {
        bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
    }
    return bytes.buffer;
};

const collectBrowserParameters = () => {
    return `${navigator.userAgent}|${
        window.screen.height}x${window.screen.width}|${
        navigator.language}`;
};

const generateSessionAESKey = async () => {
    // Collect browser-specific parameters (excluding the date)
    const parameters = collectBrowserParameters();

    // Use the SubtleCrypto API to hash these parameters
    const encoder = new TextEncoder();
    const data = encoder.encode(parameters);
    const hash = await crypto.subtle.digest('SHA-256', data); // Using SHA-256 for the hash

    // Convert the hash (ArrayBuffer) into a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
};

const encryptWithSessionKey = async (text: string) => {
    const sessionKeyHex = await generateSessionAESKey();
    const sessionKeyBuffer = hexStringToArrayBuffer(sessionKeyHex);

    // Import the session key to use with Web Crypto API
    const key = await crypto.subtle.importKey(
        'raw',
        sessionKeyBuffer,
        { name: 'AES-GCM' },
        false, // whether the key is extractable (i.e., can be used in exportKey)
        ['encrypt'],
    );

    const encoder = new TextEncoder();
    const encodedText = encoder.encode(text); // Convert the text to a Uint8Array of UTF-8 bytes
    const iv = hexStringToArrayBuffer(sessionKeyHex.slice(0, 16));
    const encryptedData = await crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv, // Initialization vector, should be unique and random for each encryption
        },
        key, // The crypto key
        encodedText, // The data to encrypt
    );

    // Convert the encrypted data to a Base64 string
    const encryptedDataArray: any = new Uint8Array(encryptedData);
    const binaryString = String.fromCharCode.apply(null, encryptedDataArray);
    const base64String = btoa(binaryString);

    return base64String; // Return encrypted data as Base64 string
};

const decryptWithSessionKey = async (base64EncryptedText: string) => {
    const sessionKeyHex = await generateSessionAESKey();
    const sessionKeyBuffer = hexStringToArrayBuffer(sessionKeyHex);

    // Import the session key to use with Web Crypto API for decryption
    const key = await crypto.subtle.importKey(
        'raw',
        sessionKeyBuffer,
        { name: 'AES-GCM' },
        false, // whether the key is extractable
        ['decrypt'], // only allow decrypt operation
    );

    const iv = hexStringToArrayBuffer(sessionKeyHex.slice(0, 16));

    // Convert the Base64 encrypted text back to a Uint8Array
    const encryptedDataArray = Uint8Array.from(atob(base64EncryptedText), (c) => c.charCodeAt(0));

    // Decrypt the data
    const decryptedData = await crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv,
        },
        key,
        encryptedDataArray,
    );

    // Convert the decrypted data back to text
    const decoder = new TextDecoder();
    return decoder.decode(decryptedData);
};


export const CryptoUtils = {
    encryptRSA,
    encryptWithSessionKey,
    decryptWithSessionKey,
};
