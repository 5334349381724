import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import {
    Button,
    message,
    Modal,
} from 'antd';
import { Header } from './settings.types';
import { resetHeaders, resetPieceHeaders } from 'network/settings-product';
import { useTranslation } from 'react-i18next';

const styles = (theme: ThemeType) => ({
    modal: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
            borderRadius: '4px 4px 0 0',
            padding: '16px 24px',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: 24,
        },
        '& .ant-btn-primary': {
            fontSize: 12,
            fontWeight: 600,
        },
    },
    row: {
        display: 'flex',
        width: '100%',
        marginTop: 12,
        marginBottom: 12,
        alignItems: 'center',
    },
    btnRow: {
        display: 'flex',
        width: '70%',
        marginLeft: '28%',
        marginTop: 12,
        marginBottom: 12,
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 16,
        fontWeight: 'bold',
        color: theme.primaryColor,
    },
    buttons: {
        display: 'flex',
    },
    column: {
        width: '48%',
        fontSize: 14,
        color: theme.primaryColor,
        fontWeight: 'bold',
    },
    values: {
        width: '100%',
        overflow: 'scroll',
        maxHeight: '55vh',
    },
    valuerow: {
        display: 'flex',
        width: '100%',
        marginTop: 6,
        color: '#4a4a4a',
    },
    valuecolumn: {
        width: '48%',
        fontSize: 14,
    },
    position: {
        width: '48%',
        fontSize: 13,
    },
});
interface ResetHeadersProps extends StylesProps<ReturnType<typeof styles>> {
    headers: Header[];
    onClose: (reload?: boolean) => void;
    pieceHeaders?: boolean;
}
const ResetHeaders = (props: ResetHeadersProps) => {
    const {
        classes,
        onClose,
        headers,
        pieceHeaders,
    } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState<boolean>(false);

    const renderColumns = () => {
        return (
            <div className={classes.row}>
                <div className={classes.column}>{t('header_name')}</div>
                <div className={classes.column}>{t('column')}</div>
            </div>
        );
    };

    const renderValues = () => {
        return (
            <div className={classes.values}>
                {headers.map((header: Header) => {
                    return (
                        <div className={classes.valuerow}>
                            <div className={classes.valuecolumn}>
                                {header.name}
                                &nbsp;
                                {header.mandatory && '*'}
                            </div>
                            <div className={classes.position}>{header.positionAlpha}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const updateHeaders = async () => {
        setSaving(true);
        const response = pieceHeaders ? await resetPieceHeaders() : await resetHeaders();
        setSaving(false);
        if (response.isSuccess) {
            message.success(t('reset_mapping_success'));
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
    };

    const renderFooter = () => {
        return (
            <div className={classes.btnRow}>
                <span>{t('restore_mapping_message')}</span>
                <div className={classes.buttons}>
                    <Button
                        type="primary"
                        loading={saving}
                        onClick={() => updateHeaders()}
                    >
                        {t('retailTransactionTrue').toUpperCase()}
                    </Button>
                    <Button
                        onClick={() => onClose()}
                    >
                        {t('retailTransactionFalse').toUpperCase()}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <Modal
            className={classes.modal}
            closable
            visible
            title={t('default_header_mapping').toUpperCase()}
            width="70%"
            footer={renderFooter()}
            onCancel={() => onClose()}
        >
            {renderColumns()}
            {renderValues()}
        </Modal>
    );
};

export default withStyles(styles)(ResetHeaders);
