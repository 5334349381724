/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Home = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.10555 4.31836L5.47176 0.821289C5.20418 0.579102 4.79695 0.579102 4.52938 0.821289L0.894611 4.31836C0.75008 4.44824 0.739337 4.66992 0.86922 4.81445C0.999103 4.95898 1.22176 4.96973 1.36531 4.83984L5.00008 1.34277L8.63485 4.83984C8.77938 4.96973 9.00106 4.95898 9.13094 4.81445C9.26082 4.66992 9.25008 4.44824 9.10555 4.31836ZM8.13485 5.61523C7.94051 5.61523 7.78328 5.77246 7.78328 5.9668V8.67188H6.3868V6.32812C6.3868 5.93945 6.07235 5.625 5.68367 5.625H4.31649C3.92781 5.625 3.61336 5.93945 3.61336 6.32812V8.67188H2.21688V5.9668C2.21688 5.77246 2.05965 5.61523 1.86531 5.61523C1.67098 5.61523 1.51375 5.77246 1.51375 5.9668V8.67188C1.51375 9.06055 1.8282 9.375 2.21688 9.375H7.78328C8.17195 9.375 8.48641 9.06055 8.48641 8.67188V5.9668C8.48641 5.77246 8.32918 5.61523 8.13485 5.61523ZM5.68367 8.67188V6.32812H4.31649V8.67188H5.68367Z" fill="#333333" />
    </svg>
);

const HomeIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Home({ ...innerProps, ...props });
    }}
    />
);

export default HomeIcon;

