import * as React from 'react';
import withStyles from 'react-jss';
import {
    Row, Col, message, Button, Input, Spin, Form, Tooltip,
} from 'antd';
import { useGenericState } from '../../hooks/use-generic-state';
import { stylesAddNewLocation } from './styles';
import { AddNewLocationProps } from './types';
import {
    saveAddress,
    editAddress,
} from '../../network/pickup.api';
import MapLocationCapture from './MapLocationCapture';
import { EnvironmentOutlined } from '@ant-design/icons';

const AddNewLocation = (props: AddNewLocationProps) => {
    const [form] = Form.useForm();
    const {
        onClose,
        editCustomerDetails,
        isEditable,
        classes,
        // mandatoryFields,
        // phoneRegex,
    } = props;

    const initialState: any = {
        isLoading: false,
        data: {},
        customerData: {},
        hideAddButton: false,
        showMapForLocation: false,
    };

    const [state, setState] = useGenericState(initialState);

    const {
        isLoading,
        hideAddButton,
        showMapForLocation,
    } = state;

    const {
        setFieldsValue,
        validateFields,
        // getFieldsValue,
        getFieldValue,
    } = form;

    React.useEffect(() => {
        if (isEditable) {
            setFieldsValue({
                customer_name: editCustomerDetails.name,
                address_line_1: editCustomerDetails.addressLine1,
                phone_number: editCustomerDetails.phone,
                address_line_2: editCustomerDetails.addressLine2,
                latitude: editCustomerDetails.latitude,
                longitude: editCustomerDetails.longitude,
                address_line_3: editCustomerDetails.address_line_3,
                pincode: editCustomerDetails.pincode,
            });
        }
    }, []);

    const lat = getFieldValue('latitude');
    const lng = getFieldValue('longitude');
    const searchText = getFieldValue('address_line_1') || getFieldValue('address_line_2');
    const locationObj = lat && lng
        ? { lat: parseFloat(lat), lng: parseFloat(lng) }
        : null;

    const setCustomerDataHandler = async (data: any) => {
        const body = {
            name: data.customer_name,
            addressLine1: data.address_line_1,
            addressLine2: data.address_line_2,
            pincode: data.pincode,
            phone: data.phone_number,
            latitude: data.latitude,
            longitude: data.longitude,
            address_line_3: data.address_line_3,
        };
        const response = await saveAddress(body);
        if (response.isSuccess) {
            message.success('Customer Data Added');
            // eslint-disable-next-line no-param-reassign
            // body.code = body.customer_name;
            setState({ hideAddButton: true });
            if (onClose) onClose(body);
        } else {
            message.error(response.errorMessage);
        }
    };

    const updateCustomerDataHandler = async (data: any) => {
        const body = {
            id: editCustomerDetails?.id,
            name: data.customer_name,
            addressLine1: data.address_line_1,
            addressLine2: data.address_line_2,
            pincode: data.pincode,
            phone: data.phone_number,
            latitude: data.latitude,
            longitude: data.longitude,
            address_line_3: data.address_line_3,
        };
        const response = await editAddress(body);
        if (response.isSuccess) {
            message.success('Customer Data Updated');
            setFieldsValue({
                customer_name: '',
                address_line_1: '',
                phone_number: '',
                address_line_2: '',
                latitude: '',
                longitude: '',
                address_line_3: '',
                pincode: '',
            });
            if (onClose) onClose(body);
        } else {
            message.error(response.errorMessage);
        }
    };

    const onNumberChange = (e: any) => {
        const invalidChars = ['e', '-', '+'];

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const onLatLongChange = (e: any) => {
        const invalidChars = ['e', '+'];

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleSubmit = async () => {
        setState({ isLoading: true });

        validateFields().then(async (formValues: any) => {
            if (isEditable) {
                await updateCustomerDataHandler(formValues);
            } else {
                await setCustomerDataHandler(formValues);
            }
            setState({ isLoading: false });
        }).catch(() => {
            message.error('Please fill the required fields!');
            setState({ isLoading: false });
        });
    };

    const handleMapSubmit = (val: any) => {
        setState({ showMapForLocation: false });
        setFieldsValue({ latitude: val.lat, longitude: val.lng, address_line_1: val.geocodedAddress });
    };

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Name"
                            name="customer_name"
                            initialValue={isEditable ? editCustomerDetails.code : null}
                            rules={[
                                {
                                    required: true,
                                    // mandatoryFields.includes('customer_name'),
                                    message: 'Please Enter the Name',
                                },
                            ]}
                        >
                            <Input
                                disabled={hideAddButton}
                                placeholder="Please enter user name"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Address Line 1"
                            name="address_line_1"
                            initialValue={isEditable
                                ? editCustomerDetails.address_line_1
                                : null}
                            rules={[
                                {
                                    required: true,
                                    // mandatoryFields.includes('address_line_1'),
                                    message: 'Please Enter Destination Address Line 1',
                                },
                            ]}
                        >
                            <Input
                                disabled={hideAddButton}
                                placeholder="Destination Address Line 1"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Address Line 2"
                            name="address_line_2"
                            initialValue={isEditable
                                ? editCustomerDetails.address_line_2
                                : null}
                            rules={[
                                {
                                    required: false,
                                    // mandatoryFields.includes('address_line_2'),
                                    message: 'Please Enter Destination Address Line 2',
                                },
                            ]}
                        >
                            <Input
                                disabled={hideAddButton}
                                placeholder="Destination Address Line 2"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Nearest Landmark"
                            name="address_line_3"
                            initialValue={isEditable
                                ? editCustomerDetails.address_line_3
                                : null}
                            rules={[
                                {
                                    required: false,
                                    // mandatoryFields.includes('address_line_3'),
                                    message: 'Please Enter Landmark',
                                },
                            ]}
                        >
                            <Input
                                disabled={hideAddButton}
                                placeholder="Nearest Landmark"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Zip Code"
                            name="pincode"
                            initialValue={isEditable
                                ? editCustomerDetails.pincode
                                : null}
                            rules={[
                                {
                                    required: false,
                                    // mandatoryFields.includes('pincode'),
                                    message: 'Please Enter Pin Code',
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                disabled={hideAddButton}
                                onKeyDown={onNumberChange}
                                placeholder="Zip Code"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Latitude"
                            name="latitude"
                            initialValue={isEditable
                                ? editCustomerDetails.latitude
                                : null}
                            rules={[
                                {
                                    required: true,
                                    // mandatoryFields.includes('latitude'),
                                    message: 'Please Enter Latitude',
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                disabled={hideAddButton}
                                onKeyDown={onLatLongChange}
                                placeholder="Latitude"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Longitude"
                            name="longitude"
                            initialValue={isEditable
                                ? editCustomerDetails.longitude
                                : null}
                            rules={[
                                {
                                    required: true,
                                    // mandatoryFields.includes('longitude'),
                                    message: 'Please Enter Longitude',
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                disabled={hideAddButton}
                                onKeyDown={onLatLongChange}
                                placeholder="Longitude"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div
                            onClick={() => setState({ showMapForLocation: true })}
                            className={classes.blueText}
                        >
                            <Tooltip title="Select on Map">
                                <EnvironmentOutlined style={{ fontSize: '24px' }} />
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Phone Number"
                            name="phone_number"
                            initialValue={isEditable
                                ? editCustomerDetails.phone_number
                                : null}
                            rules={[
                                {
                                    required: true,
                                    // mandatoryFields.includes('phone_number'),
                                    message: 'Please Enter Valid Phone Number',
                                    // pattern: new RegExp(phoneRegex),
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                maxLength={10}
                                placeholder="Phone Number"
                                disabled={isEditable || hideAddButton}
                                onKeyDown={onNumberChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {!hideAddButton && (
                    <Row className={classes.addAddressButton}>
                        <Form.Item style={{ marginBottom: '0px' }}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {isLoading ? (
                                <Spin />
                            ) : !isEditable ? (
                                <Button type="primary" onClick={handleSubmit}>
                                    Add
                                </Button>
                            ) : (
                                <>
                                    <Button type="primary" onClick={handleSubmit}>
                                        Update
                                    </Button>
                                    {onClose ? (
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={onClose}
                                            style={{ marginLeft: '5px' }}
                                        >
                                            Cancel
                                        </Button>
                                    ) : null}
                                </>
                            )}
                        </Form.Item>
                    </Row>
                )}
            </Form>
            {showMapForLocation && (
                <MapLocationCapture
                    search={searchText}
                    isVisible={showMapForLocation}
                    onClose={() => setState({ showMapForLocation: false })}
                    onSubmit={(val: any) => handleMapSubmit(val)}
                    defaultLocation={locationObj}
                />
            )}
        </div>
    );
};

export default withStyles(stylesAddNewLocation)(AddNewLocation);
