/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Primary = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" {...props}>
        <title>
            Primary Details
        </title>
        <desc>Created with Sketch.</desc>
        <g id="Primary-Details" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-28-Copy-2" transform="translate(3.000000, 4.000000)" fill="#333333">
                <g id="Group-28-Copy">
                    <rect id="Rectangle" x="0" y="0" width="30" height="8" rx="2" />
                    <path d="M28,10 C29.1045695,10 30,10.8954305 30,12 L30,16 C30,17.1045695 29.1045695,18 28,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,12 C-1.3527075e-16,10.8954305 0.8954305,10 2,10 L28,10 Z M28,11 L2,11 C1.44771525,11 1,11.4477153 1,12 L1,12 L1,16 C1,16.5522847 1.44771525,17 2,17 L2,17 L28,17 C28.5522847,17 29,16.5522847 29,16 L29,16 L29,12 C29,11.4477153 28.5522847,11 28,11 L28,11 Z" id="Combined-Shape" />
                    <path d="M28,20 C29.1045695,20 30,20.8954305 30,22 L30,26 C30,27.1045695 29.1045695,28 28,28 L2,28 C0.8954305,28 1.3527075e-16,27.1045695 0,26 L0,22 C-1.3527075e-16,20.8954305 0.8954305,20 2,20 L28,20 Z M28,21 L2,21 C1.44771525,21 1,21.4477153 1,22 L1,22 L1,26 C1,26.5522847 1.44771525,27 2,27 L2,27 L28,27 C28.5522847,27 29,26.5522847 29,26 L29,26 L29,22 C29,21.4477153 28.5522847,21 28,21 L28,21 Z" id="Combined-Shape" />
                </g>
            </g>
        </g>
    </svg>
);

const PrimaryIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Primary({ ...innerProps, ...props });
    }}
    />
);

export default PrimaryIcon;
