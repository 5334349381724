import {
    IFRAME_SET_VISIBILITY,
    IFRAME_SET_DIMENSIONS,
    IFRAME_SET_LOADED,
    IFRAME_SET_RENDERED,
} from '../reducers/redux-constants';

export const setIframeVisibility = (appName: string, visibility: boolean) => {
    return {
        appName,
        visibility,
        type: IFRAME_SET_VISIBILITY,
    };
};

export const setIframeDimensions = (appName: string, dimensions: any) => {
    return {
        appName,
        dimensions,
        type: IFRAME_SET_DIMENSIONS,
    };
};

export const setIframeLoaded = (appName: string, isLoaded: boolean) => {
    return {
        appName,
        isLoaded,
        type: IFRAME_SET_LOADED,
    };
};

export const setIframeRendered = (appName: string, isRendered: boolean) => {
    return {
        appName,
        isRendered,
        type: IFRAME_SET_RENDERED,
    };
};
