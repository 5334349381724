/* eslint-disable max-len */
import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from 'theme/jss-types';

export const rateCalculatorStyles = (theme: ThemeType) => ({
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
            borderBottom: '1px solid #999999',
        },
        '& .ant-drawer-body': {
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
            borderColor: '#082E78',
        },
        maxHeight: '100vh',
        overflow: 'scroll',
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    boxTitle: {
        ...commonStyleSheet(theme).flexRow,
        fontSize: 16,
        color: '#333333',
        fontWeight: 'bold',
        width: '100%',
    },
    box: {
        ...commonStyleSheet(theme).flexRow,
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: '100%',
    },
    boxIcon: {
        width: 'auto',
        height: 21,
        fontSize: 16,
        color: '#333333',
        textAlign: 'left',
    },
    boxText: {
        margin: '0px 12px',
        display: 'inline',
        width: '40%',
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: '#CCCCCC',
    },
    fields: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        padding: 20,
    },
    name: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginBottom: 5,
    },
    numPiecesField: {
        ...commonStyleSheet(theme).flexColumn,
        margin: '0px 4px',
        width: '48%',
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            background: 'inherit',
        },
    },
    singleField: {
        ...commonStyleSheet(theme).flexColumn,
        margin: '0px 4px',
        width: '48%',
    },
    pincodeInput: {},
    dots: {
        boxSizing: 'border-box',
        height: 24,
        width: '5%',
        borderBottom: '1px dashed #979797',
    },
    numPieces: {
        width: '50%',
    },
    weight: {
        width: '100%',
    },
    pincodeValidation: {
        ...commonStyleSheet(theme).flexRow,
        color: '#52C14B',
        fontSize: 12,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        fontWeight: 600,
    },
    dimentionField: {
        ...commonStyleSheet(theme).flexColumn,
        margin: '0px 4px',
        width: '22%',
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            background: 'inherit',
        },
        '& .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            background: 'inherit',
        },
        '& .ant-input-selection-item': {
            color: '#333',
        },
    },
    unitField: {
        ...commonStyleSheet(theme).flexColumn,
        margin: '0px 4px',
        width: '22%',
        '& .ant-select-selector, .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            background: 'inherit',
        },
    },
    rateMsg: {
        color: '#111111',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0,
        margin: '20px 0px',
    },
    rateBody: {
        ...commonStyleSheet(theme).flexColumn,
        padding: 24,
    },
    rateCard: {
        borderRadius: 4,
        backgroundColor: '#FAFAFA',
        padding: '13px 24px',
        margin: '4px 0px',
    },
    serviceName: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        color: '#111111',
        display: 'flex',
        alignItems: 'center',
    },
    serviceRate: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
    },
    internationalFields: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        padding: '0px 20px',
        '& .ant-select-selector, .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            background: 'inherit',
        },
    },
    servicePeriod: {
        fontSize: 12,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        color: '#111111',
    },
    msg: {
        fontSize: 10,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        color: '#111111',
    },
});
