import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from 'theme/jss-types';
import { newProductStyles } from './new-product.styles';
import { Product } from './settings.types';
import {
    message,
    Modal,
} from 'antd';
import { deleteProduct } from 'network/settings-product';
import { useTranslation } from 'react-i18next';

interface DeleteProductProps extends StylesProps<ReturnType<typeof newProductStyles>> {
    product?: Product;
    onClose: (reload?: boolean) => void;
}
const DeleteProduct = (props: DeleteProductProps) => {
    const {
        classes,
        onClose,
        product,
    } = props;
    const { t } = useTranslation();
    const [saving, setSaving] = React.useState<boolean>(false);

    const handleSave = async () => {
        setSaving(true);
        let response;
        if (product?.id) {
            response = await deleteProduct({
                id: product.id,
            });
        }
        setSaving(false);
        if (response?.isSuccess) {
            message.success(t('delete_product_success'));
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
    };

    return (
        <Modal
            className={classes.modal}
            closable
            visible
            title={t('delete_product').toUpperCase()}
            width="50%"
            onCancel={() => onClose(false)}
            onOk={() => handleSave()}
            okButtonProps={{
                loading: saving,
            }}
            okText={t('yes').toUpperCase()}
        >
            Are you sure you want to delete this product code mapping?
        </Modal>
    );
};

export default withStyles(newProductStyles)(DeleteProduct);
