import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Select,
} from 'antd';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
}

const PudoStoreField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        showDeliveryType,
        form,
    } = props;
    const { pudoHubs, isRequired } = params;
    const { DeliveryType, PudoStores } = formFields;
    const deliveryType = Form.useWatch(DeliveryType.key, form);

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    if (!showDeliveryType) return <></>;
    if (deliveryType === 'HOME_DELIVERY') {
        return null;
    }
    const optionList = pudoHubs.map((item: any) => {
        return {
            value: item.code,
            label: item.code,
        };
    });

    return (
        <Col span={12}>
            <div className={classes.referenceItem}>
                <div className={classes.title}>
                    {t(PudoStores.key)}
                    {isRequired(false, PudoStores.key) ? '*' : null}
                </div>
                <Form.Item
                    name={PudoStores.key}
                    className={classes.contentType}
                    valuePropName={PudoStores.valuePropName}
                    rules={isRequired(false, PudoStores.key) ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={t(PudoStores.key)}
                        style={{ width: PudoStores.width }}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        options={optionList}
                        filterOption={(query, option: any) => {
                            return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                        }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    return {
        showDeliveryType: get(parts_to_show, 'show_delivery_type_in_customerportal', false),
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PudoStoreField);
