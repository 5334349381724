import { Drawer } from 'antd';
import { capitalize } from 'lodash';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import Cross from '../../assets/cross';

const styles = (theme: ThemeType) => ({
    main: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
        },
        '& .ant-modal-close-x': {
            color: '#FFF',
        },
    },
    title: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 24,
        backgroundColor: '#EFF4FC',
        width: '100%',
        padding: 8,
    },
    itemRow: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F9F9F9',
        padding: 8,
        marginTop: 6,
        width: '100%',
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 16,
        cursor: 'pointer',
    },
    referenceTitle: {
        fontSize: 10,
        color: '#333333',
        letterSpacing: 0,
        width: '20%',
    },
    reasonTitle: {
        fontSize: 10,
        color: '#333333',
        letterSpacing: 0,
        width: '40%',
    },
    messageTitle: {
        fontSize: 10,
        color: '#333333',
        letterSpacing: 0,
        width: '40%',
    },
    reference: {
        width: '20%',
        fontSize: 12,
        color: '#111111',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    reason: {
        width: '40%',
        fontSize: 12,
        color: '#111111',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    message: {
        fontSize: 12,
        color: '#111111',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        width: '40%',
    },
});

type Failure = {
    message: string;
    reason: string;
    reference_number: string;
    row?: string;
};

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    failureList: Failure[];
    referenceNumberTitle?: keyof Failure;
    onClose: () => void
}

const FailureList = (props: IProps) => {
    const {
        classes,
        failureList,
        referenceNumberTitle,
        onClose,
    } = props;

    const renderReferenceNumber = (item: Failure) => {
        return (
            <span className={classes.reference}>
                {item[referenceNumberTitle || 'reference_number']}
            </span>
        );
    };

    const renderReason = (item: Failure) => {
        return (
            <span className={classes.reason}>
                {item.reason}
            </span>
        );
    };

    const renderMessage = (item: Failure) => {
        return (
            <span className={classes.message}>
                {item.message}
            </span>
        );
    };

    const renderItem = (item: Failure) => {
        return (
            <div className={classes.itemRow}>
                {renderReferenceNumber(item)}
                {renderReason(item)}
                {renderMessage(item)}
            </div>
        );
    };

    const renderReferenceNumberTitle = () => {
        return (
            <span className={classes.referenceTitle}>
                { referenceNumberTitle ? capitalize(referenceNumberTitle) : 'CN No.'}
            </span>
        );
    };

    const renderReasonTitle = () => {
        return (
            <span className={classes.reasonTitle}>
                Reason
            </span>
        );
    };

    const renderMessageTitle = () => {
        return (
            <span className={classes.messageTitle}>
                Message
            </span>
        );
    };

    const renderColumns = () => {
        return (
            <div className={classes.row}>
                {renderReferenceNumberTitle()}
                {renderReasonTitle()}
                {renderMessageTitle()}
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross
                        onClick={() => onClose()}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>
                        OOPS !!
                    </span>
                </div>
            </div>
        );
    };

    const renderTitle = () => {
        return (
            <div className={classes.title}>
                Failures
            </div>
        );
    };

    return (
        <Drawer
            visible
            title={renderHeader()}
            className={classes.main}
            width="40%"
            onClose={() => onClose()}
            footer=""
            closable={false}
        >
            {renderTitle()}
            {renderColumns()}
            {failureList.map((item) => {
                return renderItem(item);
            })}
        </Drawer>
    );
};

export default withStyles(styles)(FailureList);
