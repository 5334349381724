export const getEmployeeStorage = () => {
    const storage = window.localStorage;
    const employeeAuthToken = storage.getItem('employeeAuthToken');
    const employeeCode = storage.getItem('employeeCode');
    const employeeId = storage.getItem('employeeId');
    return {
        employeeAuthToken,
        employeeCode,
        employeeId,
    };
};

export const isSuperUserEmployee = () => {
    const employee = getEmployeeStorage();

    return !!employee.employeeCode && employee.employeeCode !== 'undefined';
};

export const getCustomerCode = () => {
    const storage = window.localStorage;
    const customerCode = storage.getItem('userCode');
    return customerCode;
};
