/* eslint-disable max-len */
import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from '../../theme/jss-types';

export const additionalServicesStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        width: '100%',
        padding: '0px 8px',
        '& .ant-table-container table > thead > tr:first-child th:first-child': {
            padding: '8px 0px',
        },
        '& .ant-table-thead > tr > th': {
            padding: '8px 8px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
            paddingRight: 8,
            lineHeight: '12px',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 8px',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 130,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 12,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-select': {
            marginRight: 24,
        },
        '& .ant-picker-range-separator, .ant-picker-input': {
            margin: 0,
            padding: 0,
        },
        backgroundColor: theme.backgroundColor,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 200,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor} `,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
    },
    downloadBtn: {
        marginRight: 8,
    },
    newAddressBtn: {
        marginRight: 8,
    },
    table: {
        '& td': {
            verticalAlign: 'baseline',
            cursor: 'pointer',
            border: 'none',
        },
        '& .ant-table-thead': {
            whiteSpace: 'pre-line !important',
            backgroundColor: '#',
            border: '1px solid #E8E8E8',
            color: '#262626',
            padding: 0,
        },
        '& .ant-table-selection-column': {
            borderRight: 'none !important',
        },
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    modal: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
            borderRadius: '4px 4px 0 0',
            padding: '16px 24px',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: 24,
            marginTop: -30,
        },
        '& .ant-btn-primary': {
            fontSize: 12,
            fontWeight: 600,
        },
    },
    searchInput: {
        width: '100%',
        marginRight: '1%',
    },
    input: {
        width: '100%',
        border: '1px solid #999999',
    },
    referenceBox: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFBE9',
        padding: 16,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    detailedView: {
        borderRadius: 4,
        border: '1px solid #D9D9D9',
        background: '#FFF',
        width: '100%',
        padding: 16,
    },
    detailsTitle: {
        color: '#111',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 700, /* 100% */
    },
    detailLabel: {
        color: '#333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    detailValue: {
        color: '#111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 600,
    },
    flexRowDetails: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        flexWrap: 'wrap',
    },
});
