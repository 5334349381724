/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Reports = (props: any) => (
    <svg fill="current" width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Reports Icon</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="Nav-Bar---Reports" transform="translate(-25.000000, -145.000000)" fillRule="nonzero">
                <g id="report-icon" transform="translate(25.000000, 145.000000)">
                    <g transform="translate(1 1)">
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.25,0 L9.625,0 C9.98965,0 10.3393813,0.147497 10.5972625,0.410053 C10.8551437,0.672602 11,1.028699 11,1.4 L11,11.2 C11,11.57128 10.8551437,11.92737 10.5972625,12.18994 C10.3393813,12.45251 9.98965,12.6 9.625,12.6 L1.375,12.6 C1.01032937,12.6 0.66059125,12.45251 0.402730625,12.18994 C0.144863125,11.92737 0,11.57128 0,11.2 L0,1.4 C0,1.028699 0.144863125,0.672602 0.402730625,0.410053 C0.66059125,0.147497 1.01032937,0 1.375,0 L2.75,0" transform="translate(0 1.4)" />
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.8125,0 L0.6875,0 C0.3078075,0 0,0.313404 0,0.7 L0,2.1 C0,2.486596 0.3078075,2.8 0.6875,2.8 L4.8125,2.8 C5.19220625,2.8 5.5,2.486596 5.5,2.1 L5.5,0.7 C5.5,0.313404 5.19220625,0 4.8125,0 Z" transform="translate(2.75)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const ReportsIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Reports({ ...innerProps, ...props });
    }}
    />
);

export default ReportsIcon;
