import React, { useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { StylesProps } from '../../theme/jss-types';
import { loginStyles } from './sign-in.styles';
import {
    fetchCustomerEmails,
    sendForgotPasswordOTP,
    // sendResetPasswordMail,
    validateResetPasswordOtp,
} from '../../network/common.api';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import {
    Button,
    Form,
    Input,
    Select,
    message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getLoginRoute, getResetPasswordRoute } from 'routing/routing-helper';

interface LoginProps extends StylesProps<ReturnType<typeof loginStyles>>, RouteChildrenProps { }


const ChangePassword = (props: LoginProps): React.ReactElement => {
    const { classes, history } = props;
    const [disableButton, setDisableButton] = useState(false);
    const [otpVisible, setOtpVisible] = useState(false);
    const [usernameVisible, setUsernameVisible] = useState(true);
    const [emailVisible, setEmailVisible] = useState(false);
    const [emailList, setEmailList] = useState<string[]>([]);
    const [usercode, setUsername] = useState<any>({});
    const { t } = useTranslation();


    const renderUsername = () => {
        return (
            <div>
                <span className={classes.labelForInput}>
                    {t('username')}
                </span>
                <Form.Item
                    className={classes.emailLabel}
                    name="username"
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('username_required_message'),
                    }]}
                >
                    <Input
                        className={classes.emailInput}
                        placeholder={t('login_username_placeholder')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderOtp = () => {
        return (
            <div>
                <span className={classes.labelForInput}>
                    {t('otp')}
                </span>
                <Form.Item
                    className={classes.emailLabel}
                    name="otp"
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('required'),
                    }]}
                >
                    <Input
                        className={classes.emailInput}
                        placeholder={t('otp')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderEmail = () => {
        return (
            <div>
                <span
                    className={classes.labelForInput}
                    style={{ width: 65 }}
                >
                    {t('verify_email')}
                </span>
                <Form.Item
                    className={classes.emailLabel}
                    name="email"
                    colon={false}
                    rules={[
                        {
                            type: 'email',
                            message: t('invalid_email_message'),
                        },
                        {
                            required: true,
                            message: t('email_required_message'),
                        },
                    ]}
                >
                    <Input
                        className={classes.emailInput}
                        placeholder={t('email_placeholder')}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderSubmitButton = () => {
        return (
            <Form.Item
                wrapperCol={{ span: 20 }}
                style={{
                    textAlign: 'left',
                    marginBottom: '32px',
                }}
            >
                <Button
                    className={classes.continueButton}
                    style={{ margin: '0 16px 16px 0' }}
                    type="primary"
                    htmlType="submit"
                    loading={disableButton}
                >
                    {t('submit')}
                </Button>
            </Form.Item>
        );
    };

    const handleOtpFlow = async (values: { email: string; username: string, otp: string }) => {
        setDisableButton(true);
        if (!values.otp) {
            message.error(t('form_required_fields'));
            return;
        }
        const body = {
            username: usercode,
            otp: values.otp,
        };
        const res = await validateResetPasswordOtp(body);
        if (!res.isSuccess) {
            message.error(res?.errorMessage || t('error_message'));
        } else {
            message.success(t('otp_verification_success'));
            const token = res?.data?.resetCode;
            const redirectTo = `${getResetPasswordRoute()}?resetCode=${token}`;
            history.push(redirectTo);
        }
        setDisableButton(false);
    };

    const handleEmailFlow = async (values: { email: string; selectedEmail: string }) => {
        setDisableButton(true);
        const body = {
            username: usercode,
            email: values.email,
            selectedEmail: values.selectedEmail,
        };
        const result = await sendForgotPasswordOTP(body);
        if (!result.isSuccess) {
            message.error(result?.errorMessage || t('error_message'));
        } else if (result?.data?.verify_using_otp) {
            setOtpVisible(true);
            setEmailVisible(false);
            message.success(`${t('forgot_password_success_otp')} ${result?.data?.email || ''}`);
        } else {
            message.success(t('forgot_password_success_mail'));
            history.push(getLoginRoute());
        }
        setDisableButton(false);
    };

    const handleUsernameFlow = async (values: { username: string }) => {
        setDisableButton(true);

        const { username } = values;
        if (!username) {
            message.error(t('form_required_fields'));
            return;
        }
        const body = { username };
        const res = await fetchCustomerEmails(body);
        setUsername(username);
        if (!res.isSuccess) {
            message.error(res?.errorMessage || t('error_message'));
        } else {
            setEmailVisible(true);
            setUsernameVisible(false);
            setEmailList(res?.data?.email_list);
        }
        setDisableButton(false);
    };

    const onFinish = async (values: any) => {
        if (otpVisible) {
            return handleOtpFlow(values);
        }

        if (emailVisible) {
            return handleEmailFlow(values);
        }

        return handleUsernameFlow(values);
    };

    const renderOtpNote = () => {
        if (!otpVisible) {
            return null;
        }

        return (
            <div className={classes.otpNote}>
                If OTP is not received, please contact customer support helpdesk
            </div>
        );
    };

    const renderEmailNote = () => {
        if (!emailVisible) {
            return null;
        }

        return (
            <div className={classes.otpNote} style={{ marginTop: 100, textAlign: 'center' }}>
                In case you are unable to recognize the above emails, kindly contact your respective account manager
            </div>
        );
    };

    const renderEmailList = () => {
        return (
            <div>
                <span
                    className={classes.labelForInput}
                    style={{ width: 65 }}
                >
                    {t('select_email')}
                </span>
                <Form.Item
                    className={classes.emailLabel}
                    name="selectedEmail"
                    colon={false}
                    rules={[
                        {
                            type: 'email',
                            message: t('invalid_email_message'),
                        },
                        {
                            required: true,
                            message: t('email_required_message'),
                        },
                    ]}
                >
                    <Select
                        // className={classes.emailInput}
                        placeholder={t('select_email_placeholder')}
                    >
                        {emailList.map((email) => <Select.Option value={email} key={email}>{email}</Select.Option>)}
                    </Select>
                </Form.Item>
            </div>
        );
    };

    const renderForgotPasswordForm = () => {
        return (
            <Form
                wrapperCol={{ span: 20 }}
                onFinish={onFinish}
                style={{ marginLeft: '15%' }}
            >
                {usernameVisible && renderUsername()}
                {emailVisible && renderEmailList()}
                {emailVisible && renderEmail()}
                {otpVisible && renderOtp()}
                {renderSubmitButton()}
            </Form>
        );
    };
    return (
        <div className={classes.forgotPasswordForm}>
            <div
                className={classes.welcomeMsg}
            >
                {t('forgot_password')}
            </div>
            <div
                className={[classes.formHeader, classes.workspaceHeader].join(' ')}
            >
                {t('forgot_password')}
            </div>
            {renderForgotPasswordForm()}
            {renderEmailNote()}
            {renderOtpNote()}
        </div>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: loginStyles,
    },
    connectRouter: true,
};

const ForgotPassword = GenericHoc(hocConfig)(ChangePassword);
export default ForgotPassword;
