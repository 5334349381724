import lodash from 'lodash';

export const snakeToPretty = (inp: string) => {
    const pretty = lodash.startCase(inp);
    return pretty;
};

export const uniqBy = (input: any[], key: string) => {
    return lodash.uniqBy(input, key);
};

export const uniq = (input: any[]) => {
    return lodash.uniq(input);
};
