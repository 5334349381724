import { ThemeType } from 'theme/jss-types';

export const stylesOrderCreationForm = {
    sideDrawer: {
        '& .ant-drawer-wrapper-body': {
            height: '100%',
            overflow: 'hidden !important',
        },
        '& .ant-drawer-close': {
            fontSize: '20px',
            padding: 'inherit',
        },
        '& .ant-drawer-header-title, .ant-drawer-title': {
            width: '100%',
        },
        '& .ant-drawer-body': {
            padding: '0px',
            overflow: 'hidden !important',
        },
    },
    icons: {
        fontSize: '20px',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: '15px',
        marginBottom: '5px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerLeft: {
        display: 'flex',
    },
    headerIcon: {
        margin: '0px 5px',
        cursor: 'pointer',
        fontSize: '21px',
        fontWeight: 'bold',
        marginRight: '21px',
    },
    orderNumber: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 600,
        marginRight: '10px',
    },
    locationPanel: {
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
    },
    orderNumberInput: {
        width: '80%',
    },
    locationPickPanel: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    rowCustomStyle: {},
    submitButton: {
        zIndex: 100,
    },
    customFormItemLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    selectStyle: {
        minWidth: 160,
        maxWidth: 180,
    },
    pickupDetailRow: {
        display: 'flex',
        flexFlow: 'nowrap',
    },
    formSteps: {
        background: '#FFF8E7',
        padding: '20px',
        position: 'sticky',
        top: '0px',
        zIndex: '100',
    },
    formSegment: {
        padding: '20px 20px',
    },
    sectionTitle: {
        marginTop: '16px',
    },
    divider: {
        '& .ant-divider-horizontal': {
            margin: '0px 0px 24px 0px !important',
        },
    },
    hubSelectorContainer: {
        '& .ant-select': {
            maxWidth: '100% !important',
        },
    },
    pickupDetailsContainer: {
        marginBottom: '24px',
    },
    radioOptions: {
        fontWeight: 'bold',
    },
    serviceCheckbox: {
        marginLeft: '0px !important',
        display: 'flex',
        width: '90%',
        color: '#111111',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        padding: 12,
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
        '& .ant-checkbox + span': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    serviceItem: {
        width: '25%',
        flexDirection: 'row',
        marginRight: 20,
        fontSize: 12,
    },
    boxFields: {
        height: '200px',
        overflow: 'auto',
        marginBottom: '24px',
    },
    formItem: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    piecesFields: {
        display: 'flex',
    },
    formItemWeight: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-input-wrapper': {
            width: 250,
        },
        '& .ant-form-item': {
            height: '30px',
            marginBottom: '0px !important',
        },
    },
    addMoreBoxContainer: {
        margin: '10px 0px',
    },
    contentType: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 250,
    },
    detailsContainer: {
        height: '600px',
        overflow: 'scroll',
    },
};

export const stylesLocationList = (theme:ThemeType) => ({
    sideDrawer: {
        '& .ant-drawer-wrapper-body': {
            height: '90%',
            overflow: 'hidden !important',
        },
    },
    locationList: {
        height: '250px',
        overflow: 'scroll',
        '& .ant-list-split .ant-list-item': {
            borderBottom: '0px',
        },
    },
    locationCheckbox: {
        marginRight: '5px',
    },
    locationListItem: {
        display: 'flex',
        alignItems: 'flex-start',
        background: '#fcfcfc',
        padding: '16px',
        marginBottom: '8px',
    },
    loactionSearchBar: {
        width: '200px',
        marginBottom: '5px',
    },
    spinnerContainer: {
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    editIcon: {
        fontSize: '15px',
        '&:hover': {
            color: theme.primaryColor,
            fontSize: '20px',
        },
    },
    deleteIcon: {
        fontSize: '15px',
        marginLeft: '5px',
        '&:hover': {
            color: 'red',
            fontSize: '20px',
        },
    },
    divider: {
        '& .ant-divider-horizontal': {
            margin: '0px 0px 24px 0px !important',
        },
    },
    otherDetails: {
        color: '#000000d9',
        textAlign: 'center',
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
});

export const stylesAddNewLocation = {
    sideDrawer: {
        '& .ant-drawer-wrapper-body': {
            height: '90%',
            overflow: 'hidden !important',
        },
    },
    heading: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    addAddressButton: {
        justifyContent: 'center',
    },
    blueText: {
        color: '#065492',
        fontSize: '12px',
        fontWeight: 700,
        cursor: 'pointer',
    },
};

export const stylesOrderPreview = {
    orderGridTitle: {
        fontWeight: 'bold',
    },
    orderGrid: {
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '100px auto',
        rowGap: '15px',
    },
    orderGridValue: {
        fontWeight: 'bold',
        marginLeft: '5px',
    },
    orderGridLabel: {
        color: '#999',
    },
    divider: {
        margin: '0 0 21px 0 !important',
    },
    formSegment: {
        padding: '20px 20px',
    },
};

export const stylesMapLocationCapture = {
    sideDrawer: {
        '& .ant-drawer-body': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    },
    autocomplete: {
        height: '60px',
    },
    autocompleteInput: {
        zIndex: 10,
    },
    mapContainer: {
        flex: '1 1 0%',
        margin: 0,
        padding: 0,
    },
    footer: {
        height: '80px',
        display: 'flex',
        padding: '20px',
        justifyContent: 'space-evenly',
    },
    latLng: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        padding: '5px',
    },
};
