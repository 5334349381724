import { SET_MASTER_DATA, SET_FETCHING_DATA, SET_DEFAULT_PORTAL } from './redux-constants';
import { GenericAction } from './redux.types';

const defaultState = {
    fetchingMaster: true,
    masterDataLoaded: false,
    config: {},
};

const setMaster = (state = defaultState, action: GenericAction) => {
    switch (action.type) {
        case SET_MASTER_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case SET_FETCHING_DATA: {
            return {
                ...state,
                fetchingMaster: action.data,
            };
        }

        case SET_DEFAULT_PORTAL: {
            return {
                ...state,
                config: {
                    ...state.config,
                    is_using_new_customer_portal: true,
                },
            };
        }

        default: return state;
    }
};

export default setMaster;
