import ReactGA from 'react-ga4';
import { GA_MID } from './globals';

export const initGoogleAnalytics = () => {
    if (!GA_MID) {
        return;
    }

    ReactGA.initialize([{
        trackingId: GA_MID,
        gtagOptions: {
            send_page_view: false,
        },
    }]);

    ReactGA.set({
        dimension1: 'service_name',
        dimension2: 'orgId',
        dimension3: 'customer_code',
    });
};

export const trackPageView = (path: string) => {
    if (!GA_MID) {
        return;
    }
    const storage = window.localStorage;
    const organisationId = storage.getItem('organisationId');
    const customerCode = storage.getItem('userCode');

    ReactGA.gtag('set', 'user_properties', { service_name: 'Customer Portal React' });
    ReactGA.gtag('set', 'user_properties', { orgId: organisationId });
    ReactGA.gtag('set', 'user_properties', { customer_code: customerCode });
    ReactGA.send({ hitType: 'pageview', page: path });
};
