import { ThemeType } from 'theme/jss-types';

export const settingStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input-affix-wrapper': {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)',
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        marginRight: 8,
        fontSize: 16,
        cursor: 'pointer',
    },
    hr: {
        marginRight: 16,
        width: '98%',
        height: 1,
        backgroundColor: '#EDEDED',
        margin: '18px 0px',
    },
    addText: {
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
        fontSize: 20,
    },
    newButton: {
        backgroundColor: `${theme.primaryColor} !important`,
    },
    newButtonDisabled: {
        backgroundColor: '#EDEDED !important',
    },
    contentRow: {
        display: 'flex',
        padding: 12,
        width: '100%',
        '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
            marginLeft: 0,
        },
        '& .ant-checkbox-group': {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            width: '100%',
            height: '73vh',
        },
        '& .ant-checkbox-group-item': {
            fontSize: 14,
            margin: '5px 0px',
            display: 'flex',
            color: '#111111',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    selectedPage: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: '#111111',
        padding: '12px 50px',
        borderBottom: `4px solid ${theme.primaryColor}`,
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0,
        color: '#666666',
        padding: '12px 50px',
        cursor: 'pointer',
    },
    columns: {
        width: '45%',
        padding: 16,
        paddingRight: 0,
        borderRight: '1px solid #EDEDED',
    },
    filters: {
        width: '55%',
        padding: 16,
    },
    columnList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: 16,
        overflow: 'auto',
        height: '73vh',
        '& .ant-checkbox-wrapper': {
            fontSize: 14,
            margin: '5px 0px',
            display: 'flex',
            color: '#111111',
        },
    },
    searchBox: {
        width: '98%',
    },
    filterColumns: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    filterName: {
        width: '50%',
        color: '#111111',
        fontSize: 14,
        fontWeight: 600,
        marginTop: 10,
    },
});
