/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Neutral = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Neutral</title>
        <defs>
            <polygon id="path-1" points="0 0 24.00004 0 24.00004 24 0 24" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="CP-V2-Like" transform="translate(-116.000000, -80.000000)">
                <g id="Group-5" transform="translate(12.000000, 12.000000)">
                    <g id="Group-2-Copy-2" transform="translate(104.000000, 68.000000)">
                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="36" height="36" rx="4" />
                        <g id="Group-10" transform="translate(6.000000, 6.000000)">
                            <g id="Group-3">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                </mask>
                                <g id="Clip-2" />
                                <path d="M24.00004,12.8135414 C24.00004,19.9742733 19.1607519,24.00004 12.00002,24.00004 C4.83928807,24.00004 0,19.9742733 0,12.8135414 C0,5.38160897 4.57256762,0 12.00002,0 C19.4274724,0 24.00004,5.38160897 24.00004,12.8135414" id="Fill-1" fill="#999999" mask="url(#mask-2)" />
                            </g>
                            <path d="M8.03022938,15.0507531 C9.35583159,15.018913 10.6774338,15.026033 11.999996,15.025873 C13.3226382,15.025793 14.6442404,15.018113 15.9698426,15.0507531 C16.4124034,15.0616331 16.7624839,15.4288337 16.7518439,15.8713945 C16.7419239,16.2801151 16.4268834,16.6100357 16.0302427,16.6496357 C14.6904005,16.783556 13.3447982,16.825796 11.999996,16.8256365 C10.6551938,16.825556 9.30967152,16.782916 7.96982928,16.6496357 C7.52934855,16.6057957 7.20774801,16.213155 7.25158809,15.7726743 C7.29206815,15.3655536 7.63142872,15.0603531 8.03022938,15.0507531" id="Fill-4" fill="#FFFFFF" />
                            <path d="M6.37397062,9.64407207 C6.37397062,10.0947128 6.4190907,10.5351136 6.50525084,10.9612743 C6.82517138,12.5446369 9.1227752,12.5446369 9.44269574,10.9612743 C9.52885588,10.5351136 9.57397596,10.0947128 9.57397596,9.64407207 C9.57397596,9.01983103 9.48733581,8.41519003 9.32517554,7.84094907 C8.9399749,6.47678679 7.00797168,6.47678679 6.62277104,7.84094907 C6.46061077,8.41519003 6.37397062,9.01983103 6.37397062,9.64407207" id="Fill-6" fill="#FFFFFF" />
                            <path d="M17.5739893,9.64407207 C17.5739893,10.0947128 17.5288692,10.5351136 17.4427091,10.9612743 C17.1227885,12.5446369 14.8251847,12.5446369 14.5052642,10.9612743 C14.419104,10.5351136 14.373984,10.0947128 14.373984,9.64407207 C14.373984,9.01983103 14.4606241,8.41519003 14.6227844,7.84094907 C15.007985,6.47678679 16.9399882,6.47678679 17.3251889,7.84094907 C17.4873491,8.41519003 17.5739893,9.01983103 17.5739893,9.64407207" id="Fill-8" fill="#FFFFFF" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const NeutralGrey = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Neutral({ ...innerProps, ...props });
    }}
    />
);

export default NeutralGrey;
