import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, StylesProps } from '../../theme/jss-types';
import svgImage from '../../assets/not-found.svg';

const styles: CssType = {
    main: {
        align: 'center',
        width: '100%',
    },
    message1: {
        textAlign: 'center',
        color: '#007468',
        fontSize: '1.0rem',
        fontFamily: 'Roboto, sans-serif',
    },
    message2: {
        marginTop: '2%',
        textAlign: 'center',
        color: '#4a4a4a',
        fontSize: '1.0rem',
        fontFamily: 'Roboto, sans-serif',
    },
};

interface NotFoundProps extends StylesProps<typeof styles> { }

const NotFound = (props: NotFoundProps) => {
    const { classes } = props;
    return (
        <div className={classes.main}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '20%' }}>
                <img alt="Not Found" className={classes.errorIcon} src={svgImage} />
            </div>
            <div className={classes.message1}>SORRY !</div>
            <div className={classes.message2}>
                The page you are looking for could not be found
            </div>
        </div>
    );
};

const NotFound404 = withStyles(styles)(NotFound);
export default NotFound404;
