import * as React from 'react';
import withStyles from 'react-jss';
import {
    Drawer,
    Button,
    Form,
    Select,
    Row,
    Col,
    Input,
    DatePicker,
    message,
    Modal,
} from 'antd';
import Cross from '../../assets/cross';
import { asnCreateCnStyles } from './asn-create-cn.styles';
import { DeleteOutlined } from '@ant-design/icons';
import { dimensionFields } from './asn-create-cn.constants';
import { getContentList, createConsignment } from '../../network/consignments.api';
import { fetchPickupAddress } from '../../network/pickup.api';
import moment, { Moment } from 'moment';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

const { Option } = Select;


const AsnCreateCn = (props: any) => {
    const {
        classes,
        onClose,
        data,
        loadAsn,
        uiTheme,
    } = props;
    const [form] = Form.useForm();
    const [saving, setSaving] = React.useState<boolean>(false);
    const [contentList, setContentList] = React.useState<any[]>([]);
    const [originCodeList, setOriginCodeList] = React.useState<any[]>([]);
    const [selectedOriginDetails, setSelectedOriginDetails] = React.useState<any>({});
    const weightRegExp = new RegExp('^([\\d]{0,3})(\\.[\\d]{1,2})?$');


    const loadContents = async () => {
        const response = await getContentList({
            isInternational: false,
        });
        setContentList(response?.data);
    };

    const loadOriginCodes = async () => {
        const response = await fetchPickupAddress({ searchString: '' });
        if (response.isSuccess) {
            let filteredList = [];
            filteredList = response && response.data.filter((i: any) => i.locationId);
            setOriginCodeList(filteredList);
        } else {
            setOriginCodeList([]);
        }
    };

    React.useEffect(() => {
        loadContents();
        loadOriginCodes();
        form.setFieldsValue({
            piece_details: [{
                item_unit: [],
            }],
            ewayBillList: [{
                invoiceNumber: null,
                ewbNumber: null,
                invoiceValue: null,
            }],
        });
    }, []);

    const sanitizeEwayBillList = () => {
        const santizedList: any = [];
        const ewayBillFormValue = form.getFieldValue('ewayBillList');
        if (ewayBillFormValue.length > 0) {
            ewayBillFormValue.forEach((item: any) => {
                santizedList.push({
                    type: 'ewaybill_number',
                    invoiceNumber: item.invoiceNumber || null,
                    ewbNumber: item.ewbNumber ? parseInt(item.ewbNumber, 10) : null,
                    invoiceValue: item.invoiceValue ? parseInt(item.invoiceValue, 10) : null,
                    creationTime: item.creationTime ? moment(item.creationTime).valueOf() : undefined,
                    invoiceDate: item.invoiceDate ? moment(item.invoiceDate).valueOf() : undefined,
                });
            });
        }
        return santizedList;
    };


    const getConsignmentPayload = () => {
        const modifiedPieceDetails:any[] = [];
        const pieceDetails = form.getFieldValue('piece_details');
        pieceDetails.forEach((item: any) => {
            const newItem = {
                ...item,
                dimensionUnit: 'cm',
            };
            modifiedPieceDetails.push(newItem);
        });
        const obj: any = {
            allPiecesWithSameDimensions: false,
            ewaybillObj: {},
            dstAddress: {
                addressLine1: data?.destination_address_line_1,
                addressLine2: data?.destination_address_line_2,
                cityName: data?.destination_city,
                phone: data?.destination_phone,
                pincode: data?.destination_pincode,
                countryName: data?.destination_country,
                stateName: data?.destination_state,
                name: data?.destination_hub_name,
            },
            dimensions: {},
            asnNumber: data?.asn_number,
            serviceType: data?.service_type,
            childClient: {
                code: data?.child_customer_code,
                id: window.localStorage.getItem('userId') || null,
            },
            srcAddress: selectedOriginDetails,
            consignmentType: form.getFieldValue('consignmentType') || undefined,
            piecesDetail: modifiedPieceDetails,
            ewayBillList: sanitizeEwayBillList(),
        };
        return obj;
    };

    const handleConsignmentCreation = async () => {
        setSaving(true);
        const apiBody = getConsignmentPayload();
        const response = await createConsignment(apiBody);
        if (response?.isSuccess) {
            message.success('CN Created Successfully');
            setSelectedOriginDetails({});
            onClose(true);
            loadAsn();
        } else {
            message.error(response.errorMessage);
        }
        setSaving(false);
    };

    const handleSubmit = async () => {
        const formValues = await form.validateFields();
        if (formValues.errorFields?.length) {
            return;
        }

        /**
         * The difference between Hu assigned by parent and created by child can be +1,-1
         */

        const huDetails = form.getFieldValue('piece_details');
        const huCountAssignedByParent = data?.num_of_hu;
        const huCountCreatedByChild = Array.isArray(huDetails) ? huDetails.length : 0;

        if (huCountAssignedByParent && Math.abs(huCountCreatedByChild - huCountAssignedByParent) > 1) {
            Modal.error({
                title: 'Error!!',
                content: `Sorry! Your Handling Unit Input doesn't match the Parent Customer Handling Unit expectations. 
                Please connect with the Parent Customer or try with the correct input.`,
            });
            return;
        }

        await handleConsignmentCreation();
    };


    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        Upload CN
                    </span>
                </div>
                <div>
                    <Button
                        loading={saving}
                        style={{
                            borderRadius: '4px',
                            border: `1px solid ${uiTheme.primaryColor}`,
                            color: '#FFFFFF',
                        }}
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Create
                    </Button>
                </div>
            </div>
        );
    };

    const handleOnSelect = (val: any) => {
        const selectedSource = originCodeList.filter((i: any) => i.locationId === val);
        if (selectedSource) {
            setSelectedOriginDetails(selectedSource[0]);
        }
    };
    const renderOriginCode = () => {
        const customerCode = window.localStorage.getItem('userCode');
        return (
            <Row>
                <Col md={6} lg={6}>
                    <div>
                        <Form.Item
                            label="Origin Code"
                            name="locationId"
                            className={classes.formItem}
                            required
                        >
                            <Select
                                showSearch
                                placeholder="Enter Origin Code"
                                defaultActiveFirstOption={false}
                                filterOption={
                                    (input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())
                                }
                                notFoundContent={null}
                                onSelect={(val) => handleOnSelect(val)}
                            >
                                { originCodeList && originCodeList.map((item:any) => {
                                    const originCodeLabel = `${customerCode}_${item.locationId}`;
                                    return <Option key={item.locationId} value={item.locationId}>{originCodeLabel}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        );
    };

    const validateNumber = (label: string, value: any, key: any, callback: any) => {
        if (!value && key !== 'ewbNumber') {
            return callback(`Invalid ${label}`);
        }
        if (value < 0) {
            return callback(`${label} can not be negative`);
        }

        if (value && key === 'weight') {
            if (!weightRegExp.test(value)) {
                return callback('Invalid Weight. Valid Format: 012.12');
            }
        }
        return callback();
    };

    const validateTextInput = (label: string, value: any, key: any, callback: any) => {
        const requiredKeys = ['invoiceNumber'];
        if (!value && requiredKeys.includes(key)) {
            return callback(`${label} is required!`);
        }

        return callback();
    };

    const renderInputNumber = (key:any, label: string, placeholder: string, index: any) => {
        return (
            <Form.Item
                name={[index, key]}
                label={label}
                rules={[{
                    validator: (rule, value, cb) => validateNumber(label, value, key, cb),
                }]}
                required={key !== 'ewbNumber'}
            >
                <Input
                    type="number"
                    name={key}
                    min={0}
                    placeholder={placeholder}
                    onFocus={(e) => e.target.addEventListener('wheel', (event) => {
                        event.preventDefault();
                    }, { passive: false })}
                />
            </Form.Item>

        );
    };

    const renderInputText = (key:any, label: string, placeholder: string, index: any) => {
        return (
            <Form.Item
                name={[index, key]}
                label={label}
                rules={[{
                    validator: (rule, value, cb) => validateTextInput(label, value, key, cb),
                }]}
                required

            >
                <Input
                    type="text"
                    name={key}
                    max={50}
                    placeholder={placeholder}
                />
            </Form.Item>

        );
    };
    const renderDimension = (index: number) => {
        return (
            <>
                {
                    dimensionFields.map((item:any, i:number) => {
                        return (
                            <Col md={3} lg={3} style={{ marginLeft: '5px' }} key={item.key} tabIndex={i}>
                                {renderInputNumber(item.key, item.label, item.placeholder, index)}
                            </Col>
                        );
                    })
                }
            </>
        );
    };

    const renderHus = (index: number) => {
        const optionList = contentList && contentList.map((item) => {
            return {
                id: item.COMMODITY_ID,
                value: item.COMMODITY_CODE,
                label: item.COMMODITY_NAME,
            };
        });
        return (
            <Col md={4} lg={4} style={{ marginLeft: '5px' }}>
                <Form.Item
                    name={[index, 'item_unit']}
                    label="Type of HU"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder="Type"
                        optionFilterProp="children"
                        filterOption={
                            (input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())
                        }
                        notFoundContent="NA"
                    >
                        {optionList && optionList.map((item:any) => {
                            return <Option key={item.value} value={item.value}>{item.label}</Option>;
                        })}
                    </Select>
                </Form.Item>
            </Col>
        );
    };

    const renderHUsDetails = () => {
        return (
            <div className={classes.huDetailsMain}>
                <div className={classes.huDetailHeading}>
                    HUs Details
                </div>
                <Form.List name="piece_details">
                    {(piece_details, { add, remove }) => {
                        return (
                            <div>
                                {piece_details.map((field, index) => (
                                    <Row key={field.key}>
                                        <Col md={1} lg={1} style={{ marginLeft: '5px' }}>
                                            <Form.Item
                                                label="S.No"
                                            >
                                                {index + 1}
                                            </Form.Item>
                                        </Col>
                                        {renderHus(index)}
                                        <Col md={3} lg={3} style={{ marginLeft: '5px' }}>
                                            {renderInputNumber('quantity', 'Quantity', 'Quantity', index)}
                                        </Col>
                                        {renderDimension(index as number)}
                                        <Col md={2} lg={2}>
                                            {
                                                piece_details.length > 1 ? (
                                                    <span onClick={() => remove(field.name)}>
                                                        <DeleteOutlined className={classes.deleteIconStyle} />
                                                    </span>
                                                ) : null
                                            }
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Form.Item>
                                        <Button
                                            type="link"
                                            onClick={() => add()}
                                            className={classes.addMoreButton}
                                        >
                                            + Add More Pieces
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </div>
                        );
                    }}
                </Form.List>
            </div>

        );
    };

    const handleDisabledDate = (currentDate: Moment) => {
        return currentDate && moment(currentDate) >= moment();
    };

    const renderDateField = (key:string, placeholder: string, index: number, label: any) => {
        return (
            <div>
                <Form.Item
                    name={[index, key]}
                    label={label}
                    required={key !== 'creationTime'}
                >
                    <DatePicker
                        value={moment()}
                        disabledDate={(currentDate) => handleDisabledDate(currentDate)}
                        allowClear={false}
                        placeholder={placeholder}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderInvoiceDetails = (index: number) => {
        return (
            <>
                <Col md={4} lg={4} style={{ marginLeft: '5px' }}>
                    {renderInputText('invoiceNumber', 'Invoice No.', 'Invoice No', index)}
                </Col>
                <Col md={4} lg={4} style={{ marginLeft: '5px' }}>
                    {renderDateField('invoiceDate', 'Invoice Date', index, 'Invoice Date')}
                </Col>
                <Col md={4} lg={4} style={{ marginLeft: '5px' }}>
                    {renderInputNumber('invoiceValue', 'Invoice Value', 'Invoice Value', index)}
                </Col>
            </>
        );
    };

    const renderEwbDetails = (index: number) => {
        return (
            <>
                <Col md={4} lg={4} style={{ marginLeft: '5px' }}>
                    {renderInputNumber('ewbNumber', 'EWB No.', 'EWB No', index)}
                </Col>
                <Col md={4} lg={4} style={{ marginLeft: '5px' }}>
                    {renderDateField('creationTime', 'EWB Date', index, 'EWB Date')}
                </Col>
            </>
        );
    };

    const renderOtherDetails = () => {
        return (
            <div className={classes.invoiceDetailsMain}>
                <div style={{ display: 'flex' }}>
                    <div className={classes.huDetailHeading} style={{ width: '56%' }}>
                        Invoice Details
                    </div>
                    <div className={classes.huDetailHeading} style={{ width: '30%' }}>
                        EWB Details
                    </div>
                </div>

                <Form.List name="ewayBillList">
                    {(ewayBillList, { add, remove }) => {
                        return (
                            <div>
                                {ewayBillList.map((field, index) => (
                                    <Row key={field.key}>
                                        <Col md={1} lg={1} style={{ marginLeft: '5px' }}>
                                            <Form.Item
                                                label="S.No"
                                            >
                                                {index + 1}
                                            </Form.Item>
                                        </Col>
                                        {renderInvoiceDetails(index)}
                                        {renderEwbDetails(index)}
                                        <Col md={2} lg={2}>
                                            {
                                                ewayBillList.length > 1 ? (
                                                    <span onClick={() => remove(field.name)}>
                                                        <DeleteOutlined className={classes.deleteIconStyle} />
                                                    </span>
                                                ) : null
                                            }
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Form.Item>
                                        <Button
                                            type="link"
                                            onClick={() => add()}
                                            className={classes.addMoreButton}
                                        >
                                            + Add More
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </div>
                        );
                    }}
                </Form.List>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <Form
                form={form}
                layout="vertical"
                className={classes.form}
            >
                {renderOriginCode()}
                {renderHUsDetails()}
                {renderOtherDetails()}
            </Form>

        );
    };

    return (
        <Drawer
            visible
            width="60%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderForm()}
        </Drawer>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default withStyles(asnCreateCnStyles)(GenericHoc(hocConfig)(AsnCreateCn));
