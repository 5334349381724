import { message } from 'antd';
import moment from 'moment';
import { getCODRemittanceReport } from 'network/consignments.api';
import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
    setPagination,
} from './generic-action';

const bucket = Buckets.REMITTANCE;
const dateFormat = 'DD/MM/YYYY';

export const loadRemittanceData = () => async (
    dispatch: Dispatch<any>,
    store: () => ReduxStore,
) => {
    dispatch(startLoading(bucket));

    dispatch(setGenericData(bucket, []));

    const { generic } = store();
    const { REMITTANCE } = generic;
    const { pagination, appliedFilters } = REMITTANCE;
    const storage = window?.localStorage;
    const id = storage?.getItem('userId');
    const code = storage?.getItem('userCode');

    const response = await getCODRemittanceReport({
        ...{
            fromDate: moment(appliedFilters?.fromDate * 1000).format(dateFormat),
            toDate: moment(appliedFilters?.toDate * 1000).format(dateFormat),
            clientCode: appliedFilters?.customer_code || code,
            currentPageNumber: pagination.currentPageNumber,
            clientId: appliedFilters?.customer_id || id,
        },
    });

    if (response.isSuccess) {
        dispatch(setGenericData(bucket, response?.data?.consignments || []));
        dispatch(setPagination(bucket, {
            isNextPresent: response.data?.isNextPresent,
        }));
    } else {
        message.error(response?.errorMessage || 'something went wrong!');
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
