export type ViewFilterType = {
    id: string,
    name: string,
    showOnMainDashboard: boolean,
};

export type ViewActionType = {
    id: string,
};

export type ViewDataResponseType = {
    id: string,
    object_type: string;
    description: string;
    metadata: {
        name: string;
        fieldList: {
            id: string,
            name: string,
            enable: boolean,
            showUi: boolean,
        }[],
        filterList: ViewFilterType[],
        tableActions: {
            id: string,
        }[],
        actionList: ViewActionType[],
    };
    extra_detail: { [key: string]: any };
};

export class ViewData {
    id: string;

    object_type: string;

    name: string;

    metadata: ViewDataResponseType['metadata'] | undefined;

    visibleFieldList: ViewDataResponseType['metadata']['fieldList'] | undefined;

    visibleFilterList: { id: string, show_on_main_dashboard?: boolean }[] | undefined;

    description: string | null | undefined;

    isDefault: boolean;

    isLoading: boolean = false;

    isFetchingData: boolean = false;

    shouldFetchDataOnTabChange: boolean = true;

    error?: string | null;

    fetchError?: string;

    appliedFilters: { [key: string]: any } = {};

    moreAppliedFilters: { [key: string]: any } = {};

    pagination: {
        pageNumber: number,
        isNextPresent: boolean,
        resultPerPage: '50',
    } = {
        pageNumber: 1,
        isNextPresent: true,
        resultPerPage: '50',
    };

    dataObjects: any[] = [];

    constructor(viewData: {
        id: string,
        isDefault: boolean,
        name: string,
        objectType: string,
    }) {
        this.id = viewData.id;
        this.name = viewData.name;
        this.object_type = viewData.objectType;
        this.isDefault = viewData.isDefault;
    }

    setViewData(viewData: ViewDataResponseType) {
        this.metadata = viewData.metadata;
        this.description = viewData.description;
    }

    getViewApiResponse() {
        return {
            id: this.id,
            isDefault: this.isDefault,
            name: this.name,
            objectType: this.object_type,
        };
    }

    clone() {
        const copy = new ViewData(this.getViewApiResponse());
        copy.id = this.id;
        copy.object_type = this.object_type;
        copy.name = this.name;
        copy.metadata = this.metadata ? { ...this.metadata } : undefined;
        copy.visibleFieldList = this.visibleFieldList;
        copy.visibleFilterList = this.visibleFilterList;
        copy.description = this.description;
        copy.isDefault = this.isDefault;
        copy.isLoading = this.isLoading;
        copy.isFetchingData = this.isFetchingData;
        copy.shouldFetchDataOnTabChange = this.shouldFetchDataOnTabChange;
        copy.error = this.error;
        copy.fetchError = this.fetchError;
        copy.appliedFilters = { ...this.appliedFilters };
        copy.moreAppliedFilters = { ...this.moreAppliedFilters };
        copy.pagination = { ...this.pagination };
        copy.dataObjects = [...(this.dataObjects || [])];
        return copy;
    }

    getFetchViewDataObjectsRequest(globalFilters: any) {
        return {
            viewId: this.id,
            filters: {
                ...this.appliedFilters,
                ...globalFilters,
            },
            pagination: {
                resultPerPage: +this.pagination.resultPerPage,
                pageNumber: this.pagination.pageNumber,
            },
        };
    }

    setViewDataObjects(data: any[]) {
        this.dataObjects = data;
        this.pagination.isNextPresent = (data?.length === Number.parseInt(this.pagination.resultPerPage, 10));
    }

    getMainDashboardFilters() {
        const filtersData = this.metadata?.filterList || [];
        return filtersData.filter((f) => f.showOnMainDashboard);
    }

    getMoreFilters() {
        const filtersData = this.metadata?.filterList || [];
        return filtersData.filter((f) => !f.showOnMainDashboard);
    }

    getActionList() {
        return this.metadata?.actionList || [];
    }

    getFilterValue(filterData: ViewFilterType) {
        const filterKey = filterData.id;
        return this.appliedFilters[filterKey];
    }

    applyFilters(filterObj: any) {
        Object.keys(filterObj).forEach((key) => {
            this.appliedFilters[key] = filterObj[key];
        });
    }
}
export enum TrackTypeEnum {
    CUSTOMER_REFERENCE_NO = 'Customer Reference No',
    CARRIER_AWB_NUMBER = 'Carrier AWB Number',
    CONSIGNMENT = 'Consignment Number'
}
