import React from 'react';
import { FormInstance } from 'antd';
import { formFields } from 'components/create-consignment/create-modal.constants';
import ContentTypeField from './piece-field/content-type';
import CommodityNameField from './piece-field/commodity-name';
import NumPiecesField from './num-pieces';
import WeightField from './weight';
import DimensionsField from './dimensions';
import PieceReferenceNumber from './piece-field/piece-reference-number';
import GenericField from './generic-field';
import DescriptionField from './description';
import RiskField from './risk-surcharge';
import DeclaredValueField from './declared-value';
import ItemUnit from './piece-field/item-unit';
import CodAmount from './cod-amount';
import QuantityField from './piece-field/quantity';

const {
    ContentTypes,
    ContentNameForOthers,
    NumPieces,
    Weight,
    DeclaredValue,
    ReferenceNumber,
    Vol_weight,
    Description,
    RiskSurcharge,
    ItemUnitType,
    Amount,
    Quantity,
} = formFields;

const pieceFieldsMapping = {
    [ContentTypes.key]: ContentTypeField,
    [ItemUnitType.key]: ItemUnit,
    [Amount.key]: CodAmount,
    [Quantity.key]: QuantityField,
    [ContentNameForOthers.key]: CommodityNameField,
    [ReferenceNumber.key]: PieceReferenceNumber,
    [NumPieces.key]: NumPiecesField,
    [Weight.key]: WeightField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: DeclaredValueField,
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField field={RiskSurcharge} {...props} />;
    },
    [Description.key]: DescriptionField,
};

const documentFieldMapping = {
    [Weight.key]: WeightField,
    [Description.key]: DescriptionField,
    [ContentTypes.key]: ContentTypeField,
    [ContentNameForOthers.key]: CommodityNameField,
    [ReferenceNumber.key]: PieceReferenceNumber,
    [NumPieces.key]: NumPiecesField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: DeclaredValueField,
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField field={RiskSurcharge} {...props} />;
    },
};

const envelopeFieldMapping = {
    [Weight.key]: WeightField,
    [Description.key]: DescriptionField,
    [ContentTypes.key]: ContentTypeField,
    [ContentNameForOthers.key]: CommodityNameField,
    [ReferenceNumber.key]: PieceReferenceNumber,
    [NumPieces.key]: NumPiecesField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: DeclaredValueField,
    [RiskSurcharge.key]: (props: any) => {
        return <RiskField field={RiskSurcharge} {...props} />;
    },
};

const boxDetailsFieldMapping = {
    [NumPieces.key]: NumPiecesField,
    [Weight.key]: WeightField,
    dimensions: DimensionsField,
    [DeclaredValue.key]: (props: any) => {
        return <GenericField field={DeclaredValue} {...props} />;
    },
    [Vol_weight.key]: (props: any) => {
        return <GenericField field={Vol_weight} {...props} />;
    },
    [Description.key]: DescriptionField,
};

const getMapping = (
    id: any,
    form: FormInstance,
    params: any,
    Component: any,
    shouldNotMakeItemTypeCheck: boolean = false,
) => {
    if (Component) {
        return <Component form={form} params={params} shouldNotMakeItemTypeCheck={shouldNotMakeItemTypeCheck} />;
    }
    console.log('Cannot find component for ', id);
    return null;
};

export const getPieceLevelFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, pieceFieldsMapping[id]);
};

export const getDocumentFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, documentFieldMapping[id], true);
};

export const getEnvelopeFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, envelopeFieldMapping[id], true);
};

export const getBoxLevelFieldFromMapping = (id: any, form: FormInstance, params: any) => {
    return getMapping(id, form, params, boxDetailsFieldMapping[id]);
};
