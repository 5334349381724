import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

export const asnCreateCnStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#333333',
        fontSize: 20,
    },
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        overflow: 'scroll',
    },
    form: {
        width: '100%',
    },
    deleteIconStyle: {
        color: 'red !important',
        fontSize: '16px',
        margin: '35px 0px 0px 30px',
    },
    huDetailHeading: {
        fontWeight: 600,
        color: '#111111',
        fontSize: '14px',
        lineHeight: '17px',
        marginLeft: 5,
        marginBottom: 12,
    },
    huDetailsMain: {
        background: '#FCFCFC',
        padding: '5px',
    },
    addMoreButton: {
        color: theme.primaryColor,
        width: '30%',
        fontSize: '14px',
        lineHeight: '12px',
        fontWeight: 600,
    },
    invoiceDetailsMain: {
        background: '#FCFCFC',
        padding: '5px',
        marginTop: '15px',
    },
});
