import {
    SET_UI_THEME,
    SET_DEFAULT_PORTAL,
} from '../reducers/redux-constants';

export const setRTL = (isRTL: boolean) => {
    return {
        type: SET_UI_THEME,
        data: {
            isRTL,
        },
    };
};
export const setTheme = (primaryColor: string, secondryColor: string) => {
    return {
        type: SET_UI_THEME,
        data: {
            primaryColor,
            secondryColor,
        },
    };
};



export const setSideMenuCollapsedAction = (isSideMenuCollapsed: boolean) => {
    return {
        type: SET_UI_THEME,
        data: {
            isSideMenuCollapsed,
        },
    };
};

export const setDefaultPortalAction = () => {
    return {
        type: SET_DEFAULT_PORTAL,
        data: true,
    };
};
