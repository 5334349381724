import tileStyle from './tileStyle';

export const MAP_DEFAULT_CONFIG = {
    id: 'map',
    mapStyle: tileStyle,
    initialViewState: {
        latitude: 37.8,
        longitude: -122.4,
        zoom: 14,
    },
    images: [],
};

export const DEFAULT_SOURCE = 'https://raw.githubusercontent.com/nazka/map-gl-js-spiderfy/dev/demo/json/sample-data.json';
