export type PageSelected = 'columns';

type SettingOption = {
    key: PageSelected;
    val: string;
};

export const settingOptions: SettingOption[] = [
    {
        key: 'columns',
        val: 'Columns',
    },
];
