import * as React from 'react';
import { StylesProps } from '../../theme/jss-types';
import { HocOptions } from '../common/generic-hoc.types';
import { reportsStyles } from './reports.styles';
import { Routes } from '../../library/constants';
import { RouteChildrenProps } from 'react-router';
import GenericHoc from '../common/generic-hoc';
import { ReduxStore } from 'reducers/redux.types';
import { ReportsPages } from './reports.constants';
import { getInvoiceReportsRoute, getRemittanceReportsRoute } from 'routing/routing-helper';
import RemittanceReport from './remittance-report';
import InvoiceReport from './invoice-report';
import { Master } from 'types/master-data-types';

interface ReportsProps extends StylesProps<ReturnType<typeof reportsStyles>>, RouteChildrenProps {
    master: Master;
}

const ReportsIframe = (props: ReportsProps) => {
    const {
        classes,
        history,
        master,
    } = props;
    // const bannerHeight = master?.config?.is_using_new_customer_portal ? 0 : 50;
    // const showHeaderMapping = master?.config?.customer_portal_config?.show_header_mapping !== false;
    const customerProperties = master?.properties;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    let removeCod = master?.Customer?.consignee_type !== 'B2C';
    let removeInvoice = master?.Customer?.is_cpdp;

    if (customerProperties && enable_customer_config) {
        removeInvoice = removeInvoice || !(customerProperties?.Invoices);
        removeCod = removeCod || !(customerProperties?.cod_remittance);
    }
    const getInitialPage = (): keyof typeof ReportsPages => {
        if (removeCod && removeInvoice) {
            history.push(`/${Routes.CONSIGNMENTS}`);
        }
        switch (history.location.pathname) {
            case `/${Routes.INVOICE_REPORT}`:
                if (removeInvoice) {
                    history.push(`/${Routes.REMITTANCE_REPORT}`);
                    return 'CodRemittance';
                }
                history.push(`/${Routes.INVOICE_REPORT}`);
                return 'InvoiceReports';
            case `/${Routes.REMITTANCE_REPORT}`:
            default:
                if (removeCod) {
                    history.push(`/${Routes.INVOICE_REPORT}`);
                    return 'InvoiceReports';
                }
                history.push(`/${Routes.REMITTANCE_REPORT}`);
                return 'CodRemittance';
        }
    };

    const [current, setCurrent] = React.useState<string>('ProductMapping');

    React.useEffect(() => {
        if (removeCod && removeInvoice) {
            history.push(`/${Routes.CONSIGNMENTS}`);
        } else {
            setCurrent(getInitialPage);
        }
    }, [history.location.pathname, removeCod, removeInvoice]);

    const handlePageChange = (page: keyof typeof ReportsPages) => {
        if (page === current) {
            return;
        }
        let nextPage = getRemittanceReportsRoute();
        switch (page) {
            case 'InvoiceReports':
                nextPage = getInvoiceReportsRoute(); break;
            case 'CodRemittance':
            default: nextPage = getRemittanceReportsRoute(); break;
        }
        setCurrent(page);
        history.push(nextPage);
    };

    const renderPages = () => {
        let reportKeys = Object.keys(ReportsPages);
        // put remove logic here for setting page
        if (removeCod) {
            reportKeys = reportKeys.filter((key: string) => key !== 'CodRemittance');
        }
        if (removeInvoice) {
            reportKeys = reportKeys.filter((key: string) => key !== 'InvoiceReports');
        }
        return reportKeys.map((key: any) => {
            return (
                <div
                    key={key}
                    onClick={() => handlePageChange(key)}
                    className={current === key ? classes.selected : classes.notSelected}
                >
                    {ReportsPages[key as keyof typeof ReportsPages]}
                </div>
            );
        });
    };

    const renderNavigation = () => {
        return (
            <div className={classes.navigation}>
                {renderPages()}
            </div>
        );
    };

    const renderInvoice = () => {
        if (current !== 'InvoiceReports') {
            return null;
        }
        return (
            <InvoiceReport />
        );
    };

    const renderCod = () => {
        if (current !== 'CodRemittance') {
            return null;
        }
        return (
            <RemittanceReport />
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.content}>
                {renderInvoice()}
                {renderCod()}
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {renderNavigation()}
            {renderContent()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: reportsStyles,
    },
};
export default GenericHoc(hocConfig)(ReportsIframe);
