export const dimensionFields: any = [
    {
        placeholder: 'Length',
        key: 'length',
        label: 'Length(cms)',
    },
    {
        placeholder: 'Width',
        key: 'width',
        label: 'Width(cms)',
    },
    {
        placeholder: 'Height',
        key: 'height',
        label: 'Height(cms)',
    },
    {
        placeholder: 'Weight',
        key: 'weight',
        label: 'Weight(kgs)',
    },
];
