import * as React from 'react';
import SortableList from './sortable-list';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { uniq, uniqBy, get } from 'lodash';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { PageSelected, settingOptions } from './settings.constants';
import { settingStyles } from './settings.styles';
import { SearchOutlined } from '@ant-design/icons';
import Cross from '../../assets/cross';
import {
    ConsignmentBucketKeys,
    ConsignmentColumn,
    MasterConfig,
    FilterMetadata,
} from '../../types/master-data-types';
import {
    Button,
    Drawer,
    Input,
    Checkbox,
    message,
} from 'antd';
import { LabelValue } from '../../library/Types';
import { UpdateColumns, UpdateFilters } from '../../network/consignment-api.types';
import { updateColumns, updateFilters } from '../../network/consignments.api';
import { bindActionCreators } from 'redux';
import { loadInitialData } from '../../actions/load-initial-data';
import { updateColumnsData, updateFiltersData } from '../../actions/objectpage-actions';
import GenericHoc from '../common/generic-hoc';
import { useTranslation } from 'react-i18next';
import { allowedColumns, allowedFilters } from 'library/globals';
import { PAGE_NAMES, PageData } from 'types/page.types';

interface SettingsProps extends StylesProps<ReturnType<typeof settingStyles>> {
    onClose: (refresh?: boolean) => void;
    config: MasterConfig;
    filterObject: {
        columns: Record<string, ConsignmentColumn>;
        filters: Record<string, FilterMetadata>;
    };
    bucketSelected: ConsignmentBucketKeys;
    loadInitial: () => void;
    updateBucketFilters: (data: {
        viewIndex: number,
        pageName: string,
        sectionIndex: number,
        filterList: { id: string, show_on_main_dashboard?: boolean }[],
    }) => void;
    uiTheme: ThemeType;
    consignmentPageData: PageData;
}

type Selected = Record<PageSelected, CheckboxValueType[]>;
type SelectedList = Record<PageSelected, ConsignmentColumn[]>;
type VisibleList = Record<PageSelected, LabelValue[]>;

const {
    useEffect,
    useState,
} = React;

const Settings = (props: SettingsProps) => {
    const {
        classes,
        onClose,
        config,
        filterObject,
        bucketSelected,
        loadInitial,
        uiTheme,
        consignmentPageData,
        updateBucketFilters,
    } = props;

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = React.useState<boolean>(false);

    const useMariaDb = get(config, 'customer_portal_config.use_mariadb_in_consignment_view');

    const getSelectedFilters = () => {
        let allFilters;
        if (consignmentPageData) {
            const viewData = consignmentPageData.getViewData(bucketSelected);
            allFilters = viewData?.visibleFilterList || config?.filter_list_by_bucket?.customer_portal_all;
        } else {
            allFilters = config?.filter_list_by_bucket[bucketSelected] || [];
        }

        if (useMariaDb && allowedFilters?.length) {
            allFilters = allFilters.filter((filter: any) => {
                return allowedFilters.includes(filter.id);
            });
        }

        let filters = allFilters.map((item) => {
            return {
                column_id: filterObject.filters[item.id].id,
                is_sortable: false,
                pretty_name: i18n.exists(filterObject.filters[item.id].id)
                    ? t(filterObject.filters[item.id].id)
                    : filterObject.filters[item.id].label,
                sort_key: filterObject.filters[item.id].id,
                show_on_main_dashboard: item.show_on_main_dashboard,
            };
        });
        filters = uniqBy(filters, 'column_id');
        return filters;
    };

    const getSelectedColumns = () => {
        let columns;
        if (consignmentPageData) {
            const viewData = consignmentPageData.metadata.sectionList.flat().find(
                (v) => v.id === bucketSelected,
            );
            columns = (viewData?.visibleFieldList || viewData?.metadata?.fieldList)?.filter(
                (item) => item.showUi,
            )?.map((field) => ({
                column_id: field.id,
                pretty_name: field.name,
                is_sortable: false,
            })) || [];
        } else {
            columns = config?.consignment_column_list[bucketSelected] || [];
        }
        columns = uniqBy(columns, 'column_id');
        columns = columns.map((item) => {
            return {
                ...item,
                pretty_name: !consignmentPageData && i18n.exists(item.column_id)
                    ? t(item.column_id)
                    : item.pretty_name,
            };
        });

        if (useMariaDb && allowedColumns?.length) {
            columns = columns.filter((item) => {
                return allowedColumns.includes(item.column_id);
            });
        }
        return columns;
    };

    const [query, setQuery] = useState<string>();
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [pageSelected, setPageSelected] = useState<PageSelected>('columns');
    const [columnsSelected, setColumnsSelected] = useState<Selected>({
        columns: getSelectedColumns().map((item) => (item.column_id)),
        filters: getSelectedFilters().map((item) => (item.column_id)),
    });
    const [selectedList, setSelectedList] = useState<SelectedList>({
        columns: getSelectedColumns(),
        filters: getSelectedFilters(),
    });
    const [visibleList, setVisibleList] = useState<VisibleList>({
        columns: [],
        filters: [],
    });

    const getFiltersList = () => {
        let options: any[] = uniq(
            config?.full_filter_list_by_bucket[
                consignmentPageData ? 'customer_portal_all' : bucketSelected
            ] || [],
        );

        options = options.map((item) => {
            const filterDetails = config?.filter_metadata?.find((x) => x.id === item) || {};
            return filterDetails;
        });

        if (useMariaDb && allowedFilters?.length) {
            options = options.filter((filter: any) => {
                return allowedFilters.includes(filter.id);
            });
        }

        if (query) {
            options = options.filter((item) => {
                const name = item.label.toUpperCase();
                return name.includes(query.toUpperCase());
            });
        }
        const allOptions = options.map((item) => ({
            label: item.label,
            value: item.id,
        }));
        const selectedFilters = allOptions.filter((item) => {
            return columnsSelected.filters.includes(item.value);
        });
        const unselectedFilters = allOptions.filter((item) => {
            return !columnsSelected.filters.includes(item.value);
        });
        return [...selectedFilters, ...unselectedFilters];
    };

    const getColumnsList = () => {
        let fullColumnList;
        if (consignmentPageData) {
            const viewData = consignmentPageData.metadata.sectionList.flat().find((v) => v.id === bucketSelected);
            fullColumnList = viewData?.metadata?.fieldList?.map((field) => ({
                column_id: field.id,
                pretty_name: field.name,
                is_sortable: false,
            })) || [];
        } else {
            fullColumnList = config?.full_column_list || [];
        }
        let options = uniqBy(fullColumnList, 'column_id');
        if (query) {
            options = options.filter((item) => {
                const name = item.pretty_name.toUpperCase();
                return name.includes(query.toUpperCase());
            });
        }

        if (useMariaDb && allowedColumns?.length) {
            options = options.filter((item) => {
                return allowedColumns.includes(item.column_id);
            });
        }

        const allOptions = options.map((item) => ({
            label: item.pretty_name,
            value: item.column_id,
        }));
        const selectedColumns = allOptions.filter((item) => {
            return columnsSelected[pageSelected].includes(item.value);
        });
        const unselectedColumns = allOptions.filter((item) => {
            return !columnsSelected[pageSelected].includes(item.value);
        });
        return [...selectedColumns, ...unselectedColumns];
    };

    useEffect(() => {
        setVisibleList({
            columns: getColumnsList(),
            filters: getFiltersList(),
        });
    }, [columnsSelected, query]);

    const handleUpdateColumns = async () => {
        let bucket: string = bucketSelected;
        if (consignmentPageData) {
            const viewIndex = consignmentPageData.metadata.sectionList.flat().findIndex(
                (v) => v.id === bucketSelected,
            );
            const sectionIndex = (viewIndex + 1) % consignmentPageData.metadata.sectionList.length;
            const viewId = consignmentPageData?.metadata?.sectionList?.[sectionIndex]?.[viewIndex]?.id;
            bucket = `customer_portal_consignment_view_${viewId}`;
        }

        const apiBody: UpdateColumns = {
            bucket,
            consignment_column_list: selectedList.columns,
        };
        const response = await updateColumns(apiBody);
        if (response.isSuccess) {
            message.success('Columns updated successfully');
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
    };
    const handleUpdateFilters = async () => {
        if (consignmentPageData) {
            const viewIndex = consignmentPageData.metadata.sectionList.flat().findIndex(
                (v) => v.id === bucketSelected,
            );
            const sectionIndex = (viewIndex + 1) % consignmentPageData.metadata.sectionList.length;
            updateBucketFilters({
                viewIndex,
                pageName: PAGE_NAMES.CONSIGNMENTS,
                sectionIndex,
                filterList: selectedList.filters.map((item) => {
                    return {
                        id: item.column_id,
                        show_on_main_dashboard: item.show_on_main_dashboard,
                    };
                }),
            });
            message.success('Filters updated successfully');
            setTimeout(() => {
                onClose(false);
            }, 0);
        } else {
            const filterList = selectedList.filters.map((item) => {
                return {
                    id: item.column_id,
                    show_on_main_dashboard: item.show_on_main_dashboard,
                };
            });
            const apiBody: UpdateFilters = {
                bucket: bucketSelected,
                filter_list: filterList,
            };
            const response = await updateFilters(apiBody);
            if (response.isSuccess) {
                message.success('Filters updated successfully');
                onClose(true);
            } else {
                message.error(response.errorMessage);
            }
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (pageSelected === 'columns') {
            await handleUpdateColumns();
        } else {
            await handleUpdateFilters();
        }
        if (!consignmentPageData) {
            loadInitial();
        }
        setLoading(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButtonDisabled;
        if (!btnDisabled) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                className={btnClass}
                disabled={btnDisabled}
                loading={loading}
                style={{
                    border: `1px solid ${btnDisabled ? '#EDEDED'
                        : uiTheme.primaryColor}`,
                }}
                onClick={handleSubmit}
            >
                {t('submit')}
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('customize')}
                    </span>
                </div>
                {renderButton()}
            </div>
        );
    };

    const handlePageChange = (page: PageSelected) => {
        if (page === pageSelected) {
            return;
        }
        setPageSelected(page);
    };

    const renderSettingOptions = () => {
        return (
            <div className={classes.row}>
                {settingOptions.map((item) => (
                    <div
                        key={item.key}
                        onClick={() => handlePageChange(item.key)}
                        className={pageSelected === item.key ? classes.selectedPage : classes.pageName}
                    >
                        {t(item.key)}
                    </div>
                ))}
            </div>
        );
    };

    const renderSearchBox = () => {
        return (
            <Input
                type="text"
                value={query}
                onChange={(e: React.ChangeEvent<any>) => setQuery(e.target.value)}
                placeholder={t('search_columns')}
                className={classes.searchBox}
                suffix={<SearchOutlined />}
            />
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const getUpdatedSelection = (checked: any[], useExisting: boolean) => {
        let current: any[] = checked;
        if (useExisting) {
            current = [...columnsSelected[pageSelected]];
            if (checked.length > current.length) {
                const newAddition = checked.filter((item) => !current.includes(item));
                current.push(...newAddition);
            } else {
                current = current.filter((item) => checked.includes(item));
            }
        }
        current = uniq(current);
        const updatedList = current.map((item) => {
            if (pageSelected === 'filters') {
                const currentItem = selectedList.filters.find((filter) => item === filter.column_id);
                return {
                    column_id: filterObject.filters[item].id,
                    is_sortable: false,
                    pretty_name: i18n.exists(filterObject.filters[item].id)
                        ? t(filterObject.filters[item].id)
                        : filterObject.filters[item].label,
                    sort_key: filterObject.filters[item].id,
                    show_on_main_dashboard: currentItem?.show_on_main_dashboard,
                };
            }
            return {
                ...filterObject[pageSelected][item],
                pretty_name: !consignmentPageData && i18n.exists(filterObject[pageSelected][item].column_id)
                    ? t(filterObject[pageSelected][item].column_id)
                    : filterObject[pageSelected][item].pretty_name,
            };
        });
        return {
            current,
            updatedList,
        };
    };

    const updateSelection = (checked: any[], useExisting = false) => {
        const { current, updatedList } = getUpdatedSelection(checked, useExisting);

        setColumnsSelected({
            ...columnsSelected,
            [pageSelected]: current,
        });

        setSelectedList({
            ...selectedList,
            [pageSelected]: updatedList,
        });
        setBtnDisabled(false);
    };

    const handleCheckboxChange = (checked: boolean, item: LabelValue) => {
        let newList = [...columnsSelected[pageSelected]];
        if (checked) {
            newList.push(item.value);
        } else {
            newList = newList.filter((column) => column !== item.value);
        }
        updateSelection(newList);
    };

    const renderColumns = () => {
        return (
            <div className={classes.columnList}>
                {visibleList[pageSelected].map((item) => {
                    return (
                        <Checkbox
                            onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                            key={item.value}
                            value={item.value}
                            checked={columnsSelected[pageSelected].includes(item.value)}
                        >
                            {
                                !consignmentPageData && i18n.exists(item.value)
                                    ? t(item.value)
                                    : t(item.label)
                            }
                        </Checkbox>
                    );
                })}
            </div>
        );
    };

    const renderAllColumns = () => {
        return (
            <div className={classes.columns}>
                {renderSearchBox()}
                {renderLine()}
                {renderColumns()}
            </div>
        );
    };

    const renderFilterColumns = () => {
        return (
            <div className={classes.filterColumns}>
                <div className={classes.filterName}>
                    {t(`${pageSelected}_to_show`)}
                </div>
                {pageSelected === 'filters' && (
                    <div
                        className={classes.filterName}
                    >
                        {t('show_on_main_dashboard')}
                    </div>
                )}
            </div>
        );
    };

    const updateShowOnDashboard = (column: ConsignmentColumn, checked: boolean) => {
        const updatedList = selectedList.filters.map((item) => {
            if (item.column_id === column.column_id) {
                return {
                    ...item,
                    show_on_main_dashboard: checked,
                };
            }
            return item;
        });
        setSelectedList({
            ...selectedList,
            filters: updatedList,
        });
        setBtnDisabled(false);
    };

    const renderFilterOptions = () => {
        return (
            <div className={classes.filters}>
                {renderFilterColumns()}
                {renderLine()}
                <SortableList
                    updateShowOnDashboard={updateShowOnDashboard}
                    pageSelected={pageSelected}
                    updateSelection={updateSelection}
                    selectedList={selectedList[pageSelected]}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.contentRow}>
                {renderAllColumns()}
                {renderFilterOptions()}
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="55%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderSettingOptions()}
            {renderContent()}
        </Drawer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        config: state.master.config,
        uiTheme: state.uiTheme,
        consignmentPageData: state.genericPage[PAGE_NAMES.CONSIGNMENTS],
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        loadInitial: loadInitialData,
        updateBucketColumns: updateColumnsData,
        updateBucketFilters: updateFiltersData,
    }, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: settingStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};

export default GenericHoc(hocConfig)(Settings);
