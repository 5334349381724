import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Button,
    Col,
    Form,
    FormInstance,
    Upload,
    message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import Helper from 'library/Helper';
import { getS3Urls, uploadImageToS3 } from 'network/common.api';
import { UploadFile } from 'antd/lib/upload/interface';

const styles = () => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    fieldKey: string;
    title: string;
}

const ConsignorKYCImageField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        fieldsToShowInternational,
        mandatoryFieldShipmentCommercial,
        form,
        fieldKey,
        title,
    } = props;
    const {
        ConsignmentCategory,
        ItemType,
        shipment_purpose,
    } = formFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);
    const shipmentPurpose = Form.useWatch(shipment_purpose.key, form);
    const fieldValue = Form.useWatch(fieldKey, form);
    const [uploadingFile, setUploadingFile] = React.useState<boolean>(false);
    const [fileList, setFileList] = React.useState<UploadFile[]>(fieldValue ? [{
        uid: (+new Date()).toString(),
        name: 'kyc_front',
        status: 'done',
        url: fieldValue,
    }] : []);

    const isInternational = consignmentCategory === 'international';

    if (!isInternational) {
        return null;
    }

    if (!fieldsToShowInternational[fieldKey]) return null;

    const isRequired = (key: string) => {
        if (shipmentPurpose === 'COMMERCIAL' && mandatoryFieldShipmentCommercial[key]
            && itemType === 'non-document') {
            return mandatoryFieldShipmentCommercial[key];
        }
        return false;
    };

    const handleFileUpload = async (imageType: string, file: any) => {
        const extention = Helper.getFileExtension(file?.name);

        if (!['JPG', 'JPEG', 'PNG'].includes(extention.toUpperCase())) {
            message.error('only images are allowed');
            return;
        }

        setUploadingFile(true);
        const storage = window.localStorage;
        const clientId = storage.getItem('userId');

        const response = await getS3Urls({
            clientId,
            imageType,
            contentType: file?.type,
        });

        if (response.isSuccess) {
            const upload = await uploadImageToS3(file, response?.data?.result?.imageSignedUrl, file?.type);

            if (upload.isSuccess) {
                const changedfileList: UploadFile[] = [
                    {
                        uid: file?.id,
                        name: file?.name,
                        status: 'done',
                        url: response?.data?.result?.imageUrl,
                    },
                ];
                setFileList(changedfileList);
                form.setFieldsValue({
                    [fieldKey]: response?.data?.result?.imageUrl,
                });
            } else {
                message.error(upload.errorMessage);
            }
        } else {
            message.error(response.errorMessage);
        }
        setUploadingFile(false);
    };

    const renderUpload = () => {
        return (
            <Form.Item
                name={fieldKey}
                rules={[{ required: isRequired(fieldKey), message: t('required') }]}
            >
                <Upload
                    showUploadList
                    maxCount={1}
                    customRequest={(upload) => handleFileUpload('kyc', upload?.file)}
                    disabled={uploadingFile}
                    fileList={fileList}
                >
                    <UploadOutlined />
                    <span style={{ fontSize: 12, marginLeft: 6 }}> Upload </span>
                </Upload>
            </Form.Item>
        );
    };

    return (
        <Col span={12}>
            <div className={classes.title}>
                {title}
                {isRequired(fieldKey) ? '*' : ''}
            </div>
            <Button
                style={{
                    borderRadius: 4,
                    fontSize: 12,
                }}
            >
                {renderUpload()}
            </Button>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const fieldsToShowInternational = customerPortalConfig?.fields_to_show_international || {};
    const mandatoryFieldShipmentCommercial = customerPortalConfig?.mandatory_shipment_commercial || {};
    return {
        fieldsToShowInternational,
        mandatoryFieldShipmentCommercial,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ConsignorKYCImageField);
