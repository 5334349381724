import {
    Button,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Modal,
    // DatePicker,
    // Select,
    Table,
    Tooltip,
} from 'antd';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import Paginator from 'components/common/paginator';
import { TableColumn } from 'components/settings-product/settings.types';
import { NAVBAR_HEIGHT } from 'library/globals';
import moment from 'moment';
import * as React from 'react';
import { ReduxStore } from 'reducers/redux.types';
import { bindActionCreators } from 'redux';
import { Master } from 'types/master-data-types';
import { StylesProps } from '../../theme/jss-types';
import { RouteChildrenProps } from 'react-router';
import {
    Buckets,
    NextOrPrev,
    Pagination,
} from 'library/Types';
import { applyFilters, setFilters, setPagination } from 'actions/generic-action';
import { rtoDashboardStyles } from './rto-dashboard.styles';
import {
    RTOAddressFormField,
    RTODashboardColumns,
    RTOPageOptions,
    ValidRTOActions,
} from './rto-dashboard.constants';
import { getRtoDashboardRoute } from 'routing/routing-helper';
import { loadRtoDashboardData } from 'actions/rto-dashboard-actions';
import { rtoAction } from 'network/consignments.api';
import Helper from 'library/Helper';
import CrossIcon from 'assets/cross';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import { formRules } from 'library/constants';
import FailureList from '../consignments/cancellation-failure';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface rtoDashboardProps extends StylesProps<ReturnType<typeof rtoDashboardStyles>>, RouteChildrenProps {
    master: Master,
    rtoConsignmentsData: any[],
    loadRtoConsignments: () => void,
    loading: boolean,
    pagination: Record<string, any>,
    changePagination: (bucketId: Buckets.RTO_DASHBOARD, newPagination: Partial<Pagination>) => void;
    // setFilter: (bucketId: Buckets.RTO_DASHBOARD, filters: any) => void;
    // applyFilter: (bucketId: Buckets.RTO_DASHBOARD) => void;
    filters: Record<string, any>,
    // defaultFilters: Record<string, any>,
}

type Failure = {
    message: string;
    reason: string;
    reference_number: string;
    row?: string;
};

const RTODashboardPage = (props: rtoDashboardProps) => {
    const {
        classes,
        master,
        history,
        rtoConsignmentsData,
        loadRtoConsignments,
        loading,
        pagination,
        changePagination,
        // setFilter,
        // applyFilter,
        filters,
        // defaultFilters,
    } = props;

    const [form] = Form.useForm();
    const [rtoActionStarted, setRtoActionStarted] = React.useState(false);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [consignmentsSelected, setConsignmentSelected] = React.useState<string[]>([]);
    const [reattemptAddAddress, setReattemptAddAddress] = React.useState<boolean>(false);
    const [showFailure, setShowFailure] = React.useState<boolean>(false);
    const [failureList, setFailureList] = React.useState<Failure[]>([]);
    // const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([
    //     moment().subtract(1, 'months').startOf('month'),
    //     moment(),
    // ]);
    const [pageSelected, setPageSelected] = React.useState<keyof RTOPageOptions>(
        'Dashboard' as keyof RTOPageOptions,
    );
    const { t, i18n } = useTranslation();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 150;

    const {
        AddressLine1,
        AddressLine2,
        MobileNumber,
        PinCode,
    } = RTOAddressFormField;


    React.useEffect(() => {
        loadRtoConsignments();
    }, [toggle, filters]);

    // const handleDateChange = (obj: any) => {
    //     setDateRange(obj);
    //     setFilter(Buckets.RTO_DASHBOARD, {
    //         ...filters,
    //         fromDate: obj[0].unix(),
    //         toDate: obj[1].unix(),
    //     });
    //     applyFilter(Buckets.RTO_DASHBOARD);
    // };

    // const renderDatePicker = () => {
    //     return (
    //         <div className={classes.filter}>
    //             <span className={classes.filterText}>
    //                 Date Range
    //             </span>
    //             <DatePicker.RangePicker
    //                 allowEmpty={[false, false]}
    //                 allowClear={false}
    //                 value={dateRange}
    //                 onChange={handleDateChange}
    //             />
    //         </div>
    //     );
    // };

    // const isMoreFilterAdded = () => {
    //     const dateFormat = 'YYYY-MM-DD';
    //     return (!isEqual(defaultCustomer, selectedCustomer))
    //         || (!(
    //             moment(defaultFilters?.fromDate * 1000)?.format(dateFormat) === dateRange[0].format(dateFormat)
    //             && moment(defaultFilters?.toDate * 1000)?.format(dateFormat) === dateRange[1].format(dateFormat)
    //         ));
    // };

    // const renderResetFiltersOption = () => {
    //     const filterApplied = isMoreFilterAdded();
    //     if (!filterApplied) {
    //         return null;
    //     }
    //     return (
    //         <div
    //             className={classes.resetFilter}
    //             onClick={() => {
    //                 setSelectedCustomer(defaultCustomer);
    //                 setDateRange([
    //                     moment(defaultFilters.fromDate * 1000),
    //                     moment(defaultFilters.toDate * 1000),
    //                 ]);
    //                 setFilter(Buckets.RTO_DASHBOARD, {
    //                     ...defaultFilters,
    //                 });
    //                 applyFilter(Buckets.RTO_DASHBOARD);
    //             }}
    //         >
    //             Reset Filter
    //         </div>
    //     );
    // };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        changePagination(Buckets.RTO_DASHBOARD, newPagination);
        setToggle(!toggle);
    };

    const renderRefresh = () => {
        return (
            <Tooltip title="Reload">
                <Button
                    size="small"
                    type="ghost"
                    style={{
                        color: '#082E78',
                        fontSize: 14,
                    }}
                    onClick={() => {
                        handlePagination('first');
                        loadRtoConsignments();
                    }}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };

    const renderFailureList = () => {
        return (
            <FailureList
                failureList={failureList}
                onClose={() => {
                    setShowFailure(false);
                    setFailureList([]);
                }}
            />
        );
    };

    const onClickAction = async (event: any) => {
        setRtoActionStarted(true);
        const response = await rtoAction({
            actionType: event?.key,
            referenceNumberList: consignmentsSelected,
            reattemptAddress: event?.key === ValidRTOActions.ReattemptNewAddress
                ? form.getFieldsValue()
                : undefined,
        });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else if (Helper.isValidArray(response?.data?.failedConsignments)) {
            message.error(response?.data?.message || 'Something went wrong! Please try again');
            setShowFailure(true);
            setFailureList(response?.data?.failedConsignments?.map((item: any) => {
                return {
                    message: '',
                    reason: item?.failureReason,
                    reference_number: item?.referenceNumber,
                };
            }));
        } else if (response?.data?.statusCode === 500) {
            message.error(response?.data?.message);
        } else {
            message.success(response?.data?.message);
        }
        form.resetFields();
        setConsignmentSelected([]);
        setRtoActionStarted(false);
    };

    const handleClose = () => {
        setReattemptAddAddress(false);
        form.resetFields();
    };

    const renderButtonSubmit = () => {
        return (
            <Form form={form}>
                <Form.Item>
                    <Button
                        type="primary"
                        style={{ marginRight: 16 }}
                        htmlType="submit"
                        onClick={async () => {
                            await form.validateFields();
                            await onClickAction({ key: ValidRTOActions.ReattemptNewAddress });
                            handleClose();
                            setConsignmentSelected([]);
                        }}
                        loading={rtoActionStarted}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <CrossIcon
                        onClick={() => handleClose()}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>Delivery Address</span>
                </div>
                {renderButtonSubmit()}
            </div>
        );
    };

    const renderInput = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                rules={[formRuleRequired]}
                valuePropName={field.valuePropName}
            >
                <Input
                    type={field.type}
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                    value={field.defaultValue?.toString()}
                    width={field.width}
                />
            </Form.Item>
        );
    };

    const renderFormItem = (field: FormField) => {
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <div style={{ padding: '0px 2.5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            *
                        </div>
                        {renderInput(field)}
                    </div>
                );
            default:
                return null;
        }
    };

    const renderAddressForm = () => {
        return (
            <Form
                form={form}
                layout="vertical"
            >
                {renderFormItem(AddressLine1)}
                {renderFormItem(AddressLine2)}
                {renderFormItem(MobileNumber)}
                {renderFormItem(PinCode)}
            </Form>
        );
    };

    const renderNewAddressModal = () => {
        return (
            <Modal
                visible={reattemptAddAddress}
                width="40%"
                title={renderHeader()}
                footer={null}
                closable={false}
                maskClosable
                onCancel={() => handleClose()}
                className={classes.modal}
                centered
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                }}
            >
                {renderAddressForm()}
            </Modal>
        );
    };


    const renderActionsMenu = (
        <Menu className={classes.menu} disabled={!Helper.isValidArray(consignmentsSelected)}>
            <Menu.Item
                key={ValidRTOActions.Rto}
                onClick={onClickAction}
            >
                RTO
            </Menu.Item>
            <Menu.Item
                key={ValidRTOActions.Reattempt}
                onClick={onClickAction}
            >
                Reattempt
            </Menu.Item>
            <Menu.Item
                key={ValidRTOActions.ReattemptNewAddress}
                onClick={() => setReattemptAddAddress(true)}
            >
                Reattempt (with new Address)
            </Menu.Item>
        </Menu>
    );


    const renderActions = () => {
        return (
            <Dropdown
                overlay={renderActionsMenu}
                trigger={['click']}
                className={classes.actionBtn}
            >
                <Button
                    type="ghost"
                    loading={rtoActionStarted}
                >
                    Actions
                </Button>
            </Dropdown>
        );
    };


    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination?.currentPageNumber || 1}
                isNextPresent={pagination?.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };

    const handlePageChange = (page: keyof RTOPageOptions) => {
        if (page === pageSelected) {
            return;
        }
        let nextPage = getRtoDashboardRoute();
        switch (page) {
            case 'Dashboard':
            default: nextPage = getRtoDashboardRoute(); break;
        }
        setPageSelected(page);
        history.push(nextPage);
    };

    const renderPageOption = (page: keyof RTOPageOptions) => {
        return (
            <div
                key={page as string}
                onClick={() => handlePageChange(page)}
                className={pageSelected === page ? classes.selectedPage : classes.pageName}
            >
                {RTOPageOptions[page as keyof typeof RTOPageOptions]}
            </div>
        );
    };

    const renderPageFilter = () => {
        return (
            <div className={classes.pageFilter}>
                {Object.keys(RTOPageOptions).map((page) => renderPageOption(page as keyof RTOPageOptions))}
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.filter}>
                {/* {renderDatePicker()} */}
                {/* {renderResetFiltersOption()} */}
            </div>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderActions()}
                {renderRefresh()}
                {renderPaginator()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderText = (text: string, row: any) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };
    const renderColumn = (text: any, row: any, column: TableColumn) => {
        if (['DELIVERY_DATE', 'BOOKING_DATE'].includes(column.key)) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString, row);
        }
        // please don't change this default object check
        if (typeof text === 'object') {
            return <div className={classes.cellValue}>{JSON.stringify(text)}</div>;
        }
        return renderText(text, row);
    };


    const getColumns = (): any[] => {
        return RTODashboardColumns.map((column) => {
            return {
                key: column.key,
                title: column.pretty_name,
                dataIndex: column.key,
                width: 100,
                ellipsis: true,
                fixed: false,
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
    };

    const renderTable = () => {
        return (
            <Table
                columns={getColumns()}
                dataSource={rtoConsignmentsData || []}
                loading={loading}
                className={classes.table}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                rowKey={(row) => row.CONSG_NUMBER}
                bordered={false}
                pagination={false}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 50px) - ${bannerHeight}px)`,
                }}
                rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys) => setConsignmentSelected(selectedRowKeys as string[]),
                    selectedRowKeys: consignmentsSelected,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderPageFilter()}
            {renderFilters()}
            {renderTable()}
            {renderNewAddressModal()}
            {showFailure ? renderFailureList() : null}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const RTO_DASHBOARD = generic.RTO_DASHBOARD;
    const {
        loading,
        data,
        pagination,
        filters,
        defaultFilters,
    } = RTO_DASHBOARD;
    return {
        loading,
        pagination,
        rtoConsignmentsData: data,
        filters,
        defaultFilters,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setFilter: setFilters,
        applyFilter: applyFilters,
        loadRtoConsignments: loadRtoDashboardData,
        changePagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: rtoDashboardStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const RTODashboard = GenericHoc(hocConfig)(RTODashboardPage);
export default RTODashboard;
