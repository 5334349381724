import authHelper from 'auth/auth-helper';
import { Header, Product } from 'components/settings-product/settings.types';
import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from 'library/globals';
import { GET, POST } from './api-hander.service';
import {
    GET_API_KEY,
    GET_CHILD_CUSTOMERS,
    GET_CUSTOMER_DETAILS,
    GET_DEFAULT_HEADERS,
    GET_DEFAULT_PIECE_HEADERS,
    GET_HEADERS,
    GET_PIECE_HEADERS,
    GET_ORGANISATION_MANDATORY_HEADERS,
    GET_PRODUCTS,
    RESET_HEADERS,
    RESET_PIECE_HEADERS,
    SAVE_PRODUCT,
    UPDATE_CUSTOMER,
    UPDATE_HEADERS,
    UPDATE_PIECE_HEADERS,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
} from './api.constants';

export const getApiKey = () => {
    const headers = authHelper.getAuthenticationHeaders();
    return GET(`${API_BASE_URL}${GET_API_KEY}`, { clientId: headers['user-id'] });
};

export const getCustomerDetails = () => {
    const headers = authHelper.getAuthenticationHeaders();
    return GET(`${API_BASE_URL}${GET_CUSTOMER_DETAILS}`, { customer_id: headers['user-id'] });
};

export const getChildCustomerDetails = () => {
    const headers = authHelper.getAuthenticationHeaders();
    return GET(`${API_BASE_URL}${GET_CHILD_CUSTOMERS}`, { customer_id: headers['user-id'] });
};

export const updateCustomer = (body: any) => {
    return POST(`${API_BASE_URL}${UPDATE_CUSTOMER}`, body);
};

export const getProducts = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_PRODUCTS}`, params);
};

export const getHeaders = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_HEADERS}`, params);
};

export const getPieceHeaders = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_PIECE_HEADERS}`, params);
};

export const getDefaultHeaders = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_DEFAULT_HEADERS}`, params);
};

export const getDefaultPieceHeaders = (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_DEFAULT_PIECE_HEADERS}`, params);
};

export const getOrganisationMandatoryHeaders = () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${GET_ORGANISATION_MANDATORY_HEADERS}`);
};

export const resetHeaders = () => {
    return POST(`${BUSINESS_BOOKING_API_URL}${RESET_HEADERS}`, {});
};

export const resetPieceHeaders = () => {
    return POST(`${BUSINESS_BOOKING_API_URL}${RESET_PIECE_HEADERS}`, {});
};

export const updateHeaders = (headerArray: Header[]) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${UPDATE_HEADERS}`, { headerArray });
};

export const updatePieceHeaders = (headerArray: Header[]) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${UPDATE_PIECE_HEADERS}`, { headerArray });
};

export const updateProduct = (body: Product) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${UPDATE_PRODUCT}`, body);
};

export const deleteProduct = (body: { id: string }) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${DELETE_PRODUCT}`, body);
};

export const saveProduct = (body: Product) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${SAVE_PRODUCT}`, body);
};
