import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    FormInstance,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const styles = () => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    referenceItemEway: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
}

const EwayBillDownloadDeclaration = (props: IProps) => {
    const {
        showEwayBill,
        form,
    } = props;
    const {
        ConsignmentCategory,
        DeclaredValue,
        EwayBillType,
    } = formFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const declaredValueFieldData = Form.useWatch(DeclaredValue.key, form);
    const ewayBillType = Form.useWatch(['ewayBill', EwayBillType.key], form);
    const checkDeclaredValueIsInt = (declaredValueFieldData && !Number.isNaN(declaredValueFieldData));
    const declaredValue = checkDeclaredValueIsInt ? Number(declaredValueFieldData) : 0;

    const showField = declaredValue > 50000 && showEwayBill && consignmentCategory !== 'international';

    if (!showField) return null;
    if (ewayBillType !== 'no_gstn') return null;

    return (
        <Col span={12}>
            {/* eslint-disable-next-line max-len */}
            <a href="https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipsy/sample-downloads/efr/Declaration_Note.docx">
                <DownloadOutlined
                    size={14}
                    style={{
                        marginRight: 8,
                        fontWeight: 'bold',
                    }}
                />
                Download Declaration
            </a>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    return {
        showEwayBill: config?.customer_portal_consignment_config?.show_eway_bill_details || false,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(EwayBillDownloadDeclaration);
