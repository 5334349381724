import * as React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { HocOptions } from '../common/generic-hoc.types';
import { Button, Input } from 'antd';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { CreateActions } from './create-modal.constants';
import bulkPickupIcon from '../../assets/bulk-pickup.png';
import { Pincodes } from '../../library/Types';
import GenericHoc from '../common/generic-hoc';
import PicodeAvailablityIcon from '../../assets/pincode-availability';
import TrackIcon from '../../assets/track-icon';
import SingleCNIcon from '../../assets/single-cn-icon';
import FTLHyperlocalIcon from '../../assets/ftl-hyperlocal-icon';
import SingleHyperlocalIcon from '../../assets/single-hyperlocal-icon';
import BulkCNIcon from '../../assets/bullk-cn-icon';
import BulkHyperlocalIcon from '../../assets/bulk-hyperlocal-icon';
import SinglePickupIcon from '../../assets/single-pickup-icon';
import { Master } from 'types/master-data-types';
import { useTranslation } from 'react-i18next';
import { DTDC_SRF_PORTAL } from 'library/globals';

const styles = (theme: ThemeType) => ({
    main: {
        position: 'absolute',
        marginTop: '3%',
        '& .ant-modal-content': {
            borderRadius: 5,
        },
        '& .ant-modal-body': {
            padding: 0,
        },
        '& .ant-btn-primary': {
            marginLeft: 2,
            color: '#FFF',
            backgroundColor: `${theme.primaryColor} !important`,
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 4,
        },
        '& .ant-btn-link': {
            fontSize: 12,
            paddingRight: 5,
            fontWeight: 600,
            color: theme.primaryColor,
        },
        '& .ant-input': {
            fontSize: 12,
        },
    },
    body: {
        boxSizing: 'border-box',
        border: '1px solid #FCFCFC',
        backgroundColor: '#FFFFFF',
        // padding: '16px 8px 12px 24px',
        padding: '24px',
        borderRadius: '5px',
    },
    title: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
    },
    description: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        color: '#666666',
    },
    consignment: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    consignmentOptions: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        height: 30,
        marginTop: 20,
        width: '50%',
        fontWeight: 600,
        letterSpacing: 0,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    line: {
        height: 1,
        marginTop: 24,
        marginBottom: 24,
        // width: '92%',
        backgroundColor: '#CBC2B0',
    },
    availability: {
        width: '100%',
        backgroundColor: '#F8F2DE',
        marginTop: 12,
        padding: '16px 12px 12px 24px',
        borderRadius: '0px 0px 5px 5px',
    },
    pincode: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    availabilityTitle: {
        display: 'flex',
        alignItems: 'top',
    },
    img: {
        fontSize: 32,
        marginRight: '2%',
    },
    pincodeTitle: {
        marginLeft: '1.5%',
    },
    pincodeText: {
        fontSize: 12,
        marginLeft: '6%',
        fontWeight: 600,
    },
    inputs: {
        marginLeft: '8%',
        display: 'flex',
        alignItems: 'center',
        width: '80%',
    },
    input: {
        width: '36.5%',
        margin: '0 2px',
    },
    button: {
        width: 80,
        color: '#F8F2DE !important',
    },
});

interface CreateModalProps extends StylesProps<ReturnType<typeof styles>> {
    pincodes: Pincodes;
    onClose: () => void;
    referenceNumber: string;
    setPincodes: (val: Pincodes) => void;
    setReferenceNumber: (val: string) => void;
    handleCreate: (e: any, action: CreateActions) => void;
    master: Master,
    isRTL: any,
}
const CreateModal = (props: CreateModalProps) => {
    const {
        classes,
        onClose,
        handleCreate,
        pincodes,
        setPincodes,
        referenceNumber,
        setReferenceNumber,
        master,
        isRTL,
    } = props;
    const { t } = useTranslation();
    const config = master.config;
    const showSRFRequest = master?.config?.customer_portal_config?.show_srf_request && !master?.Customer?.is_cpdp;
    const customerCode = master?.Customer?.code;
    const customerProperties = master.properties;
    const pickupCustomerConfig = master?.config?.customer_portal_ops_config?.pickup_configuration?.config_value || {};
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    let showBulkPickup = master?.config?.customer_portal_config?.show_bulk_pickup;
    let showPickUpSection = true;
    let showConsignmentSection = true;
    let showSingleCnSection = true;
    let showMultipleCnSection = true;
    let showPincodeAvailability = !config?.customer_portal_consignment_config?.is_international_client;
    let showSinglePickup = true;
    let showCnTracking = true;
    if (enable_customer_config && customerProperties) {
        showPickUpSection = customerProperties?.single_pickup_creation || customerProperties?.multiple_pickup_creation;
        showBulkPickup = (showBulkPickup && customerProperties?.multiple_pickup_creation);
        showSinglePickup = customerProperties?.single_pickup_creation;
        showConsignmentSection = customerProperties?.cn_single_creation || customerProperties?.cn_multiple_creation;
        showSingleCnSection = customerProperties?.cn_single_creation;
        showMultipleCnSection = customerProperties?.cn_multiple_creation;
        showPincodeAvailability = (showPincodeAvailability
            && customerProperties?.pincode_serviceability);
        showCnTracking = customerProperties?.cn_tracking;
    }
    if (pickupCustomerConfig?.pickup_type?.length && !pickupCustomerConfig?.pickup_type?.includes('Open Pickup')) {
        showSinglePickup = false;
        showPickUpSection = showBulkPickup || showSinglePickup;
    }

    const renderTitle = (title: string) => {
        return (
            <div className={classes.title}>
                {title}
            </div>
        );
    };

    const renderDescription = (type: string) => {
        return (
            <div className={classes.description}>
                {t(`${type}_description`)}
            </div>
        );
    };

    const handleCancel = (e: any) => {
        e.stopPropagation();
        onClose();
    };

    const renderSingleConsignment = () => {
        return (
            <div
                className={classes.consignmentOptions}
                onClick={(e) => handleCreate(e, CreateActions.SingleConsignment)}
            >
                <SingleCNIcon
                    style={{
                        fontSize: 24,
                        marginRight: 8,
                    }}
                />
                {t('single_consignment')}
            </div>
        );
    };

    const renderBulkConsignment = () => {
        return (
            <div
                className={classes.consignmentOptions}
                onClick={(e) => handleCreate(e, CreateActions.BulkConsignment)}
            >
                <BulkCNIcon
                    style={{
                        fontSize: 24,
                        marginRight: 8,
                    }}
                />
                {t('multiple_consignment')}
            </div>
        );
    };

    const renderSingleHyperlocal = () => {
        return (
            <div
                className={classes.consignmentOptions}
                onClick={(e) => handleCreate(e, CreateActions.SingleHyperlocal)}
            >
                <SingleHyperlocalIcon
                    style={{
                        fontSize: 24,
                        marginRight: 8,
                    }}
                />
                {t('single_booking')}
            </div>
        );
    };

    const renderBulkHyperlocal = () => {
        return (
            <div
                className={classes.consignmentOptions}
                onClick={(e) => handleCreate(e, CreateActions.BulkHyperlocal)}
            >
                <BulkHyperlocalIcon
                    style={{
                        fontSize: 24,
                        marginRight: 8,
                    }}
                />
                {t('bulk_bookings')}
            </div>
        );
    };

    const renderSinglePickup = () => {
        return (
            <div
                className={classes.consignmentOptions}
                onClick={(e) => handleCreate(e, CreateActions.SinglePickup)}
            >
                <SinglePickupIcon
                    style={{
                        fontSize: 24,
                        marginRight: 8,
                    }}
                />
                {t('single_pickup')}
            </div>
        );
    };

    const renderBulkPickup = () => {
        if (!showBulkPickup) return null;
        return (
            <div
                className={classes.consignmentOptions}
                onClick={(e) => handleCreate(e, CreateActions.BulkPickup)}
            >
                <img
                    alt="bulk-pickup"
                    src={bulkPickupIcon}
                    style={{ color: 'orange', fontSize: 32, marginRight: 8 }}
                />
                {t('bulk_pickup')}
            </div>
        );
    };

    const renderConsignment = () => {
        return (
            <div className={classes.consignment}>
                {showMultipleCnSection ? renderBulkConsignment() : null}
                {showSingleCnSection ? renderSingleConsignment() : null}
            </div>
        );
    };

    const renderHyperlocal = () => {
        return (
            <div className={classes.consignment}>
                {renderBulkHyperlocal()}
                {renderSingleHyperlocal()}
            </div>
        );
    };

    const renderPickup = () => {
        return (
            <div className={classes.consignment}>
                {renderBulkPickup()}
                { showSinglePickup ? renderSinglePickup() : null}
            </div>
        );
    };

    const renderFTL = () => {
        return (
            <div className={classes.consignment}>
                <div
                    className={classes.consignmentOptions}
                    onClick={(e) => handleCreate(e, CreateActions.FTLHyperlocal)}
                >
                    <FTLHyperlocalIcon
                        style={{
                            fontSize: 24,
                            marginRight: 8,
                        }}
                    />
                    {t('pickup_and_drop')}
                </div>
            </div>
        );
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const disableButton = () => {
        return (
            pincodes.dstPincode?.length !== 6
      || pincodes.srcPincode?.length !== 6
        );
    };

    const renderPincodeInputs = () => {
        return (
            <div className={classes.inputs}>
                <Input
                    placeholder={t('origin_pin')}
                    className={classes.input}
                    value={pincodes.srcPincode}
                    onChange={(e) => setPincodes({ ...pincodes, srcPincode: e.target.value })}
                />
                <Input
                    placeholder={t('destination_pin')}
                    className={classes.input}
                    value={pincodes.dstPincode}
                    onChange={(e) => setPincodes({ ...pincodes, dstPincode: e.target.value })}
                />
                <Button
                    type="primary"
                    disabled={disableButton()}
                    onClick={(e) => handleCreate(e, CreateActions.PincodeAvailability)}
                    className={classes.button}
                >
                    {t('check')}
                </Button>
            </div>
        );
    };

    const renderTrackInputs = () => {
        return (
            <div className={classes.inputs}>
                <Input
                    value={referenceNumber}
                    onChange={(e) => setReferenceNumber(e.target.value)}
                    placeholder={t('referenceNumberList')}
                    className={classes.input}
                    style={{ width: '74%' }}
                />
                <Button
                    type="primary"
                    disabled={!referenceNumber}
                    onClick={(e) => handleCreate(e, CreateActions.Track)}
                    className={classes.button}
                >
                    {t('track')}
                </Button>
            </div>
        );
    };

    const renderPincodeAvailability = () => {
        return (
            <div className={classes.pincode}>
                <div className={classes.availabilityTitle}>
                    <PicodeAvailablityIcon
                        style={{
                            fontSize: 24,
                            marginRight: 8,
                        }}
                    />
                    <span className={classes.pincodeTitle}>
                        {t('pincode_serviceability')}
                    </span>
                </div>
                {renderPincodeInputs()}
                {/* <div className={classes.pincodeText}>
      <Button type="link">Click here</Button>
      to check bulk pin services
    </div> */}
            </div>
        );
    };

    const renderTrackConsignment = () => {
        return (
            <div className={classes.pincode}>
                <div className={classes.availabilityTitle}>
                    <TrackIcon
                        style={{
                            fontSize: 24,
                            marginRight: 8,
                        }}
                    />
                    <span className={classes.pincodeTitle}>
                        {t('track_consignment')}
                    </span>
                </div>
                {renderTrackInputs()}
            </div>
        );
    };

    const renderSRF = () => {
        return (
            <div className={classes.pincode}>
                <div className={classes.availabilityTitle}>
                    <TrackIcon
                        style={{
                            fontSize: 24,
                            marginRight: 8,
                        }}
                    />
                    <span className={classes.pincodeTitle}>
                        Spot Rate Request
                    </span>
                </div>
                <div className={classes.inputs}>
                    <div className={classes.description}>
                        Got a huge booking ahead? Raise a request for a discount code.
                    </div>
                    <form
                        action={DTDC_SRF_PORTAL}
                        method="post"
                        target="_blank"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                            e.target.submit();
                            onClose();
                        }}
                    >
                        <input id="userCode" type="hidden" name="userCode" value={customerCode} />
                        <input type="hidden" name="REQUESTSOURCE" id="REQUESTSOURCE" value="DPCUSTOMER" />
                        <Button
                            type="primary"
                            className={classes.button}
                            htmlType="submit"
                            onClick={() => onClose()}
                        >
                            {t('Request')}
                        </Button>
                    </form>
                </div>
            </div>
        );
    };

    const renderAvailability = () => {
        return (
            <div className={classes.availability}>
                {showSRFRequest ? renderSRF() : null}
                { showPincodeAvailability ? renderPincodeAvailability() : null}
                { showCnTracking ? renderTrackConsignment() : null}
            </div>
        );
    };

    const renderFTLWithCondition = () => {
        if (!config?.customer_portal_ops_config?.additional_config?.config_value?.allow_full_truck_load_request) {
            return null;
        }

        return (
            <>
                {showPickUpSection ? renderLine() : null}
                {renderTitle(t('add_ftl'))}
                {renderDescription('ftl')}
                {renderFTL()}
            </>
        );
    };

    const renderBody = () => {
        const { customer_portal_ops_config } = config;
        const availableForms = customer_portal_ops_config?.consignment_flow_type_config
            ?.config_value?.allowed_consignment_flow_type_customer_portal || [];
        const showHyperlocal = availableForms.includes('Hyperlocal');
        let showStandard = availableForms.includes('Standard');
        if (!Object.keys(availableForms).length) showStandard = true;
        // const showHyperlocal = true;
        // const showStandard = true;
        return (
            <div className={classes.body}>
                {showHyperlocal ? (
                    <>
                        {renderTitle(t('hyperlocal'))}
                        {renderDescription('hyperlocal')}
                        {renderHyperlocal()}
                        {renderLine()}
                    </>
                ) : null}
                {showStandard ? (
                    <>
                        {showConsignmentSection ? (
                            <>
                                {showHyperlocal ? renderLine() : null}
                                {renderTitle(t('add_consignment'))}
                                {renderDescription('consignment')}
                                {renderConsignment()}
                                {renderLine()}
                            </>
                        ) : null}
                        {showPickUpSection ? (
                            <>
                                {renderTitle(t('add_pickup'))}
                                {showBulkPickup ? renderDescription('pickup') : null}
                                {renderPickup()}
                            </>
                        ) : null}
                        {renderFTLWithCondition()}
                    </>
                ) : null}
            </div>
        );
    };

    return (
        <Modal
            visible
            width="40%"
            style={{
                maxWidth: 450,
                marginLeft: isRTL ? 'calc(100vw - min(41%, 460px))' : '1%',
            }}
            title={null}
            footer={null}
            closable={false}
            maskClosable
            onCancel={(e) => handleCancel(e)}
            className={classes.main}
            maskStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
            }}
        >
            {renderBody()}
            {renderAvailability()}
        </Modal>
    );
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(CreateModal);
