// import * as moment from 'moment';
const moment = require('moment');

export const orderCreationFormSteps = [
    {
        key: 'primaryDetails',
        title: 'Primary Details',
    },
    {
        key: 'locationDetails',
        title: 'Address Details',
    },
    {
        key: 'orderDetails',
        title: 'Service Details',
    },
];

export const momentTimeStringLocal = (time: any) => {
    return moment(time).format('HH:mm:ss').toString();
};

export const dateFormat = (date: any, format?:any) => {
    return date?.format(format || 'YYYY-MM-DD');
};

export const momentsDateTime = (date: any, time: any) => {
    return `${dateFormat(date)} ${momentTimeStringLocal(time)}`;
};

export const formatTimeWithoutAMPM = (time: any) => {
    return time ? moment(time).format('HH:mm') : '---';
};

export const previewStructure = [
    {
        title: 'Primary Details',
        fields: [
            {
                key: 'order_number',
                label: 'Reference Number',
            },
            {
                key: 'invoice_number',
                label: 'Invoice Number',
            },
            {
                key: 'hu_details',
                label: 'HU No.',
            },
            {
                key: 'payment_mode',
                label: 'Payment Mode',
            },
            {
                key: 'order_type',
                label: 'Order Type',
            },
        ],
    },
    {
        title: 'Items Details',
        fields: [
            {
                key: 'pickup_details_list',
                label: 'Pieces Details',
                type: 'table',
                width: '500px',
            },
        ],
    },
    {
        title: 'Location Details',
        fields: [
            {
                key: 'pickup_location_address',
                label: 'Pickup Location',
            },
            {
                key: 'destination_location_address',
                label: 'Destination Location',
            },
        ],
    },
    {
        title: 'Service Details',
        fields: [
            {
                key: 'service_type_name',
                label: 'Service Type',
            },
            {
                key: 'order_date',
                label: 'Date',
            },
            {
                key: 'start_time',
                label: 'Start Time',
            },
            {
                key: 'end_time',
                label: 'End Time',
            },
        ],
    },
    {
        title: '',
        fields: [
            {
                key: 'final_amount',
                label: 'Amount',
            },
        ],
    },
];

export const columnsMap: any = {
    pickup_details_list: [
        {
            key: 'numberOfPieces',
            pretty_name: 'No. of Pieces',
        },
        {
            key: 'declaredValue',
            pretty_name: 'Declared Value',
        },
        {
            key: 'height',
            pretty_name: 'Height',
        },
        {
            key: 'width',
            pretty_name: 'Width',
        },
        {
            key: 'length',
            pretty_name: 'Length',
        },
        {
            key: 'volumetricWeight',
            pretty_name: 'Volumetric Weight',
        },
        {
            key: 'weight',
            pretty_name: 'Weight',
        },
    ],
};
