import * as React from 'react';
import { Input, message, Modal } from 'antd';
import { StylesProps, ThemeType } from 'theme/jss-types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { getPastFeedback, setNewFeedback } from 'network/common.api';
import { commonStyleSheet } from 'library/common-styles';
import Loader from 'components/common/Loader';
import VeryUnhappy from 'assets/emojis/very-unhappy';
import VeryUnhappyGrey from 'assets/emojis/very-unhappy-grey';
import Unhappy from 'assets/emojis/unhappy';
import UnhappyGrey from 'assets/emojis/unhappy-grey';
import Neutral from 'assets/emojis/neutral';
import NeutralGrey from 'assets/emojis/neutral-grey';
import Happy from 'assets/emojis/happy';
import HappyGrey from 'assets/emojis/happy-grey';
import VeryHappy from 'assets/emojis/very-happy';
import VeryHappyGrey from 'assets/emojis/very-happy-grey';

const styles = (theme: ThemeType) => ({
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexColumn,
        padding: 0,
    },
    addText: {
        color: '#111111',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        width: '50%',
        margin: '20px 0px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        fontSize: 24,
        cursor: 'pointer',
    },
    selectedIcon: {
        fontSize: 36,
        cursor: 'pointer',
        color: 'grey',
    },
    descText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
});

interface FeedbackProps extends StylesProps<ReturnType<typeof styles>> {
    landingTime: number;
    interval: number;
    moduleId: string;
    moduleName: string;
}
const Feedback = (props: FeedbackProps) => {
    const {
        classes,
        landingTime,
        interval,
        moduleId,
        moduleName,
    } = props;

    const [feedbackVisible, setFeedbackVisible] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [ratingItem, setRatingItem] = React.useState<any>();
    const [description, setDescription] = React.useState<string>('');

    const fetchFeedback = async () => {
        setLoading(true);
        const response = await getPastFeedback({
            moduleId,
        });
        setLoading(false);
        return response?.data;
    };

    const saveFeedback = async () => {
        setSaving(true);
        const body = {
            description,
            moduleId,
            rating: ratingItem.rating,
        };

        const response = await setNewFeedback(body);
        setSaving(false);
        if (response.isSuccess) {
            message.success('Feedback recorded successfully');
            setFeedbackVisible(false);
        } else {
            message.error(response.errorMessage);
        }
    };

    const triggerTimeout = async () => {
        const storage = window.localStorage;
        const lastFeedbackVisibleAt = storage.getItem('lastFeedbackTime');
        if (!landingTime) {
            return;
        }

        const lastFeedback = await fetchFeedback();
        if (lastFeedback?.data || !lastFeedback?.isSuccess) {
            return;
        }
        const currentTime = new Date().valueOf();

        if (lastFeedbackVisibleAt) {
            const lastTimeDifference = Number(currentTime) - Number(lastFeedbackVisibleAt);
            if (lastTimeDifference < 24 * 60 * 60 * 1000) {
                return;
            }
        }
        const difference = Number(currentTime) - Number(landingTime);
        if (difference >= interval) {
            setFeedbackVisible(true);
            storage.setItem('lastFeedbackTime', currentTime.toString());
        } else {
            setTimeout(() => {
                setFeedbackVisible(true);
                storage.setItem('lastFeedbackTime', currentTime.toString());
            }, interval - difference);
        }
    };

    React.useEffect(() => {
        triggerTimeout();
    }, []);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    Rate your experience
                </div>
                <div className={classes.descText}>
                    How would you rate the overall experience with &nbsp;
                    {moduleName}
                </div>
            </div>
        );
    };

    const iconMapping = [{
        rating: 1,
        SelectedIcon: VeryUnhappy,
        Icon: VeryUnhappyGrey,
        key: 'very_unhappy',
    }, {
        rating: 2,
        SelectedIcon: Unhappy,
        Icon: UnhappyGrey,
        key: 'unhappy',
    }, {
        rating: 3,
        SelectedIcon: Neutral,
        Icon: NeutralGrey,
        key: 'neutral',
    }, {
        rating: 4,
        SelectedIcon: Happy,
        Icon: HappyGrey,
        key: 'happy',
    }, {
        rating: 5,
        SelectedIcon: VeryHappy,
        Icon: VeryHappyGrey,
        key: 'very_happy',
    }];

    const renderIcon = (Item: any) => {
        if (ratingItem?.key === Item.key) {
            return (
                <Item.SelectedIcon
                    className={classes.selectedIcon}
                />
            );
        }
        return (
            <Item.SelectedIcon
                className={classes.icon}
                onClick={() => {
                    setRatingItem(Item);
                }}
            />
        );
    };

    const renderRatings = () => {
        return (
            <div className={classes.row}>
                {iconMapping.map((Item) => {
                    return (
                        renderIcon(Item)
                    );
                })}
            </div>
        );
    };

    const renderInput = () => {
        return (
            <div>
                <span className={classes.descText}>Tell us how we can improve your experience</span>
                <Input.TextArea
                    placeholder="Type Description (Max 250 Words)"
                    onChange={(e) => setDescription(e.target.value)}
                    cols={3}
                    rows={3}
                    autoSize={{
                        minRows: 3,
                        maxRows: 5,
                    }}
                    maxLength={250}
                />
            </div>
        );
    };

    const renderBody = () => {
        if (loading) {
            return <Loader />;
        }
        return (
            <div>
                {renderHeader()}
                {renderRatings()}
                {renderInput()}
            </div>
        );
    };

    return (
        <Modal
            visible={feedbackVisible}
            title={null}
            onCancel={() => setFeedbackVisible(false)}
            cancelButtonProps={{
                type: 'text',
            }}
            okText="Submit"
            cancelText="I’ll do this later"
            onOk={() => saveFeedback()}
            okButtonProps={{
                disabled: saving || !ratingItem,
                loading: saving,
            }}
            className={classes.main}
            closable={false}
        >
            {renderBody()}
        </Modal>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};
export default GenericHoc(hocConfig)(Feedback);
