import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
    setGenericSummary,
    setPagination,
} from './generic-action';
import moment from 'moment';
import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { AsnBucketChild, AsnBucketParent } from '../components/advanceShippingNote/advanceShippingNote.constants';
import {
    fetchAsnData,
    fetchSummary,
} from '../network/asn.api';
import { getFilters } from 'components/advanceShippingNote/advanceShippingNote.helper';

const bucket = Buckets.ASN;
const dateFormat = 'YYYY-MM-DD';

const arrayFilters = ['statusList'];
export const getMoreFilters = (appliedFilters: any) => {
    const filters: Record<string, any> = {};
    Object.keys(appliedFilters).forEach((filter) => {
        filters[filter] = appliedFilters[filter];
        if (arrayFilters.includes(filter)) {
            filters[filter] = [appliedFilters[filter]];
        }
    });
    return filters;
};

const loadSummary = (appliedFilters: any, filters: any) => async (dispatch: Dispatch<any>) => {
    const isParentCustomer = JSON.parse(window.localStorage.getItem('parentId') as string);
    const summaryDateFilters = [
        {
            columnName: 'asnCreatedAtDate',
            fromDateString: moment(appliedFilters.uploadStartDate).format(dateFormat),
            toDateString: moment(appliedFilters.uploadEndDate).format(dateFormat),
        },
    ];
    if (filters.isAsnDateApplied) {
        summaryDateFilters.push({
            columnName: 'asnDate',
            fromDateString: moment(appliedFilters.startDate).format(dateFormat),
            toDateString: moment(appliedFilters.endDate).format(dateFormat),
        });
    }
    const response = await fetchSummary({
        startDate: moment(appliedFilters.startDate).format('YYYY-MM-DD'),
        endDate: moment(appliedFilters.endDate).format('YYYY-MM-DD'),
        bucketIds: isParentCustomer ? Object.keys(AsnBucketChild) : Object.keys(AsnBucketParent),
        timeFilters: summaryDateFilters,
        ...filters,
    });
    if (response.isSuccess) {
        dispatch(setGenericSummary(bucket, response?.data || {}));
    } else {
        dispatch(setGenericSummary(bucket, {}));
    }
};

export const loadAsn = () => async (
    dispatch: Dispatch<any>,
    store: () => ReduxStore,
) => {
    dispatch(startLoading(bucket));

    const { generic, master } = store();
    const { ASN } = generic;
    const { config } = master;
    const { appliedFilters, pagination } = ASN;

    const filtersInfo = getFilters(appliedFilters.bucketSelected, config);
    const filters = getMoreFilters(appliedFilters);
    filtersInfo.forEach((filter: any) => {
        if (filters[filter.id] && filter.mode === 'multiple' && typeof filters[filter.id] === 'string') {
            filters[filter.id] = filters[filter.id].split(',').map((value: string) => value.trim());
        }
        if (filters[filter.id] && filter.id === 'asnCreatedAtDate' && filter.type === 'date') {
            filters[filter.id] = moment(filters[filter.id]).format('YYYY-MM-DD');
        }
    });
    const dateFilters = [
        {
            columnName: 'asnCreatedAtDate',
            fromDateString: moment(appliedFilters.uploadStartDate).format(dateFormat),
            toDateString: moment(appliedFilters.uploadEndDate).format(dateFormat),
        },
    ];
    if (filters.isAsnDateApplied) {
        dateFilters.push({
            columnName: 'asnDate',
            fromDateString: moment(appliedFilters.startDate).format(dateFormat),
            toDateString: moment(appliedFilters.endDate).format(dateFormat),
        });
    }
    dispatch(loadSummary(appliedFilters, filters));
    const response = await fetchAsnData({
        resultPerPage: pagination.resultPerPage,
        pageNumber: pagination.currentPageNumber,
        bucket: appliedFilters.bucketSelected,
        timeFilters: dateFilters,
        descendingOrder: appliedFilters.descendingOrder,
        ...filters,
        sortBy: appliedFilters.dateType || appliedFilters.sortBy,
    });
    if (response.isSuccess) {
        dispatch(setGenericData(bucket, response?.data?.page_data || []));
        dispatch(setPagination(bucket, {
            isNextPresent: response.data?.isNextPresent,
        }));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
