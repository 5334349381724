import React, { useEffect, useRef } from 'react';
import './pinlocationmap.css';
import UIMap from './UIMap/UIMap';
import { UIMapMarker } from './UIMap';
import type { MapRef } from 'react-map-gl';

type PinLocationMapProps = {
    coordinates: any;
    onLocationChange: (coords: any) => any;
};

const PinLocationMap = (props: PinLocationMapProps) => {
    const {
        coordinates,
        onLocationChange,
    } = props;

    const updateCenter = useRef<boolean>(true);
    const mapRef = React.useRef<MapRef>();

    const viewState = {
        latitude: coordinates?.lat ?? 28.4493,
        longitude: coordinates?.lng ?? 77.0759,
        zoom: 15,
    };

    const updateMapCenter = (map: any) => {
        const currentCenter = map?.target.getCenter();
        const centerCoordinates = {
            lat: currentCenter.lat,
            lng: currentCenter.lng,
        };
        if (onLocationChange) onLocationChange(centerCoordinates);
    };

    useEffect(() => {
        if (
            (updateCenter.current && coordinates?.lat && coordinates?.lng)
            || (coordinates?.lat && coordinates?.lng && coordinates?.geocodedAddress)
        ) {
            mapRef?.current?.flyTo({
                center: [coordinates.lng, coordinates.lat],
                speed: 4,
            });
            updateCenter.current = false;
        }
    }, [coordinates]);

    return (
        <UIMap
            mapRef={mapRef as any}
            initialViewState={viewState}
            onZoom={updateMapCenter}
            onDrag={updateMapCenter}
            onMove={updateMapCenter}
        >
            {(coordinates?.lat && coordinates.lng) ? (
                <UIMapMarker
                    coordinates={[
                        coordinates.lng,
                        coordinates.lat,
                    ]}
                    color="red"
                />
            ) : <></>}
        </UIMap>
    );
};

export default PinLocationMap;
