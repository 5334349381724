import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Form,
    FormInstance,
} from 'antd';
import { FormField } from 'components/create-consignment/create-consignment.types';
import GenericField from './generic-field';

const styles = () => ({});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    field: FormField;
    extraValidationForVisibility?: ((params: any) => boolean) | null;
}

const GenericInternationalField = (props: IProps) => {
    const {
        params,
        fieldsToShowInternational,
        form,
        field,
        extraValidationForVisibility,
    } = props;
    const {
        ConsignmentCategory,
        ItemType,
    } = formFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);

    const isInternational = consignmentCategory === 'international';

    if (!isInternational) {
        return null;
    }

    if (!fieldsToShowInternational[field.key]) return null;

    if (extraValidationForVisibility && !extraValidationForVisibility({
        itemType,
    })) {
        return null;
    }

    return <GenericField params={params} form={form} field={field} />;
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const fieldsToShowInternational = customerPortalConfig?.fields_to_show_international || {};
    return {
        fieldsToShowInternational,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(GenericInternationalField);
