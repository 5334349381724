import {
    Button,
    DatePicker,
    message,
    Select,
    Table,
} from 'antd';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import Paginator from 'components/common/paginator';
import { TableColumn } from 'components/settings-product/settings.types';
import { NAVBAR_HEIGHT } from 'library/globals';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { ReduxStore } from 'reducers/redux.types';
import { bindActionCreators } from 'redux';
import { getDownloadsRoute } from 'routing/routing-helper';
import { Master } from 'types/master-data-types';
import { createDownloadRequestCODRemittance, getChildList } from '../../network/consignments.api';
import { StylesProps } from '../../theme/jss-types';
import { RemittanceReportColumns } from './reports.constants';
import { remittanceReportStyles } from './remittance-report.styles';
import { RouteChildrenProps } from 'react-router';
import {
    Buckets,
    Customer,
    NextOrPrev,
    Pagination,
} from 'library/Types';
import { loadRemittanceData } from 'actions/remittance-actions';
import { applyFilters, setFilters, setPagination } from 'actions/generic-action';
import { isEqual } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';

interface remittanceReportProps extends StylesProps<ReturnType<typeof remittanceReportStyles>>, RouteChildrenProps {
    master: Master,
    remittanceData: any[],
    loadRemittance: () => void,
    loading: boolean,
    pagination: Record<string, any>,
    changePagination: (bucketId: Buckets.REMITTANCE, newPagination: Partial<Pagination>) => void;
    setFilter: (bucketId: Buckets.REMITTANCE, filters: any) => void;
    applyFilter: (bucketId: Buckets.REMITTANCE) => void;
    filters: Record<string, any>,
    defaultFilters: Record<string, any>,
}

const RemittanceReportPage = (props: remittanceReportProps) => {
    const {
        classes,
        master,
        history,
        remittanceData,
        loadRemittance,
        loading,
        pagination,
        changePagination,
        setFilter,
        applyFilter,
        filters,
        defaultFilters,
    } = props;

    const [childClients, setChildClients] = React.useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(null);
    const [defaultCustomer, setDefaultCustomer] = React.useState<any>(null);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([
        moment().subtract(1, 'months').startOf('month'),
        moment(),
    ]);
    const [selectedRows, setSelectedRows] = React.useState<any>([]);
    const [downloading, setDownloading] = React.useState<boolean>(false);

    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 150;

    const loadCustomerCodes = async () => {
        const response: any = await getChildList();
        if (response?.status) {
            setChildClients(response?.data);
        } else {
            return;
        }
        const storage = window?.localStorage;
        const id = storage?.getItem('userId');
        const code = storage?.getItem('userCode');
        const currentCustomer = JSON.stringify({
            customer_code: code,
            customer_id: id,
        });
        setDefaultCustomer(currentCustomer);
        setSelectedCustomer(currentCustomer);
    };

    React.useEffect(() => {
        loadCustomerCodes();
    }, []);

    React.useEffect(() => {
        loadRemittance();
    }, [toggle, filters]);

    const handleDateChange = (obj: any) => {
        setDateRange(obj);
        setFilter(Buckets.REMITTANCE, {
            ...filters,
            fromDate: obj[0].unix(),
            toDate: obj[1].unix(),
        });
        applyFilter(Buckets.REMITTANCE);
    };

    const renderDatePicker = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Date Range
                </span>
                <DatePicker.RangePicker
                    allowEmpty={[false, false]}
                    allowClear={false}
                    value={dateRange}
                    onChange={handleDateChange}
                />
            </div>
        );
    };

    const isMoreFilterAdded = () => {
        const dateFormat = 'YYYY-MM-DD';
        return (!isEqual(defaultCustomer, selectedCustomer))
            || (!(
                moment(defaultFilters?.fromDate * 1000)?.format(dateFormat) === dateRange[0].format(dateFormat)
                && moment(defaultFilters?.toDate * 1000)?.format(dateFormat) === dateRange[1].format(dateFormat)
            ));
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setSelectedCustomer(defaultCustomer);
                    setDateRange([
                        moment(defaultFilters.fromDate * 1000),
                        moment(defaultFilters.toDate * 1000),
                    ]);
                    setFilter(Buckets.REMITTANCE, {
                        ...defaultFilters,
                    });
                    applyFilter(Buckets.REMITTANCE);
                }}
            >
                Reset Filter
            </div>
        );
    };

    const changeCustomerCodeFilter = (obj: any) => {
        const customer = JSON.parse(obj || '{}');
        setFilter(Buckets.REMITTANCE, {
            ...filters,
            ...(customer || {}),
        });
        applyFilter(Buckets.REMITTANCE);
    };

    const renderCustomerCode = () => {
        return (
            <div className={classes.filter}>
                <Select
                    placeholder="Select Customer Code"
                    allowClear={false}
                    showSearch
                    options={childClients.map((cust) => {
                        return {
                            value: JSON.stringify({
                                customer_code: cust?.customer_code,
                                customer_id: cust?.customer_id,
                            }),
                            label: `${cust?.customer_code} - ${cust?.customer_name}`,
                        };
                    })}
                    value={selectedCustomer}
                    onChange={(value) => {
                        changeCustomerCodeFilter(value);
                        setSelectedCustomer(value);
                    }}
                />
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        changePagination(Buckets.REMITTANCE, newPagination);
        setToggle(!toggle);
    };


    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination?.currentPageNumber || 1}
                isNextPresent={pagination?.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };

    const renderDownloadButton = () => {
        return (
            <Button
                type="primary"
                className={classes.downloadButton}
                loading={downloading}
                onClick={async () => {
                    setDownloading(true);
                    const storage = window?.localStorage;
                    const code = storage?.getItem('userCode');
                    const dateFormat = 'YYYY-MM-DD';
                    const response = await createDownloadRequestCODRemittance({
                        bucket: 'customer_portal_all',
                        filterParams: {
                            fromDate: moment(filters?.fromDate * 1000).format(dateFormat),
                            toDate: moment(filters?.toDate * 1000).format(dateFormat),
                            customerCode: filters?.customer_code || code,
                        },
                        consignmentNumberList: selectedRows,
                        bucketSelected: 'customer_portal_all',
                    });
                    if (response.isSuccess) {
                        message.success(response.message);
                        history.push(getDownloadsRoute());
                    } else {
                        message.error(response.errorMessage);
                    }
                    setDownloading(false);
                }}
            >
                <DownloadOutlined />
                &nbsp;
                Download Report
            </Button>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.filter}>
                {renderCustomerCode()}
                {renderDatePicker()}
                {renderResetFiltersOption()}
            </div>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderDownloadButton()}
                {renderPaginator()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderText = (text: string, row: any) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };
    const renderColumn = (text: any, row: any, column: TableColumn) => {
        if (['DELIVERY_DATE', 'BOOKING_DATE'].includes(column.key)) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString, row);
        }
        // please don't change this default object check
        if (typeof text === 'object') {
            return <div className={classes.cellValue}>{JSON.stringify(text)}</div>;
        }
        return renderText(text, row);
    };


    const getColumns = (): any[] => {
        return RemittanceReportColumns.map((column) => {
            return {
                key: column.key,
                title: column.pretty_name,
                dataIndex: column.key,
                width: 100,
                ellipsis: true,
                fixed: false,
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
    };

    const renderTable = () => {
        return (
            <Table
                columns={getColumns()}
                dataSource={remittanceData || []}
                loading={loading}
                className={classes.table}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                bordered={false}
                pagination={false}
                rowSelection={{
                    onSelect: (row, selected: any) => {
                        let newArray = [...selectedRows];
                        if (selected && !newArray.includes(row?.Shipment_No)) {
                            newArray.push(row?.Shipment_No);
                        } else {
                            newArray = newArray.filter((item) => (item !== row?.Shipment_No));
                        }
                        setSelectedRows(newArray);
                    },
                    onSelectAll: (selected, allSelectedRows) => {
                        const newArray = allSelectedRows.map((item) => (item.Shipment_No));
                        setSelectedRows(newArray);
                    },
                }}
                rowKey={(row) => row.Shipment_No}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const remittance = generic.REMITTANCE;
    const {
        loading,
        data,
        pagination,
        filters,
        defaultFilters,
    } = remittance;
    return {
        loading,
        pagination,
        remittanceData: data,
        filters,
        defaultFilters,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setFilter: setFilters,
        applyFilter: applyFilters,
        loadRemittance: loadRemittanceData,
        changePagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: remittanceReportStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const RemittanceReport = GenericHoc(hocConfig)(RemittanceReportPage);
export default RemittanceReport;
