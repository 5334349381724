import * as React from 'react';
import Filter from '../common/filters';
import Cross from '../../assets/cross';
import { Button, Drawer } from 'antd';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { getFilters } from './consignments.helper';
import { ConsignmentBucketKeys, MasterConfig } from '../../types/master-data-types';
import { ReduxStore } from '../../reducers/redux.types';
import { Buckets, Customer } from '../../library/Types';
import {
    applyFilters,
    clearSelection,
    resetFilters,
    setFilters,
} from '../../actions/generic-action';
import { bindActionCreators } from 'redux';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { getChildList } from 'network/consignments.api';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { allowedFilters } from 'library/globals';
import { PAGE_NAMES, PageData } from 'types/page.types';

const styles = (theme : ThemeType) => ({
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        overflow: 'scroll',
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            marginBottom: 56,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input-affix-wrapper': {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input, .ant-picker': {
            width: 250,
            borderRadius: 4,
            border: '1px solid #999999',
            height: 32,
            fontSize: 11,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-drawer-content-wrapper': {
            minWidth: 440,
        },
    },
    body: {
        padding: 24,
        paddingBottom: 8,
        width: '100%',
    },
    hr: {
        width: '100%',
        height: 1,
        backgroundColor: '#CCCCCC',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
    },
    moreFilter: {
        marginLeft: 16,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.primaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelClass: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: 120,
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        height: 56,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>> {
    onClose: () => void;
    config: MasterConfig;
    bucketSelected: ConsignmentBucketKeys;
    setfilters: (bucketId: Buckets.CONSIGNMENTS, filters: any) => void;
    resetfilters: (bucketId: Buckets.CONSIGNMENTS) => void;
    applyfilters: (bucketId: Buckets.CONSIGNMENTS) => void;
    filters: Record<string, any>;
    clearselection: (bucketId: Buckets.CONSIGNMENTS) => void;
    resetVisible: boolean;
    use_maria_db: boolean;
    consignmentPageData: PageData | undefined;
}

const ExtraFilters = (props: IProps) => {
    const {
        classes,
        onClose,
        config,
        bucketSelected,
        setfilters,
        resetfilters,
        applyfilters,
        clearselection,
        filters,
        resetVisible,
        use_maria_db,
        consignmentPageData,
    } = props;

    const [loading, setLoading] = React.useState<boolean>(false);
    const [childClients, setChildClients] = React.useState<Customer[]>([]);
    const { t } = useTranslation();

    const handleClose = (refresh = false) => {
        if (refresh) {
            applyfilters(Buckets.CONSIGNMENTS);
        } else {
            clearselection(Buckets.CONSIGNMENTS);
        }
        onClose();
    };

    const loadAccounts = async () => {
        setLoading(true);
        const result = await getChildList();
        setChildClients(result?.data || []);
        setLoading(false);
    };

    React.useEffect(() => {
        loadAccounts();
    }, []);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('more_filters')}
                    </span>
                </div>
                {resetVisible && (
                    <span
                        className={classes.resetFilter}
                        onClick={() => {
                            resetfilters(Buckets.CONSIGNMENTS);
                            onClose();
                        }}
                    >
                        {t('reset_filters')}
                    </span>
                )}
            </div>
        );
    };

    const onChangeFilter = (filter: any, value: any) => {
        const newFilters = {
            ...filters,
            [filter.id]: value,
        };
        setfilters(Buckets.CONSIGNMENTS, newFilters);
    };

    const renderFilter = (filter: any) => {
        return (
            <div className={classes.row}>
                <Filter
                    childList={childClients}
                    loading={loading}
                    key={filter.id}
                    moreFilter
                    labelClass={classes.labelClass}
                    filter={filter}
                    value={filters[filter.id]}
                    onChange={(value) => onChangeFilter(filter, value)}
                />
            </div>
        );
    };

    const renderFilterList = () => {
        let filterIdList;
        if (consignmentPageData) {
            const viewData = consignmentPageData.metadata.sectionList.flat().find((v) => v.id === bucketSelected);
            filterIdList = viewData?.visibleFilterList?.map((f) => f.id) || undefined;
        }
        let filterList = getFilters(
            consignmentPageData ? 'customer_portal_all' : bucketSelected,
            config,
            filterIdList,
        );

        if (use_maria_db && allowedFilters?.length) {
            filterList = filterList.filter((filter) => allowedFilters.includes(filter.id));
        }

        return filterList.map((filter) => renderFilter(filter));
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                {renderFilterList()}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <Button
                    onClick={() => handleClose(true)}
                    type="primary"
                    style={{ marginLeft: 10, marginRight: 20 }}
                >
                    {t('apply_filters')}
                </Button>
                <Button
                    onClick={() => handleClose()}
                    type="dashed"
                >
                    {t('cancel')}
                </Button>
            </div>
        );
    };

    return (
        <Drawer
            className={classes.main}
            visible
            width="30%"
            title={renderHeader()}
            onClose={() => onClose()}
            closable={false}
        >
            {renderBody()}
            {renderLine()}
            {renderFooter()}
        </Drawer>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const {
        generic,
        uiTheme,
        master,
        genericPage,
    } = state;
    const { CONSIGNMENTS } = generic;
    const { filters } = CONSIGNMENTS;

    const use_maria_db = get(master, 'config.customer_portal_config.use_mariadb_in_consignment_view');
    return {
        use_maria_db,
        filters,
        uiTheme,
        consignmentPageData: genericPage[PAGE_NAMES.CONSIGNMENTS],
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        resetfilters: resetFilters,
        applyfilters: applyFilters,
        clearselection: clearSelection,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};
const MoreFilters = GenericHoc(hocConfig)(ExtraFilters);

export default MoreFilters;
