
export const getLabelType = (labelType: string) => {
    switch (labelType) {
        case 'STANDARD':
            return false;
        case 'SMALL':
            return true;
        default:
            return false;
    }
};

export const getLabelTypeLabelCode = (labelType: string) => {
    switch (labelType) {
        case 'STANDARD':
            return 'SHIP_LABEL_A4';
        case 'SMALL':
            return 'SHIP_LABEL_A6';
        default:
            return 'SHIP_LABEL_A4';
    }
};
