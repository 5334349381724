import { ThemeType } from 'theme/jss-types';

/* eslint-disable max-len */
export const newProductStyles = (theme: ThemeType) => ({
    modal: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
            borderRadius: '4px 4px 0 0',
            padding: '16px 12px',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: 12,
        },
        '& .ant-btn-primary': {
            fontSize: 12,
            fontWeight: 600,
        },
        top: '10%',
        left: '6%',
    },
    title: {
        padding: '1%',
        borderBottom: '0.5px solid #979797',
        fontSize: 16,
        fontWeight: 600,
        color: theme.primaryColor,
    },
    key: {
        padding: '2% 1%',
        borderBottom: '0.5px solid #979797',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
    },
    label: {
        color: theme.primaryColor,
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 550,
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 12,
        },
        '& .ant-form-item-children': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        '& .ant-form-item-explain.ant-form-item-explain-error': {
            fontSize: 10,
        },
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    form: {
        width: '100%',
        '& .ant-input, .ant-input:hover, .ant-input:focus, .ant-input-focused': {
            marginTop: 2,
            background: 'none',
            borderRadius: 0,
            boxSizing: 'border-box',
            display: 'block',
            float: 'left',
            height: 30,
            borderWidth: '0 0 1px 0',
            borderRight: 'none',
            padding: '2px 2px 2px 0px',
            transition: 'border-color .4s cubic-bezier(.25,.8,.25,1)',
            boxShadow: 'none',
            outline: 'none',
        },
        '& .ant-form-item-has-error .ant-input:focus': {
            marginTop: 2,
            background: 'none',
            borderRadius: 0,
            boxSizing: 'border-box',
            display: 'block',
            float: 'left',
            height: 30,
            borderWidth: '0 0 1px 0',
            borderRight: 'none',
            padding: '2px 2px 2px 0px',
            transition: 'border-color .4s cubic-bezier(.25,.8,.25,1)',
            boxShadow: 'none',
            outline: 'none',
        },
    },
    formParent: {
        width: '30%',
        marginRight: '2%',
    },
});
