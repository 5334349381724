import { fetchObjectPagesForCustomer } from '../network/objectpage.api';
import { Dispatch } from 'redux';
import { PageDataResponseType } from '../types/page.types';
import {
    SET_PAGE_FOR_CUSTOMERS,
    UPDATE_VIEW_COLUMNS,
    FETCH_VIEW_DATA,
    SET_VIEW_DATA,
    UPDATE_VIEW_FILTERS,
} from '../reducers/redux-constants';
import { fetchObjectViewById } from 'network/objectview.api';

export const fetchPageForEmployee = async (dispatch: Dispatch) => {
    const response: any = await fetchObjectPagesForCustomer();

    if (response.isSuccess) {
        const pages: PageDataResponseType[] = response.data;
        dispatch({
            data: pages,
            type: SET_PAGE_FOR_CUSTOMERS,
        });
    }
};

export const fetchViewData = (
    data: { viewIndex: number, pageName: string, sectionIndex: number, viewId: string },
) => async (dispatch: Dispatch) => {
    dispatch({
        data,
        type: FETCH_VIEW_DATA,
    });

    // fetch data
    const response = await fetchObjectViewById(data.viewId);

    const dataToSet = response.isSuccess ? {
        viewData: response.data,
        viewIndex: data.viewIndex,
        pageName: data.pageName,
        sectionIndex: data.sectionIndex,
    } : {
        error: response.errorMessage,
        viewIndex: data.viewIndex,
        pageId: data.pageName,
        sectionIndex: data.sectionIndex,
    };

    dispatch({
        data: dataToSet,
        type: SET_VIEW_DATA,
    });
};

export const updateColumnsData = (data: {
    viewIndex: string,
    pageName: string,
    sectionIndex: number,
    fieldList: { id: string, name: string }[],
}) => async (dispatch: Dispatch) => {
    const {
        viewIndex,
        pageName,
        sectionIndex,
        fieldList,
    } = data;

    dispatch({
        type: UPDATE_VIEW_COLUMNS,
        data: {
            pageName,
            sectionIndex,
            viewIndex,
            fieldList,
        },
    });
};

export const updateFiltersData = (data: {
    viewIndex: string,
    pageName: string,
    sectionIndex: number,
    filterList: { id: string, show_on_main_dashboard?: boolean }[],
}) => async (dispatch: Dispatch) => {
    const {
        viewIndex,
        pageName,
        sectionIndex,
        filterList,
    } = data;

    dispatch({
        type: UPDATE_VIEW_FILTERS,
        data: {
            pageName,
            sectionIndex,
            viewIndex,
            filterList,
        },
    });
};

