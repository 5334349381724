import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from 'theme/jss-types';
import { newProductStyles } from './new-product.styles';
import { Product } from './settings.types';
import {
    Form,
    Input,
    message,
    Modal,
} from 'antd';
import { productFields } from './new-product.constants';
import { saveProduct, updateProduct } from 'network/settings-product';
import { useTranslation } from 'react-i18next';

interface NewProductProps extends StylesProps<ReturnType<typeof newProductStyles>> {
    product?: Product;
    onClose: (reload?: boolean) => void;
}
const NewProduct = (props: NewProductProps) => {
    const {
        classes,
        onClose,
        product,
    } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [saving, setSaving] = React.useState<boolean>(false);

    const prefillForm = () => {
        form.setFieldsValue({
            [productFields.code.key]: product?.code,
            [productFields.name.key]: product?.name,
            [productFields.description.key]: product?.description,
            [productFields.declaredValue.key]: product?.declaredValue,
            [productFields.height.key]: product?.height,
            [productFields.width.key]: product?.width,
            [productFields.length.key]: product?.length,
            [productFields.weight.key]: product?.weight,
        });
    };

    React.useEffect(() => {
        prefillForm();
    }, []);

    const renderLabel = (key: string) => {
        const required = productFields[key].required;
        return (
            <div
                className={classes.label}
            >
                <span>{t(productFields[key].pretty_name)}</span>
                {required && <span>*</span>}
            </div>
        );
    };

    const validateInput = (key: string, value: any, callback: any) => {
        if (!value) {
            return callback(`You've missed this field. ${productFields[key].errormsg}`);
        }
        return callback();
    };

    const validateNumber = (key: string, value: any, callback: any) => {
        if (!value) {
            return callback(`You've missed this field. ${productFields[key].errormsg}`);
        }
        if (value < 0) {
            return callback(`${key} can not be negative`);
        }
        return callback();
    };

    const renderInput = (key: string) => {
        return (
            <div className={classes.formParent}>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateInput(key, value, cb),
                    }]}
                >
                    <Input
                        disabled={Boolean(product?.code && key === productFields.code.key)}
                        name={key}
                        max={50}
                        placeholder={t(productFields[key].placeholder)}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderInputNumber = (key: string) => {
        return (
            <div className={classes.formParent}>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    valuePropName="value"
                    rules={[{
                        validator: (rule, value, cb) => validateNumber(key, value, cb),
                    }]}
                >
                    <Input
                        type="number"
                        name={key}
                        min={0}
                        max={50}
                        placeholder={t(productFields[key].placeholder)}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDescription = (key: string) => {
        return (
            <div
                className={classes.formParent}
                style={{ width: '94%' }}
            >
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateInput(key, value, cb),
                    }]}
                >
                    <Input.TextArea
                        name={key}
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        placeholder={t(productFields[key].placeholder)}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDimensions = () => {
        return (
            <div className={classes.flexRow}>
                {renderInputNumber(productFields.length.key)}
                {renderInputNumber(productFields.width.key)}
                {renderInputNumber(productFields.height.key)}
            </div>
        );
    };

    const renderForm = () => {
        return (
            <Form
                form={form}
                className={classes.form}
            >
                {renderInput(productFields.code.key)}
                {renderInput(productFields.name.key)}
                {renderDescription(productFields.description.key)}
                {renderDimensions()}
                {renderInputNumber(productFields.weight.key)}
                {renderInputNumber(productFields.declaredValue.key)}
            </Form>
        );
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        if (formValues.errorFields?.length) {
            return;
        }
        setSaving(true);
        let response;
        const apiBody = form.getFieldsValue();
        if (product?.id) {
            response = await updateProduct({
                ...product,
                ...apiBody,
            });
        } else {
            response = await saveProduct(apiBody);
        }
        setSaving(false);
        if (response?.isSuccess) {
            message.success(product?.id ? t('update_product_success') : t('add_product_success'));
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
    };

    return (
        <Modal
            className={classes.modal}
            closable
            visible
            title={product?.id ? t('edit_product').toUpperCase() : t('add_product').toUpperCase()}
            width="50%"
            onCancel={() => onClose(false)}
            onOk={() => handleSave()}
            okButtonProps={{
                loading: saving,
            }}
            okText={t('save').toUpperCase()}
        >
            {renderForm()}
        </Modal>
    );
};

export default withStyles(newProductStyles)(NewProduct);
