/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const SingleCN = (props: any) => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Group 12</title>
        <g id="Uppdated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Create" transform="translate(-235.000000, -93.000000)">
                <g id="Group-12" transform="translate(236.000000, 94.000000)">
                    <polygon id="Fill-1" fill="#FFB74D" points="13.0909352 24 23.9999609 18.4006243 23.9999609 5.59941478 13.0909352 11.2010078" />
                    <polygon id="Fill-2" fill="#FFA726" points="3.2608642e-05 5.59944087 11.1999274 0 23.9999935 5.59944087 13.0909678 11.2010339" />
                    <polygon id="Fill-3" fill="#FFCC80" points="13.0909352 24 0 18.4006243 0 5.59941478 13.0909352 11.2010078" />
                    <g id="Group-10" transform="translate(0.000039, 0.000000)" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65217285">
                        <polyline id="Stroke-4" points="6.04813362 8.18743933 13.090883 11.2010078 13.090883 24 1.30434573e-05 18.4006243 1.30434573e-05 5.59941478 3.76957211 7.21243827" />
                        <polygon id="Stroke-6" points="13.090896 24 23.9999217 18.4006243 23.9999217 5.59941478 13.090896 11.2010078" />
                        <polyline id="Stroke-8" points="6.14098999 8.22718286 13.09087 11.2010339 23.9999609 5.59944087 11.1998948 0 0 5.59944087 4.32534077 7.45024723" />
                    </g>
                    <polyline id="Stroke-11" stroke="#000000" strokeWidth="0.65217285" strokeLinecap="round" strokeLinejoin="round" points="5.60050174 2.79974 18.3995243 8.40133304 18.5454806 14.799753" />
                </g>
            </g>
        </g>
    </svg>
);

const SingleCNIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return SingleCN({ ...innerProps, ...props });
    }}
    />
);

export default SingleCNIcon;
