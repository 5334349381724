export const addAsnFields: Record<string, any> = {
    asn_number: {
        key: 'asn_number',
        pretty_name: 'ASN Number',
        placeholder: 'ASN Number',
        required: true,
        errormsg: 'Enter ASN No.',
        disabled: false,
        is_editable: false,
    },
    asn_date: {
        key: 'asn_date',
        pretty_name: 'ASN Date',
        placeholder: 'ASN Date',
        required: true,
        errormsg: 'Enter ASN Date.',
        disabled: false,
        is_editable: false,
    },
    vendor_code: {
        key: 'vendor_code',
        pretty_name: 'Vendor Code',
        placeholder: 'Vendor Code',
        required: true,
        errormsg: 'Enter Vendor Code.',
        disabled: false,
        is_editable: false,
    },
    vendor_name: {
        key: 'vendor_name',
        pretty_name: 'Vendor Name',
        placeholder: 'Vendor Name',
        required: true,
        errormsg: 'Enter Vendor Name.',
        disabled: true,
        is_editable: false,
    },
    customer_id: {
        key: 'customer_id',
        pretty_name: 'Business ID',
        placeholder: 'Business ID',
        required: true,
        errormsg: 'Enter Business ID.',
        disabled: false,
        is_editable: false,
    },
    service_type: {
        key: 'service_type',
        pretty_name: 'Service Type',
        placeholder: 'Service Type',
        required: true,
        errormsg: 'Enter Service Type.',
        disabled: false,
        is_editable: true,
    },
    destination_location_code: {
        key: 'destination_location_code',
        pretty_name: 'Destination Location Code',
        placeholder: 'Destination Code',
        required: true,
        errormsg: 'Enter Destination Code.',
        disabled: false,
        is_editable: false,
    },
    allocation_tag: {
        key: 'allocation_tag',
        pretty_name: 'Allocation Tag',
        placeholder: 'Allocation Tag',
        required: false,
        errormsg: 'Enter Allocation Tag.',
        disabled: false,
        is_editable: true,
    },
    num_of_hu: {
        key: 'num_of_hu',
        pretty_name: 'Alloted HUs',
        placeholder: 'Alloted HUs',
        required: true,
        errormsg: 'Enter Alloted HUs',
        disabled: false,
        is_editable: true,
    },
};

export const serviceTypeList: Record<any, any> = [
    {
        name: 'EXPRESS',
        code: 'EXPRESS',
        id: 'EXPRESS',
    },
    {
        name: 'Bagged',
        code: 'D',
        id: 'D',
    },
    {
        name: 'ECOMMERCE',
        code: 'ECOMMERCE',
        id: 'ECOMMERCE',
    },
    {
        name: 'Next Day Delivery',
        code: 'NDD',
        id: 'NDD',
    },
    {
        name: 'PREMIUM',
        code: 'PREMIUM',
        id: 'PREMIUM',
    },
    {
        name: 'Priority',
        code: 'PRIO',
        id: 'PRIO',
    },
    {
        name: 'ROMS',
        code: 'ROMS',
        id: 'ROMS',
    },
    {
        name: 'Single Day Delivery',
        code: 'SDD',
        id: 'SDD',
    },
    {
        name: 'STAR',
        code: 'ST',
        id: 'ST',
    },
    {
        name: 'STORE',
        code: 'STORE',
        id: 'STORE',
    },

    {
        name: 'VACCINE',
        code: 'VACCINE',
        id: 'VACCINE',
    },
];

export const consignmentTypeList: any = [
    {
        label: 'Forward',
        value: 'forward',
    },
    {
        label: 'Reverse',
        value: 'reverse',
    },
    {
        label: 'Hyperlocal',
        value: 'hyperlocal',
    },
];
