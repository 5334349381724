import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Radio,
    Select,
    Space,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import TextArea from 'antd/lib/input/TextArea';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    dimensions: {
        ...commonStyleSheet(theme).flexColumn,
        alignItems: 'left',
    },
    dimentionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    isRenderedFromLayout: boolean | undefined;
}

const DimensionstField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        autoCalculateDetails,
        form,
        pieceSubLayout,
        envelopeSubLayout,
        documentSubLayout,
        isRenderedFromLayout,
    } = props;
    const {
        Length,
        Width,
        Height,
        Unit,
        ItemType,
        Vol_weight,
    } = formFields;
    const { id: pieceIndex, isRequired } = params;
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const itemType = Form.useWatch(ItemType.key, form);
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;

    if (!isRenderedFromLayout) {
        if (itemType === 'document' && !documentSubLayout.includes('dimensions') && !isPieceLevel) {
            return <></>;
        }
        if (itemType === 'envelope' && !envelopeSubLayout.includes('dimensions') && !isPieceLevel) {
            return <></>;
        }
        if ((!itemType || itemType === 'non-document') && !pieceSubLayout.includes('dimensions') && !isPieceLevel) {
            return <></>;
        }
    }

    const renderInput = (field: FormField, type: string, fieldName: string | undefined) => {
        return (
            <Form.Item
                name={fieldName}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(isPieceLevel, field.key) ? [formRuleRequired] : undefined}
            >
                <Input
                    type={type}
                    min={0}
                    placeholder={t(field.key)}
                    disabled={isPieceLevel && field === Vol_weight && autoCalculateDetails}
                />
            </Form.Item>
        );
    };

    const renderTextarea = (field: any, fieldName: string | undefined) => {
        return (
            <Form.Item
                name={fieldName}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(isPieceLevel, field.key) ? [formRuleRequired] : undefined}
            >
                <TextArea
                    placeholder={t(field.placeholder)}
                />
            </Form.Item>
        );
    };

    const renderRadio = (field: FormField, fieldName: string | undefined) => {
        return (
            <Form.Item
                name={fieldName}
                valuePropName={field.valuePropName}
                initialValue={field.defaultValue}
            >
                <Radio.Group
                    style={{ width: field.width }}
                    options={field.options}
                    defaultValue={field.defaultValue}
                />
            </Form.Item>
        );
    };

    const renderSelect = (field: FormField, fieldName: string | undefined) => {
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = i18n.exists(item.value) ? t(item.value) : item.label;
            });
        }
        return (
            <Form.Item
                name={fieldName}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(isPieceLevel, field.key) ? [formRuleRequired] : undefined}
            >
                <Select
                    disabled={field.disabled}
                    placeholder={t(field.key)}
                    style={{ width: field.width }}
                    options={field.options}
                    allowClear
                />
            </Form.Item>
        );
    };

    const renderDate = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                rules={isRequired(false, field.key) ? [formRuleRequired] : undefined}
            >
                <DatePicker
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                />
            </Form.Item>
        );
    };

    const renderFormItem = (
        field: FormField,
        type: string = 'text',
        fieldName: string | undefined = undefined,
    ) => {
        switch (field.type) {
            case InputTypes.Input: return renderInput(field, type, fieldName);
            case InputTypes.Select: return renderSelect(field, fieldName);
            case InputTypes.Textarea: return renderTextarea(field, fieldName);
            case InputTypes.Radio: return renderRadio(field, fieldName);
            case InputTypes.Date: return renderDate(field);
            default: return 'Input is not defined';
        }
    };
    const id = isPieceLevel ? `_${pieceIndex}` : '';
    return (
        <Col span={12}>
            <div className={classes.dimensions}>
                <div className={classes.dimentionTitle}>
                    {t('dimensions')}
                    {
                        isRequired(isPieceLevel, Length.key)
                        || isRequired(isPieceLevel, Width.key)
                        || isRequired(isPieceLevel, Height.key)
                            ? '*' : ''
                    }
                </div>
                <Space direction="horizontal">
                    {renderFormItem(Length, 'number', `length${id}`)}
                    {renderFormItem(Width, 'number', `width${id}`)}
                    {renderFormItem(Height, 'number', `height${id}`)}
                    {renderFormItem(Unit, 'number', `unit${id}`)}
                </Space>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const autoCalculateDetails = customerPortalConfig?.auto_calculate_single_cn_fields_international;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentSubLayout = formSubLayout?.document || [];
    const pieceSubLayout = formSubLayout?.non_document || [];
    const envelopeSubLayout = formSubLayout?.envelope || [];
    return {
        autoCalculateDetails,
        pieceSubLayout,
        envelopeSubLayout,
        documentSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DimensionstField);
