import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import { TableColumn } from 'components/settings-product/settings.types';

export const RTOAddressFormField: Record<string, FormField> = {
    AddressLine1: {
        label: 'Address Line 1',
        type: InputTypes.Input,
        key: 'addressLine1',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Address Line 1',
    },
    AddressLine2: {
        label: 'Address Line 2',
        type: InputTypes.Input,
        key: 'addressLine2',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Address Line 2',
    },
    MobileNumber: {
        label: 'Mobile Number',
        type: InputTypes.Input,
        key: 'mobileNumber',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Mobile Number',
    },
    PinCode: {
        label: 'Pincode',
        type: InputTypes.Input,
        key: 'pincode',
        valuePropName: 'value',
        direction: 'column',
        placeholder: 'Pincode',
    },
};

export const RTODashboardColumns: TableColumn[] = [
    {
        key: 'CONSG_NUMBER',
        pretty_name: 'CN Reference Number',
    },
    {
        key: 'REASON_NAME',
        pretty_name: 'Reason',
    },
    {
        key: 'ATTEMPT_NUMBER',
        pretty_name: 'Attempts',
    },
    {
        key: 'ATTEMPT_DATE_TIME',
        pretty_name: 'Last Attempted At',
    },
    // {
    //     key: 'reference_number',
    //     pretty_name: 'CN Reference Number',
    // },
    // {
    //     key: 'customer_reference_number',
    //     pretty_name: 'Customer Reference Number',
    // },
    // {
    //     key: 'consignee_name',
    //     pretty_name: 'Consignee Name',
    // },
    // {
    //     key: 'creation_date',
    //     pretty_name: 'Created Date',
    // },
    // {
    //     key: 'movement_type',
    //     pretty_name: 'Movement Type',
    // },
    // {
    //     key: 'payment_mode',
    //     pretty_name: 'Payment Mode',
    // },
    // {
    //     key: 'consignment_status',
    //     pretty_name: 'CN Status',
    // },
    // {
    //     key: 'last_updated_at',
    //     pretty_name: 'Last Updated At',
    // },
];

export enum ValidRTOActions {
    Rto = 'RTO',
    Reattempt = 'REATTEMPT',
    ReattemptNewAddress = 'REATTEMPT WITH NEW ADDRESS',
}

export enum RTOPageOptions {
    Dashboard = 'Consignments',
    // Actions = 'Action Logs'
}
