import { getOrganisationPrettyName } from './org-helper';

const config = require('../config').default;

const {
    allowedTrackingEvents,
    LOGO_URL,
    useOnlyForLocalTestingOrgId,
    API_BASE_URL_CONFIG,
    BUSINESS_BOOKING_API_CONFIG,
} = config;

const organisationPrettyName = getOrganisationPrettyName();
const origin = window.location.hostname;

/* eslint-disable max-len */
const API_BASE_URL = (API_BASE_URL_CONFIG && API_BASE_URL_CONFIG[origin]) || (API_BASE_URL_CONFIG && API_BASE_URL_CONFIG[organisationPrettyName]) || config.API_BASE_URL;
const BUSINESS_BOOKING_API_URL = (BUSINESS_BOOKING_API_CONFIG && BUSINESS_BOOKING_API_CONFIG[origin]) || (BUSINESS_BOOKING_API_CONFIG && BUSINESS_BOOKING_API_CONFIG[organisationPrettyName]) || config.BUSINESS_BOOKING_API_URL;

const applicationType = 'customer-portal';
const TEST_API_BASE_URL = 'https://api.shipsy.in:5005/api';

export const NAVBAR_HEIGHT = 63;
export const MOBILE_BREAK_POINT = 590;
export const MIN_WIDTH = 1000;
export const IFRAME_NON_STRICT_MODE = config.IFRAME_NON_STRICT_MODE || false;
export const CP_BASE_URL = config.CP_BASE_URL;
export const ANALYTICS_FRONTEND_BASE_URL = config.ANALYTICS_FRONTEND_BASE_URL;
export const FEEDBACK_INTERVAL = 30 * 60 * 1000; // 30 min
export const displayConfig = config.displayConfig || {};
export const filterConfig = config.filterConfig || {};
export const STADIA_MAPS_API_KEY = config.STADIA_MAPS_API_KEY;
export const API_DOC_FRONTEND_BASE_URL = config.API_DOC_FRONTEND_BASE_URL;
export const DTDC_SRF_PORTAL = config.DTDC_SRF_PORTAL;
export const allowedColumns = config.allowedColumns || [];
export const allowedFilters = config.allowedFilters || [];
export const GA_MID = config.GA_MID;
export const AUTH_RSA_PUBLIC_KEY = config.AUTH_RSA_PUBLIC_KEY;

export const CAPTCHA_SITE_KEY = config.CAPTCHA_SITE_KEY;


export {
    useOnlyForLocalTestingOrgId,
    API_BASE_URL,
    TEST_API_BASE_URL,
    applicationType,
    allowedTrackingEvents,
    BUSINESS_BOOKING_API_URL,
    LOGO_URL,
};
