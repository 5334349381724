import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET, POST, PUTV2 } from './api-hander.service';
import {
    FETCH_ASN_DATA,
    CREATE_ASN,
    UPDATE_ASN,
    GET_CUSTOMER,
    CANCEL_ASN,
    FETCH_DESTINATION_LOCATION_CODE,
    SAMPLE_UPLOAD_ASN,
    ASN_SUMMARY_METRICS,
    BULK_UPLOAD_ASN,
    FETCH_CSV_HISTORY,
    SAMPLE_BULK_CONVERT_ASN,
    BULK_ASN_TO_CONSIGNMENT,
} from './api.constants';

export const fetchAsnData = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${FETCH_ASN_DATA}`, body);
    return response;
};

export const createAsn = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${CREATE_ASN}`, body);
    return response;
};

export const getCustomerList = async (params: any = {}) => {
    return GET(`${API_BASE_URL}${GET_CUSTOMER}`, params);
};

export const fetchDestinationLocationData = async (params: any = {}) => {
    return GET(`${API_BASE_URL}${FETCH_DESTINATION_LOCATION_CODE}`, params);
};

export const updateAsn = async (body: any) => {
    const response = await PUTV2(`${API_BASE_URL}${UPDATE_ASN}`, body);
    return response;
};

export const cancelAsn = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${CANCEL_ASN}`, body);
    return response;
};

export const downloadSample = async (params: any) => {
    return GET(`${API_BASE_URL}${SAMPLE_UPLOAD_ASN}`, params, 'blob');
};

export const fetchSummary = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${ASN_SUMMARY_METRICS}`, body);
    return response;
};

export const createBulkAsn = async (body: any) => {
    const response = await POST(`${API_BASE_URL}${BULK_UPLOAD_ASN}`, body);
    return response;
};

export const fetchCsvHistory = async (params: any = {}) => {
    return GET(`${API_BASE_URL}${FETCH_CSV_HISTORY}`, params);
};

export const downloadBulkAsnConversionSample = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${SAMPLE_BULK_CONVERT_ASN}`, params, 'blob');
};

export const createBulkConsignmentFromASN = async (body: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BULK_ASN_TO_CONSIGNMENT}`, body);
};
