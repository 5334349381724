/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const NeutralColored = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Neutral</title>
        {/* <defs>
            <polygon id="path-1" points="0 0 36.00006 0 36.00006 36 0 36" />
        </defs> */}
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-186.000000, -293.000000)">
                <g id="Group-10" transform="translate(186.000000, 293.000000)">
                    <g id="Group-3">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g id="Clip-2" />
                        <path d="M36.00006,19.220312 C36.00006,29.9614099 28.7411279,36.00006 18.00003,36.00006 C7.2589321,36.00006 0,29.9614099 0,19.220312 C0,8.07241345 6.85885143,0 18.00003,0 C29.1412086,0 36.00006,8.07241345 36.00006,19.220312" id="Fill-1" fill="#FED132" mask="url(#mask-2)" />
                    </g>
                    <path d="M12.0453441,22.5761296 C14.0337474,22.5283695 16.0161507,22.5390496 17.999994,22.5388096 C19.9839573,22.5386896 21.9663606,22.5271695 23.9547639,22.5761296 C24.618605,22.5924497 25.1437259,23.1432506 25.1277659,23.8070917 C25.1128859,24.4201727 24.6403251,24.9150535 24.0453641,24.9744536 C22.0356007,25.175334 20.0171974,25.2386941 17.999994,25.2384547 C15.9827906,25.2383341 13.9645073,25.174374 11.9547439,24.9744536 C11.2940228,24.9086935 10.811622,24.3197325 10.8773821,23.6590114 C10.9381022,23.0483304 11.4471431,22.5905297 12.0453441,22.5761296" id="Fill-4" fill="#802D21" />
                    <path d="M9.56095593,14.4661081 C9.56095593,15.1420692 9.62863605,15.8026703 9.75787626,16.4419114 C10.2377571,18.8169554 13.6841628,18.8169554 14.1640436,16.4419114 C14.2932838,15.8026703 14.3609639,15.1420692 14.3609639,14.4661081 C14.3609639,13.5297465 14.2310037,12.622785 13.9877633,11.7614236 C13.4099623,9.71518019 10.5119575,9.71518019 9.93415656,11.7614236 C9.69091615,12.622785 9.56095593,13.5297465 9.56095593,14.4661081" id="Fill-6" fill="#802D21" />
                    <path d="M26.3609839,14.4661081 C26.3609839,15.1420692 26.2933038,15.8026703 26.1640636,16.4419114 C25.6841828,18.8169554 22.2377771,18.8169554 21.7578963,16.4419114 C21.628656,15.8026703 21.5609759,15.1420692 21.5609759,14.4661081 C21.5609759,13.5297465 21.6909362,12.622785 21.9341766,11.7614236 C22.5119775,9.71518019 25.4099823,9.71518019 25.9877833,11.7614236 C26.2310237,12.622785 26.3609839,13.5297465 26.3609839,14.4661081" id="Fill-8" fill="#802D21" />
                </g>
            </g>
        </g>
    </svg>
);

const Neutral = (props: any) => (
    <Icon component={(innerProps: any) => {
        return NeutralColored({ ...innerProps, ...props });
    }}
    />
);

export default Neutral;
