import { ThemeType } from 'theme/jss-types';

export const commonStyleSheet = (theme: ThemeType) => ({
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    justifySpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ellipses: {
        // add max width in the styles of the component using this
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonStyle: {
        backgroundColor: theme.primaryColor,
        color: 'white',
        fontSize: '12px',
        fontWeight: '600',
        cursor: 'pointer',
    },
    header: {
        padding: 24,
        border: '1px solid #EFEFEF',
        borderRadius: 8,
        margin: 16,
    },
    fixedScrollbar: {
        '&::-webkit-scrollbar:vertical': {
            width: '11px',
        },
        '&::-webkit-scrollbar:horizontal': {
            height: '11px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            border: '2px solid white',
            backgroundColor: 'rgba(0, 0, 0, .5)',
        },
        '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
        },
    },
    anchor: {
        color: theme.primaryColor,
    },
    checkbox: {
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: theme.secondryColor,
            borderColor: theme.secondryColor,
        },
        '& .ant-checkbox + span': {
            width: '100%',
        },
    },
    addressName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footer: {
        marginTop: 100,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 0,
        width: '100%',
        color: '#FFFFFF',
        height: 80,
        zIndex: 0,
        backgroundColor: '#052857',
    },
    buttons: {
        display: 'flex',
        marginTop: '20px',
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    modal: {
        '& .ant-modal-body': {
            padding: 12,
        },
    },
    flexRowDiv: {
        display: 'flex',
        flexDirection: 'row',
    },
    icd: {
        float: 'right',
        fontSize: '9px',
        borderRadius: '4px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
    formItem: {
        '& .ant-form-explain': {
            fontSize: 10,
        },
    },
});
