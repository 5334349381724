import * as React from 'react';
import Filter from '../common/filters';
import Cross from '../../assets/cross';
import { Button, Drawer, message } from 'antd';
import { Buckets } from '../../library/Types';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { getFilters } from './advanceShippingNote.helper';
import { ReduxStore } from '../../reducers/redux.types';
import {
    applyFilters,
    clearSelection,
    resetFilters,
    setFilters,
} from '../../actions/generic-action';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { ThemeType } from 'theme/jss-types';


const styles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        overflow: 'scroll',
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            marginBottom: 56,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input-affix-wrapper': {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input, .ant-picker': {
            width: 250,
            borderRadius: 4,
            border: '1px solid #999999',
            height: 32,
            fontSize: 11,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    body: {
        padding: 24,
        paddingBottom: 8,
        width: '100%',
    },
    hr: {
        width: '100%',
        height: 1,
        backgroundColor: '#CCCCCC',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
    },
    moreFilter: {
        marginLeft: 16,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.primaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelClass: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: 120,
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        height: 56,
    },
});

const ExtraFiltersAsn = (props: any) => {
    const {
        classes,
        onClose,
        config,
        bucketSelected,
        filters,
        resetVisible,
        setfilters,
        resetfilters,
        applyfilters,
        clearselection,
    } = props;

    const handleClose = (refresh = false) => {
        if (refresh) {
            applyfilters(Buckets.ASN);
        } else {
            clearselection(Buckets.ASN);
        }
        onClose();
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <Button
                    onClick={() => handleClose(true)}
                    type="primary"
                    style={{ marginLeft: 10, marginRight: 20 }}
                >
                    Apply
                </Button>
                <Button
                    onClick={() => handleClose()}
                    type="dashed"
                >
                    Cancel
                </Button>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        More Filters
                    </span>
                </div>
                {resetVisible && (
                    <span
                        className={classes.resetFilter}
                        onClick={() => {
                            resetfilters(Buckets.ASN);
                            onClose();
                        }}
                    >
                        Reset Filters
                    </span>
                )}
            </div>
        );
    };

    const onChangeFilter = (filter: any, value: any) => {
        const newFilters = {
            ...filters,
            [filter.id]: value,
        };
        setfilters(Buckets.ASN, newFilters);
    };

    const handleSelectFilter = (val: any, options: any, filter: any) => {
        const newFilters = {
            ...filters,
            [filter.id]: options.key,
            selectDestinationLocationCode: filter.id === 'destinationLocationCodeList'
                ? val : filters.selectDestinationLocationCode,
        };
        setfilters(Buckets.ASN, newFilters);
    };

    const fetchDate = (dateArr: any[]) => {
        const date: any = { start: moment(), end: moment() };
        if (dateArr?.length === 2) {
            date.start = dateArr[0];
            date.end = dateArr[1];
        }
        return date;
    };


    const handleDateSelectFilter:any = (dateArr: any[]) => {
        const date = fetchDate(dateArr);
        if (Math.abs(moment(date.start).diff(date.end, 'days')) > 31) {
            message.error('Date Range Cannot be more than 31 Days');
            return;
        }
        const date_obj = {
            start: date.start.format('YYYY-MM-DD'),
            end: date.end.format('YYYY-MM-DD'),
        };
        const newFilters = {
            ...filters,
            startDate: date_obj.start,
            endDate: date_obj.end,
            isAsnDateApplied: true,
        };
        setfilters(Buckets.ASN, newFilters);
    };


    const renderFilter = (filter: any) => {
        return (
            <div className={classes.row}>
                <Filter
                    key={filter.id}
                    moreFilter
                    labelClass={classes.labelClass}
                    filter={filter}
                    handleDateSelect={(val: any) => handleDateSelectFilter(val)}
                    handleSelect={(val: any, options: any) => handleSelectFilter(val, options, filter)}
                    value={filter.id === 'asnDate' || filter.id === 'destinationLocationCodeList'
                        ? filters : filters[filter.id]}
                    onChange={(value) => onChangeFilter(filter, value)}
                />
            </div>
        );
    };

    const renderFilterList = () => {
        const filterList = getFilters(bucketSelected, config);
        return filterList.map((filter:any) => renderFilter(filter));
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                {renderFilterList()}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    return (
        <Drawer
            className={classes.main}
            visible
            width="30%"
            title={renderHeader()}
            onClose={() => onClose()}
            closable={false}
        >
            {renderBody()}
            {renderLine()}
            {renderFooter()}
        </Drawer>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic, uiTheme } = state;
    const { ASN } = generic;
    const { filters } = ASN;
    return {
        filters, uiTheme,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        resetfilters: resetFilters,
        applyfilters: applyFilters,
        clearselection: clearSelection,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};

const AsnMoreFilters = GenericHoc(hocConfig)(ExtraFiltersAsn);

export default AsnMoreFilters;
