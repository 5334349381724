const convertToCSV = (objArray: any) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    array.forEach((item: any) => {
        let values = Object.values(item);
        values = values?.map((entry) => {
            if (typeof entry === 'string') {
                return entry.replace(/,/g, ' ');
            }
            return entry;
        });
        const line = values.join(',');
        str += `${line}\r\n`;
    });

    return str;
};

const headers = {
    referenceNumber: 'Reference Number',
    message: 'Message',
};

export const exportCSVFile = (items: any, fileTitle: string) => {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);

    const csv = convertToCSV(jsonObject);

    const exportedFilenmae = `${fileTitle}.csv`;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
