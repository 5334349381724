/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import type { MapRef } from 'react-map-gl';
import { IUIMapIcon } from './types';
import { GeocodingApi, Configuration } from '@stadiamaps/api';
import { STADIA_MAPS_API_KEY } from '../../../../library/globals';

export const loadImages = (
    mapRef: React.RefObject<MapRef>,
    images: IUIMapIcon[],
) => {
    images.forEach((image) => {
        if (!(mapRef as any).hasImage(image.name)) {
            const customImage = new Image(image.imageHeight, image.imageWidth);
            customImage.onload = () => {
                if (image.maintainRatio) {
                    const ratio = customImage.naturalHeight / customImage.naturalWidth;
                    customImage.width = image.imageWidth;
                    customImage.height = ratio * image.imageWidth;
                }
                (mapRef as any).addImage(image.name, customImage, { sdf: image.isSdf });
            };
            customImage.crossOrigin = 'Anonymous';
            customImage.src = image.src;
        }
    });
};

const config = new Configuration({ apiKey: STADIA_MAPS_API_KEY });
export const geocodingApi = new GeocodingApi(config);

