// import { ThemeType } from 'theme/jss-types';
import { ThemeType } from 'theme/jss-types';

export const cancellationStyles = (theme: ThemeType) => ({
    main: {
        '& .ant-modal-header': {
            backgroundColor: theme.primaryColor,
        },
        '& .ant-modal-close-x': {
            color: '#FFF',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: '100%',
            borderRadius: 6,
            border: '1px solid #999999',
            height: 32,
            fontSize: 14,
            color: '#111111',
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    title: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 12,
    },
    bulkOps: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    fieldTitle: {
        fontFamily: 'Open Sans',
        color: '#333333',
        letterSpacing: 0,
        fontSize: 12,
        width: '20%',
    },
    inputTitle: {
        fontFamily: 'Open Sans',
        color: '#333333',
        letterSpacing: 0,
        fontSize: 12,
    },
    actions: {
        marginBottom: 12,
    },
    input: {
        marginTop: 8,
        marginBottom: 8,
        marginRight: 8,
        width: '100%',
    },
    uploadBox: {
        boxSizing: 'border-box',
        height: 350,
        border: `1px dashed ${theme.primaryColor}`,
        borderRadius: 4,
        backgroundColor: '#F7F7F7',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        marginTop: 24,
        textAlign: 'center',
    },
    uploadText: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 0,
        marginBottom: 24,
    },
    uploadHint: {
        fontSize: 14,
        color: '#666666',
        letterSpacing: 0,
    },
    downloadBtn: {
        marginTop: 12,
        height: 90,
        width: '100%',
        padding: 0,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 16,
        cursor: 'pointer',
    },
    downloadSample: {
        alignSelf: 'center',
        marginTop: 12,
        textDecoration: 'underline',
        color: theme.primaryColor,
        fontSize: 14,
        fontWeight: 'bold',
    },
    list: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    serialTitle: {
        width: '20%',
        fontSize: 10,
        color: '#333333',
        letterSpacing: 0,
    },
    referenceTitle: {
        fontSize: 10,
        color: '#333333',
        letterSpacing: 0,
        width: '70%',
    },
    actionTitle: {
        fontSize: 10,
        color: '#333333',
        letterSpacing: 0,
        width: '10%',
    },
    itemBox: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F9F9F9',
        padding: 8,
        marginTop: 6,
        width: '100%',
    },
    columnRow: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
        backgroundColor: '#EFF4FC',
        width: '100%',
        padding: 8,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    serial: {
        width: '20%',
        fontSize: 12,
        color: '#111111',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    reference: {
        width: '70%',
        fontSize: 12,
        color: '#111111',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    action: {
        width: '10%',
        fontSize: 12,
        color: '#111111',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    icon: {
        color: theme.primaryColor,
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
        marginRight: 8,
    },
});
