import { getFilters } from 'components/consignments/consignments.helper';
import moment from 'moment';
import { Dispatch } from 'redux';
import { ConsignmentBuckets } from '../components/consignments/consignments.constants';
import { Buckets } from '../library/Types';
import { fetchConsignments, fetchSummary } from '../network/consignments.api';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
    setGenericSummary,
    setPagination,
} from './generic-action';
import { PAGE_NAMES } from 'types/page.types';
import { fetchObjectViewDataObjects } from 'network/objectview.api';

const bucket = Buckets.CONSIGNMENTS;
const dateFormat = 'YYYY-MM-DD';

// const independentFilters = [
//     'sortBy',
//     'endDate',
//     'startDate',
//     'bucketSelected',
//     'descendingOrder',
//     'isInternational',
//     'consignmentType',
//     'cncFlag',
// ];

const arrayFilters = ['statusList'];
export const getMoreFilters = (appliedFilters: any) => {
    const filters: Record<string, any> = {};
    Object.keys(appliedFilters).forEach((filter) => {
        filters[filter] = appliedFilters[filter];
        if (filter === 'cncFlag' && appliedFilters.cncFlag && appliedFilters.cncFlag !== 'all') {
            filters[filter] = appliedFilters.cncFlag === 'true';
        }
        if (arrayFilters.includes(filter)) {
            filters[filter] = [appliedFilters[filter]];
        }
    });
    return filters;
};

const loadSummary = (appliedFilters: any, filters: any) => async (dispatch: Dispatch<any>) => {
    const response = await fetchSummary({
        ...filters,
        dateFilterColumn: filters.dateType || 'created_at',
        customerIdList: filters.customerIdList || [],
        bucketIds: Object.keys(ConsignmentBuckets),
        startDate: moment(appliedFilters.startDate).format('YYYY-MM-DD'),
        endDate: moment(appliedFilters.endDate).format('YYYY-MM-DD'),
    });
    if (response.isSuccess) {
        dispatch(setGenericSummary(bucket, response?.data || {}));
    } else {
        dispatch(setGenericSummary(bucket, {}));
    }
};

export const loadConsignments = () => async (
    dispatch: Dispatch<any>,
    store: () => ReduxStore,
) => {
    dispatch(startLoading(bucket));

    const { generic, master, genericPage } = store();
    const { CONSIGNMENTS } = generic;
    const { config } = master;
    const { appliedFilters, pagination } = CONSIGNMENTS;
    const consignmentPageData = genericPage[PAGE_NAMES.CONSIGNMENTS];
    const isPageBuilderData = !!consignmentPageData;
    const filtersInfo = isPageBuilderData ? [] : getFilters(appliedFilters.bucketSelected, config);
    const filters = getMoreFilters(appliedFilters);
    filtersInfo.forEach((filter: any) => {
        if (filters[filter.id] && filter.mode === 'multiple' && typeof filters[filter.id] === 'string') {
            filters[filter.id] = filters[filter.id].split(',').map((value: string) => value.trim());
        }
    });

    if (isPageBuilderData) {
        // sortBy: appliedFilters.dateType || appliedFilters.sortBy,
        // descendingOrder: appliedFilters.descendingOrder,
        const body: any = {
            viewId: appliedFilters.bucketSelected,
            filters: {
                ...filters,
                customerIdList: filters.customerIdList || [],
                timeFilters: [
                    {
                        columnName: appliedFilters.dateType || appliedFilters.sortBy,
                        fromDateString: moment(appliedFilters.startDate).format(dateFormat),
                        toDateString: moment(appliedFilters.endDate).format(dateFormat),
                    },
                ],
            },
            pagination: {
                resultPerPage: pagination.resultPerPage,
                pageNumber: pagination.currentPageNumber,
            },
        };
        delete body.filters.bucketSelected;
        Object.keys(body.filters).forEach((key) => {
            if (!body.filters[key]) {
                delete body.filters[key];
            }
        });
        const response = await fetchObjectViewDataObjects(body);
        if (response.isSuccess) {
            dispatch(setGenericData(bucket, response?.data?.page_data || []));
            dispatch(setPagination(bucket, {
                isNextPresent: response.data?.isNextPresent,
            }));
        } else {
            dispatch(setGenericData(bucket, []));
        }
    } else {
        dispatch(loadSummary(appliedFilters, filters));
        const response = await fetchConsignments({
            ...filters,
            resultPerPage: pagination.resultPerPage,
            pageNumber: pagination.currentPageNumber,
            customerIdList: filters.customerIdList || [],
            bucket: appliedFilters.bucketSelected,
            timeFilters: [
                {
                    columnName: appliedFilters.dateType || appliedFilters.sortBy,
                    fromDateString: moment(appliedFilters.startDate).format(dateFormat),
                    toDateString: moment(appliedFilters.endDate).format(dateFormat),
                },
            ],
            descendingOrder: appliedFilters.descendingOrder,
            startDate: moment(appliedFilters.startDate).format(dateFormat),
            endDate: moment(appliedFilters.endDate).format(dateFormat),
            sortBy: appliedFilters.dateType || appliedFilters.sortBy,
        });
        if (response.isSuccess) {
            dispatch(setGenericData(bucket, response?.data?.page_data || []));
            dispatch(setPagination(bucket, {
                isNextPresent: response.data?.isNextPresent,
            }));
        } else {
            dispatch(setGenericData(bucket, []));
        }
    }

    dispatch(endLoading(bucket));
};
