import { PageData, PageDataResponseType } from 'types/page.types';
import {
    FETCH_VIEW_DATA,
    SET_PAGE_FOR_CUSTOMERS,
    SET_VIEW_DATA,
    UPDATE_VIEW_COLUMNS,
    UPDATE_VIEW_FILTERS,
} from './redux-constants';
import { GenericPage } from './redux.types';

const defaultState: GenericPage = {};

export default function (
    state = defaultState,
    action: {
        type: string,
        data: any,
    },
) {
    switch (action.type) {
        case SET_PAGE_FOR_CUSTOMERS: {
            const pages: PageDataResponseType[] = action.data;
            const newState = pages.reduce<{[key: string]: PageData}>((acc, page) => {
                if (!acc[page.name]) {
                    acc[page.name] = new PageData(page);
                }
                return acc;
            }, {});
            return {
                ...state,
                ...newState,
            };
        }
        case FETCH_VIEW_DATA: {
            const { sectionIndex, viewIndex, pageName } = action.data;
            const pageData = state[pageName].clone();
            const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
            const newViewData = oldViewData.clone();
            newViewData.isLoading = true;
            newViewData.error = null;
            pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
            return {
                ...state,
                [pageName]: pageData,
            };
        }
        case SET_VIEW_DATA: {
            const {
                sectionIndex,
                viewIndex,
                pageName,
                viewData,
                error,
            } = action.data;
            const pageData = state[pageName].clone();
            const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
            const newViewData = oldViewData.clone();
            if (viewData) {
                newViewData.setViewData(viewData);
            } else {
                newViewData.error = error || 'Something went wrong';
            }
            newViewData.isLoading = false;
            pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
            return {
                ...state,
                [pageName]: pageData,
            };
        }
        case UPDATE_VIEW_COLUMNS: {
            const {
                pageName,
                viewIndex,
                sectionIndex,
                fieldList,
            } = action.data;
            const pageData = state[pageName].clone();
            const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
            const newViewData = oldViewData.clone();
            newViewData.visibleFieldList = [...(fieldList || [])];
            pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;
            return {
                ...state,
                [pageName]: pageData,
            };
        }
        case UPDATE_VIEW_FILTERS: {
            const {
                pageName,
                viewIndex,
                sectionIndex,
                filterList,
            } = action.data;
            const pageData = state[pageName].clone();
            const oldViewData = pageData.metadata.sectionList[sectionIndex][viewIndex];
            const newViewData = oldViewData.clone();
            newViewData.visibleFilterList = [...(filterList || [])];
            pageData.metadata.sectionList[sectionIndex][viewIndex] = newViewData;

            return {
                ...state,
                [pageName]: pageData,
            };
        }
        default: return state;
    }
}
