import Cross from '../../assets/cross';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import Helper from '../../library/Helper';
import { asnBulkUploadStyles } from './asn-bulk-upload.styles';
import { downloadBulkAsnConversionSample, createBulkConsignmentFromASN } from '../../network/asn.api';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

interface AsnCreateCnBulkUploadProps extends StylesProps<ReturnType<typeof asnBulkUploadStyles >> {
    onClose: () => void;
    loadAsn: () => void;
    uiTheme: ThemeType;
}


const AsnCreateCnBulkUpload = (props: AsnCreateCnBulkUploadProps) => {
    const {
        classes,
        onClose,
        loadAsn,
        uiTheme,
    } = props;

    const [consignmentArray, setConsignments] = React.useState<any>([]);
    const [piecesArray, setPieces] = React.useState<any>([]);
    const [uploading, setUploading] = React.useState<boolean>(false);


    const handleCreate = async () => {
        setUploading(true);
        const response = await createBulkConsignmentFromASN({
            consignmentArray,
            piecesArray,
        });
        if (response.isSuccess) {
            message.success('Excel uploaded successfully');
            onClose();
            loadAsn();
        } else {
            message.error(response.errorMessage);
        }
        setUploading(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButtonDisabled;
        if (consignmentArray.length) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                loading={uploading}
                className={btnClass}
                disabled={!consignmentArray.length}
                style={{
                    border: `1px solid ${!consignmentArray?.length
                        ? '#EDEDED' : uiTheme.primaryColor}`,
                }}
                onClick={handleCreate}
            >
                Submit
            </Button>
        );
    };

    const mapping: Record<string, string> = {
        'Unique Id': 'uniqueId',
        'Client Code': 'clientCode',
        'ASN Number': 'asnNumber',
        'Length (cms)': 'dimensionslength',
        'Width (cms)': 'dimensionswidth',
        'Height (cms)': 'dimensionsheight',
        'Weight (kgs)': 'weight',
        'Origin Location Code': 'originLocationCode',
        'Invoice Number': 'invoiceNumber',
        'Invoice Value': 'invoiceValue',
        'Invoice Date': 'invoiceDate',
        'EWB Number': 'ewbNumber',
        'EWB Date': 'ewbDate',
    };

    const pieceDetailHeaderMapping: Record<string, string> = {
        Unique_Id: 'uniqueId',
        Description: 'description',
        Quantity: 'quantity',
        Length: 'length',
        Width: 'width',
        Height: 'height',
        Weight: 'weight',
        'Dimension Unit': 'dimensionUnit',
        'Weight Unit': 'weightUnit',
        Volume: 'volume',
        'Volume Unit': 'volumeUnit',
        'Model Code': 'modelCode',
        'Item Category': 'itemCategory',
        'Item Size': 'itemSize',
    };

    const getConsignmentArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = mapping[keyUpdate];
                returnObj[keyUpdate] = item[key];
            });
            return returnObj;
        });

        return newData;
    };

    const getPiecesArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = pieceDetailHeaderMapping[keyUpdate];
                if (keyUpdate) {
                    returnObj[keyUpdate] = item[key];
                }
            });
            return returnObj;
        });
        return newData.filter((obj: any) => (Object.keys(obj).length) > 0);
    };

    const transformData = (data: any[]) => {
        const newData: any = {};
        newData.consignmentArray = getConsignmentArray(data[0]);
        if (data.length > 1) {
            newData.piecesArray = getPiecesArray(data[1]);
        }
        return newData;
    };

    const handleParsedData = async (data: any[]) => {
        if (!data || !data.length) return;
        const slicedData = transformData(data);
        setConsignments(slicedData.consignmentArray);
        setPieces(slicedData.piecesArray);
    };

    const onFileRecieved = async (file: any) => {
        const consignmentArr: any[] = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
        handleParsedData(consignmentArr);
    };


    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };


    const handleUploadChange = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            onFileRecieved(info.file.originFileObj);
        }
    };

    const renderUpload = () => {
        return (
            <div
                className={classes.uploadBox}
            >
                <Dragger
                    multiple={false}
                    accept=".xlsx, .xls, .csv"
                    name="file"
                    disabled={consignmentArray.length}
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                >
                    <p className={classes.uploadText}>
                        <UploadOutlined className={classes.closeIcon} />
                        Upload excel file to convert ASN to CN
                    </p>
                    <p className={classes.uploadHint}>
                        Drag and drop to upload or
                        <Button type="link">Browse</Button>
                        to choose file
                    </p>
                </Dragger>
            </div>
        );
    };


    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        Upload CN
                    </span>
                </div>
                {renderButton()}
            </div>
        );
    };

    const handleDownload = async () => {
        const fileBuffer = await downloadBulkAsnConversionSample({});
        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'sample-consignment-upload.xlsx',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }
    };

    const renderDownload = () => {
        return (
            <Button
                type="link"
                className={classes.downloadBtn}
                onClick={handleDownload}
            >
                <DownloadOutlined />
                Download Sample File
            </Button>
        );
    };

    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderUpload()}
            {renderDownload()}
        </Drawer>

    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default withStyles(asnBulkUploadStyles)(GenericHoc(hocConfig)(AsnCreateCnBulkUpload));
