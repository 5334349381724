import { BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET, POST } from './api-hander.service';
import {
    EXCEL_PRINT_A4, FETCH_EXCELS,
} from './api.constants';

export const getExcels = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_EXCELS}`, params);
};

export const printExcelA4 = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${EXCEL_PRINT_A4}`, params);
};
