import { API_BASE_URL } from '../library/globals';
import { GET, POST } from './api-hander.service';

import {
    FETCH_OBJECT_VIEW_BY_ID,
    FETCH_OBJECT_VIEW_DATA_OBJECTS,
} from './api.constants';

export const fetchObjectViewById = (viewId: string) => {
    return GET(`${API_BASE_URL}${FETCH_OBJECT_VIEW_BY_ID(viewId)}`);
};

export const fetchObjectViewDataObjects = (body: any) => {
    return POST(`${API_BASE_URL}${FETCH_OBJECT_VIEW_DATA_OBJECTS}`, body);
};
