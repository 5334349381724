import * as React from 'react';
import {
    Drawer,
    Button,
    Form,
    Input,
    Row,
    Col,
    Select,
    DatePicker,
    message,
} from 'antd';
import { HocOptions } from '../common/generic-hoc.types';
import Cross from '../../assets/cross';
import GenericHoc from '../common/generic-hoc';
import moment from 'moment';
import {
    addAsnFields,
    serviceTypeList,
} from './addAsnForm.constants';
import {
    createAsn,
    updateAsn,
    fetchDestinationLocationData,
} from '../../network/asn.api';
import FailureArrayHandler from '../common/FailureArrayHandler';
import { ReduxStore } from 'reducers/redux.types';

const { Option } = Select;

const styles = {
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        overflow: 'scroll',
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 12,
            marginLeft: 5,
        },
        '& .ant-form-item-children': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        '& .ant-form-item-explain.ant-form-item-explain-error': {
            fontSize: 10,
        },
        '& .ant-picker': {
            width: '100%',
        },
    },
    form: {
        width: '100%',
    },
};


const AddAsnForm = (props: any) => {
    const [form] = Form.useForm();
    const {
        classes,
        onClose,
        asnDetails,
        vendors,
        uiTheme,
    } = props;

    const [saving, setSaving] = React.useState<boolean>(false);
    const [vendorId, setVendorId] = React.useState<any>(null);
    const [destinationLocationId, setDestinationLocationId] = React.useState<any>(null);
    const [asnDate, setAsnDate] = React.useState<any>(moment().format('YYYY-MM-DD'));
    const [addAsnErrorModalVisible, setAddAsnErrorModalVisible] = React.useState<boolean>(false);
    const [asnfailureArray, setAsnFailureArray] = React.useState<any[]>([]);
    const searchTimeOut = React.useRef(undefined as any);
    const [destinationLocationList, setDestinationLocationList] = React.useState<any[]>([]);

    const prefillForm = () => {
        form.setFieldsValue({
            [addAsnFields.asn_number.key]: asnDetails?.asn_number,
            [addAsnFields.vendor_code.key]: asnDetails?.child_customer_code,
            [addAsnFields.vendor_name.key]: asnDetails?.child_customer_name,
            [addAsnFields.service_type.key]: asnDetails?.service_type ? asnDetails.service_type : 'EXPRESS',
            [addAsnFields.destination_location_code.key]: asnDetails?.destination_hub_code,
            [addAsnFields.allocation_tag.key]: asnDetails?.allocation_tag,
            [addAsnFields.num_of_hu.key]: asnDetails?.num_of_hu,
        });
    };

    React.useEffect(() => {
        prefillForm();
    }, []);

    const handleSave = async () => {
        const formValues = await form.validateFields();
        if (formValues.errorFields?.length) {
            return;
        }
        setSaving(true);
        const apiBody = form.getFieldsValue();
        let response;
        if (asnDetails?.asn_number) {
            const asnEditPayload = {
                asn_number: form.getFieldValue('asn_number'),
                num_of_hu: parseInt(form.getFieldValue('num_of_hu'), 10),
                service_type: form.getFieldValue('service_type'),
                allocation_tag: form.getFieldValue('allocation_tag'),
            };
            response = await updateAsn(asnEditPayload);
        } else {
            const addAsnPayload = {
                ...apiBody,
                vendor_id: vendorId,
                destination_location_id: destinationLocationId,
                asn_date: asnDate,
                customer_id: window.localStorage.getItem('userId') || null,
                num_of_hu: parseInt(form.getFieldValue('num_of_hu'), 10),
            };
            response = await createAsn(addAsnPayload);
        }
        if (response?.isSuccess) {
            if (response?.data?.failures?.length) {
                setAddAsnErrorModalVisible(true);
                setAsnFailureArray(response.data.failures);
            } else {
                message.success(asnDetails?.asn_number ? 'Asn updated successfully' : 'Asn created successfully');
                onClose(true);
            }
        } else {
            message.error(response.errorMessage);
        }
        setSaving(false);
    };

    const handleCloseAddAsnModal = () => {
        setAddAsnErrorModalVisible(false);
    };


    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {asnDetails?.asn_number ? 'EDIT ASN' : 'ADD ASN'}
                    </span>
                </div>
                <div>
                    <Button
                        loading={saving}
                        style={{
                            borderRadius: '4px',
                            border: `1px solid ${uiTheme.primaryColor}`,
                            color: '#FFFFFF',
                        }}
                        type="primary"
                        onClick={handleSave}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        );
    };

    const renderLabel = (key: string) => {
        const required = addAsnFields[key].required;
        return (
            <div
                className={classes.label}
            >
                <span>{addAsnFields[key].pretty_name}</span>
                {required && <span>*</span>}
            </div>
        );
    };

    const validateInput = (key: string, value: any, callback: any) => {
        if (!value && key !== 'allocation_tag') {
            return callback(`${addAsnFields[key].errormsg}`);
        }
        if (key === 'asn_number' && value.length !== 9) {
            return callback('Invalid Asn Number');
        }
        return callback();
    };

    const validateInputNumber = (key: string, value: any, callback: any) => {
        if (!value) {
            return callback(`${addAsnFields[key].errormsg}`);
        }
        if (value < 0) {
            return callback(`${addAsnFields[key].pretty_name} can not be negative`);
        }
        return callback();
    };

    const renderInput = (key: string) => {
        return (
            <div>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateInput(key, value, cb),
                    }]}
                >
                    <Input
                        name={key}
                        max={50}
                        disabled={asnDetails?.asn_number ? !addAsnFields[key].is_editable : addAsnFields[key].disabled}
                        placeholder={addAsnFields[key].placeholder}
                    />
                </Form.Item>
            </div>
        );
    };

    const handleDateSelect = (val: any) => {
        setAsnDate(moment(val).format('YYYY-MM-DD'));
    };

    const validateDate = (key: string, value: any, callback: any) => {
        if (!value && !asnDetails?.asn_number) {
            return callback(`${addAsnFields[key].errormsg}`);
        }
        return callback();
    };

    const validateSelect = (key: string, value: any, callback: any) => {
        if (!value) {
            return callback(`${addAsnFields[key].errormsg}`);
        }
        return callback();
    };

    const renderDateField = (key:string) => {
        return (
            <div>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateDate(key, value, cb),
                    }]}
                >
                    <DatePicker
                        disabled={asnDetails?.asn_number ? !addAsnFields[key].is_editable : false}
                        onChange={handleDateSelect}
                        allowClear={false}
                        placeholder={addAsnFields[key].placeholder}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderAsnDetails = () => {
        return (
            <Row>
                <Col md={11} lg={11} offset={1}>
                    {renderInput(addAsnFields.asn_number.key)}
                </Col>
                <Col md={11} lg={11} offset={1}>
                    {renderDateField(addAsnFields.asn_date.key)}
                </Col>
            </Row>
        );
    };

    const commitSearch = async (query:any) => {
        const response = await fetchDestinationLocationData({ queryStr: query });
        if (response.isSuccess) {
            setDestinationLocationList(response?.data);
        }
    };

    const handleSearch = (query:any) => {
        if (searchTimeOut.current) {
            clearTimeout(searchTimeOut.current);
            searchTimeOut.current = undefined;
        }
        searchTimeOut.current = setTimeout(() => {
            commitSearch(query);
        }, 300);
    };

    const handleDestinationLocationIdOnSelect = (option: any) => {
        setDestinationLocationId(option.key);
    };

    const handleClearDestinationId = () => {
        setDestinationLocationId(null);
    };

    const renderSelectWithSearch = (key:string) => {
        return (
            <div>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateSelect(key, value, cb),
                    }]}
                >
                    <Select
                        showSearch
                        allowClear
                        disabled={asnDetails?.asn_number ? !addAsnFields[key].is_editable : addAsnFields[key].disabled}
                        placeholder={addAsnFields[key].placeholder}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        onClear={handleClearDestinationId}
                        onSearch={handleSearch}
                        onSelect={(val, option) => handleDestinationLocationIdOnSelect(option)}
                        notFoundContent={null}
                    >
                        { destinationLocationList && destinationLocationList.map((item:any) => {
                            return <Option key={item.id} value={item.code}>{item.code}</Option>;
                        })}
                    </Select>
                </Form.Item>
            </div>
        );
    };

    const handleSelectOnChange = (val: any, options: any, key: string) => {
        if (key === 'vendor_code') {
            form.setFieldsValue({
                vendor_name: options.label || null,
            });
            setVendorId(options.key);
        }
    };

    const handleOnClear = (key: any) => {
        if (key === 'vendor_code') {
            setVendorId(null);
            form.setFieldsValue({
                vendor_name: null,
            });
        }
    };

    const renderSelect = (key:string, options: any) => {
        return (
            <div>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    rules={[{
                        validator: (rule, value, cb) => validateSelect(key, value, cb),
                    }]}
                >
                    <Select
                        showSearch
                        allowClear
                        disabled={asnDetails?.asn_number ? !addAsnFields[key].is_editable : addAsnFields[key].disabled}
                        placeholder="Type"
                        optionFilterProp="children"
                        defaultValue={!asnDetails?.asn_number && key === 'service_type' ? 'EXPRESS' : ''}
                        filterOption={
                            (input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())
                        }
                        onClear={() => handleOnClear(key)}
                        notFoundContent="NA"
                        onSelect={(val, option) => handleSelectOnChange(val, option, key)}
                    >
                        {options && options.map((item:any) => {
                            return <Option key={item.id} value={item.code} label={item.name}>{item.code}</Option>;
                        })}
                    </Select>
                </Form.Item>
            </div>
        );
    };


    const renderInputNumber = (key: string) => {
        return (
            <div>
                {renderLabel(key)}
                <Form.Item
                    name={key}
                    className={classes.formItem}
                    valuePropName="value"
                    rules={[{
                        validator: addAsnFields[key].required
                            ? (rule, value, cb) => validateInputNumber(key, value, cb)
                            : () => {},
                    }]}
                >
                    <Input
                        type="number"
                        name={key}
                        min={1}
                        placeholder={addAsnFields[key].placeholder}
                    />
                </Form.Item>
            </div>
        );
    };


    const renderOtherDetails = () => {
        return (
            <>
                <Row>
                    <Col md={11} lg={11} offset={1}>
                        {renderSelectWithSearch(addAsnFields.destination_location_code.key)}
                    </Col>
                    <Col md={11} lg={11} offset={1}>
                        {renderSelect(addAsnFields.service_type.key, serviceTypeList)}
                    </Col>
                </Row>
                <Row>
                    <Col md={11} lg={11} offset={1}>
                        {renderInput(addAsnFields.allocation_tag.key)}
                    </Col>
                    <Col md={11} lg={11} offset={1}>
                        {renderInputNumber(addAsnFields.num_of_hu.key)}
                    </Col>
                </Row>
            </>
        );
    };

    const renderVendorDetails = () => {
        return (
            <Row>
                <Col md={11} lg={11} offset={1}>
                    {renderSelect(addAsnFields.vendor_code.key, vendors)}
                </Col>
                <Col md={11} lg={11} offset={1}>
                    {renderInput(addAsnFields.vendor_name.key)}
                </Col>
            </Row>

        );
    };

    const renderForm = () => {
        return (
            <Form
                form={form}
                className={classes.form}
            >
                {renderAsnDetails()}
                {renderVendorDetails()}
                {renderOtherDetails()}
            </Form>
        );
    };

    const renderAddAsnErrorModal = () => {
        if (!addAsnErrorModalVisible) {
            return null;
        }
        return (
            <FailureArrayHandler
                onModalClose={handleCloseAddAsnModal}
                failureArray={asnfailureArray}
                isVisible={addAsnErrorModalVisible}
            />
        );
    };

    return (
        <>
            <Drawer
                className={classes.main}
                visible
                width="40%"
                title={renderHeader()}
                onClose={() => onClose()}
                closable={false}
            >
                {renderForm()}
            </Drawer>
            {renderAddAsnErrorModal()}
        </>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(AddAsnForm);
