import * as React from 'react';
import withStyles from 'react-jss';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { StylesProps } from '../../theme/jss-types';

const styles = {
    main: {
        textAlign: 'center',
        width: '100%',
    },
    message1: {
        textAlign: 'center',
        color: '#EA2626',
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
    },
    message2: {
        marginTop: '2%',
        textAlign: 'center',
        color: '#4a4a4a',
        fontSize: 12,
        width: '100%',
        padding: '12px 25%',
        fontWeight: 600,
        fontFamily: 'Roboto, sans-serif',
    },
    errorIcon: {
        width: '100%',
        textAlign: 'center',
        marginTop: '20%',
    },
};

interface FallbackProps extends StylesProps<typeof styles> {
    errorMsg: string;
}

const Fallback = (props: FallbackProps) => {
    const { classes, errorMsg } = props;
    return (
        <div className={classes.main}>
            <div className={classes.errorIcon}>
                <ExclamationCircleFilled
                    style={{
                        color: '#EA2626',
                        fontSize: 32,
                    }}
                />
            </div>
            <div className={classes.message1}>OOPS !</div>
            <div className={classes.message2}>
                {errorMsg}
            </div>
        </div>
    );
};

const FallbackPage = withStyles(styles)(Fallback);
export default FallbackPage;
