import { API_BASE_URL } from '../library/globals';
import { GET } from './api-hander.service';

import {
    FETCH_OBJECT_PAGES_CUSTOMER,
} from './api.constants';

export const fetchObjectPagesForCustomer = async () => {
    const response = await GET(`${API_BASE_URL}${FETCH_OBJECT_PAGES_CUSTOMER}`);
    return response;
};
