import moment from 'moment';
import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { getPickups } from '../network/pickup.api';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
} from './generic-action';

const bucket = Buckets.PICKUPS;
const dateFormat = 'DD/MM/YYYY';

const getRiderAssigned = (value: string) => {
    if (value === 'ALL') {
        return undefined;
    }
    return value === 'ASSIGNED';
};

const getFilterValue = (value: string) => {
    if (value === 'ALL') {
        return undefined;
    }
    return value;
};

export const loadPickups = () => async (dispatch: Dispatch<any>, store: () => ReduxStore) => {
    dispatch(startLoading(bucket));

    const { generic } = store();
    const { PICKUPS } = generic;
    const { appliedFilters } = PICKUPS;
    const response = await getPickups({
        resultPerPage: 20,
        pageNumber: 1,
        fromDate: moment(appliedFilters.fromDate).format(dateFormat),
        toDate: moment(appliedFilters.toDate).format(dateFormat),
        sortBy: appliedFilters.sortBy,
        courierType: getFilterValue(appliedFilters.courierType),
        isRiderAssigned: getRiderAssigned(appliedFilters.isRiderAssigned),
        status: getFilterValue(appliedFilters.status),
    });
    if (response.isSuccess) {
        dispatch(setGenericData(bucket, response?.data || []));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
