/* eslint-disable max-len */
import { ThemeType } from '../../theme/jss-types';

export const walletStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        padding: '0px 8px',
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 11,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
            paddingRight: 8,
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        backgroundColor: theme.backgroundColor,
    },
    pageFilter: {
        display: 'flex',
        marginTop: 8,
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        color: '#666666',
        padding: '6px 16px',
        cursor: 'pointer',
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        marginRight: 2,
    },
    selectedPage: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: '#111111',
        padding: '6px 16px',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px 4px 0 0',
        marginRight: 2,
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 16px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
    },
    count: {
        height: 18,
        width: 28,
        borderRadius: 9,
        backgroundColor: '#E9E9E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        color: '#333',
        fontWeight: 600,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 11,
        letterSpacing: 0,
        marginRight: 8,
    },
    countSelected: {
        backgroundColor: theme.primaryColor,
        color: '#FFF',
        fontWeight: 'bold',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
    },
    table: {
        overflow: 'scroll',
        maxHeight: '81vh',
        minHeight: '81vh',
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    bucketSelected: {
        color: '#111111',
        fontWeight: 'bold',
        backgroundColor: '#FFF',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    moreFilter: {
        marginLeft: 16,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.primaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    bucketRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    bucketName: {
        marginRight: 12,
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        '& .ant-picker': {
            width: 130,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 11,
        },
    },
    recharge: {
        display: 'flex',
        alignItems: 'center',
    },
    cashNCarry: {
        fontWeight: 'bold',
        width: '100%',
        borderRadius: '0 2px 0 0',
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        backgroundColor: '#E6FFE5',
        marginTop: 8,
        alignItems: 'center',
    },
    balanceDiv: {
        display: 'flex',
        width: '50%',
    },
    img: {
        height: 40,
    },
    balanceText: {
        fontSize: 10,
        color: '#111111',
        fontWeight: 500,
    },
    balance: {
        marginLeft: '5%',
        marginRight: '10%',
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor}`,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            // backgroundColor: `${theme.primaryColor} !important`,
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
    },
    downloadBtn: {
        marginRight: 8,
    },
    settingIcon: {
        color: '#333333',
        fontSize: 20,
        cursor: 'pointer',
        marginLeft: 8,
    },
    modal: {
        '& .ant-modal-header': {
            backgroundColor: '#065492',
            borderRadius: '4px 4px 0 0',
            padding: '16px 12px',
        },
        '& .ant-modal-footer': {
            backgroundColor: '#065492',
            borderRadius: '0 0 4px 4px',
            padding: '16px 12px',
            color: '#FFFFFF',
            fontSize: 10,
            textAlign: 'center',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
        },
        '& .ant-modal-body': {
            padding: 12,
        },
        '& .ant-btn-primary': {
            fontSize: 12,
            fontWeight: 600,
        },
        top: '11%',
        left: '30%',
    },
    faqText: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0,
        marginRight: 5,
    },
    faqBox: {
        display: 'flex',
        alignItems: 'center',
    },
});
