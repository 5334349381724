import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
} from './generic-action';
import { fetchSupplementaryServices } from 'network/supp-services.api';
import { message } from 'antd';
import moment from 'moment';

const bucket = Buckets.SUPP_SERVICES;

export const loadSuppServices = () => async (dispatch: Dispatch<any>, store: () => ReduxStore) => {
    dispatch(startLoading(bucket));

    dispatch(setGenericData(bucket, []));

    const { generic } = store();
    const { SUPP_SERVICES } = generic;
    const { pagination, filters } = SUPP_SERVICES;
    console.log('supp filters', pagination, filters);

    const response = await fetchSupplementaryServices({
        from_date: moment(filters?.fromDate).format('YYYY-MM-DD'),
        to_date: moment(filters?.toDate).format('YYYY-MM-DD'),
        status: filters.status,
        page_number: pagination.page,
        request_type: filters.objectType,
        reference_number: filters.referenceNumberType === 'supplementary_request_reference_number'
            ? filters.referenceNumber : undefined,
        consignment_number:
            filters.referenceNumberType === 'consignment_reference_number' ? filters.referenceNumber : undefined,
    });

    if (response.isSuccess) {
        dispatch(setGenericData(bucket, response?.data || {}));
    } else {
        message.error(response?.errorMessage || 'Something went wrong');
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
