/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const SinglePickup = (props: any) => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Group 29</title>
        <g id="Uppdated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Create" transform="translate(-235.000000, -228.000000)">
                <g id="Group-29" transform="translate(236.000000, 229.000000)">
                    <path d="M19.0368272,24 L0.679886686,24 C0.304453258,24 0,23.6956147 0,23.3201133 L0,4.9631728 C0,4.58773938 0.304453258,4.28328612 0.679886686,4.28328612 L19.0368272,4.28328612 C19.4122606,4.28328612 19.7167139,4.58773938 19.7167139,4.9631728 L19.7167139,23.3201133 C19.7167139,23.6956147 19.4122606,24 19.0368272,24" id="Fill-1" fill="#DDA986" />
                    <path d="M19.0368272,24 L2.03966006,24 C1.66422663,24 1.35977337,23.6956147 1.35977337,23.3201133 L1.35977337,4.9631728 C1.35977337,4.58773938 1.66422663,4.28328612 2.03966006,4.28328612 L19.0368272,4.28328612 C19.4122606,4.28328612 19.7167139,4.58773938 19.7167139,4.9631728 L19.7167139,23.3201133 C19.7167139,23.6956147 19.4122606,24 19.0368272,24" id="Fill-3" fill="#EDBA94" />
                    <polygon id="Fill-5" fill="#F2F6F7" points="4.65748895 16.5698448 2.59838414 19.1436918 3.88534164 19.1436918 3.88534164 22.232281 5.42956827 22.232281 5.42956827 19.1436918 6.71659377 19.1436918" />
                    <path d="M0,7.73683173 L0,4.96316601 C0,4.58773258 0.304453258,4.28327932 0.679886686,4.28327932 L19.0368272,4.28327932 C19.4122606,4.28327932 19.7167139,4.58773258 19.7167139,4.96316601 L19.7167139,23.3201065 C19.7167139,23.6956759 19.4122606,23.9999932 19.0368272,23.9999932 L0.679886686,23.9999932 C0.304453258,23.9999932 0,23.6956759 0,23.3201065 L0,9.94911501" id="Stroke-7" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.5084147,10.2152975 L9.85832975,9.11048159 L8.20838074,10.2152975 C8.02780283,10.301915 7.81866969,10.1703569 7.81866969,9.97013031 L7.81866969,4.28328612 L11.8979898,4.28328612 L11.8979898,9.97013031 C11.8979898,10.1703569 11.6889246,10.301915 11.5084147,10.2152975" id="Fill-9" fill="#F76725" />
                    <path d="M11.5084147,10.2152975 L9.85832975,9.11048159 L8.20838074,10.2152975 C8.02780283,10.301915 7.81866969,10.1703569 7.81866969,9.97013031 L7.81866969,4.28328612 L11.8979898,4.28328612 L11.8979898,9.97013031 C11.8979898,10.1703569 11.6889246,10.301915 11.5084147,10.2152975 Z" id="Stroke-11" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <polygon id="Fill-13" fill="#F76725" points="12.9072476 22.2323218 17.9490153 22.2323218 17.9490153 20.5266901 12.9072476 20.5266901" />
                    <polygon id="Stroke-15" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" points="12.9072476 22.2323218 17.9490153 22.2323218 17.9490153 20.5266901 12.9072476 20.5266901" />
                    <line x1="12.9072272" y1="18.9652793" x2="17.9489949" y2="18.9652793" id="Stroke-16" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <polyline id="Stroke-17" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" points="5.42967025 19.1437258 6.71655977 19.1437258 4.65752295 16.5698788 2.59835014 19.1437258 3.88530765 19.1437258" />
                    <polyline id="Stroke-18" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" points="3.88531445 19.1437258 3.88531445 22.232315 5.42967705 22.232315 5.42967705 19.1437258" />
                    <path d="M24,5.09915014 C24,2.28292351 21.7170765,0 18.9008499,0 C16.0846232,0 13.8016997,2.28292351 13.8016997,5.09915014 C13.8016997,7.91537677 16.0846232,10.1983003 18.9008499,10.1983003 C21.7170765,10.1983003 24,7.91537677 24,5.09915014" id="Fill-19" fill="#8CBBF7" />
                    <path d="M18.1539059,0.0543093484 C15.6918323,0.415669122 13.8016793,2.53671161 13.8016793,5.09913654 C13.8016793,7.91536317 16.0846028,10.1982867 18.9008295,10.1982867 C21.7170561,10.1982867 23.9999796,7.91536317 23.9999796,5.09913654 C23.9999796,2.50387309 22.0612827,0.361550142 19.5530448,0.0413915014" id="Stroke-20" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.6013847,5.11813938 C22.6013847,3.07956714 20.9472204,1.42689858 18.9066085,1.42689858 C16.8661326,1.42689858 15.2119003,3.07956714 15.2119003,5.11813938 C15.2119003,7.15671161 16.8661326,8.80938017 18.9066085,8.80938017 C20.9472204,8.80938017 22.6013847,7.15671161 22.6013847,5.11813938" id="Fill-21" fill="#F2F6F7" />
                    <path d="M22.5944839,5.09994561 C22.5944839,3.06511275 20.9409314,1.41563966 18.9010674,1.41563966 C16.8612034,1.41563966 15.207651,3.06511275 15.207651,5.09994561 C15.207651,7.13477847 16.8612034,8.78431955 18.9010674,8.78431955 C20.9409314,8.78431955 22.5944839,7.13477847 22.5944839,5.09994561 Z" id="Stroke-22" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="17.523617" y1="5.13556147" x2="18.9066425" y2="5.13556147" id="Stroke-23" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="18.924051" y1="7.80575184" x2="18.924051" y2="8.68953654" id="Stroke-24" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="18.924051" y1="1.48685099" x2="18.924051" y2="2.37063569" id="Stroke-25" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="22.5469394" y1="5.1299864" x2="21.6626108" y2="5.1299864" id="Stroke-26" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="16.1506946" y1="5.1299864" x2="15.266366" y2="5.1299864" id="Stroke-27" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                    <line x1="20.1994742" y1="3.27699943" x2="18.9066017" y2="5.11813258" id="Stroke-28" stroke="#000000" strokeWidth="0.689485637" strokeLinecap="round" strokeLinejoin="round" />
                </g>
            </g>
        </g>
    </svg>
);

const SinglePickupIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return SinglePickup({ ...innerProps, ...props });
    }}
    />
);

export default SinglePickupIcon;
