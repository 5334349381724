/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const CustomerSupportSVG = (props: any) => (
    <svg
        fill="#000000"
        id="Icons"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 30 30"
        xmlSpace="preserve"
        {...props}
    >
        <path d="M16,2C9.4,2,4,7.4,4,14v3c0,2.8,2.2,5,5,5c0.6,0,1-0.4,1-1v-8c0-0.6-0.4-1-1-1c-1.1,0-2.1,0.4-2.9,1c0.5-5,4.8-9,9.9-9 s9.4,3.9,9.9,9c-0.8-0.6-1.8-1-2.9-1c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1c0.6,0,1.3-0.1,1.8-0.4c-1.1,2-2.8,3.6-4.9,4.5 c-0.2-1.2-1.2-2.2-2.5-2.2c-1.3,0-2.4,1.1-2.4,2.4c0,0.7,0.3,1.4,0.9,1.9c0.5,0.4,1,0.6,1.6,0.6c0.1,0,0.3,0,0.4,0 C23.6,28,28,22.9,28,17v-3C28,7.4,22.6,2,16,2z" />
    </svg>
);

const CustromSupportIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return CustomerSupportSVG({ ...innerProps, ...props });
    }}
    />
);

export default CustromSupportIcon;
