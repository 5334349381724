import axios from 'axios';
import AuthHelper from '../auth/auth-helper';


const apiProvider = axios.create({});

// Add 401 interceptor
apiProvider.interceptors.response.use((response) => {
    return response;
}, async (error: any) => {
    if (error.response.status === 401) {
        // Dispatch action that user is being force logged out
        AuthHelper.logoutUser();
    }

    return Promise.reject(error);
});

export default apiProvider;
