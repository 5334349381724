import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../theme/jss-types';
import { LeftOutlined, RightOutlined, VerticalRightOutlined } from '@ant-design/icons';

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 8px',
    },
    pageNumber: {
        boxSizing: 'border-box',
        height: 22,
        width: 32,
        border: '1px solid #999999',
        borderRadius: 3,
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        textAlign: 'center',
    },
    left: {
        marginRight: 4,
        cursor: 'pointer',
    },
    right: {
        marginLeft: 4,
        cursor: 'pointer',
    },
};

interface PaginatorProps extends StylesProps<typeof styles> {
    isNextPresent: boolean;
    onNextClick: () => void;
    onPrevClick: () => void;
    onFirstClick?: () => void;
    currentPageNumber: number;
}

const Paginator = (props: PaginatorProps) => {
    const {
        classes,
        isNextPresent,
        onNextClick,
        onPrevClick,
        onFirstClick,
        currentPageNumber,
    } = props;

    const renderLeft = () => {
        return (
            <LeftOutlined
                className={classes.left}
                onClick={currentPageNumber > 1 ? onPrevClick : undefined}
                disabled={currentPageNumber === 1}
                style={{
                    color: currentPageNumber === 1 ? '#ddd' : '#000000',
                    cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                }}
            />
        );
    };

    const renderRight = () => {
        return (
            <RightOutlined
                className={classes.right}
                onClick={isNextPresent ? onNextClick : undefined}
                disabled={!isNextPresent}
                style={{
                    color: isNextPresent ? '#000000' : '#ddd',
                    cursor: isNextPresent ? 'pointer' : 'default',
                }}
            />
        );
    };

    const renderPageNumber = () => {
        return (
            <div className={classes.pageNumber}>
                {currentPageNumber}
            </div>
        );
    };

    const renderFirst = () => {
        return (
            <VerticalRightOutlined
                className={classes.left}
                onClick={currentPageNumber > 1 ? onFirstClick : undefined}
                style={{
                    color: currentPageNumber > 1 ? '#000000' : '#ddd',
                    cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderFirst()}
            {renderLeft()}
            {renderPageNumber()}
            {renderRight()}
        </div>
    );
};

export default withStyles(styles)(Paginator);
