import { ReduxStore } from '../../reducers/redux.types';
import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { consignemtStyles } from './consignment.styles';
import GenericHoc from '../common/generic-hoc';
import { RouteChildrenProps } from 'react-router';
import {
    getLabelTypeLabelCode,
} from '../labels/labels.constants';
import { StylesProps } from '../../theme/jss-types';
import {
    Master,
} from '../../types/master-data-types';
import { useTranslation } from 'react-i18next';
import {
    getPublishedLabels,
    printBulkLabel,
} from '../../network/consignments.api';
import {
    Modal,
    Row,
    Col,
    Button,
    message,
    Select,
    Checkbox,
} from 'antd';
import {
    InfoCircleFilled,
    MoreOutlined,
} from '@ant-design/icons';
import DragNSelect from '../common/drag-n-select';

const {
    useState,
    useEffect,
} = React;


interface BulkPrintProps
    extends StylesProps<ReturnType<typeof consignemtStyles>>, RouteChildrenProps {
    master: Master;
    consignments: string[];
    isVisible: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

interface DragItemProps
    extends StylesProps<ReturnType<typeof consignemtStyles>>, RouteChildrenProps {
    element: {
        key: string;
        value: string;
        pretty_name: string;
        options: Array<{ label: string; value: string }>;
        handleLabelCodeUpdate: (key: string, labelType: string | null) => void;
    };
}
const DragItem = (props: DragItemProps) => {
    const {
        element,
    } = props;

    return (
        <div style={{ padding: '10px' }}>
            <Row>
                <Col span={2}>
                    <MoreOutlined />
                    <MoreOutlined style={{ marginLeft: -8 }} />

                </Col>
                <Col span={2}>
                    <Checkbox
                        checked={!!element?.value}
                        onChange={(value) => element?.handleLabelCodeUpdate(
                            element?.key,
                            value.target.checked ? element?.options?.[0].value : null,
                        )}
                    />
                </Col>
                <Col span={6}>
                    {element.pretty_name}
                </Col>
                <Col span={10}>
                    <Select
                        style={{ width: '200px' }}
                        disabled={!element?.options?.length || !element?.value}
                        onSelect={(value) => element?.handleLabelCodeUpdate(element?.key, value)}
                        value={element?.value}
                    >
                        {element?.options?.map((item:any) => {
                            return (
                                <Select.Option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>
        </div>
    );
};

const DEFAULT_LABEL_CODE = {
    shipping_label: null,
    routing_label: null,
    invoice: null,
    address_label: null,
};

const BulkPrintModal = (props: BulkPrintProps) => {
    const {
        master,
        isVisible,
        consignments,
        onClose,
        onSuccess,
    } = props;
    const [allPublishLabels, setPublishedLabels] = useState<any[]>([]);
    const [labelTypeOrder, setLabelTypeOrder] = useState<string[]>([]);
    const [labelCodeObject, setLabelCodeObject] = useState<any>(DEFAULT_LABEL_CODE);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const customerProperties = master?.properties;
    const enableCodefree = master?.parts_to_show?.enable_code_free_label_generation || false;

    let restrictPrintInvoice = false;
    let showShipmentLabelA4 = true;
    let showShippingLabelA6 = true;
    let showshippingLabelPod = true;
    let showShippingLabel4X6 = true;
    let showAddressLabelA4 = true;
    let showAddressLabel4X2 = true;
    let showRoutingLabelA4 = true;
    let showRoutingLabel4x4 = true;
    if (customerProperties && enable_customer_config) {
        restrictPrintInvoice = !customerProperties?.print_invoice;
        showShipmentLabelA4 = customerProperties?.a4_shipping_label;
        showShippingLabelA6 = customerProperties?.a6_shipping_label;
        showshippingLabelPod = customerProperties?.shipping_label_pod;
        showShippingLabel4X6 = customerProperties?.x46_shipping_label;
        showAddressLabelA4 = customerProperties?.a4_address_label;
        showAddressLabel4X2 = customerProperties?.x42_address_label;
        showRoutingLabelA4 = customerProperties?.a4_routing_label;
        showRoutingLabel4x4 = customerProperties?.x44_routing_label;
    }
    const showShippingLabelButton = showShipmentLabelA4 || showShippingLabelA6
    || showshippingLabelPod || showShippingLabel4X6 || enableCodefree;
    const showRoutingLabelButton = showRoutingLabelA4 || showRoutingLabel4x4;
    const showAddressLabelButton = showAddressLabelA4 || showAddressLabel4X2;

    const getAllPublishedLabels = async () => {
        const response = await getPublishedLabels();
        if (response.isSuccess) {
            const validateList = (label: any) => label.is_active;
            const updatedLabels = response.data?.metadata_list?.filter(validateList);
            if (updatedLabels && Array.isArray(updatedLabels) && updatedLabels.length) {
                setPublishedLabels(updatedLabels);
            }
        } else {
            const errorMessage = response.errorMessage || t('error_message');
            message.error(errorMessage);
        }
    };

    useEffect(() => {
        getAllPublishedLabels();
    }, []);


    const generateShippingLabelOptions = () => {
        const labelOptions = [
            { isVisible: showShipmentLabelA4, label: t('label_a4'), value: 'SHIP_LABEL_A4' },
            { isVisible: showShippingLabelA6, label: t('label_a6'), value: 'SHIP_LABEL_A6' },
            { isVisible: showshippingLabelPod, label: t('shipping_label_pod'), value: 'SHIP_LABEL_POD' },
            { isVisible: showShippingLabel4X6, label: '4*6', value: 'SHIP_LABEL_4X6' },
        ];

        const standardOptions = labelOptions.filter((option) => option.isVisible)
            .map(({ label, value }) => ({ label, value }));

        if (enableCodefree) {
            const codefreeOptions = allPublishLabels?.map((entry) => {
                return {
                    label: entry.label_category,
                    value: getLabelTypeLabelCode(entry.label_category),
                };
            });
            return codefreeOptions;
        }

        return standardOptions;
    };

    const generateRoutingLabelOptions = () => {
        const labelOptions = [
            { isVisible: showRoutingLabelA4, label: t('label_a4'), value: 'ROUTE_LABEL_A4' },
            { isVisible: showRoutingLabel4x4, label: '4*4', value: 'ROUTE_LABEL_4X4' },
        ];

        const options = labelOptions.filter((option) => option.isVisible).map(({ label, value }) => ({ label, value }));

        return options;
    };

    const generateInvoiceOptions = () => {
        const labelOptions = [
            { isVisible: true, label: 'Print Invoice', value: 'INVOICE' },
        ];

        const options = labelOptions.filter((option) => option.isVisible).map(({ label, value }) => ({ label, value }));

        return options;
    };

    const generateAddressLabelOptions = () => {
        const labelOptions = [
            { isVisible: showAddressLabelA4, label: t('label_a4'), value: 'ADDR_LABEL_A4' },
            { isVisible: showAddressLabel4X2, label: '4*2', value: 'ADDR_LABEL_4X2' },
        ];

        const options = labelOptions.filter((option) => option.isVisible).map(({ label, value }) => ({ label, value }));

        return options;
    };

    const resetSelection = () => {
        setLabelTypeOrder([]);
        setLabelCodeObject(DEFAULT_LABEL_CODE);
    };

    const handleBulkPrintModalCancel = () => {
        resetSelection();
        onClose();
    };

    const handleLabelCodeUpdate = (key: string, labelType: string) => {
        setLabelCodeObject({
            ...labelCodeObject,
            [key]: labelType,
        });
    };

    const labelTypeOptions: any = {
        shipping_label: generateShippingLabelOptions(),
        routing_label: generateRoutingLabelOptions(),
        invoice: generateInvoiceOptions(),
        address_label: generateAddressLabelOptions(),
    };

    const labelTypesToShow: any = {
        shipping_label: showShippingLabelButton,
        routing_label: showRoutingLabelButton,
        invoice: master.Customer.show_invoice_options || restrictPrintInvoice,
        address_label: showAddressLabelButton,
    };

    const labelTypesToRender = Object.keys(labelTypesToShow).filter((key) => labelTypesToShow[key]);
    const arrayToUse = labelTypeOrder.length ? labelTypeOrder : labelTypesToRender;
    const sortableItems = arrayToUse.map((labelType: string) => {
        return {
            key: labelType,
            value: labelCodeObject[labelType],
            pretty_name: t(labelType),
            handleLabelCodeUpdate,
            options: labelTypeOptions[labelType],
        };
    });

    const handleLabelTypeOrder = (newOrder: string[]) => {
        setLabelTypeOrder(newOrder);
    };

    const handleBulkPrintModalSuccess = async () => {
        setLoading(true);
        const consignmentLength = consignments.length;
        if (!consignmentLength) {
            message.error('Please select atleast one consignment');
            return;
        }

        if (!arrayToUse.length) {
            message.error('Please select atleast one label type');
        }

        const labelCodes = arrayToUse.map((code) => labelCodeObject[code])
            .filter((labelCode: string) => !!labelCode);

        const labelCodesLength = labelCodes.length;
        /**
         * If user select C1,C2,C3 consignments of L1,L2 label codes
         * consignmentsToSend = [C1,C1,C2,C2,C3,C3]
         * labelCodesToSend = [L1,L2,L1,L2,L1,L2]
         */
        const consignmentsToSend = consignments.flatMap((referenceNumber) => Array(labelCodesLength).fill(referenceNumber));
        const labelCodesToSend = Array.from({ length: consignmentLength }, () => labelCodes).flat();

        const payload = {
            labelType: 'LABEL_CODE_HANDLER',
            consignmentList: consignmentsToSend,
            labelCodeArray: labelCodesToSend,
        };

        const response = await printBulkLabel(payload);
        if (response.isSuccess) {
            message.success('Print Request Added');
            resetSelection();
            onSuccess();
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    return (
        <Modal
            width="45%"
            visible={isVisible}
            onCancel={handleBulkPrintModalCancel}
            title={(
                <span
                    style={{ color: '#092e79', fontWeight: 'bold' }}
                >
                    {t('bulk_print')}
                </span>
            )}
            footer={[
                <Row>
                    <Col span={17}>
                        <div style={{ textAlign: 'left' }}>
                            <Row>
                                <Col span={2}>
                                    <InfoCircleFilled
                                        style={{
                                            color: '#016FC3',
                                            marginLeft: 12,
                                        }}
                                    />
                                </Col>
                                <Col span={22}>
                                    <div>
                                        {t('bulk_print_note')}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={3}>
                        <Button
                            key="ok"
                            type="primary"
                            onClick={handleBulkPrintModalSuccess}
                            loading={loading}
                        >
                            {t('submit')}
                        </Button>
                    </Col>
                    <Col span={3} style={{ paddingLeft: '10px' }}>
                        <Button key="ok" type="dashed" onClick={handleBulkPrintModalCancel}>
                            {t('cancel')}
                        </Button>
                    </Col>
                </Row>,
            ]}
        >
            <div style={{ height: '300px' }}>
                <p>{t('bulk_print_body_title')}</p>
                <DragNSelect
                    selectedList={sortableItems}
                    ItemComponent={DragItem}
                    updateSelection={handleLabelTypeOrder}
                />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const {
        master,
        uiTheme,
    } = state;

    return {
        master,
        uiTheme,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: consignemtStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(BulkPrintModal);

