import {
    getProducts,
} from 'network/settings-product';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { Product, TableColumn } from './settings.types';
import {
    Button,
    message,
    Table,
} from 'antd';
import { productColumns } from './settings.constants';
import { accountStyles } from './settings.styles';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import NewProduct from './new-product';
import DeleteProduct from './delete-product';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

interface ProductMappingProps extends StylesProps<ReturnType<typeof accountStyles>> {
    uiTheme :ThemeType,
}

const ProductMapping = (props: ProductMappingProps) => {
    const { classes, uiTheme } = props;
    const { t } = useTranslation();
    const [products, setProducts] = React.useState<Product[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [newVisible, setNewVisible] = React.useState<boolean>(false);
    const [product, setProduct] = React.useState<Product | undefined>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState<boolean>(false);

    const loadProducts = async () => {
        setLoading(true);
        const response = await getProducts({ searchKey: '' });
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setProducts(response?.data || {});
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadProducts();
    }, [toggle]);

    const renderTitle = () => {
        return (
            <div className={classes.title}>
                <span>{t('product_code_mapping')}</span>
                <Button
                    type="primary"
                    onClick={() => setNewVisible(true)}
                    className={classes.newButton}
                >
                    {t('add')}
                </Button>
            </div>
        );
    };

    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const handleEditProduct = (row: Product) => {
        setProduct(row);
        setNewVisible(true);
    };

    const handleDeleteProduct = (row: Product) => {
        setProduct(row);
        setIsDeleteModalVisible(true);
    };

    const renderActions = (row: Product) => {
        return (
            <>
                <EditFilled
                    key={row.id}
                    style={{
                        color: uiTheme.primaryColor,
                        cursor: 'pointer',
                        marginRight: '10px',
                    }}
                    onClick={() => handleEditProduct(row)}
                />
                <DeleteFilled
                    key={row.id}
                    style={{
                        color: uiTheme.primaryColor,
                        cursor: 'pointer',
                    }}
                    onClick={() => handleDeleteProduct(row)}
                />
            </>
        );
    };

    const renderColumn = (
        text: string,
        row: Product,
        column: string,
        index: number,
    ) => {
        switch (column) {
            case 'index': return renderText(index + 1);
            case 'actions': return renderActions(row);
            case 'dimensions': return renderText(`${row.length} * ${row.width} * ${row.height}`);
            default: return renderText(text as string);
        }
    };

    const renderColumns = () => {
        const columns: any = productColumns.map((column: TableColumn) => {
            return {
                key: column.key,
                title: column.key === 'name' ? t('product_name') : t(column.key),
                dataIndex: column.key,
                ellipsis: true,
                bordered: false,
                render: (text: string, row: any, idx: number) => renderColumn(text, row, column.key, idx),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                columns={renderColumns()}
                loading={loading}
                className={classes.table}
                dataSource={products}
                pagination={false}
                rowKey={(row) => (row.id)}
                scroll={{
                    y: 650,
                }}
            />
        );
    };

    const handleFormClose = (reload?: boolean) => {
        setNewVisible(false);
        setProduct(undefined);
        setIsDeleteModalVisible(false);
        if (reload) {
            setToggle(!toggle);
        }
    };

    const renderCreate = () => {
        if (!newVisible) {
            return null;
        }
        return (
            <NewProduct
                product={product}
                onClose={(reload) => handleFormClose(reload)}
            />
        );
    };

    const renderDelete = () => {
        if (!isDeleteModalVisible) {
            return null;
        }
        return (
            <DeleteProduct
                product={product}
                onClose={(reload) => handleFormClose(reload)}
            />
        );
    };
    return (
        <div className={classes.main}>
            {renderTitle()}
            {renderTable()}
            {renderCreate()}
            {renderDelete()}
        </div>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(accountStyles)(GenericHoc(hocConfig)(ProductMapping));
