import * as React from 'react';
import { StylesProps } from '../../../theme/jss-types';
import { FormField, InputTypes } from '../create-consignment.types';
import { formFields } from '../create-modal.constants';
import { scheduleStepStyles } from '../single-consignment.styles';
import ServicesIcon from '../single-consignment-icons/services';
import ScheduleIcon from '../../../assets/schedule-details';
import moment, { Moment } from 'moment';
import {
    Checkbox,
    DatePicker,
    Form,
    FormInstance,
    Select,
} from 'antd';
import { Service, TimeSlot } from '../../../library/Types';
import Loader from '../../common/Loader';
import { getTimeSlots } from '../../../network/consignments.api';
import { getBusinessTat, getInternationalBusinessTatV2, getServiceabilityRateTat } from '../../../network/common.api';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import DocPopup from './doc-popup';
import Helper from 'library/Helper';
import { useTranslation } from 'react-i18next';
import GenericHoc from 'components/common/generic-hoc';
import { ReduxStore } from 'reducers/redux.types';
import { HocOptions } from 'components/common/generic-hoc.types';

const {
    Services,
    PickupSchedule,
    PickupTime,
} = formFields;

const {
    useState,
    useEffect,
} = React;
interface StepFourProps extends StylesProps<ReturnType<typeof scheduleStepStyles>> {
    formData: any;
    form: FormInstance;
    nextPage: any;
    showScheduleOnlyWhenHyperlocal: any;
    isFTL?: boolean;
    enableServiceability?: boolean;
    pickup_schedule_creation?: boolean;
    getAPIBody: () => any;
}

const StepThree = (props: StepFourProps) => {
    const {
        classes, formData, form, nextPage, showScheduleOnlyWhenHyperlocal, isFTL,
        getAPIBody, enableServiceability, pickup_schedule_creation,
    } = props;
    const { t } = useTranslation();
    const [selectedService, setSelected] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [services, setServices] = React.useState<any[]>([]);
    const [pickupDate, setPickupDate] = useState<Moment | null>(moment());
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const [docProps, setDocProps] = useState<any>({
        isVisible: false,
        mandatoryList: [],
        optionalList: [],
    });

    const loadServices = async () => {
        setLoading(true);
        const storage = window.localStorage;

        const commonParts = {
            srcPincode: formData.srcAddress?.pincode,
            srcStateName: formData.srcAddress?.stateName,
            dstStateName: formData.dstAddress?.stateName,
            dstPincode: formData.dstAddress?.pincode,
            countryName: formData.dstAddress?.countryName,
            clientCode: storage.getItem('userCode'),
            width: formData.width,
            hight: formData.hight,
            length: formData.length,
            weight: formData.weight || 1,
            numPieces: formData?.numberOfPieces,
            referenceNumber: formData.referenceNumber,
            courierType: formData.courierType?.toUpperCase(),
            is_international: formData.is_international,
            pieceDetails: formData?.courierType === 'non-document'
                ? [{
                    length: Helper.converttoCentimeter(formData?.length, formData?.unit),
                    width: Helper.converttoCentimeter(formData?.width, formData?.unit),
                    height: Helper.converttoCentimeter(formData?.height, formData?.unit),
                }] : undefined,
            source: 'CUSTOMER_PORTAL_SINGLE_V2',
        };
        if (enableServiceability) {
            let apiBody = {};
            try {
                apiBody = (await getAPIBody()).apiBody;
            } catch (error) {
                apiBody = {};
            }
            const response = await getServiceabilityRateTat(apiBody);
            setServices(response?.data || []);
            setLoading(false);
            return;
        }
        if (formData.consignmentCategory === 'international') {
            let commodityCode;
            formData?.contentList?.forEach((commodity: any) => {
                if (formData?.commodityId && formData?.commodityId[0] === commodity?.COMMODITY_ID) {
                    commodityCode = commodity?.COMMODITY_CODE;
                }
            });
            commonParts.pieceDetails = formData?.courierType === 'non-document'
                ? Object.keys(formData.pieces).map((key) => {
                    const piece = formData.pieces[key];
                    return {
                        length: Helper.converttoCentimeter(piece.length, piece.dimensionsUnit),
                        width: Helper.converttoCentimeter(piece.width, piece.dimensionsUnit),
                        height: Helper.converttoCentimeter(piece.height, piece.dimensionsUnit),
                        weight: Helper.converttoKilogram(piece.weight, piece.weightUnit),
                    };
                }) : undefined;
            const response = await getInternationalBusinessTatV2({
                commodityCode,
                dstCountry: formData?.dstAddress?.countryName,
                dstCity: formData?.dstAddress?.cityName,
                srcCity: formData?.srcAddress?.cityName,
                ...commonParts,
            });
            setServices(response?.data?.map((service: any) => {
                return {
                    ...service,
                    serviceType: service.serviceType,
                    serviceTypeId: service.serviceType,
                };
            }) || []);
        } else {
            const response = await getBusinessTat(commonParts);
            setServices(response?.data || []);
        }
        setLoading(false);
    };

    const prefillData = () => {
        form.setFieldsValue({
            [Services.key]: formData[Services.key],
            [PickupTime.key]: formData[PickupTime.key],
            [PickupSchedule.key]: formData[PickupSchedule.key],
        });
        setSelected(formData[Services.key]);
    };

    useEffect(() => {
        loadServices();
        prefillData();
    }, []);

    useEffect(() => {
        if (nextPage) nextPage();
    }, [selectedService]);

    const loadTimeSlots = async () => {
        const response = await getTimeSlots({
            pickupDate: moment(pickupDate).format('DD/MM/YYYY').toString(),
        });
        setTimeSlots(Helper.isValidArray(response?.data) ? response?.data : []);
    };

    useEffect(() => {
        loadTimeSlots();
    }, [pickupDate]);

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const renderTitle = (field: FormField) => {
        return (
            <div
                className={classes.referenceTitle}
            >
                {t(field.key)}
            </div>
        );
    };

    function handleSelection(item: Service) {
        if (selectedService === item.serviceTypeId) {
            setSelected(undefined);
            form.setFieldsValue({
                [Services.key]: undefined,
            });
            setDocProps({
                isVisible: false,
                mandatoryList: [],
                optionalList: [],
            });
        } else {
            if (item.mandatoryDocuments?.length || item.additionalDocuments?.length) {
                setDocProps({
                    isVisible: true,
                    mandatoryList: item.mandatoryDocuments || [],
                    optionalList: item.additionalDocuments || [],
                    notes: item?.notes || [],
                });
            }
            setSelected(item.serviceTypeId);
            form.setFieldsValue({
                [Services.key]: item.serviceTypeId,
            });
        }
    }

    const getCheckboxClass = (item: Service) => {
        const classList = [classes.checkbox, classes.checkboxBody];
        if (selectedService === item.serviceTypeId) {
            classList.push(classes.checkboxSelected);
        }
        return classList.join(' ');
    };

    const renderServiceName = (item: Service) => {
        return (
            <div className={classes.serviceItem}>
                {item.serviceType}
            </div>
        );
    };

    const renderTat = (item: Service) => {
        return (
            <div className={classes.tat}>
                {`Delivery | ${item.period ? item.period : ''}`}
            </div>
        );
    };

    const renderRate = (item: Service) => {
        if (!item.rate?.amount) {
            return <div className={classes.codService} />;
        }
        return (
            <div className={classes.tat}>
                {`Rate | ${item.rate?.amount} INR`}
            </div>
        );
    };

    const checkedStyle = {
        color: '#27B479',
        fontSize: 12,
    };

    const outlined = {
        color: '#EA2626',
        fontSize: 12,
    };

    const renderCodServiceable = (item: Service) => {
        if (typeof (item.isCodServiceable) !== 'string') {
            return <div className={classes.codService} />;
        }
        return (
            <div className={classes.codService}>
                <span
                    className={classes.serviceableTitle}
                >
                    COD Serviceable
                </span>
                {item.isCodServiceable && <CheckCircleFilled style={checkedStyle} />}
                {!item.isCodServiceable && <CloseCircleOutlined style={outlined} />}
            </div>
        );
    };

    const renderLiteServiceable = (item: Service) => {
        if (typeof (item.isLiteServiceable) !== 'string') {
            return <div className={classes.codService} />;
        }
        return (
            <div className={classes.codService}>
                <span
                    className={classes.serviceableTitle}
                >
                    LITE Serviceable
                </span>
                {item.isLiteServiceable && <CheckCircleFilled style={checkedStyle} />}
                {!item.isLiteServiceable && <CloseCircleOutlined style={outlined} />}
            </div>
        );
    };

    const renderService = (item: Service) => {
        return (
            <Checkbox
                name={item.serviceTypeId}
                key={item.serviceTypeId}
                checked={item.serviceTypeId === selectedService}
                onClick={() => handleSelection(item)}
                className={getCheckboxClass(item)}
                disabled={formData.isEditingCN}
            >
                {renderServiceName(item)}
                {formData.consignmentCategory === 'international' ? null : renderTat(item)}
                {formData.consignmentCategory === 'international' ? null : renderCodServiceable(item)}
                {formData.consignmentCategory === 'international' ? null : renderLiteServiceable(item)}
                {renderRate(item)}
            </Checkbox>
        );
    };

    const renderCheckbox = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                {services?.map((item) => renderService(item))}
            </Form.Item>
        );
    };

    const renderSelect = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                <Select
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                    options={field.options}
                />
            </Form.Item>
        );
    };

    const disabledDate = (current: Moment) => {
        return moment(current) <= moment().subtract(1, 'd');
    };

    const handlePickupDate = (date: Moment | null) => {
        setPickupDate(date);
        form.resetFields([PickupTime.key]);
    };

    const renderDate = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                <DatePicker
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                    onChange={(val) => handlePickupDate(val)}
                    disabledDate={disabledDate}
                />
            </Form.Item>
        );
    };

    const renderTime = (field: FormField) => {
        return (
            <Form.Item
                name={field.key}
                key={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
            >
                <Select
                    placeholder="Time Slot"
                    style={{ width: field.width }}
                    disabled={pickupDate === null}
                >
                    {timeSlots.map((item) => {
                        const name = `${item.start} - ${item.end}`;
                        return (
                            <Select.Option key={name} value={name}>
                                {name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const renderFormItem = (field: FormField) => {
        switch (field.type) {
            case InputTypes.Select: return renderSelect(field);
            case InputTypes.Time: return renderTime(field);
            case InputTypes.Date: return renderDate(field);
            default: return 'Input is not defined';
        }
    };

    const renderLine = () => {
        return (
            <div className={classes.line} />
        );
    };

    const renderServicesForm = () => {
        return (
            <div className={classes.boxFields}>
                {renderTitle(Services)}
                {renderLine()}
                {renderCheckbox(Services)}
            </div>
        );
    };

    const renderServices = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(ServicesIcon, t('services'))}
                {renderServicesForm()}
            </div>
        );
    };

    const renderField = (field: FormField) => {
        return (
            <div className={classes.field}>
                {renderTitle(field)}
                {renderFormItem(field)}
            </div>
        );
    };

    const renderSchedule = () => {
        if (!pickup_schedule_creation) {
            return null;
        }
        if (!isFTL && showScheduleOnlyWhenHyperlocal && formData.consignmentType !== 'hyperlocal') {
            return null;
        }
        return (
            <div className={classes.box}>
                {renderRowDescription(ScheduleIcon, t('schedule_details'))}
                <div className={classes.scheduleBox}>
                    <div className={classes.schedule}>
                        {renderField(PickupSchedule)}
                        {renderField(PickupTime)}
                    </div>
                </div>
            </div>
        );
    };

    const renderPopup = () => {
        if (!docProps.isVisible) {
            return null;
        }
        return (
            <DocPopup
                optionalList={docProps.optionalList}
                mandatoryList={docProps.mandatoryList}
                onClose={() => {
                    setDocProps({
                        isVisible: false,
                    });
                }}
                notes={docProps.notes}
            />
        );
    };

    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            {renderServices()}
            {renderSchedule()}
            {renderPopup()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { config, properties } = state.master;
    return {
        enableServiceability: config?.customer_portal_config?.enable_serviceability,
        pickup_schedule_creation: properties?.pickup_schedule_creation,
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: scheduleStepStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
};

export default GenericHoc(hocConfig)(StepThree);
