import {
    defaultGenericState,
} from './default-values';
import {
    SET_FILTERS,
    RESET_FILTERS,
    SET_PAGINATION,
    RESET_PAGINATION,
    APPLY_FILTERS,
    START_LOADING,
    END_LOADING,
    SET_GENERIC_DATA,
    CLEAR_SELECTION,
    SET_GENERIC_SUMMARY,
} from './redux-constants';
import { DefaultState, GenericAction } from './redux.types';

function setFilters(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            filters: {
                ...action.data,
            },
            pagination: {
                ...defaultGenericState[action.bucketId].pagination,
            },
        },
    };
}

function resetFilters(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            filters: {
                ...defaultGenericState[action.bucketId].appliedFilters,
                bucketSelected: state[action.bucketId].filters.bucketSelected,
            },
            appliedFilters: {
                ...defaultGenericState[action.bucketId].appliedFilters,
                bucketSelected: state[action.bucketId].appliedFilters.bucketSelected,
            },
            pagination: defaultGenericState[action.bucketId].pagination,
        },
    };
}

function applyFilters(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            appliedFilters: state[action.bucketId].filters,
            pagination: defaultGenericState[action.bucketId].pagination,
        },
    };
}

function clearSelection(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            filters: state[action.bucketId].appliedFilters,
        },
    };
}

function setPagination(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            pagination: {
                ...state[action.bucketId].pagination,
                ...action.data,
            },
        },
    };
}

function resetPaginations(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            pagination: defaultGenericState[action.bucketId].pagination,
        },
    };
}

function handleLoading(state: DefaultState, action: GenericAction, loading: boolean) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            loading,
        },
    };
}

function setGenericData(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            data: action.data,
        },
    };
}

function setGenericSummary(state: DefaultState, action: GenericAction) {
    return {
        ...state,
        [action.bucketId]: {
            ...state[action.bucketId],
            summary: action.data,
        },
    };
}

const generic = (state = defaultGenericState, action: GenericAction) => {
    switch (action.type) {
        default: return state;
        case SET_FILTERS: return setFilters(state, action);
        case APPLY_FILTERS: return applyFilters(state, action);
        case RESET_FILTERS: return resetFilters(state, action);
        case SET_PAGINATION: return setPagination(state, action);
        case SET_GENERIC_DATA: return setGenericData(state, action);
        case RESET_PAGINATION: return resetPaginations(state, action);
        case END_LOADING: return handleLoading(state, action, false);
        case START_LOADING: return handleLoading(state, action, true);
        case CLEAR_SELECTION: return clearSelection(state, action);
        case SET_GENERIC_SUMMARY: return setGenericSummary(state, action);
    }
};

export default generic;
