/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const VeryHappy = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Very Happy</title>
        <defs>
            <polygon id="path-1" points="0 0 24.00004 0 24.00004 24 0 24" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="CP-V2-Like" transform="translate(-196.000000, -80.000000)">
                <g id="Group-5" transform="translate(12.000000, 12.000000)">
                    <g id="Group-2-Copy-4" transform="translate(184.000000, 68.000000)">
                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="36" height="36" rx="4" />
                        <g id="Group-14" transform="translate(6.000000, 6.000000)">
                            <g id="Group-3">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                </mask>
                                <g id="Clip-2" />
                                <path d="M24.00004,12.8135814 C24.00004,19.9743133 19.1607519,24 12.00002,24 C4.83928807,24 0,19.9743133 0,12.8135814 C0,5.38164897 4.57256762,-4.00000667e-05 12.00002,-4.00000667e-05 C19.4274724,-4.00000667e-05 24.00004,5.38164897 24.00004,12.8135814" id="Fill-1" fill="#999999" mask="url(#mask-2)" />
                            </g>
                            <path d="M18.8218154,12.9406456 C16.7443719,12.1771243 14.1850076,11.9226439 12.000044,11.9190439 C9.80100034,11.9125639 7.22907605,12.1800843 5.17827263,12.9406456 C4.30299117,13.2656061 4.6785118,14.1004075 4.82171204,14.5004082 C5.89619383,17.5021732 8.64675841,19.6664168 12.000044,19.6620235 C15.1324492,19.6430567 17.977094,17.8274537 19.3401362,14.0527274 C19.5025365,13.6029667 19.2713361,13.1058458 18.8218154,12.9406456" id="Fill-4" fill="#FFFFFF" />
                            <path d="M18.0924622,13.5416786 C16.4434194,13.0164777 14.2514958,12.7163172 11.973652,12.7163172 C9.65460809,12.7163172 7.50780451,13.0153577 5.85708176,13.5566386 C5.95132192,14.2303997 6.71348319,14.7163172 11.973652,14.7163172 C17.2727808,14.7163172 18.007262,14.2232797 18.0924622,13.5416786" id="Fill-6" fill="#FFFFFF" />
                            <path d="M15.1348732,18.0742541 C14.6721525,17.1704126 12.7894293,16.6110517 11.984948,17.5148132 C11.1805466,16.6110517 9.2977435,17.1704126 8.83502273,18.0742541 C10.774306,19.1224959 13.18767,19.1267359 15.1348732,18.0742541" id="Fill-8" fill="#FFFFFF" />
                            <path d="M7.84735708,2.29545183 C6.57279495,2.54897225 6.04783408,3.38673364 5.67127345,4.04945475 C5.06975245,3.58121397 4.26415111,3.00817301 2.98958898,3.26169344 C1.50678651,3.55665393 0.545184909,5.08497647 0.865345442,6.69481916 C1.32830621,9.02218304 4.77727196,10.5159455 6.33767456,10.9826663 C6.81295535,11.1248265 7.32407621,11.0231464 7.70879685,10.7099458 C8.97183895,9.68162414 11.5865633,6.98169964 11.1236825,4.65433576 C10.803442,3.04449307 9.33007955,2.00049133 7.84735708,2.29545183" id="Fill-10" fill="#FFFFFF" />
                            <path d="M16.1526829,2.29545183 C17.427245,2.54897225 17.9522059,3.38673364 18.3287665,4.04945475 C18.9302876,3.58121397 19.7358889,3.00817301 21.010451,3.26169344 C22.4932535,3.55665393 23.4548551,5.08497647 23.1346946,6.69481916 C22.6717338,9.02218304 19.222768,10.5159455 17.6623654,10.9826663 C17.1870846,11.1248265 16.6759638,11.0231464 16.2912432,10.7099458 C15.028201,9.68162414 12.4134767,6.98169964 12.8763575,4.65433576 C13.196598,3.04449307 14.6699604,2.00049133 16.1526829,2.29545183" id="Fill-12" fill="#FFFFFF" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const VeryHappyGrey = (props: any) => (
    <Icon component={(innerProps: any) => {
        return VeryHappy({ ...innerProps, ...props });
    }}
    />
);

export default VeryHappyGrey;
