import { message } from 'antd';
import { getRtoConsignments } from 'network/consignments.api';
import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
    setPagination,
} from './generic-action';

const bucket = Buckets.RTO_DASHBOARD;
// const dateFormat = 'DD/MM/YYYY';

export const loadRtoDashboardData = () => async (
    dispatch: Dispatch<any>,
    store: () => ReduxStore,
) => {
    dispatch(startLoading(bucket));

    dispatch(setGenericData(bucket, []));

    const { generic } = store();
    const { RTO_DASHBOARD } = generic;
    const { pagination } = RTO_DASHBOARD;

    const response = await getRtoConsignments({
        ...{
            pageNumber: pagination.currentPageNumber,
        },
    });

    if (response) {
        dispatch(setGenericData(bucket, response?.data?.ndrConsignments || []));
        dispatch(setPagination(bucket, {
            isNextPresent: response?.data?.isNextPresent,
        }));
    } else {
        message.error(response?.errorMessage || 'something went wrong!');
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
