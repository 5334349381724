export const rules = (t: any, passwordPolicy: any) => ({
    is_digit_required: {
        regex: '^(?=.*[0-9])',
        label: t('is_digit_required'),
    },
    max_password_length: {
        label: `${t('max_password_length')} ${passwordPolicy?.max_password_length?.val || ''}`,
        max_length: passwordPolicy?.max_password_length?.val,
    },
    min_password_length: {
        label: `${t('min_password_length')} ${passwordPolicy?.min_password_length?.val || ''}`,
        min_length: passwordPolicy?.min_password_length?.val,
    },
    is_lower_case_required: {
        regex: '^(?=.*[a-z])',
        label: t('is_lower_case_required'),
    },
    is_upper_case_required: {
        regex: '^(?=.*[A-Z])',
        label: t('is_upper_case_required'),
    },
    is_special_character_required: {
        regex: '^(?=.*[!@#$%^&*?])',
        label: t('is_special_character_required'),
    },
});

export const isRuleSatified = (rule: any, password: any) => {
    if (!password) {
        return false;
    }
    if (rule.regex) {
        const regex = new RegExp(rule.regex);
        return regex.test(password);
    }
    if (rule?.max_length && password.length > rule.max_length) {
        return false;
    }
    if (rule?.min_length && password.length < rule.min_length) {
        return false;
    }

    return true;
};
