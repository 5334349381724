import * as React from 'react';
import withStyles from 'react-jss';
import { MapLocationCaptureProps } from './types';
import { useGenericState } from '../../hooks/use-generic-state';
import { stylesMapLocationCapture } from './styles';
import {
    Drawer,
    Button,
    Input,
    Spin,
} from 'antd';
import PinLocationMap from 'components/common/PinLocationMap';
import SearchBox from 'components/common/UIMap/SearchBox';

const geoLocationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const DEFAULT_MAP_POSITION = {
    lat: 28.4493,
    lng: 77.0759,
};

const MapLocationCapture = (props: MapLocationCaptureProps) => {
    const {
        isVisible,
        classes,
        defaultLocation,
        onClose,
        onSubmit,
        // search,
    } = props;

    const initialState : { location: any, isLoading: boolean, placesScriptLoaded: boolean } = {
        location: defaultLocation,
        isLoading: false,
        placesScriptLoaded: false,
    };

    const [state, setState] = useGenericState(initialState);

    const {
        location,
        isLoading,
    } = state;

    const success = (pos: any) => {
        const crd = pos.coords;
        setState({
            location: {
                lat: crd.latitude,
                lng: crd.longitude,
            },
        });
    };

    const errors = () => {
        if (defaultLocation.lat && defaultLocation.lng) {
            setState({ location: defaultLocation });
        } else {
            setState({ location: DEFAULT_MAP_POSITION });
        }
    };

    React.useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((result: any) => {
                    if (result.state === 'granted') {
                        navigator.geolocation.getCurrentPosition(success);
                    } else if (result.state === 'prompt') {
                        navigator.geolocation.getCurrentPosition(success, errors, geoLocationOptions);
                    } else if (result.state === 'denied') {
                        errors();
                    }
                });
        } else {
            errors();
        }
    }, []);

    const handleLocationChange = (coordinates: any) => {
        if (!coordinates || !coordinates.lat || !coordinates.lng) {
            return;
        }

        setState({
            location: coordinates,
        });
    };

    const handleLocationSelect = (loc: any) => {
        setState({
            location: {
                lat: loc.geometry.coordinates[1],
                lng: loc.geometry.coordinates[0],
                geocodedAddress: loc.properties.label,
            },
        });
    };

    return (
        <Drawer
            destroyOnClose
            onClose={onClose}
            className={classes.sideDrawer}
            visible={isVisible}
            width="60%"
            extra={isLoading ? <Spin /> : <Button onClick={() => onSubmit(location)}>Submit</Button>}
        >
            <>
                <div className={classes.autocomplete}>
                    <SearchBox
                        onSelect={handleLocationSelect}
                        style={{
                            width: '100%',
                        }}
                    />
                </div>
                <div className={classes.mapContainer}>
                    <PinLocationMap
                        coordinates={location}
                        onLocationChange={handleLocationChange}
                    />
                </div>
                <div className={classes.footer}>
                    <div className={classes.latLng}>
                        <span className={classes.label}>Latitude:</span>
                        <Input disabled value={location?.lat} />
                    </div>
                    <div className={classes.latLng}>
                        <span className={classes.label}>Longitude:</span>
                        <Input disabled value={location?.lng} />
                    </div>
                </div>
            </>
        </Drawer>
    );
};

export default withStyles(stylesMapLocationCapture)(MapLocationCapture);
