export const Routes = {
    DASHBOARD: 'dashboard',
    CONSIGNMENTS: 'consignments',
    PLUGIN_ORDERS: 'plugin-orders',
    PICKUPS: 'pickups',
    ADDRESS_BOOK: 'address-book',
    WEIGHT_MISMTACH: 'weight-mismatch',
    VIRTUAL_INVENTORY: 'virtual-inventory',
    DOWNLOADS: 'downloads',
    WALLET: 'wallet',
    WALLET_LEDGER: 'wallet/ledger',
    BLOCKED_LEDGER: 'wallet/blocked/ledger',
    WALLET_WEIGHT_MISMATCH: 'wallet/weight-mismatch',
    INVOICE: 'invoice',
    HELP: 'help',
    PLUGIN: 'plugin',
    TUTORIAL: 'tutorial',
    FORGOT: 'forgot-password',
    LOGIN: 'login',
    SUPER_LOGIN: 'superuser/login',
    CUSTOMER_ACTIVATION: 'customerActivation',
    RESET_PASSWORD: 'resetPassword',
    EXCEL_UPLOAD: 'consignments/excel-upload',
    PRINT_HISTORY: 'consignments/print-history',
    CONSIGNMENT_DETAILS: 'consignments/details',
    SETTINGS: 'settings',
    SETTINGS_HEADER: 'settings/header-mapping',
    SETTINGS_PIECE_HEADER: 'settings/piece-header-mapping',
    SETTINGS_PRODUCT: 'settings/product-mapping',
    SETTINGS_CHANNEL: 'settings/channel-integrations',
    SETTINGS_ACCOUNT: 'settings/account',
    SCHEDULE_PICKUP: 'schedule-pickup',
    ASN: 'asn',
    ASN_EXCEL_UPLOAD: 'asn/excel-upload',
    ANALYTICS: 'analytics',
    API_PLAYGROUND: 'api-playground',
    RTO_DASHBOARD: 'rto-dashboard',
    ANALYTICS_DASHBOARD: 'analytics/dashboard',
    REMITTANCE: 'remittance',
    INVOICE_REPORT: 'reports/invoice',
    REPORT: 'reports',
    REMITTANCE_REPORT: 'reports/remittance',
    ADDITIONAL_SERVICES: 'additional-services',
    SSO_LOGIN_REQUEST: 'sso-login',
};

export const formRules = {
    required: {
        required: true,
        message: 'Required',
    },
    pattern: {
        pattern: /^[^\s]+$/,
        message: 'No whitespace allowed',
    },
    number: {
        pattern: /^[0-9]*$/,
        message: 'Only Integer allowed',
    },
    max: {
        max: 50,
        message: 'Max length allowed is 50',
    },
    maxNumber1000: {
        pattern: /^(?:[1-9]\d{0,2}|1000)$/,
        message: 'Max number allowed is 1000',
    },
};

export const languageCodeMapping: any = {
    en: {
        prettyName: 'English',
    },
    hi: {
        prettyName: 'Hindi',
    },
    ba: {
        prettyName: 'Bahasa',
    },
    ar: {
        prettyName: 'Arabic',
    },
    hb: {
        prettyName: 'Hebrew',
    },
    us: {
        prettyName: 'English(US)',
    },
    ger: {
        prettyName: 'German',
    },
    'en-EU': {
        prettyName: 'English-EU',
    },
    fr: {
        prettyName: 'French',
    },
    en_MY: {
        prettyName: 'English (Malaysia)',
    },
};

export const SEARCH_TIMEOUT = 500;
