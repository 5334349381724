import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET } from './api-hander.service';
import {
    FETCH_VIRTUAL_INVENTORY,
    FETCH_SERVICES,
    FETCH_STORES,
} from './api.constants';

export const getVirtualInventory = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_VIRTUAL_INVENTORY}`, {
        ...params,
    });
};

export const getServiceList = async (params: any) => {
    const storage = window.localStorage;
    const clientId = storage.getItem('userId');
    return GET(`${BUSINESS_BOOKING_API_URL}${FETCH_SERVICES}`, { clientId }, { ...params });
};

export const getPluginStoresList = async () => {
    return GET(`${API_BASE_URL}${FETCH_STORES}`);
};
