import Icon from '@ant-design/icons';
import * as React from 'react';
/* eslint-disable max-len */
const RateSvg = (props: any) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.01" width="16" height="16" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5043 0.0270675C14.7533 -0.0607727 15.0259 0.0721876 15.113 0.324508C15.9746 2.82643 14.4222 4.8722 12.9261 6.02852L13.2008 6.42548C13.3517 6.6434 13.4042 7.02708 13.3181 7.27844L12.5277 9.79541C12.4411 10.0466 12.1947 10.377 11.9795 10.5298L4.54222 15.8004C4.18206 16.0556 3.77662 16.0993 3.5243 15.7345L0.143971 10.8484C-0.108189 10.4836 -0.0205091 9.98117 0.339652 9.72581L7.77711 4.45508C7.99231 4.30244 8.38351 4.18068 8.64591 4.18484L11.1702 4.17332C11.4328 4.17732 11.7714 4.35876 11.9221 4.57652L12.3728 5.22804C13.4856 4.37364 14.9462 2.77907 14.2104 0.642909C14.1237 0.390588 14.2552 0.114908 14.5043 0.0270675ZM9.87807 7.49253C10.2816 8.07573 11.0762 8.21765 11.6526 7.80933C12.0682 7.51493 12.2552 7.01524 12.1776 6.54068C12.0056 6.64356 11.8406 6.73188 11.6874 6.805C11.6214 6.83636 11.5522 6.8514 11.484 6.8514C11.3046 6.8514 11.1328 6.74884 11.0512 6.57364C10.939 6.33236 11.041 6.04436 11.2794 5.93044C11.3075 5.917 11.4552 5.84148 11.6706 5.71028C11.2429 5.39556 10.6486 5.37188 10.1912 5.6962C9.61439 6.105 9.47439 6.90884 9.87807 7.49253Z" fill="white" />
    </svg>
);

const RateIcon = (props: any) => (
    <Icon component={RateSvg} {...props} />
);

export default RateIcon;
