import * as React from 'react';
import withStyles from 'react-jss';
import {
    CssType,
} from '../../theme/jss-types';
import { Modal, Table } from 'antd';

const styles = (): CssType => ({
    failureModal: {
        '& .ant-modal-body': {
            padding: 0,
        },
    },
    downloadRow: {
        padding: '5px',
        display: 'flex',
    },
});

const FailureArrayHandler = (props: any) => {
    const defaultColumns = [
        {
            title: 'ASN NUMBER',
            dataIndex: 'asn_number',
        },
        {
            title: 'Reason',
            dataIndex: 'message',
        },
    ];

    const {
        classes,
        failureArray,
        onModalClose,
        isVisible,
        width,
    } = props;

    const generateColumnForTable = () => {
        const columnData: any = [];
        if (failureArray == null || failureArray.length === 0) {
            return columnData;
        }
        const indexList = defaultColumns;
        indexList.forEach((item: any) => {
            const itemToPush: any = {};
            itemToPush.title = item.title;
            itemToPush.dataIndex = item.dataIndex;
            itemToPush.key = item.dataIndex;
            itemToPush.width = 150;
            itemToPush.render = (text: any) => {
                return <div>{text || 'NA'}</div>;
            };
            columnData.push(itemToPush);
        });
        return columnData;
    };

    const handleModalClose = () => {
        onModalClose();
    };

    return (
        <Modal
            title="Errors"
            className={classes.failureModal}
            visible={isVisible}
            onCancel={handleModalClose}
            destroyOnClose
            maskClosable={false}
            width={width || 800}
            okText="Ok"
            footer={null}
        >
            <div>
                <Table
                    pagination={false}
                    scroll={{ y: '400px' }}
                    columns={generateColumnForTable()}
                    dataSource={failureArray}
                    rowKey={(record: any) => record.row_num}
                />

            </div>
        </Modal>
    );
};

export default withStyles(styles)(FailureArrayHandler);
