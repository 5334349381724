import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import {
    Col,
    FormInstance,
    Row,
    Form,
} from 'antd';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { getDocumentFieldFromMapping } from '../../generic-sub-layout-renderer';
import { uniqueId } from 'lodash';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const DocumentDetailsField = (props: IProps) => {
    const {
        form,
        params,
        documentDetailsLayout,
    } = props;

    const {
        ItemType,
    } = formFields;

    const itemType = Form.useWatch(ItemType.key, form);

    if (itemType !== 'document') {
        return null;
    }

    const documentDetailsForm = documentDetailsLayout.map(
        (fieldKey: any) => getDocumentFieldFromMapping(fieldKey, form, {
            ...params,
        }),
    );

    return (
        <Col span={24}>
            <div
                key={uniqueId() + (+new Date())}
            >
                <Row gutter={10}>
                    {documentDetailsForm}
                </Row>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentDetailsLayout = formSubLayout?.document || [];
    return {
        documentDetailsLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DocumentDetailsField);
