import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    setIframeDimensions,
    setIframeRendered,
    setIframeVisibility,
} from '../../actions/iframe-actions';
import authHelper from '../../auth/auth-helper';
import { sendCredentials, sendRoutingEvent } from './persistent-iframe.helper';
import { AppMetadata } from './persistent-iframe.constants';

const PersistentIframeController = (props: any) => {
    const {
        route,
        appName,
        setiframeVisibility,
        setiframeDimensions,
        setiframeRendered,
    } = props;

    const credentials = authHelper.getAuthenticationHeaders();
    const enclosingDiv = React.useRef(null);
    const dimensionRef = React.useRef({} as any);

    const setPosition = (visibility: boolean) => {
        if (!enclosingDiv.current) {
            return;
        }
        const rect = (enclosingDiv.current as any).getBoundingClientRect();
        const { top, left } = rect;
        const { height } = rect;
        let { width } = rect;
        const oldValue = dimensionRef.current;
        if (
            oldValue.top !== top
      || oldValue.left !== left
      || oldValue.width !== width
      || oldValue.height !== height
        ) {
            if (visibility && width === 0) {
                width = 1200;
            }
            setiframeDimensions(appName, {
                top,
                left,
                width,
                height,
            });
            dimensionRef.current = {
                top, left, width, height,
            };
        }
    };

    React.useEffect(() => {
    // console.log('injector mounted', route);
    // setTimeout(() => {
    //   // Show iframe
    //   setIframeVisibility(appName, true);
    // }, 50);

        // Show iframe
        setiframeVisibility(appName, true);

        // Set interval to check for size and position
        setPosition(true);
        const interval = setInterval(() => {
            setPosition(true);
        }, 50);

        return () => {
            // Hide iframe
            setiframeVisibility(appName, false);

            // Clear interval
            clearInterval(interval);

            // console.log('injector unmounted', route);
        };
    }, []);

    React.useEffect(() => {
    // console.log('sending routing event', route);
        sendRoutingEvent(appName, route);
    }, [route]);

    React.useEffect(() => {
        const shouldPassCredentials = AppMetadata[appName]?.passCredentials || false;
        if (shouldPassCredentials) {
            sendCredentials(appName, credentials);
        }
    }, [credentials]);

    React.useEffect(() => {
        setiframeRendered(appName, true);
        setPosition(true);
    }, []);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                flex: '1 1 auto',
            }}
            ref={enclosingDiv}
        />
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setiframeVisibility: setIframeVisibility,
        setiframeDimensions: setIframeDimensions,
        setiframeRendered: setIframeRendered,
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(PersistentIframeController);
