/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const SingleHyperlocal = (props: any) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M10.0914 19.8762C10.0914 21.4881 8.77461 22.8049 7.16272 22.8049C5.53947 22.8049 4.2265 21.4881 4.2265 19.8762C4.2265 19.0975 4.53585 18.3506 5.0865 17.8C5.63715 17.2494 6.38398 16.94 7.16272 16.94C8.77461 16.94 10.0914 18.2568 10.0914 19.8762Z" fill="#5A6464" />
        <path opacity="0.2" d="M10.0914 19.8762C10.0914 21.4881 8.77461 22.8049 7.16272 22.8049C5.53947 22.8049 4.2265 21.4881 4.2265 19.8762C4.2265 19.0975 4.53585 18.3506 5.0865 17.8C5.63715 17.2494 6.38398 16.94 7.16272 16.94C8.77461 16.94 10.0914 18.2568 10.0914 19.8762Z" fill="#5A6464" />
        <path d="M7.15893 22.2411C5.85353 22.2411 4.79028 21.1816 4.79028 19.88C4.79028 18.5746 5.85353 17.5076 7.15893 17.5076C8.46055 17.5076 9.52001 18.5708 9.52001 19.88C9.5238 21.1778 8.46434 22.2411 7.15893 22.2411Z" fill="#5A6464" />
        <path d="M7.15893 22.2411C5.85353 22.2411 4.79028 21.1816 4.79028 19.88C4.79028 18.5746 5.85353 17.5076 7.15893 17.5076C8.46055 17.5076 9.52001 18.5708 9.52001 19.88C9.5238 21.1778 8.46434 22.2411 7.15893 22.2411Z" fill="#5A6464" />
        <path d="M8.85786 19.7703C8.85786 20.7049 8.09354 21.4692 7.15894 21.4692C6.22056 21.4692 5.45624 20.7049 5.45624 19.7703C5.45624 18.8319 6.22056 18.0676 7.15894 18.0676C8.09354 18.0676 8.85786 18.8281 8.85786 19.7703Z" fill="#E6E6E6" />
        <path d="M7.15891 22.9978C5.43351 22.9978 4.03351 21.5978 4.03351 19.88C4.03351 18.1546 5.43729 16.7508 7.15891 16.7508C8.88054 16.7508 10.2768 18.1546 10.2768 19.88C10.2805 21.5978 8.88054 22.9978 7.15891 22.9978ZM7.15891 17.1292C5.64162 17.1292 4.41189 18.3627 4.41189 19.88C4.41189 21.3897 5.6454 22.6194 7.15891 22.6194C8.66864 22.6194 9.89837 21.3897 9.89837 19.88C9.90216 18.3627 8.67243 17.1292 7.15891 17.1292Z" fill="black" />
        <path d="M7.15892 21.6546C6.65781 21.6546 6.17718 21.4558 5.82249 21.1018C5.4678 20.7478 5.26803 20.2676 5.26703 19.7665C5.26703 18.7221 6.1146 17.8746 7.15892 17.8746C7.66003 17.8756 8.14026 18.0753 8.49425 18.43C8.84823 18.7847 9.04703 19.2654 9.04703 19.7665C9.04703 20.8108 8.19946 21.6546 7.15892 21.6546ZM7.15892 18.2567C6.3227 18.2567 5.64541 18.934 5.64541 19.7702C5.64578 20.0691 5.73471 20.3611 5.90095 20.6093C6.06719 20.8576 6.30329 21.0511 6.57943 21.1653C6.85557 21.2794 7.15935 21.3092 7.45239 21.2508C7.74544 21.1925 8.01462 21.0485 8.22591 20.8372C8.4372 20.6259 8.58113 20.3568 8.63951 20.0637C8.6979 19.7707 8.66812 19.4669 8.55395 19.1908C8.43977 18.9146 8.24631 18.6785 7.99802 18.5123C7.74973 18.346 7.45773 18.2571 7.15892 18.2567Z" fill="black" />
        <path d="M25.3514 19.8762C25.3514 21.4881 24.0346 22.8049 22.4227 22.8049C20.7995 22.8049 19.4865 21.4881 19.4865 19.8762C19.4865 19.0975 19.7959 18.3506 20.3465 17.8C20.8972 17.2494 21.644 16.94 22.4227 16.94C24.0346 16.94 25.3514 18.2568 25.3514 19.8762Z" fill="#5A6464" />
        <path opacity="0.2" d="M25.3514 19.8762C25.3514 21.4881 24.0346 22.8049 22.4227 22.8049C20.7995 22.8049 19.4865 21.4881 19.4865 19.8762C19.4865 19.0975 19.7959 18.3506 20.3465 17.8C20.8972 17.2494 21.644 16.94 22.4227 16.94C24.0346 16.94 25.3514 18.2568 25.3514 19.8762Z" fill="#5A6464" />
        <path d="M22.4227 22.2411C21.1173 22.2411 20.0503 21.1816 20.0503 19.88C20.0503 18.5746 21.1135 17.5076 22.4227 17.5076C23.7243 17.5076 24.7838 18.5708 24.7838 19.88C24.7838 21.1778 23.7243 22.2411 22.4227 22.2411Z" fill="#5A6464" />
        <path d="M24.1178 19.7703C24.1178 20.7049 23.3535 21.4692 22.4189 21.4692C21.4805 21.4692 20.7162 20.7049 20.7162 19.7703C20.7162 18.8319 21.4805 18.0676 22.4189 18.0676C23.3535 18.0676 24.1178 18.8281 24.1178 19.7703Z" fill="#E6E6E6" />
        <path d="M22.4227 22.9978C20.6973 22.9978 19.2935 21.5978 19.2935 19.88C19.2935 18.1546 20.6973 16.7508 22.4227 16.7508C24.1443 16.7508 25.5405 18.1546 25.5405 19.88C25.5405 21.5978 24.1405 22.9978 22.4227 22.9978ZM22.4227 17.1292C20.9054 17.1292 19.6719 18.3627 19.6719 19.88C19.6719 21.3897 20.9054 22.6194 22.4227 22.6194C23.9324 22.6194 25.1622 21.3897 25.1622 19.88C25.1622 18.3627 23.9324 17.1292 22.4227 17.1292Z" fill="black" />
        <path d="M22.4189 21.6546C21.9178 21.6546 21.4372 21.4558 21.0825 21.1018C20.7278 20.7478 20.528 20.2676 20.527 19.7665C20.527 18.7221 21.3746 17.8746 22.4189 17.8746C22.92 17.8756 23.4003 18.0753 23.7543 18.43C24.1082 18.7847 24.307 19.2654 24.307 19.7665C24.307 20.8108 23.4595 21.6546 22.4189 21.6546ZM22.4189 18.2567C21.5865 18.2567 20.9054 18.934 20.9054 19.7702C20.9058 20.0691 20.9947 20.3611 21.161 20.6093C21.3272 20.8576 21.5633 21.0511 21.8394 21.1653C22.1156 21.2794 22.4194 21.3092 22.7124 21.2508C23.0055 21.1925 23.2746 21.0485 23.4859 20.8372C23.6972 20.6259 23.8411 20.3568 23.8995 20.0637C23.9579 19.7707 23.9281 19.4669 23.814 19.1908C23.6998 18.9146 23.5063 18.6785 23.258 18.5123C23.0097 18.346 22.7177 18.2571 22.4189 18.2567Z" fill="black" />
        <path d="M22.4152 16.4519H19.4941V11.4384H20.2357C20.6595 11.4384 21.0341 11.7184 21.1552 12.127L22.4152 16.4519Z" fill="#D51323" />
        <path d="M22.4151 16.6411H19.4978C19.3919 16.6411 19.3087 16.5579 19.3087 16.4519V11.4384C19.3087 11.3324 19.3919 11.2492 19.4978 11.2492H20.2395C20.7465 11.2492 21.1968 11.5897 21.3405 12.0741L22.6005 16.3989C22.6157 16.4557 22.6043 16.5162 22.5703 16.5654C22.5324 16.6108 22.4757 16.6411 22.4151 16.6411ZM19.687 16.2627H22.1654L20.9735 12.18C20.9265 12.0207 20.8292 11.8809 20.6963 11.7814C20.5633 11.6818 20.4018 11.6279 20.2357 11.6276H19.6833V16.2627H19.687Z" fill="black" />
        <path d="M3.02325 14.2043H6.25839V16.2514H3.02325V14.2043Z" fill="#D51323" />
        <path d="M6.25837 16.4405H3.02323C2.91729 16.4405 2.83405 16.3573 2.83405 16.2514V14.2043C2.83405 14.0984 2.91729 14.0151 3.02323 14.0151H6.25837C6.36432 14.0151 6.44756 14.0984 6.44756 14.2043V16.2514C6.44756 16.3573 6.36053 16.4405 6.25837 16.4405ZM3.21242 16.0622H6.06918V14.3935H3.21242V16.0622Z" fill="black" />
        <path d="M2.33081 14.454H3.02324V16.0054H2.33081V14.454Z" fill="#FFCC80" />
        <path d="M3.02322 16.1946H2.33079C2.22484 16.1946 2.1416 16.1114 2.1416 16.0054V14.4541C2.1416 14.3481 2.22484 14.2649 2.33079 14.2649H3.02322C3.12917 14.2649 3.21241 14.3481 3.21241 14.4541V16.0054C3.21241 16.1114 3.12917 16.1946 3.02322 16.1946ZM2.51998 15.8162H2.83403V14.6433H2.51998V15.8162Z" fill="black" />
        <path d="M18.7222 19.1838C18.7222 17.1443 20.3795 15.487 22.4189 15.487C24.4546 15.487 26.1081 17.1443 26.1081 19.1838H18.7222Z" fill="#D51323" />
        <path opacity="0.2" d="M18.7222 19.1838C18.7222 17.1443 20.3795 15.487 22.4189 15.487C24.4546 15.487 26.1081 17.1443 26.1081 19.1838H18.7222Z" fill="#5A6464" />
        <path d="M19.3427 18.6162C19.6114 17.1632 20.8865 16.0546 22.4189 16.0546C23.9476 16.0546 25.2227 17.1594 25.4914 18.6162H19.3427Z" fill="#D51323" />
        <path d="M26.1081 19.373H18.7221C18.6162 19.373 18.533 19.2897 18.533 19.1838C18.533 17.0422 20.2735 15.2979 22.4189 15.2979C24.5567 15.2979 26.2973 17.0422 26.2973 19.1838C26.2973 19.2897 26.214 19.373 26.1081 19.373ZM18.9189 18.9946H25.9151C25.8167 17.1481 24.2843 15.6762 22.4189 15.6762C20.5497 15.68 19.0173 17.1481 18.9189 18.9946Z" fill="black" />
        <path d="M20.0843 10.7157H18.4913L19.0476 15.8579C19.1762 17.0384 18.2492 18.0714 17.0611 18.0714H13.9659C12.6038 18.0714 11.6427 16.7395 12.0703 15.4454L12.8081 13.213H3.96161V18.0714H5.45999C5.45999 19.0665 6.26593 19.8725 7.26107 19.8725H19.074C20.0692 19.8725 20.8751 19.0665 20.8751 18.0714L20.0843 10.7157Z" fill="#F12D3D" />
        <path opacity="0.2" d="M20.0843 10.7157H18.4913L19.0476 15.8579C19.1762 17.0384 18.2492 18.0714 17.0611 18.0714H13.9659C12.6038 18.0714 11.6427 16.7395 12.0703 15.4454L12.8081 13.213H3.96161V18.0714H5.45999C5.45999 19.0665 6.26593 19.8725 7.26107 19.8725H19.074C20.0692 19.8725 20.8751 19.0665 20.8751 18.0714L20.0843 10.7157Z" fill="#5A6464" />
        <path d="M7.26107 19.3049C6.93392 19.3049 6.62017 19.1749 6.38884 18.9436C6.15751 18.7123 6.02755 18.3985 6.02755 18.0714V17.5038H4.52917V13.7806H12.0211L11.5292 15.2676C11.2681 16.0584 11.3967 16.9022 11.8849 17.5757C12.373 18.2492 13.1297 18.6389 13.9659 18.6389H17.0611C17.7876 18.6389 18.4838 18.3287 18.9719 17.7876C19.2119 17.5192 19.3929 17.2033 19.5031 16.8604C19.6132 16.5175 19.6501 16.1553 19.6113 15.7973L19.1232 11.2833H19.5773L20.3151 18.0979C20.3076 18.4202 20.1744 18.7269 19.944 18.9524C19.7135 19.1779 19.404 19.3044 19.0816 19.3049H7.26107Z" fill="#F12D3D" />
        <path d="M19.0779 20.0616H7.26111C6.22814 20.0616 5.373 19.267 5.27841 18.2605H3.96165C3.8557 18.2605 3.77246 18.1773 3.77246 18.0714V13.213C3.77246 13.107 3.8557 13.0238 3.96165 13.0238H12.8044C12.8649 13.0238 12.9216 13.0541 12.9595 13.1032C12.9935 13.1524 13.0049 13.2168 12.986 13.2735L12.2481 15.506C12.0627 16.0622 12.1535 16.6562 12.4979 17.133C12.6646 17.3664 12.885 17.5564 13.1405 17.6868C13.396 17.8172 13.6791 17.8842 13.966 17.8822H17.0611C17.5757 17.8822 18.0638 17.6627 18.4081 17.2805C18.5781 17.0916 18.7061 16.8689 18.7837 16.6269C18.8613 16.3849 18.8868 16.1293 18.8584 15.8768L18.3022 10.7346C18.2946 10.6816 18.3135 10.6287 18.3514 10.587C18.3892 10.5454 18.4384 10.5227 18.4914 10.5227H20.0844C20.1314 10.5225 20.1769 10.5398 20.2119 10.5713C20.2468 10.6028 20.2688 10.6461 20.2735 10.693L21.0719 18.0487C21.0681 19.1687 20.1752 20.0616 19.0779 20.0616ZM4.15084 17.8822H5.46003C5.56597 17.8822 5.64922 17.9654 5.64922 18.0714C5.64922 18.9605 6.37192 19.6832 7.26111 19.6832H19.0779C19.9671 19.6832 20.6898 18.9605 20.6898 18.0714L19.9103 10.9049H18.6957L19.2292 15.8389C19.2973 16.4557 19.0968 17.0724 18.6844 17.5341C18.4792 17.7624 18.2283 17.945 17.948 18.0702C17.6677 18.1953 17.3643 18.2602 17.0573 18.2605H13.9622C13.6152 18.2628 13.2728 18.1815 12.9639 18.0234C12.655 17.8653 12.3887 17.6352 12.1876 17.3524C11.9833 17.0727 11.8491 16.7479 11.7965 16.4055C11.7438 16.0631 11.7741 15.7131 11.8849 15.3849L12.5395 13.4022H4.15084V17.8822Z" fill="black" />
        <path d="M1.89191 19.8724C1.89191 16.9665 4.25299 14.6054 7.15893 14.6054C10.0573 14.6054 12.4184 16.9665 12.4184 19.8724H1.89191Z" fill="#F12D3D" />
        <path opacity="0.2" d="M1.89191 19.8724C1.89191 16.9665 4.25299 14.6054 7.15893 14.6054C10.0573 14.6054 12.4184 16.9665 12.4184 19.8724H1.89191Z" fill="#5A6464" />
        <path d="M2.49353 19.3049C2.77353 16.9778 4.76002 15.173 7.15894 15.173C9.55407 15.173 11.5368 16.9816 11.8168 19.3049H2.49353Z" fill="#F12D3D" />
        <path d="M12.4222 20.0616H1.89189C1.78594 20.0616 1.7027 19.9784 1.7027 19.8724C1.7027 16.8643 4.15081 14.4162 7.15891 14.4162C10.1632 14.4162 12.6076 16.8643 12.6076 19.8724C12.6113 19.9784 12.5243 20.0616 12.4222 20.0616ZM2.08486 19.6832H12.2292C12.1308 16.9703 9.89459 14.7946 7.1627 14.7946C4.42324 14.7946 2.18324 16.9703 2.08486 19.6832Z" fill="black" />
        <path d="M9.39514 16.8454H4.91893C4.81298 16.8454 4.72974 16.7622 4.72974 16.6562C4.72974 16.5503 4.81298 16.467 4.91893 16.467H9.39514C9.50109 16.467 9.58433 16.5503 9.58433 16.6562C9.58433 16.7622 9.4973 16.8454 9.39514 16.8454ZM9.39514 17.8443H4.91893C4.81298 17.8443 4.72974 17.7611 4.72974 17.6551C4.72974 17.5492 4.81298 17.466 4.91893 17.466H9.39514C9.50109 17.466 9.58433 17.5492 9.58433 17.6551C9.58433 17.7611 9.4973 17.8443 9.39514 17.8443ZM9.39514 18.8433H4.91893C4.81298 18.8433 4.72974 18.76 4.72974 18.6541C4.72974 18.5481 4.81298 18.4649 4.91893 18.4649H9.39514C9.50109 18.4649 9.58433 18.5481 9.58433 18.6541C9.58433 18.76 9.4973 18.8433 9.39514 18.8433Z" fill="black" />
        <path d="M13.5876 12.9519L13.8411 12.1876C13.9432 11.8773 13.7124 11.5557 13.3832 11.5557H3.86702C3.60215 11.5557 3.38647 11.7713 3.38647 12.0362V12.8005C3.38647 13.0654 3.60215 13.2811 3.86702 13.2811H13.1297C13.3378 13.2811 13.5194 13.1486 13.5876 12.9519Z" fill="#5A6464" />
        <path d="M13.1298 13.4702H3.86706C3.49625 13.4702 3.19733 13.1675 3.19733 12.8005V12.0324C3.19733 11.6616 3.50003 11.3627 3.86706 11.3627H13.3795C13.4859 11.3622 13.5909 11.387 13.6858 11.4352C13.7807 11.4833 13.8628 11.5533 13.9253 11.6395C13.9878 11.7256 14.0289 11.8254 14.0452 11.9306C14.0614 12.0358 14.0525 12.1433 14.0189 12.2443L13.7654 13.0086C13.6746 13.2848 13.4173 13.4702 13.1298 13.4702ZM3.86706 11.7411C3.78979 11.7411 3.71568 11.7718 3.66104 11.8264C3.6064 11.881 3.57571 11.9551 3.57571 12.0324V12.7967C3.57571 12.9594 3.70814 13.0881 3.86706 13.0881H13.1298C13.2546 13.0881 13.3681 13.0086 13.406 12.8875L13.6595 12.1232C13.6898 12.0324 13.6746 11.9378 13.6179 11.8584C13.5903 11.821 13.5544 11.7905 13.5131 11.7695C13.4717 11.7485 13.4259 11.7374 13.3795 11.7373H3.86706V11.7411Z" fill="black" />
        <path d="M10.0876 5.59621V11.1508C10.0876 11.374 9.90595 11.5519 9.68649 11.5519H3.3373C3.11406 11.5519 2.93622 11.3703 2.93622 11.1508V5.59621C2.93622 5.37296 3.11784 5.19513 3.3373 5.19513H9.68649C9.90973 5.19134 10.0876 5.37296 10.0876 5.59621Z" fill="#FFA726" />
        <path opacity="0.2" d="M10.0876 5.59621V11.1508C10.0876 11.374 9.90595 11.5519 9.68649 11.5519H3.3373C3.11406 11.5519 2.93622 11.3703 2.93622 11.1508V5.59621C2.93622 5.37296 3.11784 5.19513 3.3373 5.19513H9.68649C9.90973 5.19134 10.0876 5.37296 10.0876 5.59621Z" fill="#5A6464" />
        <path d="M3.50378 5.7627H9.52V10.9843H3.50378V5.7627Z" fill="#FFA726" />
        <path d="M7.39349 5.19135V7.16648L6.51187 6.79567L5.63025 7.16648V5.19135H7.39349Z" fill="#E6E6E6" />
        <path d="M9.68647 11.7411H3.33728C3.01187 11.7411 2.74701 11.4762 2.74701 11.1508V5.59619C2.74701 5.27079 3.01187 5.00592 3.33728 5.00592H9.68647C10.0119 5.00592 10.2767 5.27079 10.2767 5.59619V11.1508C10.2767 11.4762 10.0119 11.7411 9.68647 11.7411ZM3.33728 5.38052C3.28108 5.38052 3.22719 5.40284 3.18745 5.44258C3.14771 5.48231 3.12539 5.53621 3.12539 5.59241V11.147C3.12539 11.2643 3.21998 11.3589 3.33728 11.3589H9.68647C9.80377 11.3589 9.89836 11.2643 9.89836 11.147V5.59619C9.89836 5.53999 9.87604 5.4861 9.8363 5.44636C9.79656 5.40662 9.74267 5.3843 9.68647 5.3843H3.33728V5.38052Z" fill="black" />
        <path d="M7.3935 7.35565C7.36701 7.35565 7.34431 7.35187 7.32161 7.34051L6.51188 6.99997L5.70215 7.34051C5.64161 7.36322 5.57728 7.35943 5.52431 7.32538C5.49825 7.30797 5.47697 7.2843 5.46242 7.25654C5.44788 7.22877 5.44054 7.1978 5.44107 7.16646V5.19133C5.44107 5.08538 5.52431 5.00214 5.63026 5.00214H7.39728C7.50323 5.00214 7.58647 5.08538 7.58647 5.19133V7.16646C7.58647 7.23079 7.5562 7.28754 7.50323 7.32538C7.47053 7.34627 7.43229 7.35682 7.3935 7.35565ZM6.51188 6.60646C6.53836 6.60646 6.56107 6.61024 6.58377 6.6216L7.20431 6.88268V5.38051H5.81566V6.88268L6.4362 6.6216C6.46269 6.61024 6.48917 6.60646 6.51188 6.60646Z" fill="black" />
        <path d="M20.1411 8.30542C20.9205 8.30542 21.5562 8.92218 21.5562 9.67893C21.5562 10.4357 20.9243 11.0524 20.1411 11.0524V8.30542Z" fill="#E6E6E6" />
        <path d="M20.1411 11.2416C20.0351 11.2416 19.9519 11.1584 19.9519 11.0524V8.3054C19.9519 8.19945 20.0351 8.11621 20.1411 8.11621C21.0227 8.11621 21.7416 8.81621 21.7416 9.67891C21.7454 10.5416 21.0265 11.2416 20.1411 11.2416ZM20.3303 8.50972V10.8519C20.9168 10.7649 21.3633 10.273 21.3633 9.6827C21.367 9.08864 20.9168 8.59675 20.3303 8.50972Z" fill="black" />
        <path d="M20.527 11.3324C18.9757 11.3324 17.7119 10.5908 17.7119 9.6789C17.7119 8.76701 18.9719 8.02539 20.527 8.02539V11.3324Z" fill="#F12D3D" />
        <path opacity="0.2" d="M20.527 11.3324C18.9757 11.3324 17.7119 10.5908 17.7119 9.6789C17.7119 8.76701 18.9719 8.02539 20.527 8.02539V11.3324Z" fill="#5A6464" />
        <path d="M20.527 10.9049C19.3767 10.9049 18.4459 10.3562 18.4459 9.67895C18.4459 9.00544 19.3805 8.45679 20.527 8.45679V10.9049Z" fill="#F12D3D" />
        <path d="M20.527 11.5216C18.8432 11.5216 17.5227 10.7119 17.5227 9.67895C17.5227 8.64597 18.8432 7.83624 20.527 7.83624C20.633 7.83624 20.7162 7.91949 20.7162 8.02543V11.3325C20.7162 11.4384 20.633 11.5216 20.527 11.5216ZM20.3378 8.2184C18.9984 8.27516 17.9011 8.91841 17.9011 9.67895C17.9011 10.4395 18.9984 11.0827 20.3378 11.1395V8.2184Z" fill="black" />
        <path d="M18.5935 10.2805C18.5935 10.5757 18.3514 10.814 18.06 10.814C17.7687 10.814 17.5265 10.5719 17.5265 10.2805C17.5265 9.98539 17.7687 9.74701 18.06 9.74701C18.3514 9.74701 18.5935 9.98917 18.5935 10.2805Z" fill="#5A6464" />
        <path d="M18.0562 11.007C17.8645 11.007 17.6807 10.9309 17.5452 10.7953C17.4096 10.6598 17.3335 10.476 17.3335 10.2843C17.3335 10.0926 17.4096 9.90882 17.5452 9.77329C17.6807 9.63775 17.8645 9.56161 18.0562 9.56161C18.2479 9.56161 18.4317 9.63775 18.5672 9.77329C18.7028 9.90882 18.7789 10.0926 18.7789 10.2843C18.7789 10.476 18.7028 10.6598 18.5672 10.7953C18.4317 10.9309 18.2479 11.007 18.0562 11.007ZM18.0562 9.93621C17.867 9.93621 17.7119 10.0913 17.7119 10.2805C17.7119 10.4697 17.867 10.6249 18.0562 10.6249C18.2454 10.6249 18.4005 10.4697 18.4005 10.2805C18.4005 10.0913 18.2492 9.93621 18.0562 9.93621ZM19.3843 9.97026C19.3048 9.97026 19.2292 9.91729 19.2027 9.83783L18.6086 7.95729C18.5997 7.93008 18.5825 7.90633 18.5595 7.88937C18.5364 7.87241 18.5086 7.86308 18.48 7.86269H18.06C17.954 7.86269 17.8708 7.77945 17.8708 7.6735C17.8708 7.56756 17.954 7.48431 18.06 7.48431H18.48C18.707 7.48431 18.9038 7.6281 18.9719 7.84377L19.5659 9.72431C19.5962 9.82269 19.5432 9.92864 19.4411 9.96269C19.4221 9.97026 19.4032 9.97026 19.3843 9.97026Z" fill="black" />
        <path d="M17.5227 6.70111C18.007 6.70111 18.4005 7.08327 18.4005 7.55625C18.4005 8.02544 18.007 8.4076 17.5227 8.4076V6.70111Z" fill="#5A6464" />
        <path d="M17.5227 8.59677C17.4167 8.59677 17.3335 8.51352 17.3335 8.40758V6.70109C17.3335 6.59515 17.4167 6.5119 17.5227 6.5119C18.113 6.5119 18.5897 6.98109 18.5897 7.55623C18.5897 8.13136 18.1092 8.59677 17.5227 8.59677ZM17.7119 6.91677V8.19569C17.9994 8.11623 18.2113 7.85893 18.2113 7.55623C18.2113 7.25352 17.9994 6.99623 17.7119 6.91677Z" fill="black" />
    </svg>
);

const SingleHyperlocalIcon = (props: any) => (
    <Icon component={() => {
        return SingleHyperlocal({ ...props });
    }}
    />
);

export default SingleHyperlocalIcon;
