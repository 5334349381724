import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

export const rtoDashboardStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        width: '100%',
        maxWidth: '100%',
        padding: '5px 8px',
        '& .ant-table-thead > tr > th': {
            padding: '0 12px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 12px',
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        backgroundColor: theme.backgroundColor,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        justifyContent: 'space-between',
        marginBottom: 4,
        '& .ant-picker': {
            width: 180,
            border: '1px solid #999999',
            height: 28,
            fontSize: 10,
            borderRadius: 14,
        },
        '& .ant-picker-input > input': {
            fontSize: 10,
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 200,
            border: '1px solid #999999',
            height: 28,
            fontSize: 12,
            borderRadius: 14,
        },
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    pageFilter: {
        display: 'flex',
        marginBottom: 8,
        borderBottom: '1px solid #D9D9D9',
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        color: '#666666',
        padding: '12px 50px',
        cursor: 'pointer',
    },
    selectedPage: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: '#082E78',
        padding: '12px 50px',
        borderBottom: '4px solid #082E78',
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
        fontFamily: 'Open Sans',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: '1px solid #082E78',
            color: '#082E78',
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: '#082E78',
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
    },
    table: {
        '& td': {
            verticalAlign: 'baseline',
            cursor: 'pointer',
            border: 'none',
        },
        '& .ant-table-thead': {
            whiteSpace: 'pre-line !important',
            backgroundColor: '#',
            border: '1px solid #E8E8E8',
            color: '#262626',
            padding: 0,
        },
        '& .ant-table-selection-column': {
            borderRight: 'none !important',
        },
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    actionBtn: {
        marginRight: 8,
    },
    menu: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    flexRow: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
});
