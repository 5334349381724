import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { Select } from 'antd';
import { IdName } from '../../library/Types';
import { loadSortBy } from '../../network/pickup.api';
import { useTranslation } from 'react-i18next';

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 100,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 10,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    label: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0,
        marginRight: 8,
    },
};

interface IProps extends WithStylesProps<typeof styles> {
    labelClass?: string;
    value?: string;
    onChange: (value: string) => void;
}
const SortBy = (props: IProps) => {
    const {
        classes,
        labelClass,
        onChange,
        value,
    } = props;

    const { t } = useTranslation();

    const [sortBy, setSortBy] = React.useState<IdName[]>([]);

    const loadSortByList = async () => {
        const response = await loadSortBy();
        setSortBy(response?.data || []);
    };

    React.useEffect(() => {
        loadSortByList();
    }, []);

    return (
        <div className={classes.main}>
            <span className={labelClass || classes.label}>
                {t('sort_by')}
            </span>
            <Select
                value={value}
                onChange={onChange}
                placeholder={t('select_sort_by')}
            >
                {sortBy.map((item) => {
                    return (
                        <Select.Option value={item.id} key={item.id}>
                            {t(item.name)}
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
};

const SortByFilter = withStyles(styles)(SortBy);
export default SortByFilter;
