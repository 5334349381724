import { BUSINESS_BOOKING_API_URL } from '../library/globals';
import { POST } from './api-hander.service';
import {
    VERIFY_SRF_NUMBER,
} from './api.constants';


export const verifySRFNumber = async (params: any) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${VERIFY_SRF_NUMBER}`, params);
};
