import Icon from '@ant-design/icons';
import * as React from 'react';
/* eslint-disable max-len */
const LocationIconSvg = (props: any) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Add_Order-2" transform="translate(-28.000000, -164.000000)" fill="#000000" fillRule="nonzero">
                <g id="map" transform="translate(28.000000, 164.000000)">
                    <path d="M18,5.2734375 C16.2553711,5.2734375 14.8359375,6.69287109 14.8359375,8.4375 C14.8359375,10.1821289 16.2553711,11.6015625 18,11.6015625 C19.7446289,11.6015625 21.1640625,10.1821289 21.1640625,8.4375 C21.1640625,6.69287109 19.7446289,5.2734375 18,5.2734375 Z" id="Path" />
                    <path d="M18,0 C13.3475647,0 9.5625,3.78506468 9.5625,8.4375 C9.5625,10.0329895 10.0104675,11.5872803 10.8583374,12.9322815 L18,24.328125 L25.1416626,12.9322815 C25.9895325,11.5872803 26.4375,10.0329895 26.4375,8.4375 C26.4375,3.78506468 22.6524353,0 18,0 Z M18,13.7109375 C15.0924683,13.7109375 12.7265625,11.3450317 12.7265625,8.4375 C12.7265625,5.5299683 15.0924683,3.1640625 18,3.1640625 C20.9075317,3.1640625 23.2734375,5.5299683 23.2734375,8.4375 C23.2734375,11.3450317 20.9075317,13.7109375 18,13.7109375 Z" id="Shape" />
                    <polygon id="Path" points="12.8669129 36 23.1295166 36 22.3184509 29.6015625 13.6576538 29.6015625" />
                    <polygon id="Path" points="13.9213257 27.4921875 17.5012207 27.4921875 14.5137634 22.7540588" />
                    <polygon id="Path" points="24.175415 27.4921875 32.4846496 27.4921875 29.8479309 21.1640625 23.3638 21.1640625" />
                    <polygon id="Path" points="3.51535036 27.4921875 11.7957459 27.4921875 12.5867615 21.1640625 6.15206911 21.1640625" />
                    <polygon id="Path" points="18.4987793 27.4921875 22.0479126 27.4921875 21.4483338 22.8147583" />
                    <polygon id="Path" points="11.532074 29.6015625 2.63644411 29.6015625 0 36 10.741333 36" />
                    <polygon id="Path" points="33.3635559 29.6015625 24.4456787 29.6015625 25.2570191 36 36 36" />
                </g>
            </g>
        </g>
    </svg>
);

const LocationIcon = (props: any) => (
    <Icon component={LocationIconSvg} {...props} />
);

export default LocationIcon;
