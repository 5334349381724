import { ThemeType } from 'theme/jss-types';

export const createPasswordStyles = (theme: ThemeType) => ({
    emailInput: {
        fontSize: '14px',
        textAlign: 'left',
        height: '42px',
        border: '1px solid #CCCCCC',
        borderRadius: '5px',
    },
    passwordInput: {
        fontWeight: 600,
        marginBottom: '16px',
        '& .ant-form-item-label > label': {
            width: 150,
        },
    },
    labelForInput: {
        position: 'relative',
        display: 'block',
        top: 3,
        left: 20,
        height: 10,
        width: 50,
        backgroundColor: 'white',
        color: '#36393C',
        fontSize: 10,
        zIndex: 500,
    },
    signInForm: {
        width: '100%',
        padding: '13.43% 14.64% 0 13.32%',
        '@media (max-width: 768px)': { padding: '4.21% 6.67% 0' },
    },
    welcomeMsg: {
        fontFamily: 'Open Sans',
        color: 'black',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: -0.09,
        textAlign: 'center',
        marginBottom: 32,
    },
    submitButton: {
        height: '48px',
        width: '100%',
        borderRadius: 12,
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: theme.primaryColor,
        '&:hover': {
            backgroundColor: theme.primaryColor,
        },
    },
    backgroundDesign: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: 'white',
        top: 0,
        left: 0,
        zIndex: 200,
    },
    clickHere: {
        margin: 0,
        padding: 0,
        fontWeight: 600,
    },
});
