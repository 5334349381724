import * as React from 'react';
import { rateCalculatorStyles } from './rate-calculator.styles';
import { StylesProps } from 'theme/jss-types';
import CrossIcon from 'assets/cross';
import { find, sortBy } from 'lodash';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import PrimaryIcon from 'components/create-consignment/single-consignment-icons/primary';
import ContentsIcon from 'components/create-consignment/single-consignment-icons/content';
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
    Button, Drawer, Input, Radio, Select, Tooltip, message,
} from 'antd';
import { getCityState } from 'network/consignments.api';
import Helper from 'library/Helper';
import {
    getBusinessTat,
    getCities,
    getCountries,
    getInternationalBusinessTatV2,
    getStates,
} from 'network/common.api';
import {
    City, Country, Service, State,
} from 'library/Types';
import Loader from 'components/common/Loader';

interface RateCalculatorProps extends StylesProps<ReturnType<typeof rateCalculatorStyles>> {
    onClose: () => void;
}

const RateCalculator = (props: RateCalculatorProps) => {
    const { classes, onClose } = props;

    const [rateList, setRateList] = React.useState<Service[]>([]);
    const [fetching, setFetching] = React.useState<boolean>(false);
    const [pincodeData, setPincodeData] = React.useState<Record<string, any>>({
        src: {},
        dst: {},
    });
    const [category, setCategory] = React.useState<string>('domestic');
    const [loadtype, setLoadtype] = React.useState<string>('NON-DOCUMENT');
    // const [numpieces, setNumPieces] = React.useState<string | undefined>();
    const [weight, setWeight] = React.useState<string | undefined>();
    const [height, setHeight] = React.useState<string | undefined>();
    const [width, setWidth] = React.useState<string | undefined>();
    const [length, setLength] = React.useState<string | undefined>();
    const [unit, setUnit] = React.useState<'cm' | 'ft' | 'mtr' | 'inch'>('cm');
    const [weightUnit, setWeightUnit] = React.useState<'gm' | 'kg'>('kg');
    const [countryList, setCountryList] = React.useState<Country[]>([]);
    const [stateList, setStateList] = React.useState<State[]>([]);
    const [cityList, setCityList] = React.useState<City[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchCountries = async () => {
        const result = await getCountries();
        setCountryList(result?.data || []);
    };

    const fetchStateList = async () => {
        if (!pincodeData.dst?.country) {
            return;
        }
        const result = await getStates({
            stateName: '',
            countryName: pincodeData.dst?.country,
        });
        setStateList(result?.data || []);
    };

    let timeout: any = null;
    const fetchCityList = async (cityName: string) => {
        if (!pincodeData.dst?.country || cityName?.length < 2) {
            return;
        }
        setLoading(true);

        if (timeout) {
            timeout = null;
        }
        timeout = setTimeout(async () => {
            const result = await getCities({
                cityName,
                countryName: pincodeData.dst?.country,
            });
            setCityList(result?.data || []);
            setLoading(false);
        }, 500);
    };

    React.useEffect(() => {
        fetchCountries();
    }, []);

    React.useEffect(() => {
        fetchStateList();
    }, [pincodeData.dst?.country]);

    const saveCityState = async (type: string, pincode: string) => {
        const response = await getCityState({
            pincode,
            isLTL: false,
        });

        if (!response.isSuccess) {
            message.error(response.errorMessage);
            return;
        }
        setPincodeData({
            ...pincodeData,
            [type]: {
                ...pincodeData[type],
                pincode,
                valid: true,
                city: response?.data?.city,
                state: response?.data?.state,
                country: response?.data?.country || 'INDIA',
            },
        });
    };

    const getButtonName = () => {
        if (rateList?.length) {
            return 'Edit Details';
        }

        return 'Calculate Rate';
    };

    const getApiBody = () => {
        const storage = window.localStorage;

        const hei = Helper.converttoCentimeter(Number(height), unit);
        const len = Helper.converttoCentimeter(Number(length), unit);
        const wid = Helper.converttoCentimeter(Number(width), unit);
        const wei = Helper.converttoKilogram(Number(weight), weightUnit);

        return {
            weight: wei,
            height: hei,
            width: wid,
            length: len,
            srcPincode: pincodeData.src?.pincode,
            srcStateName: pincodeData.src?.state,
            dstStateName: pincodeData.dst?.state,
            dstPincode: pincodeData.dst?.pincode,
            countryName: pincodeData.dst?.country,
            dstCountry: pincodeData.dst?.country,
            dstCity: pincodeData.dst?.city,
            srcCity: pincodeData.src?.city,
            clientCode: storage.getItem('userCode'),
            courierType: loadtype,
            commodityCode: category === 'international' ? 'ICOM9999' : undefined,
            is_international: category === 'international',
            pieceDetails: [{
                height: hei,
                width: wid,
                length: len,
            }],
            calculateRate: true,
            source: 'CUSTOMER_PORTAL_SINGLE_V2',
        };
    };


    const fetchRates = async () => {
        if (rateList?.length) {
            setRateList([]);
            return;
        }
        setFetching(true);
        const apiBody = getApiBody();

        if (category === 'domestic') {
            const response = await getBusinessTat(apiBody);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
                return;
            }
            const rateListSorted = sortBy(response?.data || [], ['serviceType']);
            setRateList(rateListSorted);
        } else {
            const response = await getInternationalBusinessTatV2(apiBody);
            if (!response.isSuccess) {
                message.error(response.errorMessage);
                return;
            }
            setRateList(response?.data || []);
        }

        setFetching(false);
    };

    const getDisabled = () => {
        const apiBody: any = getApiBody();

        const requiredFields = ['srcPincode', 'dstPincode', 'weight'];

        if (category === 'international') {
            requiredFields.push('dstCountry');
            requiredFields.push('dstCity');
            requiredFields.push('srcCity');
        }

        const error = requiredFields.some((item: string) => !apiBody[item]);

        if (!pincodeData.src?.valid || !pincodeData.dst?.valid) {
            return true;
        }
        return !!error;
    };

    const getButtonProps = () => {
        const disabled = getDisabled();

        if (rateList?.length) {
            return {
                type: 'default',
                styles: {
                    color: '#082E78',
                },
            };
        }
        return {
            type: disabled ? 'default' : 'primary',
            styles: {
                background: disabled ? 'default' : '#082E78',
            },
        };
    };

    const renderHeader = () => {
        const buttonProps = getButtonProps();
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <CrossIcon onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        Rate Calculator
                    </span>
                </div>
                <Button
                    loading={fetching}
                    disabled={getDisabled()}
                    type={buttonProps.type as any}
                    style={buttonProps.styles}
                    onClick={() => fetchRates()}
                >
                    {getButtonName()}
                </Button>
            </div>
        );
    };

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <FieldIcon className={classes.boxIcon} />
                <span className={classes.boxText}>
                    {label}
                </span>
            </div>
        );
    };

    const renderPrimary = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, 'Primary Details')}
            </div>
        );
    };

    const renderItemDetails = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(ContentsIcon, 'Item Details')}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.line} />;
    };

    const fieldMapping: Record<string, string> = {
        src: 'Origin Pincode *',
        dst: 'Destination Pincode *',
    };

    const renderValidation = (type: string) => {
        if (!pincodeData[type]?.valid) {
            return null;
        }

        return (
            <div className={classes.pincodeValidation}>
                <span style={{ marginRight: 5 }}>
                    {pincodeData[type]?.city}
                </span>
                <CheckCircleFilled />
            </div>
        );
    };

    const handlePincodeChange = (e: any, type: string) => {
        const pincode = e?.target?.value;

        setPincodeData({
            ...pincodeData,
            [type]: {
                pincode,
                valid: false,
            },
        });

        let isValid = false;
        if (category === 'domestic' || type === 'src') {
            if (pincode?.length === 6) {
                saveCityState(type, pincode);
            }
        } else if (pincodeData.dst?.country) {
            const country = find(countryList, { country: pincodeData.dst?.country });

            if (country?.pincode_regex) {
                const regex = new RegExp(country?.pincode_regex.replace(/^\//, '')?.replace(/\/$/, ''));
                if (regex.test(pincode)) {
                    isValid = true;
                }
            }
        }

        setPincodeData({
            ...pincodeData,
            [type]: {
                ...pincodeData[type],
                pincode,
                valid: isValid,
            },
        });
    };

    const renderPincode = (type: string) => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>{fieldMapping[type]}</span>
                <Input
                    style={{
                        boxSizing: 'border-box',
                        height: 32,
                        border: '1px solid #999999',
                        borderRadius: 4,
                    }}
                    autoFocus
                    disabled={rateList?.length > 0}
                    className={classes.pincodeInput}
                    value={pincodeData[type]?.pincode}
                    placeholder={fieldMapping[type]}
                    suffix={renderValidation(type)}
                    onChange={(e) => handlePincodeChange(e, type)}
                />
            </div>
        );
    };

    const renderDots = () => {
        return (
            <div className={classes.dots} />
        );
    };

    const renderPincodes = () => {
        return (
            <div className={classes.fields}>
                {renderPincode('src')}
                {renderDots()}
                {renderPincode('dst')}
            </div>
        );
    };

    const options = [{
        label: 'Domestic',
        value: 'domestic',
    },
    {
        label: 'International',
        value: 'international',
    }];

    const renderCategory = () => {
        return (
            <div className={classes.fields}>
                <div className={classes.singleField}>
                    <span className={classes.name}>Consignment Category</span>
                    <Radio.Group
                        disabled={rateList?.length > 0}
                        value={category}
                        options={options}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        onChange={(e) => {
                            const value = e?.target?.value;
                            setCategory(value);
                            if (value === 'domestic') {
                                setPincodeData({
                                    ...pincodeData,
                                    dst: null,
                                });
                            }
                        }}
                    />
                </div>
            </div>
        );
    };

    // const renderNumPieces = () => {
    //     if (loadtype === 'DOCUMENT') {
    //         return null;
    //     }
    //     return (
    //         <div className={classes.numPiecesField}>
    //             <span className={classes.name}>No. of Pieces *</span>
    //             <Input
    //                 className={classes.numPieces}
    //                 type="number"
    //                 disabled={rateList?.length > 0}
    //                 value={numpieces}
    //                 placeholder="No of pieces"
    //                 onChange={(e) => {
    //                     const value = Number(e?.target?.value);
    //                     if (value < 0) {
    //                         message.error('No of pieces should be a positive number');
    //                         return;
    //                     }
    //                     setNumPieces(e?.target?.value);
    //                 }}
    //             />
    //         </div>
    //     );
    // };

    const loadOptions = [{
        label: 'Document',
        value: 'DOCUMENT',
    },
    {
        label: 'Non Document',
        value: 'NON-DOCUMENT',
    }];

    const renderLoadType = () => {
        return (
            <div className={classes.fields}>
                <div className={classes.singleField}>
                    <span className={classes.name}>Item Type</span>
                    <Radio.Group
                        value={loadtype}
                        disabled={rateList?.length > 0}
                        options={loadOptions}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        onChange={(e) => setLoadtype(e?.target?.value)}
                    />
                </div>
                {/* {renderNumPieces()} */}
            </div>
        );
    };

    const renderWeightUnit = () => {
        return (
            <Select
                disabled={rateList?.length > 0}
                value={weightUnit}
                autoFocus={false}
                placeholder="Height"
                onChange={(val: string) => setWeightUnit(val as any)}
            >
                <Select.Option value="gm">gm</Select.Option>
                <Select.Option value="kg">Kg</Select.Option>
            </Select>
        );
    };

    const renderWeight = () => {
        return (
            <div
                className={classes.dimentionField}
                style={{
                    width: '30%',
                }}
            >
                <span className={classes.name}>Weight *</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={weight}
                    placeholder="Weight"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Weight should be a positive number');
                            return;
                        }
                        setWeight(e?.target?.value);
                    }}
                    addonAfter={renderWeightUnit()}
                />
            </div>
        );
    };

    const renderLength = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.dimentionField}>
                <span className={classes.name}>Length</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={length}
                    placeholder="Length"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Length should be a positive number');
                            return;
                        }
                        setLength(e?.target?.value);
                    }}
                />
            </div>
        );
    };

    const renderHeight = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.dimentionField}>
                <span className={classes.name}>Height</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={height}
                    placeholder="Height"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Height should be a positive number');
                            return;
                        }
                        setHeight(e?.target?.value);
                    }}
                />
            </div>
        );
    };

    const renderWidth = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.dimentionField}>
                <span className={classes.name}>Width</span>
                <Input
                    className={classes.weight}
                    type="number"
                    disabled={rateList?.length > 0}
                    value={width}
                    placeholder="Width"
                    onChange={(e) => {
                        const value = Number(e?.target?.value);
                        if (value < 0) {
                            message.error('Width should be a positive number');
                            return;
                        }
                        setWidth(e?.target?.value);
                    }}
                />
            </div>
        );
    };

    const renderUnit = () => {
        if (loadtype === 'DOCUMENT') {
            return null;
        }
        return (
            <div className={classes.unitField}>
                <span className={classes.name}>Unit</span>
                <Select
                    className={classes.weight}
                    disabled={rateList?.length > 0}
                    value={unit}
                    placeholder="Height"
                    onChange={(val: string) => setUnit(val as any)}
                >
                    <Select.Option value="cm">cm</Select.Option>
                    <Select.Option value="inch">Inch</Select.Option>
                    <Select.Option value="mtr">Mtr</Select.Option>
                    <Select.Option value="ft">Ft</Select.Option>
                </Select>
            </div>
        );
    };

    const renderDimestions = () => {
        return (
            <div className={classes.fields}>
                {renderWeight()}
                {renderLength()}
                {renderHeight()}
                {renderWidth()}
                {renderUnit()}
            </div>
        );
    };

    const renderMessage = () => {
        return (
            <div className={classes.rateMsg}>
                Below are the offered services and their respective rates.
            </div>
        );
    };

    const renderServiceName = (serviceName: string) => {
        return (
            <div className={classes.serviceName}>{serviceName}</div>
        );
    };

    const renderPrice = (price?: number) => {
        const rateMsg = `Rates are inclusive of GST and other charges. These are approximate rates
        and actual rates may vary as per exact details provided at time of booking`;
        return (
            <div className={classes.serviceName}>
                <span style={{ margin: '0px 6px' }}>
                    {price ? `₹ ${price}` : '--'}
                </span>
                <Tooltip
                    title={rateMsg}
                    placement="leftTop"
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </div>
        );
    };

    const renderTat = (period: string) => {
        return (
            <div className={classes.servicePeriod}>{period}</div>
        );
    };

    const renderMsg = () => {
        return (
            <div className={classes.msg}>
                Delivery of consignment across India via air.
            </div>
        );
    };

    const renderRateCard = (service: Service) => {
        return (
            <div className={classes.rateCard}>
                <div className={classes.serviceRate}>
                    {renderServiceName(service.serviceType)}
                    {renderPrice(service?.rate?.amount)}
                </div>

                <div className={classes.serviceRate}>
                    {renderMsg()}
                    {renderTat(service?.period)}
                </div>
            </div>
        );
    };

    const renderRateList = () => {
        const filteredList = rateList.filter((item) => Boolean(item?.rate?.amount));
        if (!filteredList?.length) {
            return (
                <div>
                    No rates found.
                </div>
            );
        }
        return filteredList?.map((rate) => {
            return renderRateCard(rate);
        });
    };

    const renderRateAndTat = () => {
        if (!rateList?.length) {
            return null;
        }

        return (
            <div className={classes.rateBody}>
                {renderLine()}
                {renderMessage()}
                {renderRateList()}
            </div>
        );
    };

    const renderCountry = () => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>Country *</span>
                <Select
                    showSearch
                    disabled={rateList?.length > 0}
                    value={pincodeData?.dst?.country}
                    placeholder="Country"
                    filterOption={(input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())}
                    onChange={(value: string) => {
                        const country = find(countryList, { country: value });

                        let valid = false;
                        if (country?.pincode_regex) {
                            const regex = new RegExp(country?.pincode_regex.replace(/^\//, '')?.replace(/\/$/, ''));
                            if (regex.test(pincodeData.dst?.pincode)) {
                                valid = true;
                            }
                        }
                        setStateList([]);
                        timeout = undefined;
                        setCityList([]);
                        setPincodeData({
                            ...pincodeData,
                            dst: {
                                ...pincodeData.dst,
                                valid,
                                country: value,
                                state: undefined,
                                city: undefined,
                            },
                        });
                    }}
                >
                    {countryList.map((item: Country) => {
                        return <Select.Option value={item.country}>{item.country}</Select.Option>;
                    })}
                </Select>
            </div>
        );
    };

    const renderState = () => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>State</span>
                <Select
                    disabled={rateList?.length > 0 || !pincodeData?.dst?.country}
                    value={pincodeData?.dst?.state}
                    placeholder="State"
                    filterOption={(input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())}
                    onChange={(value: string) => {
                        setPincodeData({
                            ...pincodeData,
                            dst: {
                                ...pincodeData.dst,
                                state: value,
                                city: undefined,
                            },
                        });
                    }}
                    showSearch
                >
                    {stateList.map((item: State) => {
                        return <Select.Option value={item.state_name}>{item.state_name}</Select.Option>;
                    })}
                </Select>
            </div>
        );
    };

    const renderCity = () => {
        return (
            <div className={classes.singleField}>
                <span className={classes.name}>City *</span>
                <Select
                    disabled={rateList?.length > 0 || !pincodeData?.dst?.country}
                    value={pincodeData?.dst?.city}
                    placeholder="City"
                    onChange={(value: string) => {
                        setPincodeData({
                            ...pincodeData,
                            dst: {
                                ...pincodeData.dst,
                                city: value,
                            },
                        });
                    }}
                    onSearch={fetchCityList}
                    showSearch
                    notFoundContent={loading ? <Loader zIndex={5} /> : null}
                >
                    {cityList.map((item: City) => {
                        return <Select.Option value={item.city_name}>{item.city_name}</Select.Option>;
                    })}
                </Select>
            </div>
        );
    };

    const renderInternationalFields = () => {
        if (category !== 'international') {
            return null;
        }

        return (
            <div className={classes.internationalFields}>
                {renderCountry()}
                {renderState()}
                {renderCity()}
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="45%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderPrimary()}
            {renderCategory()}
            {renderInternationalFields()}
            {renderPincodes()}
            {renderLine()}
            {renderItemDetails()}
            {renderLoadType()}
            {renderDimestions()}
            {renderRateAndTat()}
        </Drawer>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: rateCalculatorStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(RateCalculator);
