import * as React from 'react';
import withStyles from 'react-jss';
import {
    Drawer,
    Row,
    Col,
    message,
    Button,
    Input,
    DatePicker,
    Radio,
    TimePicker,
    Divider,
    Select,
    Steps,
    Typography,
    Form,
    Checkbox,
    InputNumber,
    Space,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
// import { Form } from '@ant-design/compatible';
import { createConsignment } from '../../network/consignments.api';
import { getBusinessTatV2 } from '../../network/common.api';
import Loader from '../common/Loader';
import LocationList from './LocationList';
import AddNewLocation from './AddNewLocation';
// import { CloseCircleOutlined } from '@ant-design/icons';
import { OrderCreationFormProps } from './types';
import { useGenericState } from '../../hooks/use-generic-state';
import { stylesOrderCreationForm } from './styles';
import DetailsIcon from '../../assets/DetailsIcon';
import LocationIcon from '../../assets/LocationIcon';
import TimeSlotIcon from '../../assets/TimeSlotIcon';
import GoodsIcon from '../../assets/GoodsIcon';
import ServicesIcon from '../../assets/ServicesIcon';
import OrderPreview from './OrderPreview';
import {
    dateFormat,
    // momentsDateTime,
    formatTimeWithoutAMPM,
    orderCreationFormSteps,
} from './utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    // get,
    omit,
    uniqueId,
} from 'lodash';
import { InputTypes } from '../create-consignment/create-consignment.types';
import { formFields } from '../create-consignment/create-modal.constants';
import Helper from 'library/Helper';

export type Service = {
    TAT: number;
    period: string;
    serviceType: string;
    serviceTypeId: string;
    serviceCode: string;
    tatUnit?: string;
    cutoffTime?: string;
    startTime?: string;
};

interface PieceDetails {
    key: any;
    height?: any;
    length?: any;
    numberOfPieces?: any;
    unit?: any;
    weight?: any;
    weightUnit?: any;
    dimensionsUnit?: any;
    width?: any;
    declaredValue?: any;
    description?: any;
    items?: Record<any, any>;
    codAmount?: any;
}

const { Title } = Typography;

const Option = Select.Option;
const Step = Steps.Step;

const ref = React.createRef<any>();

const paymentModes = [
    {
        id: 'cod',
        name: 'COD',
    },
    {
        id: 'prepaid',
        name: 'Prepaid',
    },
    {
        id: 'prepaid',
        name: 'Online',
    },
];

const {
    Unit,
    Weight,
    Length,
    Width,
    Height,
    Description,
    DeclaredValue,
    Vol_weight,
    NumPieces,
    WeightUnit,
} = formFields;

const OrderCreationForm = (props: OrderCreationFormProps) => {
    const [form] = Form.useForm();
    const {
        onClose,
        classes,
        visible,
        // orderFormFields,
        // pickUpLocationFields,
        // destinationLocationFields,
        // piecesDetailsFields,
        loadconsignments,
    } = props;

    const {
        setFieldsValue,
        validateFields,
        getFieldsValue,
    } = form;

    const initialState: any = {
        isLoading: false,
        data: {},
        customerData: [],
        selectedPickUpLocation: 'existing',
        selectedDestinationLocation: 'existing',
        forceRemount: 0, // this state is used to force rerender the LocationList Component
        mandatoryFields: [],
        pickUpLocationMandatoryFields: [],
        destinationLocationMandatoryFields: [],
        piecesDetailsMandatoryFields: [],
        destAddress: {},
        pickupAddress: {},
        currentStep: 0,
        formData: {},
        isPreviewVisible: false,
        services: [],
        selectedService: {},
        pieces: {},
        addMoreBoxes: 1,
        startAddMoreBoxes: 0,
    };

    const { t, i18n } = useTranslation();
    const [state, setState] = useGenericState(initialState);

    const {
        isLoading,
        selectedPickUpLocation,
        selectedDestinationLocation,
        forceRemount,
        // mandatoryFields,
        // pickUpLocationMandatoryFields,
        // destinationLocationMandatoryFields,
        // piecesDetailsMandatoryFields,
        destAddress,
        pickupAddress,
        currentStep,
        formData,
        isPreviewVisible,
        services,
        selectedService,
        pieces,
        addMoreBoxes,
        startAddMoreBoxes,
    } = state;

    const setFormData = () => {
        setFieldsValue({ ...formData });
    };

    // const getMandatoryFields = (obj: any) => {
    //     const temp: any = [];
    //     if (Array.isArray(obj)) {
    //         obj.forEach((item) => {
    //             if (!item.is_optional) temp.push(item.key);
    //         });
    //     }
    //     return temp;
    // };

    const renderLine = () => {
        return (
            <div className={classes.divider}>
                <Divider />
            </div>
        );
    };

    const removePiece = (key: any) => {
        setState({
            pieces: omit(pieces, key),
        });
    };

    const renderRemovePiece = (id: any) => {
        return (
            <Button danger type="link" onClick={() => removePiece(id)}>{t('remove')}</Button>
        );
    };

    const renderNumPeices = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                    style={NumPieces.titleWidth ? { width: NumPieces.titleWidth } : {}}
                >
                    {t('num_pieces_label')}
                    {/* {isRequired(piece, NumPieces.key) ? '*' : ''} */}
                </div>
                <Form.Item
                    name={piece ? ['pieces', piece.key, NumPieces.key] : NumPieces.key}
                    className={classes.contentType}
                    initialValue={NumPieces.defaultValue}
                    valuePropName={NumPieces.valuePropName}
                    // rules={isRequired(piece, NumPieces.key) ? [formRules.required, formRules.number] : [formRules.number]}
                >
                    <Input
                        type="number"
                        min={0}
                        placeholder={t('num_pieces_label')}
                        style={{ width: NumPieces.width }}
                        value={piece ? piece.numberOfPieces : formData[NumPieces.key]}
                        // disabled={!piece && consignmentCategory === 'international' && autoCalculateDetails}
                        // onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderTitle = (field: any) => {
        return (
            <div
                className={classes.title}
                style={field.titleWidth ? { width: field.titleWidth } : {}}
            >
                {t(field.key)}
                {/* {isRequired(isPiece, field.key) ? '*' : null} */}
            </div>
        );
    };

    const renderRadio = (field: any) => {
        return (
            <Form.Item
                name={field.key}
                valuePropName={field.valuePropName}
                initialValue={field.defaultValue}
            >
                <Radio.Group
                    style={{ width: field.width }}
                    options={field.options}
                    // defaultValue={field.defaultValue}
                />
            </Form.Item>
        );
    };

    const renderTextarea = (field: any) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                // rules={isRequired(false, field.key) ? [formRules.required] : undefined}
            >
                <TextArea
                    placeholder={t(field.placeholder)}
                    style={{ width: field.width }}
                />
            </Form.Item>
        );
    };

    const renderSelect = (field: any, piece: PieceDetails | undefined) => {
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = i18n.exists(item.value) ? t(item.value) : item.label;
            });
        }
        return (
            <Form.Item
                name={piece ? ['pieces', piece.key, 'dimensionsUnit'] : field.key}
                initialValue={piece ? piece.dimensionsUnit : field.defaultValue}
                valuePropName={field.valuePropName}
                // rules={isRequired(piece, field.key) ? [formRules.required] : undefined}
            >
                <Select
                    disabled={field.disabled}
                    placeholder={t(field.key)}
                    style={{ width: field.width }}
                    options={field.options}
                    allowClear
                    // defaultValue={piece ? piece.dimensionsUnit : undefined}
                    // onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                />
            </Form.Item>
        );
    };

    const renderInput = (field: any, type: string, piece: PieceDetails | undefined) => {
        return (
            <Form.Item
                name={piece ? ['pieces', piece.key, field.key] : field.key}
                // initialValue={field.defaultValue}
                // valuePropName={field.valuePropName}
                // rules={isRequired(piece, field.key) ? [formRules.required] : undefined}
            >
                <Input
                    type={type}
                    min={0}
                    placeholder={t(field.key)}
                    style={{ width: field.width }}
                    value={piece ? (piece as any)[field.key] : formData[field.key]}
                    // disabled={piece && field === Vol_weight && autoCalculateDetails}
                    // onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                />
            </Form.Item>
        );
    };

    const renderDate = (field: any) => {
        return (
            <Form.Item
                name={field.key}
                initialValue={field.defaultValue}
                valuePropName={field.valuePropName}
                // rules={isRequired(false, field.key) ? [formRules.required] : undefined}
            >
                <DatePicker
                    placeholder={field.placeholder}
                    style={{ width: field.width }}
                />
            </Form.Item>
        );
    };

    const renderFormItem = (field: any, type: string = 'text', piece: PieceDetails | undefined = undefined) => {
        switch (field.type) {
            case InputTypes.Input: return renderInput(field, type, piece);
            case InputTypes.Select: return renderSelect(field, piece);
            case InputTypes.Textarea: return renderTextarea(field);
            case InputTypes.Radio: return renderRadio(field);
            case InputTypes.Date: return renderDate(field);
            default: return 'Input is not defined';
        }
    };

    const renderWeightUnit = (piece: PieceDetails | undefined) => {
        return (
            <Form.Item
                name={piece ? ['pieces', piece.key, WeightUnit.key] : WeightUnit.key}
                key={WeightUnit.key}
                initialValue={piece ? piece.weightUnit : WeightUnit.defaultValue}
                // rules={isRequired(piece, WeightUnit.key) ? [formRules.required] : undefined}
            >
                <Select
                    placeholder={WeightUnit.placeholder}
                    options={WeightUnit.options}
                    style={{ width: WeightUnit.width }}
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                    // onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                >
                    {WeightUnit.options?.map((item) => {
                        return (
                            <Select.Option
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const renderWeight = (piece: PieceDetails| undefined) => {
        return (
            <div className={classes.formItemWeight}>
                {renderTitle(Weight)}
                <Form.Item
                    name={piece ? ['pieces', piece.key, Weight.key] : Weight.key}
                    className={classes.contentType}
                    initialValue={Weight.defaultValue}
                    valuePropName={Weight.valuePropName}
                    // style={{ width: Weight.width }}
                    // rules={isRequired(piece, Weight.key) ? [formRules.required] : undefined}
                >
                    <Input
                        type="number"
                        min={0}
                        placeholder={t(Weight.key)}
                        style={{ width: Weight.width }}
                        value={piece ? piece.weight : formData[Weight.key]}
                        // onChange={piece ? () => calculateTotalAndGrossWeightVolume(piece) : undefined}
                        addonAfter={renderWeightUnit(piece)}
                        // disabled={!piece && consignmentCategory === 'international'
                        //     && itemType === 'non-document' && autoCalculateDetails}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderDimensions = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.dimensions}>
                <div className={classes.dimentionTitle}>
                    {t('dimensions')}
                </div>
                <Space direction="horizontal">
                    {renderFormItem(Length, 'number', piece)}
                    {renderFormItem(Width, 'number', piece)}
                    {renderFormItem(Height, 'number', piece)}
                    {renderFormItem(Unit, 'number', piece)}
                    {/* {renderVolume(piece)} */}
                </Space>
            </div>
        );
    };

    const renderWeightDimenstion = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.piecesFields}>
                {renderWeight(piece)}
                {renderDimensions(piece)}
            </div>
        );
    };

    const renderDescription = (piece: PieceDetails | undefined) => {
        return (
            <div className={classes.description}>
                <div className={classes.descriptionTitle}>
                    {t(Description.key)}
                </div>
                <Form.Item
                    name={piece ? ['pieces', piece.key, Description.key] : Description.key}
                    initialValue={Description.defaultValue}
                    valuePropName={Description.valuePropName}
                    // rules={isRequired(piece, Description.key) ? [formRules.required] : undefined}
                >
                    <TextArea
                        placeholder={t(Description.key)}
                        style={{ width: Description.width }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderBoxDetails = () => {
        return (
            <div>
                {
                    Object.keys(pieces).map((key, idx) => {
                        const piece = pieces[key];
                        return (
                            <div
                                key={piece.key}
                                style={{
                                    background: '#FAFAFA',
                                    padding: 4,
                                }}
                            >
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}
                                >
                                    Box&nbsp;
                                    {idx + 1}
                                    { Object.keys(pieces).length !== 1 ? renderRemovePiece(piece.key) : null}
                                </div>
                                <div className={classes.piecesFields}>
                                    { renderNumPeices(piece) }
                                    <div className={classes.formItem}>
                                        { renderTitle(DeclaredValue) }
                                        { renderFormItem(DeclaredValue, 'number', piece) }
                                    </div>
                                </div>
                                { renderWeightDimenstion(piece) }
                                <div className={classes.piecesFields}>
                                    <div className={classes.formItem}>
                                        { renderTitle(Vol_weight) }
                                        { renderFormItem(Vol_weight, 'number', piece) }
                                    </div>
                                </div>
                                { renderDescription(piece)}
                                { renderLine() }
                            </div>
                        );
                    })
                }
                <div className={classes.addMoreBoxContainer}>
                    {t('add_more_boxes')}
                    <InputNumber
                        style={{ margin: '0 10px' }}
                        min={0}
                        max={10}
                        placeholder="Write here"
                        defaultValue={1}
                        value={addMoreBoxes}
                        onChange={(e) => {
                            if (e) {
                                setState({ addMoreBoxes: e });
                            }
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => setState({ startAddMoreBoxes: addMoreBoxes })}
                    >
                        {t('add_more_text')}
                    </Button>
                </div>
            </div>
        );
    };

    const getSubmitBody = (data: any) => {
        const { destination_location_details, pickup_location_details } = data;
        const dest_address = selectedDestinationLocation === 'existing'
            ? destination_location_details
            : destAddress;
        const pickup_address = selectedPickUpLocation === 'existing'
            ? pickup_location_details
            : pickupAddress;

        const body = {
            codAmount: data.final_amount,
            codCollectionMode: data.payment_mode,
            dstAddress: {
                name: dest_address.name,
                phone: dest_address.phone,
                addressLine1: dest_address.addressLine1,
                addressLine2: dest_address.addressLine2,
                pincode: dest_address.pincode,
                latitude: dest_address.latitude,
                longitude: dest_address.longitude,
            },
            referenceNumber: data.order_number,
            serviceType: null,
            srcAddress: {
                name: pickup_address.name,
                phone: pickup_address.phone,
                addressLine1: pickup_address.addressLine1,
                addressLine2: pickup_address.addressLine2,
                pincode: pickup_address.pincode,
                latitude: pickup_address.latitude,
                longitude: pickup_address.longitude,
            },
            deliveryDate: data.order_date ? dateFormat(data.order_date, 'YYYY-MM-DD') : null,
            deliveryTimeSlotStart: data.start_time ? formatTimeWithoutAMPM(data.start_time) : null,
            deliveryTimeSlotEnd: '',
            piecesDetail: null,
            orderType: data.order_type,
            consignmentFlowType: 'Hyperlocal',
        };

        if (data.pieces) {
            const piecesDetail : any = [];
            Object.keys(data.pieces).forEach((key: any, index: any) => {
                const piece: any = data.pieces[key];
                piecesDetail.push({
                    description: piece.description,
                    declaredValue: piece.declaredValue,
                    item_details: Object.keys(piece.items || {}).map((itemKey) => {
                        return {
                            ...piece.items[itemKey],
                            pieceNumber: index,
                        };
                    }),
                    length: Helper.converttoCentimeter(piece.length, piece.dimensionsUnit),
                    width: Helper.converttoCentimeter(piece.width, piece.dimensionsUnit),
                    height: Helper.converttoCentimeter(piece.height, piece.dimensionsUnit),
                    pieceNumber: index,
                    weight: Helper.converttoKilogram(Number(piece.weight), piece.weightUnit),
                    volumetricWeight: piece.volumetricWeight,
                    numberOfItems: Number(piece.numberOfPieces),
                    // quantity: 1,
                });
                body.piecesDetail = piecesDetail;
            });
        }

        if (selectedService) {
            body.serviceType = selectedService.serviceTypeId;
            if (selectedService.TAT && data.start_time) {
                const endTime = moment(data.start_time, 'HH:mm')
                    .add(Number(selectedService.TAT), selectedService.tatUnit)
                    .format('HH:mm');
                body.deliveryTimeSlotEnd = endTime;
            }
        }

        return body;
    };

    const loadServices = async () => {
        if (!formData.destination_location_details || !formData.pickup_location_details) {
            message.error('Please select Pickup and Destination Address');
            return;
        }
        setState({ isLoading: true });
        const body = getSubmitBody(formData);
        const response = await getBusinessTatV2(body);
        if (response.isSuccess) {
            setState({
                isLoading: false,
                services: response?.data || [],
            });
            if (!response?.data?.length) {
                message.error('Selected Pickup and Destination is not serviceable');
            }
        } else {
            setState({
                isLoading: false,
                services: [],
            });
            message.error(response.errorMessage);
        }
    };

    const addPiece = () => {
        let currentPieces: Record<any, PieceDetails> = {};
        const id = uniqueId();
        if (pieces) currentPieces = { ...pieces };
        setState({
            pieces: {
                ...currentPieces,
                [id]: {
                    key: id,
                },
            },
        });
    };

    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(0, 0);
        }
    }, [currentStep]);

    React.useEffect(() => {
        addPiece();
    }, []);

    React.useEffect(() => {
        if (!isPreviewVisible) {
            setFormData();
        }
    }, [currentStep, isPreviewVisible]);

    React.useEffect(() => {
        if (startAddMoreBoxes > 0) {
            addPiece();
        }
    }, [startAddMoreBoxes]);

    React.useEffect(() => {
        if (startAddMoreBoxes > 0) {
            setState({ startAddMoreBoxes: startAddMoreBoxes - 1 });
        }
        setFieldsValue({
            numberOfPieces: Object.keys(pieces).length,
        });
    }, [pieces]);

    // React.useEffect(() => {
    //     setState({
    //         mandatoryFields: getMandatoryFields(orderFormFields),
    //         pickUpLocationMandatoryFields:
    //             getMandatoryFields(pickUpLocationFields),
    //         destinationLocationMandatoryFields: getMandatoryFields(
    //             destinationLocationFields,
    //         ),
    //         piecesDetailsMandatoryFields:
    //             getMandatoryFields(piecesDetailsFields),
    //     });
    // }, []);

    const handlePickUpLocationType = (e: any) => {
        setState({
            selectedPickUpLocation: e.target.value,
            pickupAddress: {},
        });
    };

    const handleDestinationLocationType = (e: any) => {
        setState({
            selectedDestinationLocation: e.target.value,
            destAddress: {},
        });
    };

    const onStepChange = async (step: number) => {
        try {
            const formValues = await validateFields();
            if (formValues.errorFields?.length) {
                message.error('Please fill the required fields!');
                return;
            }

            const data = getFieldsValue();

            setState({
                currentStep: step,
                formData: { ...formData, ...data },
            });
        } catch (e) {
            message.error('Please fill the required fields!');
        }
    };

    const handleSubmit = async () => {
        const { destination_location_details, pickup_location_details } = formData;
        const dest_address = selectedDestinationLocation === 'existing'
            ? destination_location_details
            : destAddress;
        const pickup_address = selectedPickUpLocation === 'existing'
            ? pickup_location_details
            : pickupAddress;

        setState({ isLoading: true });

        if (!pickup_address) {
            message.error('Please select Pickup Address');
            setState({ isLoading: false });
            return;
        }

        if (!dest_address) {
            message.error('Please select Destination Address');
            setState({ isLoading: false });
            return;
        }

        const body = getSubmitBody(formData);

        const response = await createConsignment(body);

        if (response.isSuccess) {
            if (loadconsignments) loadconsignments();
            message.success('Order added successfully!');
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
        setState({ isLoading: false });
    };

    const handleContinue = () => {
        onStepChange(currentStep + 1);
    };

    const handlePreviewVisibility = async (val: any) => {
        if (!selectedService.serviceTypeId) {
            message.error('Please select a service');
            return;
        }
        if (val) {
            const formValues = await validateFields();
            if (formValues.errorFields?.length) {
                message.error('Please fill the required fields!');
                return;
            }
            const data = getFieldsValue();
            setState({
                formData: { ...formData, ...data },
                isPreviewVisible: val,
            });
        } else {
            setState({
                isPreviewVisible: val,
            });
        }
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.headerLeft}>
                    <div className={classes.orderNumber}>Add Order</div>
                </div>
                <div className={classes.submitButton}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {currentStep < 2 ? (
                        <Button
                            type="primary"
                            onClick={handleContinue}
                            disabled={isLoading}
                        >
                            Continue
                        </Button>
                    ) : (
                        isPreviewVisible ? (
                            <>
                                <Button
                                    onClick={() => handlePreviewVisibility(false)}
                                    disabled={isLoading}
                                    style={{ marginRight: '5px' }}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                >
                                    Submit
                                </Button>
                            </>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => handlePreviewVisibility(true)}
                                disabled={isLoading}
                            >
                                Preview
                            </Button>
                        )
                    )}
                </div>
            </div>
        );
    };

    const onNumberChange = (e: any) => {
        const invalidChars = ['e', '-', '+'];

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const forceRemountHandler = () => {
        setState({
            forceRemount: 1 + state.forceRemount,
        });
    };

    const renderPrimaryDetails = () => {
        return (
            <div ref={ref as any} className={classes.detailsContainer}>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <DetailsIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Primary Details
                        </Title>
                    </Col>
                    <Col span={18}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Reference Number"
                                    name="order_number"
                                    // initialValue={field.defaultValue}
                                    rules={[
                                        {
                                            required: false,
                                            // mandatoryFields.includes('order_number'),
                                            message: 'Please Enter Order Number',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter or Scan"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Invoice Number"
                                    name="invoice_number"
                                    // initialValue={field.defaultValue}
                                    rules={[
                                        {
                                            required: false,
                                            // mandatoryFields.includes('invoice_number'),
                                            message: 'Please Enter Invoice Number',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter or Scan"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="HU Number"
                                    name="hu_details"
                                    // initialValue={field.defaultValue}
                                    rules={[
                                        {
                                            required: false,
                                            // mandatoryFields.includes('hu_details'),
                                            message: 'Please Enter HU Number',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="HU Number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Order Type"
                                    // initialValue={formData.order_type || 'express'}
                                    name="order_type"
                                    initialValue="express"
                                >
                                    <Radio.Group className={classes.radioOptions}>
                                        <Radio value="express">Express</Radio>
                                        <Radio value="planned">Planned</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className={classes.divider}>
                            <Divider />
                        </div>
                    </Col>
                </Row>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <GoodsIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Pieces Details
                        </Title>
                    </Col>
                    <Col span={18}>
                        <Row gutter={16}>
                            <Col span={24}>
                                {renderBoxDetails()}
                            </Col>
                        </Row>
                        <div className={classes.divider}>
                            <Divider />
                        </div>
                    </Col>
                </Row>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <TimeSlotIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Payment Details
                        </Title>
                    </Col>
                    <Col span={18}>
                        {paymentModes && paymentModes.length !== 0 ? (
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Amount"
                                        name="final_amount"
                                        // initialValue={ondemandPaymentModes[0].name}
                                        rules={[
                                            {
                                                required: false,
                                                // mandatoryFields.includes('final_amount'),
                                                message: 'Please Enter Amount',
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            onKeyDown={onNumberChange}
                                            placeholder="Enter Amount"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Mode"
                                        name="payment_mode"
                                        initialValue={paymentModes[0].name}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please select Payment Mode',
                                            },
                                        ]}
                                    >
                                        <Select className={classes.radioOptions}>
                                            {paymentModes.map((item: any) => (
                                                <Option value={item.name} key={item.name}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    };

    const renderLocationDetails = () => {
        return (
            <div ref={ref as any} className={classes.detailsContainer}>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <LocationIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Pickup Location
                        </Title>
                    </Col>
                    <Col span={18}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Pickup Location"
                                    name="pickup_location"
                                    initialValue="existing"
                                    rules={[]}
                                >
                                    <Radio.Group
                                        className={classes.radioOptions}
                                        onChange={handlePickUpLocationType}
                                    >
                                        <Radio value="existing">From Saved Address</Radio>
                                        <Radio value="add_new">Add New Address</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className={classes.locationPanel}>
                            {selectedPickUpLocation === 'existing' ? (
                                <Form.Item
                                    name="pickup_location_details"
                                    initialValue={formData.pickup_location_details}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Pickup Location',
                                        },
                                    ]}
                                >
                                    <LocationList
                                        key={forceRemount}
                                        forceRemountHandler={forceRemountHandler}
                                        // mandatoryFields={pickUpLocationMandatoryFields}
                                    />
                                </Form.Item>
                            ) : (
                                <AddNewLocation
                                    // mandatoryFields={pickUpLocationMandatoryFields}
                                    onClose={(data: any) => {
                                        setState({
                                            pickupAddress: data,
                                            selectedPickUpLocation: 'existing',
                                        });
                                        setFieldsValue({
                                            pickup_location: 'existing',
                                        });
                                    }}
                                />
                            )}
                        </div>
                        <div className={classes.divider}>
                            <Divider />
                        </div>
                    </Col>
                </Row>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <LocationIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Destination Location
                        </Title>
                    </Col>
                    <Col span={18}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Destination Location"
                                    name="destination_location"
                                    initialValue="existing"
                                    rules={[]}
                                >
                                    <Radio.Group
                                        className={classes.radioOptions}
                                        onChange={handleDestinationLocationType}
                                    >
                                        <Radio value="existing">From Saved Address</Radio>
                                        <Radio value="add_new">Add New Address</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className={classes.locationPanel}>
                            {selectedDestinationLocation === 'existing' ? (
                                <Form.Item
                                    style={{ marginBottom: '0px' }}
                                    name="destination_location_details"
                                    initialValue={formData.destination_location_details}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select Destination Location',
                                        },
                                    ]}
                                >
                                    <LocationList
                                        key={forceRemount}
                                        forceRemountHandler={forceRemountHandler}
                                        // mandatoryFields={destinationLocationMandatoryFields}
                                    />
                                </Form.Item>
                            ) : (
                                <AddNewLocation
                                    // mandatoryFields={destinationLocationMandatoryFields}
                                    onClose={(data: any) => {
                                        setState({
                                            destAddress: data,
                                            selectedDestinationLocation: 'existing',
                                        });
                                        setFieldsValue({
                                            destination_location: 'existing',
                                        });
                                    }}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const handleSelection = (item: Service) => {
        setState({
            selectedService: item || {},
        });
        setFieldsValue({
            service_type: item,
        });
    };

    const renderServiceName = (item: Service) => {
        return (
            <div className={classes.serviceItem}>
                {item.serviceTypeId}
            </div>
        );
    };

    const renderTat = (item: Service) => {
        return (
            <div className={classes.tat}>
                {
                    item.TAT ? `Delivery | ${item.TAT} ${item.tatUnit}${item.TAT > 1 ? 's' : ''}` : null
                }
            </div>
        );
    };

    const renderService = (item: Service) => {
        return (
            <Checkbox
                name={item.serviceTypeId}
                key={item.serviceTypeId}
                checked={item.serviceTypeId === selectedService.serviceTypeId}
                onClick={() => handleSelection(item)}
                className={classes.serviceCheckbox}
            >
                {renderServiceName(item)}
                {renderTat(item)}
            </Checkbox>
        );
    };

    const renderCheckbox = () => {
        return (
            <Form.Item
                name="service_type"
                // initialValue={field.defaultValue}
                // valuePropName={field.valuePropName}
                // rules={[
                //     {
                //         required: true,
                //         // piecesDetailsMandatoryFields.includes(
                //         //     'service_type',
                //         // ),
                //         message: 'Please Select Service Type',
                //     },
                // ]}
            >
                <div>
                    {services?.map((item: any) => renderService(item))}
                </div>
            </Form.Item>
        );
    };

    const renderServicesForm = () => {
        return (
            <div className={classes.boxFields}>
                {renderCheckbox()}
            </div>
        );
    };

    // const handleStartTimeChange = (time: any) => {
    //     console.log(time);
    // };

    const handleDisabledPickupDate = (currentDate : any) => {
        if (selectedService && selectedService.cutoffTime && selectedService.TAT) {
            let startTime = getFieldsValue().start_time;
            startTime = moment(startTime, 'HH:mm');
            let cuttoffTime = selectedService.cutoffTime;
            cuttoffTime = moment(cuttoffTime, 'HH:mm');
            const TAT = selectedService.TAT;
            if (Math.abs(startTime.diff(cuttoffTime, selectedService.tatUnit)) < Number(TAT)
                || startTime.diff(cuttoffTime, selectedService.tatUnit) >= 0
            ) {
                return currentDate && currentDate.format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD');
            }
        }
        return currentDate && currentDate.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
    };

    const getDisabledHours = () => {
        let hour = -1;
        if (selectedService && selectedService.startTime) {
            hour = Number(selectedService.startTime.split(':')[0]);
        }
        const hours: any = [];
        if (hour >= 0) {
            for (let i = 0; i < hour;) {
                hours.push(i);
                i += 1;
            }
        }
        return hours;
    };

    const getDisabledMinutes = () => {
        let minute = -1;
        if (selectedService && selectedService.startTime) {
            minute = Number(selectedService.startTime.split(':')[1]);
        }
        const minutes: any = [];
        if (minute >= 0) {
            for (let i = 0; i < minute;) {
                minutes.push(i);
                i += 1;
            }
        }
        return minutes;
    };

    const renderOrderDetails = () => {
        return (
            <div ref={ref as any} className={classes.detailsContainer}>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <ServicesIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Services
                        </Title>
                    </Col>
                    <Col span={18}>
                        <Button
                            onClick={loadServices}
                            type="primary"
                            style={{ marginBottom: '10px' }}
                            loading={isLoading}
                        >
                            Fetch Services
                        </Button>
                        {renderServicesForm()}
                        <div className={classes.divider}>
                            <Divider />
                        </div>
                    </Col>
                </Row>
                <Row className={classes.formSegment}>
                    <Col span={6} className={classes.iconSide}>
                        <TimeSlotIcon style={{ fontSize: '60px' }} />
                        <Title
                            level={4}
                            className={classes.sectionTitle}
                        >
                            Schedule Details
                        </Title>
                    </Col>
                    <Col span={18}>
                        <Row gutter={16}>
                            <Col span={24} style={{ display: 'flex' }}>
                                <Form.Item
                                    className={classes.customFormItemLayout}
                                    label="Date"
                                    name="order_date"
                                    // initialValue={formData.pickup_location_details}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select pickup Date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        disabledDate={handleDisabledPickupDate}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className={classes.customFormItemLayout}
                                    label="Start Time"
                                    style={{ marginLeft: '5px' }}
                                    name="start_time"
                                    // initialValue={formData.pickup_location_details}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select Start Time',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        // onChange={handleStartTimeChange}
                                        disabledHours={getDisabledHours}
                                        disabledMinutes={getDisabledMinutes}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderFormStepWise = (step: any) => {
        switch (step) {
            case 0:
                return renderPrimaryDetails();
            case 1:
                return renderLocationDetails();
            case 2:
                return renderOrderDetails();
            default:
                return null;
        }
    };

    const renderAddOrderForm = () => {
        return (
            <>
                {isLoading && <Loader zIndex={100} />}
                <Steps
                    current={currentStep}
                    onChange={(current) => onStepChange(current)}
                    className={classes.formSteps}
                >
                    {orderCreationFormSteps.map((item: any) => (
                        <Step key={item.key} title={item.title} />
                    ))}
                </Steps>
                <Form
                    colon={false}
                    form={form}
                    layout="vertical"
                >
                    {renderFormStepWise(currentStep)}
                </Form>
            </>
        );
    };

    const generateCompleteAddress = (locationDetails: any) => {
        if (!locationDetails) return '';

        const temp = [];
        const {
            addressLine1, addressLine2, pincode,
        } = locationDetails;

        if (addressLine1) temp.push(addressLine1);
        if (addressLine2) temp.push(addressLine2);
        if (pincode) temp.push(pincode);

        return temp.join(', ');
    };

    const transformData = (data: any) => {
        const newDetails: any = {
            pickup_location_address: generateCompleteAddress(data.pickup_location_details),
            destination_location_address: generateCompleteAddress(data.destination_location_details),
            pickup_details_list: [],
            start_time: data.start_time ? formatTimeWithoutAMPM(data.start_time) : null,
            end_time: '',
            order_date: data.order_date ? dateFormat(data.order_date, 'DD-MM-YYYY') : null,
            service_type_name: null,
        };

        if (data.pieces) {
            const temp: any[] = [];
            Object.keys(data.pieces).forEach((item: any) => {
                temp.push(data.pieces[item]);
            });

            newDetails.pickup_details_list = temp;
        }

        if (selectedService) {
            newDetails.service_type_name = selectedService.serviceTypeId;
            if (selectedService.TAT && data.start_time) {
                const endTime = moment(data.start_time, 'HH:mm')
                    .add(Number(selectedService.TAT), selectedService.tatUnit)
                    .format('HH:mm');
                newDetails.end_time = endTime;
            }
        }

        return { ...data, ...newDetails };
    };

    return (
        <Drawer
            width="80%"
            onClose={onClose}
            visible={visible}
            className={classes.sideDrawer}
            title={renderHeader()}
        >
            {isPreviewVisible ? (
                <OrderPreview data={transformData(formData)} />
            ) : (renderAddOrderForm())}
        </Drawer>
    );
};

export default withStyles(stylesOrderCreationForm)(OrderCreationForm);
