/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Unhappy = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Unhappy</title>
        <defs>
            <polygon id="path-1" points="0 0 24.00004 0 24.00004 24 0 24" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="CP-V2-Like" transform="translate(-76.000000, -80.000000)">
                <g id="Group-5" transform="translate(12.000000, 12.000000)">
                    <g id="Group-2-Copy" transform="translate(64.000000, 68.000000)">
                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="36" height="36" rx="4" />
                        <g id="Group-10" transform="translate(6.000000, 6.000000)">
                            <g id="Group-3">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                </mask>
                                <g id="Clip-2" />
                                <path d="M24.00004,12.8135414 C24.00004,19.9742733 19.1607519,24.00004 12.00002,24.00004 C4.83928807,24.00004 0,19.9742733 0,12.8135414 C0,5.38160897 4.57256762,0 12.00002,0 C19.4274724,0 24.00004,5.38160897 24.00004,12.8135414" id="Fill-1" fill="#999999" mask="url(#mask-2)" />
                            </g>
                            <path d="M7.33593223,17.5375572 C8.27441379,16.7437959 9.42753571,15.8081943 11.9793,15.8740345 C14.3508239,15.9353146 15.3665856,16.4018753 16.6658678,17.5374772 C16.8536281,17.7016375 17.1109885,17.6688374 17.2553088,17.5036372 C17.3551489,17.389317 17.380589,17.2349967 17.3360289,17.1005965 C16.5833076,14.8504328 14.384264,13.9081912 12.0213,13.8790711 C9.32881555,13.8459511 7.37377229,15.1974733 6.66577111,17.1006765 C6.58689098,17.3125969 6.69481116,17.5483572 6.90681151,17.6272374 C7.04449174,17.6784375 7.19217199,17.6590774 7.33593223,17.5375572" id="Fill-4" fill="#FFFFFF" />
                            <path d="M8.88469481,10.5742896 C8.41861403,11.7029315 6.7813313,11.7029315 6.31525053,10.5742896 C6.13581023,10.1397289 6.06781011,9.66964812 6.11381019,9.20332734 C6.28445047,7.47228445 8.91549486,7.47228445 9.08613514,9.20332734 C9.13205522,9.66924812 9.06437511,10.1391689 8.88469481,10.5742896" id="Fill-6" fill="#FFFFFF" />
                            <path d="M15.1153452,10.5742896 C15.581426,11.7029315 17.2187087,11.7029315 17.6847895,10.5742896 C17.8642298,10.1397289 17.9322299,9.66964812 17.8862298,9.20332734 C17.7155895,7.47228445 15.0845451,7.47228445 14.9139049,9.20332734 C14.8679848,9.66924812 14.9356649,10.1391689 15.1153452,10.5742896" id="Fill-8" fill="#FFFFFF" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const UnhappyGrey = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Unhappy({ ...innerProps, ...props });
    }}
    />
);

export default UnhappyGrey;
