import { Dispatch } from 'redux';
import { Buckets, Pagination } from '../library/Types';
import {
    APPLY_FILTERS,
    CLEAR_SELECTION,
    END_LOADING,
    RESET_FILTERS,
    RESET_PAGINATION,
    SET_FILTERS,
    SET_GENERIC_DATA,
    SET_GENERIC_SUMMARY,
    SET_PAGINATION,
    START_LOADING,
} from '../reducers/redux-constants';
import { GenericAction } from '../reducers/redux.types';

export const setFilters = (bucketId: Buckets, filters: any) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        data: filters,
        type: SET_FILTERS,
    };
    dispatch(action);
};

export const clearSelection = (bucketId: Buckets) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: CLEAR_SELECTION,
    };
    dispatch(action);
};

export const resetFilters = (bucketId: Buckets) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: RESET_FILTERS,
    };
    dispatch(action);
};

export const applyFilters = (bucketId: Buckets) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: APPLY_FILTERS,
    };
    dispatch(action);
};

export const setPagination = (
    bucketId: Buckets,
    pagination: Partial<Pagination>,
) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: SET_PAGINATION,
        data: pagination,
    };
    dispatch(action);
};

export const resetPagination = (bucketId: Buckets) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: RESET_PAGINATION,
    };
    dispatch(action);
};

export const startLoading = (bucketId: Buckets) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: START_LOADING,
    };
    dispatch(action);
};

export const endLoading = (bucketId: Buckets) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        bucketId,
        type: END_LOADING,
    };
    dispatch(action);
};

export const setGenericData = (bucketId: Buckets, data: any) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        data,
        bucketId,
        type: SET_GENERIC_DATA,
    };
    dispatch(action);
};

export const setGenericSummary = (bucketId: Buckets, data: any) => (dispatch: Dispatch) => {
    const action: GenericAction = {
        data,
        bucketId,
        type: SET_GENERIC_SUMMARY,
    };
    dispatch(action);
};
