import * as React from 'react';
import { Marker } from 'react-map-gl';

import { IUIMapMarker } from './utils/types';

const UIMapMarker = (props: IUIMapMarker) => {
    const {
        coordinates,
        offset,
        color,
        ...rest
    } = props;

    return (
        <Marker
            longitude={coordinates[0]}
            latitude={coordinates[1]}
            offset={offset}
            color={color}
            {...rest}
        />
    );
};

export default UIMapMarker;
