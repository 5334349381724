/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const VeryHappyColored = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Very Happy</title>
        <defs>
            <polygon id="path-1" points="0 0 36.00006 0 36.00006 36 0 36" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-282.000000, -293.000000)">
                <g id="Group-14" transform="translate(282.000000, 293.000000)">
                    <g id="Group-3">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g id="Clip-2" />
                        <path d="M36.00006,19.220372 C36.00006,29.9614699 28.7411279,36 18.00003,36 C7.2589321,36 0,29.9614699 0,19.220372 C0,8.07247345 6.85885143,-6.00001e-05 18.00003,-6.00001e-05 C29.1412086,-6.00001e-05 36.00006,8.07247345 36.00006,19.220372" id="Fill-1" fill="#FED132" mask="url(#mask-2)" />
                    </g>
                    <path d="M28.2327231,19.4109684 C25.1165579,18.2656864 21.2775115,17.8839658 18.000066,17.8785658 C14.7015005,17.8688458 10.8436141,18.2701265 7.76740895,19.4109684 C6.45448676,19.8984092 7.0177677,21.1506113 7.23256805,21.7506123 C8.84429074,26.2532598 12.9701376,29.4996252 18.000066,29.4930352 C22.6986738,29.4645851 26.9656409,26.7411806 29.0102044,21.0790911 C29.2538048,20.40445 28.9070042,19.6587688 28.2327231,19.4109684" id="Fill-4" fill="#802D21" />
                    <path d="M27.1386932,20.3125179 C24.6651291,19.5247165 21.3772436,19.0744758 17.9604779,19.0744758 C14.4819121,19.0744758 11.2617068,19.5230365 8.78562264,20.3349579 C8.92698288,21.3455996 10.0702248,22.0744758 17.9604779,22.0744758 C25.9091712,22.0744758 27.010893,21.3349196 27.1386932,20.3125179" id="Fill-6" fill="#FFFFFF" />
                    <path d="M22.7023098,27.1113812 C22.0082287,25.7556189 19.184144,24.9165775 17.977422,26.2722198 C16.77082,24.9165775 13.9466152,25.7556189 13.2525341,27.1113812 C16.1614589,28.6837438 19.781505,28.6901038 22.7023098,27.1113812" id="Fill-8" fill="#F55151" />
                    <path d="M11.7710356,3.44317774 C9.85919243,3.82345837 9.07175112,5.08010047 8.50691018,6.07418212 C7.60462867,5.37182095 6.39622666,4.51225952 4.48438347,4.89254015 C2.26017977,5.33498089 0.817777363,7.62746471 1.29801816,10.0422287 C1.99245932,13.5332746 7.16590794,15.7739183 9.50651184,16.4739995 C10.219433,16.6872398 10.9861143,16.5347196 11.5631953,16.0649188 C13.4577584,14.5224362 17.379845,10.4725495 16.6855238,6.98150364 C16.205163,4.56673961 13.9951193,3.000737 11.7710356,3.44317774" id="Fill-10" fill="#F55151" />
                    <path d="M24.2290244,3.44317774 C26.1408676,3.82345837 26.9283089,5.08010047 27.4931498,6.07418212 C28.3954313,5.37182095 29.6038333,4.51225952 31.5156765,4.89254015 C33.7398802,5.33498089 35.1822826,7.62746471 34.7020418,10.0422287 C34.0076007,13.5332746 28.8341521,15.7739183 26.4935482,16.4739995 C25.780627,16.6872398 25.0139457,16.5347196 24.4368647,16.0649188 C22.5423016,14.5224362 18.620215,10.4725495 19.3145362,6.98150364 C19.794897,4.56673961 22.0049407,3.000737 24.2290244,3.44317774" id="Fill-12" fill="#F55151" />
                </g>
            </g>
        </g>
    </svg>
);

const VeryHappy = (props: any) => (
    <Icon component={(innerProps: any) => {
        return VeryHappyColored({ ...innerProps, ...props });
    }}
    />
);

export default VeryHappy;
