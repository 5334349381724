import { API_BASE_URL, BUSINESS_BOOKING_API_URL } from '../library/globals';
import { GET, POST } from './api-hander.service';
import {
    WALLET_FETCH_CNC,
    WALLET_LEDGER,
    WALLET_WEIGHT_MISMATCH,
    WALLET_LEDGER_DOWNLOAD,
    BLOCKED_LEDGER,
    BLOCKED_WALLET_LEDGER_DOWNLOAD,
} from './api.constants';

export const fetchWalletLedger = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${WALLET_LEDGER}`, params);
};

export const fetchBlockedLedger = async (params: any) => {
    return GET(`${BUSINESS_BOOKING_API_URL}${BLOCKED_LEDGER}`, params);
};

export const fetchCncData = async () => {
    return GET(`${API_BASE_URL}${WALLET_FETCH_CNC}`, {});
};

export const fetchWalletWeightMismatch = async () => {
    return GET(`${BUSINESS_BOOKING_API_URL}${WALLET_WEIGHT_MISMATCH}`, {});
};

export const downloadWalletLedger = async (params: any, isBlob: boolean = true) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${WALLET_LEDGER_DOWNLOAD}`, params, isBlob);
};

export const downloadBlockedWalletLedger = async (params: any, isBlob: boolean = true) => {
    return POST(`${BUSINESS_BOOKING_API_URL}${BLOCKED_WALLET_LEDGER_DOWNLOAD}`, params, isBlob);
};
