import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Form,
    FormInstance,
} from 'antd';
import GenericField from './generic-field';

const styles = () => ({});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const PickupScheduleField = (props: IProps) => {
    const {
        form,
        showScheduleOnlyWhenHyperlocal,
        params,
    } = props;

    const {
        MovementType,
        PickupSchedule,
    } = formFields;

    const consignmentType = Form.useWatch(MovementType.key, form);
    const field = PickupSchedule;

    if (showScheduleOnlyWhenHyperlocal && consignmentType !== 'hyperlocal') {
        return null;
    }

    return (
        <GenericField
            field={field}
            form={form}
            params={{
                ...params,
                shouldNotSelectPastDate: true,
                isRequired: () => false,
            }}
        />
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const consignmentConfig = config?.customer_portal_consignment_config;
    const partsToShowSingleConsignment = consignmentConfig?.parts_to_show_single_consignment;
    const showScheduleOnlyWhenHyperlocal = partsToShowSingleConsignment?.scheduleOnlyWhenHyperlocal;
    return {
        showScheduleOnlyWhenHyperlocal,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PickupScheduleField);
