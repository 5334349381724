/* eslint-disable max-len */

import Icon from '@ant-design/icons';
import * as React from 'react';

const CollapseMenu = (props: any) => (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.83346 8.87955C0.738538 8.95455 0.624866 8.99205 0.512366 8.99205C0.362366 8.99205 0.214709 8.92643 0.112756 8.80104C-0.0641969 8.58072 -0.0290406 8.25846 0.191272 8.08151L4.66198 4.48619L0.213537 0.910803C-0.00677531 0.73385 -0.0419316 0.411584 0.135022 0.191272C0.311975 -0.0290406 0.63424 -0.0641969 0.854553 0.112756L5.79987 4.08658C5.92057 4.18268 5.99088 4.33033 5.99088 4.48502C5.99088 4.64088 5.92057 4.78737 5.79987 4.88463L0.83346 8.87955ZM6.83346 8.87955C6.73854 8.95455 6.62487 8.99205 6.51237 8.99205C6.36237 8.99205 6.21471 8.92643 6.11276 8.80104C5.9358 8.58072 5.97096 8.25846 6.19127 8.08151L10.662 4.48619L6.21354 0.910803C5.99322 0.73385 5.95807 0.411584 6.13502 0.191272C6.31197 -0.0290406 6.63424 -0.0641969 6.85455 0.112756L11.7999 4.08658C11.9206 4.18268 11.9909 4.33033 11.9909 4.48502C11.9909 4.64088 11.9206 4.78737 11.7999 4.88463L6.83346 8.87955Z" fill="currentColor" />
    </svg>
);

const CollapseMenuIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return CollapseMenu({ ...innerProps, ...props });
    }}
    />
);

export default CollapseMenuIcon;
