import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { advanceShippingNoteStyle } from './advanceShippingNote.styles';
import {
    PageOptions,
    getAsnBucket,
    ASN_PAGE_KEY,
    asnChildColumns,
    asnParentColumns,
    dateKeys,
    epoachDates,
    cancelAsnText,
} from './advanceShippingNote.constants';
import Paginator from '../common/paginator';
import FilterBlue from '../../assets/filter-blue';
import { ReduxStore } from '../../reducers/redux.types';
import moment from 'moment';
import { PageKeys } from './advanceShippingNote.types';
import { Buckets } from '../../library/Types';
import {
    getAdvanceShippingNoteRoute,
    getAsnExcelUploadRoute,
} from '../../routing/routing-helper';
import { Routes } from '../../library/constants';
import {
    getChildCustomerDetails,
} from 'network/settings-product';
import {
    Table,
    DatePicker,
    Input,
    Tooltip,
    Dropdown,
    Menu,
    Button,
    message,
    Select,
    Modal,
} from 'antd';
import { NAVBAR_HEIGHT } from 'library/globals';
import {
    EditOutlined,
    CloseOutlined,
    ReloadOutlined,
    FolderAddOutlined,
} from '@ant-design/icons';
import AsnExcelUpload from './asn-excel-upload';
import AddAsnForm from './addAsnForm';
import AsnBulkUpload from './asn-bulk-upload';
import AsnCreateCn from './asn-create-cn';
import AsnCreateCnBulkUpload from './asn-create-cn-bulk-upload';
import {
    applyFilters,
    setFilters,
    setPagination,
} from '../../actions/generic-action';
import { bindActionCreators } from 'redux';
import AsnMoreFilters from './asn-more-filters';
import { loadAsn } from '../../actions/asn-actions';
import { cancelAsn } from 'network/asn.api';
import FailureArrayHandler from '../common/FailureArrayHandler';


const { Option } = Select;

const {
    useState,
} = React;

const AdvanceShippingNotePage = (props: any) => {
    const {
        classes,
        history,
        master,
        filters,
        defaultFilters,
        setfilters,
        applyfilters,
        setpagination,
        pagination,
        loadasn,
        loading,
        summary,
        asnList,
        uiTheme,
    } = props;

    const { config } = master;
    const { bucketSelected } = filters;

    const getInitialPage = (): PageKeys => {
        switch (history.location.pathname) {
            case `/${Routes.ASN_EXCEL_UPLOAD}`: return 'ExcelUpload';
            case `/${Routes.ASN}`:
            default: return 'Asn';
        }
    };

    const checkIsParentCustomer = () => {
        const storage = window.localStorage;
        const parentId = JSON.parse(storage.getItem('parentId') as string);
        if (parentId) {
            return false;
        }
        return true;
    };

    const [moreFilterVisible, setMoreFiltersVisible] = useState<boolean>(false);
    const [pageSelected, setPageSelected] = useState<PageKeys>(getInitialPage());
    const [isAddAsnFormVisble, setAddAsnFormVisible] = useState<boolean>(false);
    const [isAsnBulkUploadVisble, setAsnBulkUploadVisible] = useState<boolean>(false);
    const [isAsnCreateCnVisible, setAsnCreateCnVisible] = useState<boolean>(false);
    const [selectedAsn, setSelectedAsnData] = useState<Record<any, any>>({});
    const [isAsnCreateCnBulkUploadVisible, setAsnCreateCnBulkUploadVisible] = useState<boolean>(false);
    const [vendors, setVendors] = React.useState<any>([]);
    const [asnSelected, setAsnSelected] = useState<string[]>([]);
    const [isCancelAsnModalVisible, setCancelAsnModalVisible] = useState<boolean>(false);
    const [asnData, setAsnData] = useState<string[]>([]);
    const [cancelLoading, setCancelLoading] = useState<boolean>(false);
    const [cancelErrorModalVisible, setCancelErrorModalVisible] = useState<boolean>(false);
    const [cancelFailureArray, setCancelFailureArray] = useState<string[]>([]);

    const isParentCustomer = checkIsParentCustomer();

    const loadVendors = async () => {
        const response = await getChildCustomerDetails();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setVendors(response?.data || []);
        }
    };


    React.useEffect(() => {
        loadVendors();
    }, []);

    React.useEffect(() => {
        if (pageSelected !== ASN_PAGE_KEY) {
            return;
        }
        loadasn();
    }, [filters]);


    const handlePageChange = (page: PageKeys) => {
        if (page === pageSelected) {
            return;
        }
        let nextPage = getAdvanceShippingNoteRoute();
        switch (page) {
            case 'ExcelUpload': nextPage = getAsnExcelUploadRoute(); break;
            case 'Asn':
            default: nextPage = getAdvanceShippingNoteRoute(); break;
        }
        setPageSelected(page);
        history.push(nextPage);
    };


    const renderPageOption = (page: PageKeys) => {
        return (
            <div
                key={page}
                onClick={() => handlePageChange(page)}
                className={pageSelected === page ? classes.selectedPage : classes.pageName}
            >
                {PageOptions[page]}
            </div>
        );
    };

    const renderPageFilter = () => {
        return (
            <div className={classes.pageFilter}>
                {Object.keys(PageOptions).map((page) => renderPageOption(page as PageKeys))}
            </div>
        );
    };

    const handleBucketChange = (bucket: any) => {
        if (bucket === filters.bucketSelected) {
            return;
        }
        const newFilters = {
            ...filters,
            bucketSelected: bucket,
        };
        setfilters(Buckets.ASN, newFilters);
        applyfilters(Buckets.ASN);
    };

    const renderBucket = (bucket: any) => {
        const AsnBucket = getAsnBucket(isParentCustomer);
        let bucketClass = classes.bucket;
        let countClass = classes.count;
        if (bucket === bucketSelected) {
            bucketClass = [classes.bucketSelected, classes.bucket].join(' ');
            countClass = [classes.count, classes.countSelected].join(' ');
        }
        return (
            <div
                key={bucket}
                className={bucketClass}
                onClick={() => handleBucketChange(bucket)}
            >
                <span
                    className={classes.bucketName}
                >
                    {AsnBucket[bucket].name}
                </span>
                <span className={countClass}>{summary[bucket] || 0}</span>
            </div>
        );
    };

    const renderBuckets = () => {
        const AsnBucket = getAsnBucket(isParentCustomer);
        return (
            <div className={classes.bucketRow}>
                {Object.keys(AsnBucket)
                    .map((bucket) => renderBucket(bucket as any))}
            </div>
        );
    };

    const fetchDate = (dateArr: any[]) => {
        const date: any = { start: moment(), end: moment() };
        if (dateArr?.length === 2) {
            date.start = dateArr[0];
            date.end = dateArr[1];
        }
        return date;
    };


    const handleDateSelect:any = (dateArr: any[]) => {
        const date = fetchDate(dateArr);
        if (Math.abs(moment(date.start).diff(date.end, 'days')) > 31) {
            message.error('Date Range Cannot be more than 31 Days');
            return;
        }
        const date_obj = {
            start: date.start.format('YYYY-MM-DD'),
            end: date.end.format('YYYY-MM-DD'),
        };
        const newFilters = {
            ...filters,
            uploadStartDate: date_obj.start,
            uploadEndDate: date_obj.end,
        };
        setfilters(Buckets.ASN, newFilters);
        applyfilters(Buckets.ASN);
    };

    const renderDateField = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Uploaded Date
                </span>
                <span>
                    <DatePicker.RangePicker
                        value={[
                            moment(filters.uploadStartDate),
                            moment(filters.uploadEndDate),
                        ]}
                        format="DD/MM/YYYY"
                        onChange={handleDateSelect}
                        className={classes.datePicker}
                    />
                </span>
            </div>
        );
    };

    const handlePagination = (nextOrPrev: any) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.ASN, newPagination);
    };


    const handleVendorCodeSearch = (val: any) => {
        let newFilters = {};
        if (val) {
            newFilters = {
                ...filters,
                vendorCodeIdList: isParentCustomer ? [val] : undefined,
            };
        } else {
            newFilters = {
                ...filters,
                vendorCodeIdList: isParentCustomer ? [] : undefined,
            };
        }
        setfilters(Buckets.ASN, newFilters);
        applyfilters(Buckets.ASN);
    };


    const renderVendorCodeField = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    Vendor Code
                </span>
                <span style={{ marginRight: '5px' }}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Type"
                        optionFilterProp="children"
                        filterOption={
                            (input, option) => (option?.children as any).toLowerCase().includes(input?.toLowerCase())
                        }
                        notFoundContent="NA"
                        onChange={handleVendorCodeSearch}
                    >
                        { vendors && vendors.map((item:any) => {
                            return <Option value={item.id}>{item.code}</Option>;
                        })}
                    </Select>
                </span>
            </div>
        );
    };

    const handleChangeAsnNo = (e: any) => {
        const newFilters = {
            ...filters,
            asnNumberList: [e.target.value],
        };
        setfilters(Buckets.ASN, newFilters);
        applyfilters(Buckets.ASN);
    };

    const handleAsnOnChange = (e: any) => {
        if (e.target.value === '') {
            const newFilters = {
                ...filters,
                asnNumberList: [],
            };
            setfilters(Buckets.ASN, newFilters);
            applyfilters(Buckets.ASN);
        }
    };

    const renderAsnNoField = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    ASN No.
                </span>
                <span style={{ marginRight: '5px' }}>
                    <Input
                        type="text"
                        placeholder="Type & enter"
                        onChange={handleAsnOnChange}
                        onPressEnter={handleChangeAsnNo}
                    />
                </span>
            </div>
        );
    };

    const isMoreFilterAdded = () => {
        const dashboardFilterList = isParentCustomer
            ? ['uploadEndDate', 'uploadStartDate', 'asnNumberList', 'vendorCodeIdList']
            : ['uploadEndDate', 'uploadStartDate', 'asnNumberList'];
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (dashboardFilterList.includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const renderMoreFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        return (
            <div
                className={classes.moreFilter}
                onClick={() => setMoreFiltersVisible(true)}
                style={{
                    color: filterApplied ? uiTheme.primaryColor : '#111111',
                    fontWeight: filterApplied ? 600 : 500,
                }}
            >
                <FilterBlue style={{ marginRight: 2 }} />
                <span>More Filters</span>
            </div>
        );
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setfilters(Buckets.ASN, {
                        ...defaultFilters,
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        asnNumberList: filters.asnNumberList,
                        vendorCodeIdList: isParentCustomer ? filters.vendorCodeIdList : undefined,
                        uploadStartDate: moment().format('YYYY-MM-DD'),
                        uploadEndDate: moment().format('YYYY-MM-DD'),
                        isAsnDateApplied: false,
                    });
                    applyfilters(Buckets.ASN);
                }}
            >
                Reset Filter
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderAsnNoField()}
                {renderDateField()}
                {isParentCustomer && renderVendorCodeField()}
                {renderMoreFiltersOption()}
                {renderResetFiltersOption()}
            </div>
        );
    };

    const renderRefresh = () => {
        return (
            <Tooltip title="Reload">
                <Button
                    size="small"
                    type="ghost"
                    style={{
                        color: uiTheme.primaryColor,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        handlePagination('first');
                        loadasn();
                    }}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };


    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination.currentPageNumber}
                isNextPresent={pagination.isNextPresent}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
                onFirstClick={() => handlePagination('first')}
            />
        );
    };

    const handleCancelAsnMultiple = () => {
        setCancelAsnModalVisible(true);
    };

    const renderCancelButton = () => {
        return (
            <Button
                className={classes.filterButton}
                onClick={handleCancelAsnMultiple}
                disabled={!asnSelected.length}
            >
                Cancel
            </Button>
        );
    };

    const handleOpenAddAsnForm = () => {
        setAddAsnFormVisible(true);
    };

    const handleCloseAddAsnForm = (flag: any) => {
        if (flag) {
            loadasn();
        }
        setAsnData([]);
        setAddAsnFormVisible(false);
    };

    const handleOpenAsnBulkUpload = () => {
        setAsnBulkUploadVisible(true);
    };

    const handleCloseAsnBulkUpload = () => {
        setAsnBulkUploadVisible(false);
    };


    const menu = (
        <Menu>
            <Menu.Item
                key="single"
                onClick={() => handleOpenAddAsnForm()}
            >
                Single
            </Menu.Item>
            <Menu.Item
                key="excel_upload"
                onClick={() => handleOpenAsnBulkUpload()}
            >
                Excel Upload
            </Menu.Item>
        </Menu>
    );


    const renderAddAsnButton = () => {
        return (
            <Dropdown
                overlay={menu}
                trigger={['hover']}
            >
                <Button
                    className={classes.filterButton}
                    type="primary"
                >
                    Add ASN
                </Button>
            </Dropdown>
        );
    };

    const handleOpenAsnCreateCnBulkUpload = () => {
        setAsnCreateCnBulkUploadVisible(true);
    };

    const handleCloseAsnCreateCnBulkUpload = () => {
        setAsnCreateCnBulkUploadVisible(false);
    };

    const renderConvertToCNButton = () => {
        return (
            <Button
                className={classes.filterButton}
                type="primary"
                onClick={handleOpenAsnCreateCnBulkUpload}
            >
                Upload Excel
            </Button>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {isParentCustomer && renderCancelButton()}
                {isParentCustomer && renderAddAsnButton()}
                {!isParentCustomer && renderConvertToCNButton()}
                {renderRefresh()}
                {renderPaginator()}
            </div>
        );
    };


    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderBuckets()}
                {renderExtraFilters()}
            </div>
        );
    };

    const handleOpenAsnCreateCn = (row: any) => {
        setAsnCreateCnVisible(true);
        setSelectedAsnData(row);
    };

    const handleCloseAsnCreateCn = (isSuccess: boolean) => {
        if (isSuccess) {
            handlePagination('first');
            loadAsn();
        }
        setAsnCreateCnVisible(false);
    };

    const handleEditAsn = (row: any) => {
        setAsnData(row);
        setAddAsnFormVisible(true);
    };

    const handleCancelAsn = (row: any) => {
        setAsnSelected([row.asn_number]);
        setCancelAsnModalVisible(true);
    };

    const renderDropdown = (row: any) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {
                    isParentCustomer && (
                        <EditOutlined
                            style={{ cursor: 'pointer', marginRight: 10 }}
                            onClick={() => handleEditAsn(row)}
                        />
                    )
                }
                {
                    isParentCustomer && (
                        <CloseOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCancelAsn(row)}
                        />
                    )
                }
                {
                    !isParentCustomer && (
                        <FolderAddOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleOpenAsnCreateCn(row)}
                        />
                    )
                }
            </div>
        );
    };

    const formatDateTime = (dateTime: any) => {
        return dateTime ? moment(dateTime).format('DD MMM YYYY, HH:mm') : 'Not Available';
    };

    const renderColumn = (text: any, row: any, column: any) => {
        if (dateKeys.includes(column.column_id)) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return <div>{dateString}</div>;
        }
        if (column.column_id === 'actions') {
            return renderDropdown(row);
        }
        if (!text || text === null) {
            return <div className={classes.cellNa}>-NA-</div>;
        }

        if (epoachDates.includes(column.column_id)) {
            const dateTimeString = formatDateTime(text);
            return <div>{dateTimeString}</div>;
        }

        return <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', display: 'inline-block' }}>{text}</div>;
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'actions': return 90;
            default: return 120;
        }
    };

    const getColumns = (): any[] => {
        const { asn_config } = config || {};
        const { full_column_list_by_bucket } = asn_config || {};

        const actionColumn = {
            column_id: 'actions',
            pretty_name: 'Actions',
        };
        let columnLables = [];


        if (full_column_list_by_bucket && full_column_list_by_bucket[bucketSelected as any].length) {
            columnLables.push(...full_column_list_by_bucket[bucketSelected as any]);
        } else {
            columnLables = isParentCustomer ? [...asnParentColumns] : [...asnChildColumns];
        }

        if ((isParentCustomer && bucketSelected === 'customer_portal_asn_uploaded')
            || (!isParentCustomer && bucketSelected === 'customer_portal_asn_assigned')) {
            columnLables.push(actionColumn);
        }

        const columns: any = columnLables.map((column: any) => {
            return {
                key: column.column_id,
                title: column.pretty_name,
                dataIndex: column.column_id,
                width: getWidth(column.column_id),
                ellipsis: true,
                align: 'center',
                fixed: column.column_id === 'actions' ? 'right' : undefined,
                render: (text: any, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const renderExcelUpload = () => {
        if (pageSelected !== 'ExcelUpload') {
            return null;
        }
        return (
            <AsnExcelUpload />
        );
    };

    const rowSelection = {
        asnSelected,
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            const curr_list:any = [];
            setAsnSelected(selectedRowKeys);
            selectedRows?.forEach((elem:any) => {
                curr_list.push(elem.asn_number);
            });
            setAsnSelected(curr_list);
        },
    };


    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                className={classes.tableRowColor}
                rowKey={(record: any) => `${record.asn_number}`}
                columns={getColumns()}
                scroll={{
                    y: `calc((100vh - ${NAVBAR_HEIGHT}px) - 200px)`,
                    x: 1200,
                }}
                rowSelection={rowSelection}
                locale={{
                    emptyText: <div className={classes.cellNa}>—NA—</div>,
                }}
                dataSource={asnList}
            />
        );
    };

    const renderMoreFilters = () => {
        if (!moreFilterVisible) {
            return null;
        }
        return (
            <AsnMoreFilters
                resetVisible={isMoreFilterAdded()}
                config={config}
                bucketSelected={bucketSelected}
                onClose={() => setMoreFiltersVisible(false)}
            />
        );
    };


    const renderAsnMain = () => {
        if (pageSelected !== ASN_PAGE_KEY) {
            return null;
        }
        return (
            <>
                {renderFilters()}
                {renderTable()}
            </>
        );
    };

    const renderAddAsnForm = () => {
        if (!isAddAsnFormVisble) {
            return null;
        }

        return (
            <AddAsnForm
                vendors={vendors || []}
                asnDetails={asnData}
                onClose={handleCloseAddAsnForm}
            />
        );
    };

    const handleCloseCancelErrorModal = () => {
        setCancelErrorModalVisible(false);
    };

    const handleOk = async (data: any) => {
        setCancelLoading(true);
        const cancel_list: any[] = [];
        if (data && data.length > 0) {
            data.forEach((item: any) => {
                cancel_list.push({ asn_number: item });
            });
        }
        const body = {
            asn_cancelation_list: cancel_list,
        };
        const response = await cancelAsn(body);
        if (response?.isSuccess) {
            if (response?.data?.failures?.length) {
                setCancelErrorModalVisible(true);
                setCancelFailureArray(response.data.failures);
            } else {
                message.success('ASN(s) Cancelled Successfully');
                setCancelAsnModalVisible(false);
                loadasn();
            }
        } else {
            message.error(response.errorMessage);
        }
        setCancelLoading(false);
    };

    const handleCloseCancelModal = () => {
        setCancelAsnModalVisible(false);
    };

    const renderCancelModal = () => {
        if (!isCancelAsnModalVisible) {
            return null;
        }
        return (
            <div>
                <Modal
                    visible={isCancelAsnModalVisible}
                    title="Confirm Action ?"
                    width="25%"
                    confirmLoading={cancelLoading}
                    okText="Submit"
                    onCancel={handleCloseCancelModal}
                    onOk={() => handleOk(asnSelected)}
                >
                    <p>{cancelAsnText}</p>
                </Modal>
            </div>
        );
    };

    const renderAsnBulkUpload = () => {
        if (!isAsnBulkUploadVisble) {
            return null;
        }
        return (
            <AsnBulkUpload
                onClose={handleCloseAsnBulkUpload}
                loadAsn={loadasn}
            />
        );
    };

    const renderAsnCreateCnForm = () => {
        if (!isAsnCreateCnVisible) {
            return null;
        }

        return (
            <AsnCreateCn
                onClose={handleCloseAsnCreateCn}
                data={selectedAsn}
                loadAsn={loadasn}
            />
        );
    };

    const renderAsnCreateCnBulkUpload = () => {
        if (!isAsnCreateCnBulkUploadVisible) {
            return null;
        }

        return (
            <AsnCreateCnBulkUpload
                onClose={handleCloseAsnCreateCnBulkUpload}
                loadAsn={loadasn}
            />
        );
    };

    const renderCancelErrorModal = () => {
        if (!cancelErrorModalVisible) {
            return null;
        }
        return (
            <FailureArrayHandler
                onModalClose={handleCloseCancelErrorModal}
                failureArray={cancelFailureArray}
                isVisible={cancelErrorModalVisible}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderPageFilter()}
            {renderAsnMain()}
            {renderExcelUpload()}
            {renderAsnBulkUpload()}
            {renderAddAsnForm()}
            {renderAsnCreateCnForm()}
            {renderAsnCreateCnBulkUpload()}
            {renderMoreFilters()}
            {renderCancelModal()}
            {renderCancelErrorModal()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master, generic, uiTheme } = state;
    const { ASN } = generic;
    const {
        appliedFilters,
        defaultFilters,
        pagination,
        loading,
        summary,
        data,
    } = ASN;

    return {
        master,
        loading,
        defaultFilters,
        pagination,
        summary,
        filters: appliedFilters,
        asnList: data,
        uiTheme,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        applyfilters: applyFilters,
        loadasn: loadAsn,
        setpagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: advanceShippingNoteStyle,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(AdvanceShippingNotePage);

