import * as React from 'react';
import {
    Modal,
    Row,
    Col,
    Typography,
} from 'antd';
import {
    LinkOutlined,
    DownloadOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import GenericHoc from '../common/generic-hoc';
import { HocOptions } from '../common/generic-hoc.types';
import { ReduxStore } from '../../reducers/redux.types';
import { StylesProps } from '../../theme/jss-types';
import lodash from 'lodash';

const { Text } = Typography;

const styles = {
    grayCircle: {
        width: '32px',
        height: '32px',
        fontSize: '14px',
        backgroundColor: '#F5F5F5',
        borderRadius: '64px',
        marginRight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

interface IDocumentTypeProps extends StylesProps<typeof styles> {
    documentType: string;
    documentUrls: string[];
    index: number;
    documentIdToNameMapping: { [key: string]: string };
}

const DocumentType = (props: IDocumentTypeProps) => {
    const {
        documentType,
        documentUrls = [],
        index,
        classes,
        documentIdToNameMapping,
    } = props;
    const rows = [];
    for (let i = 0; i < documentUrls.length; i += 3) {
        const rowData = documentUrls.slice(i, i + 3);
        rows.push(rowData);
    }

    return (
        <div style={{ marginTop: '10px' }}>
            <div
                style={{
                    display: 'flex',
                    marginTop: '10px',
                    alignItems: 'center',
                }}
            >
                <div className={classes.grayCircle}>{index + 1}</div>
                <div style={{ color: '#111111', fontSize: '12px' }}>
                    {documentIdToNameMapping[documentType] || documentType}
                </div>
            </div>
            <>
                {rows.map((row) => (
                    <Row
                        gutter={[16, 16]}
                        style={{ marginTop: '10px', marginLeft: '42px' }}
                    >
                        {row.map((item, i) => (
                            <Col key={item[i]} span={8}>
                                <div key={item[i]}>
                                    <a href={item} target="_blank" rel="noreferrer">
                                        <>
                                            <LinkOutlined color="#666666" />
                                            <Text
                                                style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    maxWidth: '80%',
                                                    textOverflow: 'ellipsis',
                                                    color: '#006EC3',
                                                }}
                                                ellipsis={{
                                                    tooltip: 'Click to Open',
                                                }}
                                            >
                                                {item}
                                            </Text>
                                        </>
                                    </a>
                                </div>
                            </Col>
                        ))}
                    </Row>
                ))}
            </>
        </div>
    );
};

const DocumentDetailsModal = (props: any) => {
    const handleModalClose = () => {
        props.onModalClose(false);
    };

    const { isVisible, documentDetails = [], classes } = props;
    const documentDetailsByType = lodash.groupBy(documentDetails, 'type');

    const handleDownloadAll = async () => {
        const documentUrls: string[] = [];
        Object.keys(documentDetailsByType).forEach((key) => {
            documentUrls.push(...documentDetailsByType[key].map((item) => item.url));
        });
        const promises = documentUrls.map(async (url) => {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                const link = document.createElement('a');
                const downloadUrl = window.URL.createObjectURL(blob);
                link.href = downloadUrl;
                link.download = '';
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
            } catch (err) {
                console.error('Error in downloading document', err);
            }
        });
        await Promise.all(promises);
    };

    return (
        <Modal
            title={
                (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: '16px' }}>Documents</div>
                        <div style={{ flexGrow: 1 }} />
                        <div
                            style={{
                                marginRight: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: '#006EC3',
                            }}
                            onClick={handleDownloadAll}
                        >
                            <DownloadOutlined
                                style={{ color: '#006EC3', marginRight: '5px' }}
                            />
                            <div>Download All</div>
                        </div>
                        <div>
                            <CloseOutlined
                                style={{ fontSize: '16px' }}
                                onClick={handleModalClose}
                            />
                        </div>
                    </div>
                )
            }
            visible={isVisible}
            onCancel={handleModalClose}
            width="50%"
            footer={null}
            centered
            closable={false}
        >
            {Object.keys(documentDetailsByType).map((key: string, index: number) => {
                const documentUrls = documentDetailsByType[key]?.map((document) => document.url) || [];
                return (
                    <DocumentType
                        documentType={key}
                        documentUrls={documentUrls}
                        index={index}
                        classes={classes}
                        documentIdToNameMapping={
                            props.documentIdToNameMapping
                        }
                    />
                );
            })}
        </Modal>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic, master } = state;
    const { SUPP_SERVICES } = generic;
    const { loading, data, pagination } = SUPP_SERVICES;
    const documentConfig = master?.config?.customer_portal_ops_config?.document_config || {};
    return {
        loading,
        SuppServicesData: data,
        uiTheme: state.uiTheme,
        pagination,
        availableDocumentTypesList: documentConfig?.config_value?.allowed_document_types_customer_portal || [],
        documentIdToNameMapping: master?.config?.available_document_types_id_to_name_mapping || {},
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const DocumentDetailsModalStyled = GenericHoc(hocConfig)(DocumentDetailsModal);
export default DocumentDetailsModalStyled;
