import * as React from 'react';

// load component
import FreshdeskJWT from './freshdesk-jwt';
import NotFound404 from '../common/not-found';


// component map
const componentMap: Record<string, any> = {
    freshdesk_jwt: FreshdeskJWT,
    invalid: () => { return (<div><NotFound404 /></div>); },
};


const SpLoginRequest = () => {
    const [componentName] = React.useState(() => {
        try {
            const urlParams = (window?.location?.pathname ?? '').split('/');
            if (urlParams.length !== 4) {
                return 'invalid';
            }

            const componentKey = `${urlParams[2]}_${urlParams[3]}`;

            return componentKey in componentMap ? componentKey : 'invalid';
        } catch (error) {
            return 'invalid';
        }
    });

    return (
        <div>{componentMap[componentName]()}</div>
    );
};

export default SpLoginRequest;
