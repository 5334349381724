/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Track = (props: any) => (
    <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Group</title>
        <g id="Uppdated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Create" transform="translate(-35.000000, -403.000000)">
                <g id="Group" transform="translate(36.000000, 403.000000)">
                    <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                    <g id="Group-37" transform="translate(0.000000, 2.000000)">
                        <path d="M19.8442464,19.9999938 L2.39863783,19.9999938 C1.56195508,19.9999938 0.883708676,19.337496 0.883708676,18.5202406 L0.883708676,1.47977177 C0.883708676,0.66251637 1.56195508,1.84969158e-05 2.39863783,1.84969158e-05 L19.8442464,1.84969158e-05 C20.6809292,1.84969158e-05 21.3591756,0.66251637 21.3591756,1.47977177 L21.3591756,18.5202406 C21.3591756,19.337496 20.6809292,19.9999938 19.8442464,19.9999938" id="Fill-1" fill="#D39C72" />
                        <path d="M18.5733597,19.9999938 L2.53985448,19.9999938 C1.70317173,19.9999938 1.02492532,19.337496 1.02492532,18.5202406 L1.02492532,1.47977177 C1.02492532,0.66251637 1.70317173,1.84969158e-05 2.53985448,1.84969158e-05 L18.5733597,1.84969158e-05 C19.4100425,1.84969158e-05 20.0882889,0.66251637 20.0882889,1.47977177 L20.0882889,18.5202406 C20.0882889,19.337496 19.4100425,19.9999938 18.5733597,19.9999938" id="Fill-3" fill="#E2AE83" />
                        <path d="M21.3591756,6.49339167 L21.3591756,1.65515336 C21.3591756,0.741035778 20.6004486,4.93251091e-05 19.6646011,4.93251091e-05 L2.57828318,4.93251091e-05 C1.6424357,4.93251091e-05 0.883708676,0.741035778 0.883708676,1.65515336 L0.883708676,18.344859 C0.883708676,19.2589766 1.6424357,19.999963 2.57828318,19.999963 L19.6646011,19.999963 C20.6004486,19.999963 21.3591756,19.2589766 21.3591756,18.344859 L21.3591756,17.4391883" id="Stroke-5" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" />
                        <path d="M18.1589271,10.807896 C18.1638554,13.3353762 21.0894361,15.7792504 21.0894361,15.7792504 C21.0894361,15.7792504 24.0049173,13.3242164 24,10.7966745 C23.9967745,9.21229034 22.6866133,7.93150222 21.0737187,7.93427226 C19.4615816,7.93735958 18.1560283,9.22338849 18.1589271,10.807896" id="Fill-7" fill="#D35B3A" />
                        <path d="M18.2220476,10.807896 C18.2269775,13.3353762 21.0006234,15.5952061 21.0006234,15.5952061 C21.0006234,15.5952061 23.0699535,13.3260044 23.0649759,10.7985242 C23.0617476,9.21401672 21.960836,7.93285866 20.6062368,7.93513691 C19.2521427,7.93779117 18.2190872,9.22345014 18.2220476,10.807896" id="Fill-9" fill="#FC734E" />
                        <path d="M18.1589271,10.807896 C18.1638554,13.3353762 21.0894361,15.7792504 21.0894361,15.7792504 C21.0894361,15.7792504 24.0049173,13.3242164 24,10.7966745 C23.9967745,9.21229034 22.6866133,7.93150222 21.0737187,7.93427226 C19.4615816,7.93735958 18.1560283,9.22338849 18.1589271,10.807896 Z" id="Stroke-11" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" />
                        <path d="M21.1698094,12.1226691 C20.6452021,12.1226691 20.219507,11.7068584 20.219507,11.1944322 C20.219507,10.6821293 20.6452021,10.2662569 21.1698094,10.2662569 C21.6943537,10.2662569 22.1201119,10.6821293 22.1201119,11.1944322 C22.1201119,11.7068584 21.6943537,12.1226691 21.1698094,12.1226691" id="Fill-13" fill="#F2B1B9" />
                        <path d="M21.1698094,12.1226691 C20.6452021,12.1226691 20.219507,11.7068584 20.219507,11.1944322 C20.219507,10.6821293 20.6452021,10.2662569 21.1698094,10.2662569 C21.6943537,10.2662569 22.1201119,10.6821293 22.1201119,11.1944322 C22.1201119,11.7068584 21.6943537,12.1226691 21.1698094,12.1226691 Z" id="Stroke-15" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.037018,13.8110552 C14.037018,13.606726 13.867409,13.4411169 13.6582857,13.4411169 L1.26244097,13.4411169 C1.05331762,13.4411169 0.883708676,13.606726 0.883708676,13.8110552 L0.883708676,18.3448343 C0.883708676,19.2589519 1.6424357,20 2.57828318,20 L13.6582857,20 C13.867409,20 14.037018,19.8343293 14.037018,19.6300617 L14.037018,13.8110552 Z" id="Fill-17" fill="#F9F9F9" />
                        <path d="M14.037018,13.8110552 C14.037018,13.606726 13.867409,13.4411169 13.6582857,13.4411169 L1.26244097,13.4411169 C1.05331762,13.4411169 0.883708676,13.606726 0.883708676,13.8110552 L0.883708676,18.3448343 C0.883708676,19.2589519 1.6424357,20 2.57828318,20 L13.6582857,20 C13.867409,20 14.037018,19.8343293 14.037018,19.6300617 L14.037018,13.8110552 Z" id="Stroke-19" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <line x1="9.27759028" y1="15.2986602" x2="11.9287163" y2="15.2986602" id="Stroke-21" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <line x1="2.71474674" y1="15.2986602" x2="5.87084915" y2="15.2986602" id="Stroke-23" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <line x1="2.71474674" y1="17.5459307" x2="11.9287352" y2="17.5459307" id="Stroke-25" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <line x1="7.59358343" y1="15.2986602" x2="7.59358343" y2="15.2986602" id="Stroke-27" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0,4.34282924 L0,1.6551657 C0,0.741048109 0.75872702,0 1.69457451,0 L20.5483097,0 C21.4841572,0 22.2428842,0.741048109 22.2428842,1.6551657 L22.2428842,4.34282924 L0,4.34282924 Z" id="Fill-29" fill="#D35B3A" />
                        <path d="M13.942518,8.77582478 L11.1214674,7.44830112 L8.30035367,8.77582478 C8.13118658,8.86374678 7.93519262,8.73026071 7.93519262,8.52710291 L7.93519262,2.46625548e-05 L14.307679,2.46625548e-05 L14.307679,8.52710291 C14.307679,8.73026071 14.1117482,8.86374678 13.942518,8.77582478" id="Fill-31" fill="#FCDE8B" />
                        <path d="M13.942518,8.77582478 L11.1214674,7.44830112 L8.30035367,8.77582478 C8.13118658,8.86374678 7.93519262,8.73026071 7.93519262,8.52710291 L7.93519262,2.46625548e-05 L14.307679,2.46625548e-05 L14.307679,8.52710291 C14.307679,8.73026071 14.1117482,8.86374678 13.942518,8.77582478 Z" id="Stroke-33" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0,3.97289092 L0,1.6551657 C0,0.741048109 0.75872702,0 1.69457451,0 L20.5483097,0 C21.4841572,0 22.2428842,0.741048109 22.2428842,1.6551657 L22.2428842,3.97289092 C22.2428842,4.17715853 22.0732753,4.34282924 21.864152,4.34282924 L0.37873229,4.34282924 C0.169608944,4.34282924 0,4.17715853 0,3.97289092 Z" id="Stroke-35" stroke="#000000" strokeWidth="0.631220483" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const TrackIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Track({ ...innerProps, ...props });
    }}
    />
);

export default TrackIcon;
