import * as React from 'react';
import withStyles from 'react-jss';
import {
    List, Checkbox, Spin, Popconfirm, message, Divider, Row, Col,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddNewLocation from './AddNewLocation';
import { useGenericState } from '../../hooks/use-generic-state';
import { stylesLocationList } from './styles';
import { LocationListProps } from './types';
import {
    deleteAddress,
    fetchPickupAddress,
    // getDefaultAddress,
    // setDefaultAddressData,
    // editAddress as editBusinessAddress,
} from '../../network/pickup.api';

const LocationList = (props: LocationListProps) => {
    const {
        classes,
        forceRemountHandler,
        onChange,
        value,
        mandatoryFields,
    } = props;

    const initialState: any = {
        isLoading: false,
        data: {},
        selectedLocationId: null,
        customerData: [],
        showEditCustomerForm: false,
        editCustomerDetails: null,
    };

    const [state, setState] = useGenericState(initialState);

    const {
        isLoading,
        // data,
        selectedLocationId,
        customerData,
        showEditCustomerForm,
        editCustomerDetails,
    } = state;

    const getCustomerData = async (searchText: any) => {
        const response = await fetchPickupAddress({
            showDefaultBy: '',
            searchString: searchText || '',
        });
        if (response.isSuccess) {
            setState({
                customerData: [...response.data],
            });
        } else {
            setState({
                customerData: [],
            });
        }
    };

    const getCustomerDataWithLoader = async () => {
        setState({ isLoading: true });
        await getCustomerData('');
        setState({ isLoading: false });
    };

    const componentDidMount = async () => {
        getCustomerDataWithLoader();
        if (value) {
            setState({
                selectedLocationId: value.id,
            });
        }
    };

    React.useEffect(() => {
        componentDidMount();
    }, []);

    const confirmDelete = async (id: any) => {
        setState({
            isLoading: true,
        });
        const response = await deleteAddress({ id });

        if (response.isSuccess) {
            message.success('Deleted Successfully');
            forceRemountHandler?.call();
        } else {
            message.success(response.errorMessage);
        }

        setState({
            isLoading: false,
        });
    };

    const handleLocationSelect = (id: any) => {
        setState({
            selectedLocationId: id,
        });
        const selectedItem = customerData.find((el: any) => el.id === id);
        if (onChange) onChange(selectedItem);
    };

    let searchTimeOut: any = null;
    const handleSearch = (e: any) => {
        const searchText = e.target.value;
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        searchTimeOut = setTimeout(() => {
            getCustomerData(searchText);
        }, 300);
    };

    const editCustomer = (customerDetails: any) => {
        setState({
            showEditCustomerForm: true,
            editCustomerDetails: customerDetails,
        });
    };

    const handleClose = async (location: any) => {
        setState({
            showEditCustomerForm: false,
        });
        if (location.id === selectedLocationId && onChange) {
            onChange(location);
        }
        forceRemountHandler?.call();
    };

    return showEditCustomerForm ? (
        <AddNewLocation
            isEditable={showEditCustomerForm}
            editCustomerDetails={editCustomerDetails}
            onClose={handleClose}
            mandatoryFields={mandatoryFields}
        />
    ) : (
        <div>
            <div className={classes.searchContainer}>
                <Search
                    placeholder="Search Address"
                    allowClear
                    onChange={handleSearch}
                    className={classes.loactionSearchBar}
                />
            </div>
            <div className={classes.divider}>
                <Divider />
            </div>
            <div className={classes.locationList}>
                {isLoading ? (
                    <div className={classes.spinnerContainer}>
                        <Spin />
                    </div>
                ) : (
                    <List
                        itemLayout="horizontal"
                        dataSource={customerData}
                        renderItem={(item: any) => (
                            <List.Item className={classes.locationListItem}>
                                <div className={classes.locationCheckbox}>
                                    <Checkbox
                                        checked={selectedLocationId === item.id}
                                        key={item.id}
                                        onChange={() => {
                                            handleLocationSelect(item.id);
                                        }}
                                    />
                                </div>

                                <List.Item.Meta
                                    title={<b>{item.name}</b>}
                                    description={(
                                        <Row>
                                            <Col span={8}>
                                                {item.addressLine1 || ''}
                                                {item.addressLine1 ? ', ' : ''}
                                                {item.addressLine2 || ''}
                                            </Col>
                                            <Col span={8} className={classes.otherDetails}>
                                                {item.pincode || ''}
                                                <br />
                                                {item.stateName || ''}
                                                {item.stateName ? ', ' : ''}
                                                {item.countryName || ''}
                                            </Col>
                                            <Col span={8} className={classes.otherDetails}>
                                                {item.phone
                                                    ? (
                                                        <>
                                                            {item.phone}
                                                            <br />
                                                        </>
                                                    ) : null}
                                            </Col>
                                        </Row>
                                    )}
                                />
                                <>
                                    <EditOutlined
                                        className={classes.editIcon}
                                        onClick={() => editCustomer(item)}
                                    />
                                    <Popconfirm
                                        title="Are you sure to delete ?"
                                        onConfirm={() => {
                                            confirmDelete(item.id);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteOutlined className={classes.deleteIcon} />
                                    </Popconfirm>
                                </>
                            </List.Item>
                        )}
                    />
                )}
            </div>
        </div>
    );
};

export default withStyles(stylesLocationList)(LocationList);
