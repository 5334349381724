/* eslint-disable react/jsx-closing-tag-location */
import * as React from 'react';

const walletFaqList = [{
    question: 'What is DTDC closed loop Wallet?',
    answer: <div>
        A closed wallet&nbsp;
        <b>enables users to make payments via an app or website.</b>
        &nbsp;They are typically created by businesses that sell products or services to their customers.
        Users of a closed wallet can only use the stored funds to complete a transaction with the wallet&#39;s issuer.
    </div>,
    answerText: `A closed walletenables users to make payments via an app or website. They are typically created by
    businesses that sell products or services to their customers. Users of a closed wallet can only use the stored
    funds to complete a transaction with the wallet's issuer.`,
}, {
    question: 'What is my Wallet Account Number or How can I recharge my Wallet?',
    answer: <div>
        Customer will get wallet account details over mail once your wallet get registered in DTDC account.
        <p>
            The format of Wallet account number is :
            <b>
                &nbsp;&#39;DTDC+Customercode&#39;&nbsp;
            </b>
        </p>
        Please recharge your wallet with the
        following:
        <p>
            Payment options is allowed only for&nbsp;
            <b>NEFT / IMPS </b>
        </p>
        Please register your wallet account number
        in your NetBanking and start doing wallet recharge.
        <p>Beneficiary Name: DTDC Express Ltd</p>
        <p>Beneficiary Account Number: &nbsp;&#39;DTDCXXXXXX&#39;&nbsp; (Your Wallet ID)</p>
        <p>Beneficiary IFSC Code: ICIC0000104 (Hardcoded value)</p>
    </div>,
    answerText: `Customer will get wallet account details over mail once your wallet get registered in DTDC account.
    The format of Wallet account number is : 'DTDC+Customercode' Please recharge your wallet with the
    following:Payment options is allowed only for NEFT / IMPS Please register your wallet account number
    in your NetBanking and start doing wallet recharge.Beneficiary Name: DTDC Express Ltd
    Beneficiary Account Number: 'DTDCXXXXXX' (Your Wallet ID) Beneficiary IFSC Code: ICIC0000104 (Hardcoded value)`,
}, {
    question: 'Can I Recharge my Wallet using Credit / Debit card payment method?',
    answer: <div>
        Currently only&nbsp;
        <b>NEFT / RTGS / IMPS</b>
        &nbsp;payment method is allowed.
        We will keep you posted over mail once other payment method is allowed.
    </div>,
    answerText: `Currently only NEFT / RTGS / IMPS payment method is allowed.
    We will keep you posted over mail once other payment method is allowed.`,
}, {
    question: 'What is the minimum balance need to be maintained?',
    answer: <div>
        Currently there is no mandatory to maintain any minimum balance but it is suggested to maintain at least Rs 2000
        in the wallet account to avoid any weigh mismatch cases.
    </div>,
    answerText: `Currently there is no mandatory to maintain any minimum balance but it is suggested to maintain
    at least Rs 2000 in the wallet account to avoid any weigh mismatch cases.`,
}, {
    question: 'What could happen, if customer bank account debited but wallet is not loaded?',
    answer: <div>
        Customer can write to&nbsp;
        <b>
            <a>walletsupport@dtdc.com</a>
        </b>
        &nbsp;with your transaction details. Within one working day you will
        get a response and within two working days it will be resolved.
    </div>,
    answerText: `Customer can write to Walletsupport@dtdc.com with your transaction details. Within one working day you will
    get a response and within two working days it will be resolved.`,
}, {
    question: 'How much time it may take to credit the Wallet Account?',
    answer: <div>NEFT / IMPS – It will be as per your source bank fund transfer norms.</div>,
    answerText: 'NEFT / IMPS – It will be as per your source bank fund transfer norms.',
}, {
    question: 'If any shipment is cancelled how he can get back his money into his Wallet?',
    answerText: `Customer should take a booking cancellation confirmation from the Branch / account
    manager where the booking has been cancelled and share the mail to walletsupport@dtdc.com.
    Within two working days the cancelled money will be loaded into wallet.`,
    answer: <div>
        Customer should take a booking cancellation confirmation from the Branch / account
        manager where the booking has been cancelled and share the mail to&nbsp;
        <b>
            <a>walletsupport@dtdc.com</a>
        </b>
        &nbsp;Within two working days the cancelled money will be loaded into wallet.
    </div>,
}, {
    question: 'To whom we can connect, For any wallet related issue?',
    answerText: `Customer can write to walletsupport@dtdc.com for any such wallet related issue.
    Escalation can be done to their DTDC account manager assigned to the customer.`,
    answer: <div>
        Customer can write to&nbsp;
        <b>
            <a>walletsupport@dtdc.com</a>
        </b>
        &nbsp;for any such wallet related issue. Escalation can be done to their
        DTDC account manager assigned to the customer.
    </div>,
}, {
    question: 'Where can customer see his wallet related Transactions?',
    answer: <div>
        You can access the DTDC Customer portal using the following link:&nbsp;
        <b>
            <a
                href="https://myaccount.dtdc.com"
                target="_blank"
                rel="noreferrer"
            >
                https://myaccount.dtdc.com
            </a>
            &nbsp;
        </b>
    </div>,
    answerText: 'You can access the DTDC Customer portal using the following link: https://myaccount.dtdc.com',
}, {
    question: 'Can customer change his Wallet configured Mobile number and Mail ID?',
    answer: <div>
        Customer can write to &nbsp;
        <b>
            <a>walletsupport@dtdc.com</a>
        </b>
        &nbsp;
        for any change in wallet mobile number or mail id with a
        copy mark to their assigned account manager. We are also in process to activate this option in their customer
        portal for self-use.

    </div>,
    answerText: `Customer can write to walletsupport@dtdc.com for any change in wallet mobile number or mail id with a
    copy mark to their assigned account manager. We are also in process to activate this option in their customer
    portal for self-use.`,
}];

export {
    walletFaqList,
};
