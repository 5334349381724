import { TableColumn } from 'components/settings-product/settings.types';
import { LabelValue } from 'library/Types';

export const RemittanceReportColumns: TableColumn[] = [
    {
        key: 'Shipment_No',
        pretty_name: 'CN Number',
    },
    {
        key: 'Customer_Ref_No',
        pretty_name: 'Customer Reference Number',
    },
    {
        key: 'BOOKING_DATE',
        pretty_name: 'Booking Date',
    },
    {
        key: 'DELIVERY_DATE',
        pretty_name: 'Delivery Date',
    },
    {
        key: 'COD_Amount',
        pretty_name: 'COD Amount',
    },
    {
        key: 'Remitted_Amount',
        pretty_name: 'Remitted Amount',
    },
    {
        key: 'Remittance_Status',
        pretty_name: 'Remittance Status',
    },
    {
        key: 'UTR_Date',
        pretty_name: 'Remittance Date',
    },
    {
        key: 'UTR_No',
        pretty_name: 'UTR Number',
    },
];

export const InvoiceReportColumns: TableColumn[] = [
    {
        key: 'key',
        pretty_name: 'S. No.',
    },
    {
        key: 'CIH_INVOICE_NO',
        pretty_name: 'Invoice Number',
    },
    {
        key: 'CIH_INV_DATE',
        pretty_name: 'Invoice Date',
    },
    {
        key: 'downloadPdfAction',
        pretty_name: 'Download PDF',
    },
];

export const SBUOptions: LabelValue[] = [
    {
        label: 'DOM Express',
        value: 'CTB',
    },
    {
        label: 'E-Com',
        value: 'INS',
    },
    {
        label: '3PL/EFF',
        value: 'EFF',
    },
    {
        label: 'International',
        value: 'ITB',
    },
    {
        label: 'Credit Note',
        value: 'CTN',
    },
    {
        label: 'Supplementary invoices',
        value: 'MAN',
    },
    {
        label: 'Paid/To Pay',
        value: 'TMS',
    },
    {
        label: 'Service invoice',
        value: 'SIN',
    },
    {
        label: 'GEC GA',
        value: 'TMS1',
    },
    {
        label: 'LTL/FTL/Distribution',
        value: 'TMS2',
    },
];

export enum ReportsPages {
    CodRemittance = 'COD Remittance',
    InvoiceReports = 'Invoices',
}
