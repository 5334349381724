import { API_BASE_URL } from 'library/globals';
import { GENERATE_ASSERTION_RESPONSE_PREFIX } from 'network/api.constants';

import { POST } from './api-hander.service';


export const generateJWTSSOResponseCallback = async (sp_integration_id: string, protocol: string, body: any) => {
    const resposne = await POST(
        `${API_BASE_URL}${GENERATE_ASSERTION_RESPONSE_PREFIX}${sp_integration_id}/${protocol}`,
        body,
    );
    return resposne;
};
