import { ThemeType } from 'theme/jss-types';

/* eslint-disable max-len */
export const reportsStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        padding: '0px 8px',
    },
    navigation: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: 1,
        boxSizing: 'border-box',
        maxHeight: '10%',
        width: '75%',
    },
    content: {
        marginTop: 8,
        width: '100%',
    },
    selected: {
        color: theme.primaryColor,
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0,
        textAlign: 'center',
        borderBottom: `4px solid ${theme.primaryColor}`,
        margin: '0px 5px',
        padding: 12,
        width: '25%',
    },
    notSelected: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        textAlign: 'center',
        cursor: 'pointer',
        margin: '0px 5px',
        padding: 12,
        width: '25%',
    },
});

export const accountStyles = (theme: ThemeType) => ({
    subAccount: {
        borderRadius: '0 2px 0 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: 24,
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: 12,
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
    },
    drawerBody: {
        '& .ant-drawer-body': {
            padding: 0,
        },
    },
    main: {
        backgroundColor: '#FFF',
        borderRadius: '0 2px 0 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: 12,
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
    },
    title: {
        margin: '0 24px',
        padding: '8px 0',
        borderBottom: '0.5px solid #979797',
        fontSize: 16,
        fontWeight: 'bold',
        color: '#111111',
        display: 'flex',
        fontFamily: 'Open Sans',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    newButton: {
        height: 32,
        fontSize: 12,
        backgroundColor: theme.primaryColor,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        padding: '0px 24px',
        borderRadius: 4,
        marginLeft: 12,
    },
    cancelButton: {
        height: 32,
        fontSize: 12,
        backgroundColor: theme.primaryColor,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        padding: '0px 24px',
        borderRadius: 4,
        marginRight: 12,
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
        color: '#111111',
        fontFamily: 'Open Sans',
        alignItems: 'center',
    },
    newSubButton: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: theme.primaryColor,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    row: {
        padding: '8px 24px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        margin: '12px 0',
    },
    pair: {
        display: 'flex',
        flexDirection: 'row',
        width: '19%',
        color: '#111111',
        fontSize: 12,
        marginRight: '1%',
    },
    bankpair: {
        display: 'flex',
        flexDirection: 'row',
        width: '24%',
        color: '#111111',
        fontSize: 12,
        marginRight: '1%',
    },
    pairTitle: {
        fontWeight: 500,
        color: '#333333',
        width: '30%',
    },
    pairValue: {
        fontWeight: 'bold',
        width: '60%',
    },
    bankTitle: {
        fontWeight: 500,
        color: '#333333',
        width: '35%',
    },
    bankValue: {
        fontWeight: 'bold',
        width: '60%',
    },
    formParent: {
        width: '42%',
        marginRight: '8%',
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        display: 'flex',
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    label: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 12,
        },
        '& .ant-form-item-children': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        '& .ant-form-item-explain.ant-form-item-explain-error': {
            fontSize: 10,
        },
        '& .ant-form label': {
            color: '#333333',
            fontSize: 12,
        },
    },
    flexRow: {
        display: 'flex',
        // alignItems: 'center',
        width: '100%',
        padding: '0 1%',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0 1%',
    },
    formFields: {
        width: '70%',
    },
    boxTitle: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        color: '#333333',
        fontWeight: 'bold',
        width: '25%',
    },
    boxFields: {
        width: '100%',
        display: 'flex',
    },
    boxFieldsCol: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
    },
    boxIcon: {
        width: '100%',
        height: 30,
        fontSize: 16,
        color: '#333333',
        textAlign: 'left',
    },
    box: {
        display: 'flex',
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: '100%',
    },
    changePassword: {
        color: theme.primaryColor,
        cursor: 'pointer',
        width: '45%',
    },
    embedImage: {
        cursor: 'pointer',
        display: 'flex',
        width: '95%',
        color: '#333333',
        fontSize: 12,
        marginBottom: 6,
    },
    uploadRules: {
        fontSize: 10,
        margin: '6px 0',
    },
    checkbox: {
        width: '40%',
    },
    image: {
        display: 'flex',
        marginLeft: 16,
    },
    changePasswordText: {
        color: theme.primaryColor,
        marginTop: 6,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        fontSize: 12,
        cursor: 'pointer',
    },
    form: {
        width: '100%',
        '& .ant-input, .ant-input:hover, .ant-input:focus, .ant-input-focused': {
            marginTop: 2,
            background: 'none',
            borderRadius: 4,
            display: 'block',
            float: 'left',
            height: 30,
            border: '1px solid #999999',
            padding: 8,
            transition: 'border-color .4s cubic-bezier(.25,.8,.25,1)',
            boxShadow: 'none',
            outline: 'none',
        },
        '& .ant-form-item-has-error .ant-input:focus': {
            marginTop: 2,
            background: 'none',
            borderRadius: 0,
            boxSizing: 'border-box',
            display: 'block',
            float: 'left',
            height: 30,
            padding: '2px 2px 2px 0px',
            transition: 'border-color .4s cubic-bezier(.25,.8,.25,1)',
            boxShadow: 'none',
            outline: 'none',
            border: '1px solid #999999',
        },
        '& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
            minHeight: 26,
            paddingBottom: 1,
            alignItems: 'center',
            borderBottomStyle: 'solid',
            marginTop: 2,
            height: 30,
            border: '1px solid #999999',
            borderRadius: 4,
        },
        '& .ant-select': {
            width: '100%',
        },
        '& .ant-form label': {
            color: '#333333',
            fontSize: 12,
        },
    },
});

export const productStyles = (theme: ThemeType) => ({
    main: {
        height: '100%',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 11,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: 12,
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
    },
    title: {
        padding: '2% 1%',
        borderBottom: '0.5px solid #979797',
        fontSize: 16,
        fontWeight: 'bold',
        color: theme.primaryColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    newButton: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: theme.primaryColor,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        marginRight: 24,
        padding: '0px 24px',
    },
});
