import React, { useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
import { StylesProps } from '../../theme/jss-types';
import {
    Button,
    Form,
    Input,
    Modal,
    message,
} from 'antd';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { resetPasswordStyles } from './reset-password.styles';
import { resetPassword } from '../../network/common.api';
import { ReduxStore } from 'reducers/redux.types';
import { Master } from 'types/master-data-types';
import authHelper from 'auth/auth-helper';
import { CP_BASE_URL } from 'library/globals';
import { useTranslation } from 'react-i18next';
import PasswordRules from 'routing/PasswordRules';

interface CreatePasswordProps extends StylesProps<ReturnType<typeof resetPasswordStyles>>, RouteChildrenProps {
    master: Master;
    passwordPolicy: any;
}

const ResetPasswordComponent = (props: CreatePasswordProps): React.ReactElement => {
    const { classes, master, passwordPolicy } = props;
    const history = useHistory();
    const { t } = useTranslation();


    const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [isResetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [isUsingCustomerPortalV2, setIsUsingCustomerPortalV2] = useState(false);
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(false);
    const [currPassword, setPassword] = useState<string>('');
    const [currConPassword, setConPassword] = useState<string>('');

    const oldBaseURL = master?.config?.customer_portal_config?.old_cp_base_url;


    const onFinish = async (values: { password: string; conPassword: string }) => {
        const { password, conPassword } = values;
        setDisableButton(true);
        if (conPassword !== password) {
            setDisableButton(false);
            message.error(t('password_not_match'));
            return;
        }
        const urlParams = new URLSearchParams(history.location.search);
        const params: any = urlParams.get('resetCode');
        if (!params) {
            setDisableButton(false);
            message.error('Reset code is missing');
            return;
        }
        try {
            const resetInfo: any = atob(params).split(':');
            setIsUsingCustomerPortalV2(resetInfo[3] === 'true');
            if (!resetInfo[1] || !resetInfo[2]) {
                setDisableButton(false);
                message.error('Invalid Customer Details');
                return;
            }
            const reset_obj: any = {
                password,
                username: resetInfo[1],
                auth_token: resetInfo[2],
                new_password: conPassword,
            };
            const result = await resetPassword(reset_obj);
            if (!result?.isSuccess) {
                message.error(result.errorMessage || t('reset_password_error'));
            } else {
                setResetPasswordSuccess(true);
                setIsModalOpen(true);
            }
        } catch (e: any) {
            message.error(e);
        }
        setDisableButton(false);
    };

    const renderPassword = () => {
        return (
            <div>
                <span className={classes.labelForInput}>
                    {t('password')}
                </span>
                <Form.Item
                    className={classes.emailLabel}
                    name="password"
                    wrapperCol={{ span: 20 }}
                    colon={false}
                    rules={[{
                        required: true,
                        message: t('login_error_message'),
                    }]}
                >
                    <Input.Password
                        className={classes.emailInput}
                        placeholder={t('login_password_placeholder')}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderConfirmPassword = () => {
        return (
            <div>
                <span className={classes.labelForInput} style={{ width: 90 }}>
                    {t('comPassword')}
                </span>
                <Form.Item
                    className={classes.passwordInput}
                    name="conPassword"
                    colon
                    rules={[{
                        required: true,
                        message: t('required'),
                    }]}
                >
                    <Input.Password
                        className={classes.emailInput}
                        placeholder={t('confirm_password')}
                        onChange={(e) => {
                            setConPassword(e.target.value);
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderSubmitButton = () => {
        return (
            <Form.Item
                wrapperCol={{ span: 20 }}
                style={{
                    textAlign: 'left',
                    marginBottom: '16px',
                }}
            >
                <Button
                    className={classes.submitButton}
                    style={{ margin: '0 16px 16px 0' }}
                    type="primary"
                    htmlType="submit"
                    loading={disableButton}
                    disabled={!isPasswordCorrect}
                    onClick={() => onFinish({ password: currPassword, conPassword: currConPassword })}
                >
                    {t('reset_password')}
                </Button>
            </Form.Item>
        );
    };

    const renderSuccessModal = () => {
        return (
            <Modal
                title={t('password_change_success_message')}
                centered
                closable={false}
                visible={isModalOpen}
                footer={null}
            >
                {t('check_mail_on_login')}
                <Button
                    className={classes.clickHere}
                    type="link"
                    onClick={() => {
                        if (isUsingCustomerPortalV2) {
                            history.push('/login');
                            window.location.href = '/login';
                        } else if (oldBaseURL) {
                            window.location.href = oldBaseURL;
                        } else {
                            const orgId = authHelper.getOrganisationId();
                            window.location.href = CP_BASE_URL.replace('{organisationId}', orgId === '1' ? 'dtdc' : orgId);
                        }
                    }}
                >
                    {t('click_here')}
                </Button>
                &nbsp;to redirect
            </Modal>
        );
    };


    const renderSetPassword = () => {
        return (
            <Form
                wrapperCol={{ span: 20 }}
                style={{ marginLeft: '15%' }}
            >
                {renderPassword()}
                {renderConfirmPassword()}
                {renderSubmitButton()}
                <PasswordRules
                    password_policy={passwordPolicy}
                    password={currPassword}
                    conPassword={currConPassword}
                    enableSubmit={() => setIsPasswordCorrect(true)}
                />
            </Form>
        );
    };

    if (isResetPasswordSuccess) {
        return (
            <>
                <div className={classes.backgroundDesign} />
                { renderSuccessModal() }
            </>
        );
    }

    return (
        <div className={classes.signInForm}>
            <div className={classes.welcomeMsg}>
                {t('reset_password')}
            </div>
            {renderSetPassword()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: resetPasswordStyles,
    },
    connectRouter: true,
};

const ResetPassword = GenericHoc(hocConfig)(ResetPasswordComponent);
export default ResetPassword;
