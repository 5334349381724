import { ConsignmentBucket } from 'components/consignments/consignment.types';
import { PickupColumns } from 'types/master-data-types';

export const pickupColumns: Record<string, string> = {
    pickupId: 'Pickup Id',
    pickupAddress: 'Pickup Address',
    totalItems: 'Number of Shipments',
    totalWeight: 'Weight',
    closingTimeString: 'Closing Time',
    courierType: 'Item Type',
    pickupDate: 'Pickup Date',
    pickupSlot: 'Pickup Slot',
    pickupTaskStatus: 'Status',
    isRiderAssigned: 'is Assigned',
    pickupFailureReason: 'Rejection Reason',
    destinationDetails: 'Destination Details',
    waybillType: 'Waybill Type',
    actions: 'Actions',
};

export const pickupConsignmentColumns = [
    {
        title: 'S.No.',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
    },
    {
        title: 'Reference Number',
        dataIndex: 'referenceNumber',
        key: 'referenceNumber',
    },
];

export const PickupBuckets: PickupColumns<ConsignmentBucket> = {
    customer_portal_all: {
        name: 'All',
    },
    customer_portal_created: {
        name: 'Created',
    },
    customer_portal_attempted: {
        name: 'Attempted',
    },
    customer_portal_success: {
        name: 'Success',
    },
    customer_portal_cancelled: {
        name: 'Cancelled',
    },
    customer_portal_unresolved: {
        name: 'Unresolved',
    },
};
