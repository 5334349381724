import { MasterConfig } from '../../types/master-data-types';

export const getFilters = (bucket: any, config: MasterConfig) => {
    const { asn_config } = config;
    if (!bucket || !config || !asn_config || !asn_config.full_filter_list_by_bucket || !asn_config.full_filter_list) {
        return [];
    }
    let dashboardFilters = asn_config?.full_filter_list_by_bucket[bucket];
    dashboardFilters = dashboardFilters.filter((filter:any) => (!filter.show_on_main_dashboard));
    const filterIdList = dashboardFilters.map((filter:any) => (filter.id));

    const filtersToShow = asn_config.full_filter_list.filter((filter:any) => filterIdList.includes(filter.id));
    return filtersToShow;
};
