/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { JssProvider } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';
import { create as createJss } from 'jss';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import jssPreset from 'jss-preset-default';
import Helper from './library/Helper';
import jssrtl from 'jss-rtl';
import store from './redux-store/store';
import JssThemeProvider from './theme/theme-provider';
import i18next from 'i18next';

declare const module: any;
const localJss = createJss({
    plugins: [...jssPreset().plugins, jssrtl({ enabled: Helper.isLanguageRTL(i18n.language) })],
});

const launchApp = (Component: any) => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <JssProvider jss={localJss}>
                    <JssThemeProvider>
                        <BrowserRouter>
                            <I18nextProvider i18n={i18next}>
                                <ConfigProvider
                                    direction={Helper.isLanguageRTL(i18n.language) ? 'rtl' : 'ltr'}
                                >
                                    <Component />
                                </ConfigProvider>
                            </I18nextProvider>
                        </BrowserRouter>
                    </JssThemeProvider>
                </JssProvider>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
};

const launchMain = () => launchApp(App);
launchMain();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js');
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (module.hot && process?.env?.NODE_ENV !== 'production') {
    // module.hot.accept();
    module.hot.accept('./App', () => {
        /* eslint-disable-next-line global-require */
        const NextApp = require('./App').default;
        launchApp(NextApp);
    });
}
