import * as React from 'react';
import withStyles from 'react-jss';
import {
    SortableContainer,
    SortableElement,
    SortEnd,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { StylesProps } from '../../theme/jss-types';
import { ConsignmentColumn } from '../../types/master-data-types';
import { Switch, Tooltip } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import Cross from '../../assets/cross';
import { PageSelected } from './settings.constants';

const styles = {
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    sortableItemIcon: {
        marginRight: 10,
        color: '#595959',
        width: '10%',
    },
    sortableItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        '& .ant-switch': {
            height: 14,
            minWidth: 25,
            maxWidth: 25,
            borderRadius: 7,
            marginBottom: 7,
        },
        '& .ant-switch-handle': {
            borderRadius: 5,
            height: 10,
            width: 10,
        },
        '& .ant-switch-checked .ant-switch-handle': {
            left: 12,
        },
    },
    filterColumns: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    filterName: {
        color: '#111111',
        fontSize: 14,
        width: '80%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingRight: 10,
    },
    closeIcon: {
        marginLeft: 8,
        cursor: 'pointer',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
        padding: 6,
        borderRadius: 6,
        backgroundColor: 'rgba(7,128,254,0.15)',
        fontSize: 12,
        width: '60%',
        marginRight: '10%',
    },
    list: {
        overflow: 'auto',
        width: '100%',
        height: '73vh',
    },
};

interface IProps extends StylesProps<typeof styles> {
    selectedList: any[];
    pageSelected: PageSelected;
    updateSelection: (checked: any[]) => void;
    updateShowOnDashboard: (column: ConsignmentColumn, checked: boolean) => void;
}

const Sortable = (props: IProps) => {
    const {
        classes,
        selectedList,
        pageSelected,
        updateSelection,
        updateShowOnDashboard,
    } = props;

    const onSortEnd = (sortEnd: SortEnd) => {
        const result = arrayMoveImmutable(
            selectedList,
            sortEnd.oldIndex,
            sortEnd.newIndex,
        );
        updateSelection(result.map((item) => (item.column_id)));
    };

    const handleRemoveItem = (column: ConsignmentColumn) => {
        let updatedList = [...selectedList];
        updatedList = updatedList.filter((item) => item.column_id !== column.column_id);
        updateSelection(updatedList.map((item) => (item.column_id)));
    };

    const ListItem = (itemProps: { column: ConsignmentColumn, idx: number }) => {
        const { column, idx } = itemProps;
        return (
            <li
                tabIndex={idx}
                className={classes.sortableItem}
            >
                <div className={classes.row}>
                    <span className={classes.sortableItemIcon}>
                        <MoreOutlined />
                        <MoreOutlined style={{ marginLeft: -8 }} />
                    </span>
                    <Tooltip
                        title={column.pretty_name}
                        className={classes.filterName}
                    >
                        {column.pretty_name}
                    </Tooltip>
                    <Cross
                        onClick={(e:any) => { e.stopPropagation(); handleRemoveItem(column); }}
                        alt="close"
                        className={classes.closeIcon}
                    />
                </div>
                {pageSelected === 'filters' && (
                    <Switch
                        checked={column.show_on_main_dashboard}
                        onChange={(checked) => updateShowOnDashboard(column, checked)}
                    />
                )}
            </li>
        );
    };

    const SortableItem = SortableElement((
        itemProps: { column: ConsignmentColumn, index: number },
    ) => (
        <ListItem
            column={itemProps.column}
            idx={itemProps.index}
        />
    ));

    const SortableList = SortableContainer(() => {
        return (
            <ul style={{ paddingInlineStart: 0 }}>
                {selectedList.map((column, idx) => (
                    <SortableItem
                        index={idx}
                        column={column}
                    />
                ))}
            </ul>
        );
    });

    return (
        <div className={classes.list}>
            <SortableList
                pressDelay={100}
                onSortEnd={onSortEnd}
            />
        </div>
    );
};

export default withStyles(styles)(Sortable);
