import * as React from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    message,
} from 'antd';
import { StylesProps, ThemeType } from 'theme/jss-types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { commonStyleSheet } from 'library/common-styles';
import Loader from 'components/common/Loader';
import { useTranslation } from 'react-i18next';
import PasswordRules from './PasswordRules';
import { updateCustomer } from 'network/settings-product';
import { isSuperUserEmployee } from 'auth/local-storage';

const styles = (theme: ThemeType) => ({
    form: {
        width: '100%',
        '& .ant-form-item-explain-error': {
            fontSize: 10,
        },
    },
    footer: {
        width: '100%',
        textAlign: 'right',
        borderRadius: 4,
        display: 'flex',
        justtifyContent: 'flex-end',
        padding: 8,
    },
    primaryButtom: {
        height: 32,
        borderRadius: 4,
        fontSize: 12,
        backgroundColor: `${theme.primaryColor} !important`,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    disabledButton: {
        height: 32,
        borderRadius: 4,
        fontSize: 12,
        color: '#111',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        '& .ant-modal-body': {
            padding: '12px 24px',
        },
        marginTop: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexColumn,
        padding: 0,
    },
    addText: {
        color: '#111111',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 'bold',
    },
    descText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    input: {
        width: '70%',
    },
    passwordInput: {
        fontWeight: 600,
        marginBottom: '16px',
        '& .ant-form-item-label > label': {
            width: 150,
        },
    },
});

interface PasswordChangeProps extends StylesProps<ReturnType<typeof styles>> {
    password_policy: any;
    password_expiry: any;
}
const PasswordChange = (props: PasswordChangeProps) => {
    const {
        classes,
        password_policy,
        password_expiry,
    } = props;

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [complete, setComplete] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [password, setPassword] = React.useState<string | undefined>();
    const [conPassword, setConPassword] = React.useState<string | undefined>();
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(false);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    {t('change_password')}
                </div>
                <div className={classes.descText}>
                    {t('change_password_text')}
                </div>
            </div>
        );
    };

    // this is to be removed
    React.useEffect(() => {
        setSaving(false);
        setLoading(false);
    }, []);

    const renderPassword = () => {
        return (
            <Form.Item
                className={classes.passwordInput}
                name="password"
                colon
                label={t('password')}
            >
                <Input
                    type="password"
                    className={classes.input}
                    placeholder={t('password')}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Item>
        );
    };

    const renderConfirmPassword = () => {
        return (
            <Form.Item
                className={classes.passwordInput}
                name="conPassword"
                colon
                label={t('confirm_password')}
                rules={[{
                    validator: (rule, value, callback) => {
                        if (value !== password) {
                            callback(t('password_not_match'));
                        } else {
                            callback();
                        }
                    },
                }]}
            >
                <Input
                    type="password"
                    className={classes.input}
                    placeholder={t('confirm_password')}
                    onChange={(e) => setConPassword(e.target.value)}
                />
            </Form.Item>
        );
    };

    const renderRules = () => {
        return (
            <PasswordRules
                password_policy={password_policy}
                password={password}
                conPassword={conPassword}
                enableSubmit={() => setIsPasswordCorrect(true)}
            />
        );
    };

    const renderBody = () => {
        if (loading) {
            return <Loader />;
        }
        return (
            <Form
                form={form}
                className={classes.form}
            >
                {renderPassword()}
                {renderConfirmPassword()}
                {renderRules()}
            </Form>
        );
    };

    const createApiBody = async () => {
        const storage = window.localStorage;
        return {
            code: storage.getItem('userCode'),
            id: storage.getItem('userId'),
            is_active: true,
            password,
            comPassword: conPassword,
        };
    };

    const changePassword = async () => {
        setSaving(true);

        const apiBody = await createApiBody();
        if (!apiBody) {
            return;
        }
        const response = await updateCustomer(apiBody);

        setSaving(false);

        if (response.isSuccess) {
            message.success(t('password_change_success_message'));
            setComplete(true);
        } else {
            message.error(response.errorMessage);
        }
    };

    const renderFooter = () => {
        const enableSubmit = isPasswordCorrect && conPassword === password;

        return (
            <div className={classes.footer}>
                <Button
                    onClick={() => changePassword()}
                    type="primary"
                    loading={saving}
                    className={enableSubmit ? classes.primaryButtom : classes.disabledButton}
                    disabled={!enableSubmit}
                >
                    {t('submit')}
                </Button>
            </div>
        );
    };

    const storage = window.localStorage;
    const userId = storage.getItem('userId');
    const childUserId = storage.getItem('childCustomerId');
    return (
        <Modal
            visible={
                !complete
                && password_expiry?.should_send
                && !password_expiry?.allow_skip
                && userId === childUserId
                && !isSuperUserEmployee()
            }
            title={renderHeader()}
            cancelText={null}
            okButtonProps={{
                disabled: saving,
                loading: saving,
            }}
            className={classes.main}
            closable={false}
            footer={renderFooter()}
        >
            {renderBody()}
        </Modal>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};
export default GenericHoc(hocConfig)(PasswordChange);
