import * as React from 'react';
import withStyles from 'react-jss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { HocOptions } from './generic-hoc.types';
import { withTranslation } from 'react-i18next';

const GenericHoc = (options: HocOptions) => {
    const {
        connectRedux,
        connectRouter,
        connectJss,
        connectTranslession,
    } = options;

    function reduxConnector(Component: any) {
        if (!connectRedux?.useRedux) {
            return Component;
        }
        const { mapStateToProps, mapDispatchToProps } = connectRedux;
        return connect(mapStateToProps, mapDispatchToProps)(Component);
    }

    function jssConnector(Component: any) {
        if (!connectJss?.useJss) {
            return Component;
        }
        const { styleSheet } = connectJss;
        return withStyles(styleSheet, { injectTheme: true })(Component);
    }

    function routeConnector(Component: any) {
        if (!connectRouter) {
            return Component;
        }
        return withRouter(Component);
    }

    function translessionConnector(Component: any) {
        if (!connectTranslession) {
            return Component;
        }
        return withTranslation()(Component);
    }

    return (Page: any) => {
        let Component = routeConnector(Page);
        Component = reduxConnector(Component);
        Component = jssConnector(Component);
        Component = translessionConnector(Component);

        class ComponentClass extends React.PureComponent<any, any> {
            render() {
                return <Component {...this.props} />;
            }
        }
        const state = (store: any) => {
            const { master, authUser } = store;
            return {
                master,
                authUser,
            };
        };
        const AuthenticatedComponent = ComponentClass;
        return connect(state)(AuthenticatedComponent);
    };
};

export default GenericHoc;
