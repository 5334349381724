import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { geocodingApi } from './utils/utils';

let timeout: ReturnType<typeof setTimeout> | null;

const fetch = (value: string, callback: any) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    const autocompleteApi = () => {
        const options = {
            text: value,
        };
        geocodingApi.autocomplete(options)
            .then((response: any) => {
                callback(response?.features || []);
            });
    };
    if (value) {
        timeout = setTimeout(autocompleteApi, 500);
    } else {
        callback([]);
    }
};

const SearchBox = (props: any) => {
    const {
        placeholder,
        style,
        onSelect,
    } = props;
    const [data, setData] = useState<SelectProps['options']>([]);
    const [value, setValue] = useState<any>();
    const [selected, setSelected] = useState<any>();


    const handleSearch = (newValue: string) => {
        fetch(newValue, setData);
    };

    useEffect(() => {
        if (selected && onSelect) {
            onSelect(selected);
        }
    }, [selected]);

    const handleChange = (newValue: string) => {
        const selectedItem = data?.find((item: any) => {
            return item.properties.id === newValue;
        });
        setSelected(selectedItem);
        setValue(newValue);
    };

    const generateOptions = (featureItems: any) => {
        return featureItems?.map((d: any) => {
            return {
                value: d.properties.id,
                label: d.properties.label,
            };
        });
    };

    return (
        <Select
            showSearch
            value={value}
            placeholder={placeholder || 'Search Places'}
            style={style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={generateOptions(data)}
        />
    );
};

export default SearchBox;
