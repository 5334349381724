import { combineReducers } from 'redux';
import uiTheme from './ui-theme';
import setMaster from './set-initial-data';
import generic from './generic-reducer';
import iframeReducer from './persistent-iframe';
import genericPageReducer from './generic-page-reducer';

export default combineReducers({
    uiTheme,
    generic,
    iframeReducer,
    master: setMaster,
    genericPage: genericPageReducer,
});
