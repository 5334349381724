import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';

export const additionalServicesColumns: Record<string, string> = {
    reference_number: 'Reference Number',
    request_type: 'Request Type',
    requested_by: 'Requested By',
    consignment_reference_number: 'Consignment #',
    created_at: 'Created At',
    status: 'Status',
    actions: 'Actions',
};

export const serviceListColumns: Record<string, string> = {
    service_code: 'Service',
    quantity: 'Quantity',
    remarks: 'Remarks',
};

export const formFields: Record<string, FormField> = {
    ConsignmentReferenceNumber: {
        label: 'Consignment Number',
        type: InputTypes.Input,
        key: 'consignment_reference_number',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '80%',
    },
    ServiceRequestTime: {
        label: 'Service Request Date & Time',
        type: InputTypes.Time,
        key: 'serviceRequestTime',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '80%',
    },
    AddressType: {
        label: 'Address Type',
        type: InputTypes.Radio,
        key: 'address_type',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        options: [
            {
                label: 'Pickup',
                value: 'PICKUP',
            },
            {
                label: 'Destination',
                value: 'DESTINATION',
            },
        ],
        width: '100%',
    },
    Service: {
        label: 'Service',
        type: InputTypes.Select,
        key: 'service_code',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '100%',
    },
    Quantity: {
        label: 'Quantity',
        type: InputTypes.Number,
        key: 'quantity',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '100%',
    },
    Remarks: {
        label: 'Remarks',
        type: InputTypes.Input,
        key: 'remarks',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '100%',
    },
    CustomerCode: {
        label: 'Customer Code',
        type: InputTypes.Select,
        key: 'customer_code',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '80%',
        options: [
            {
                label: 'Customer Code 1',
                value: 'customerCode1',
            },
        ],
    },
    SupplyRequestID: {
        label: 'Supply Request ID',
        type: InputTypes.Input,
        key: 'request_name',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '80%',
    },
    IsFulfilled: {
        label: 'Is Fulfilled',
        type: InputTypes.Checkbox,
        key: 'is_fulfilled',
        defaultValue: '',
        valuePropName: 'value',
        direction: 'column',
        width: '100%',
    },
    AddressRadio: {
        label: 'Address',
        type: InputTypes.Radio,
        key: 'addressRadio',
        defaultValue: 'saved',
        valuePropName: 'value',
        direction: 'column',
        options: [
            {
                label: 'Create',
                value: 'create',
            },
            {
                label: 'Saved',
                value: 'saved',
            },
        ],
    },
};
