import * as React from 'react';
import AuthHelper from '../auth/auth-helper';
import UnauthenticatedPages from './unauthenticated';
import Authenticated from './authenticated';
import PersistentIframe from '../components/persistent-iframe/persistentIframeInjector';
import { IframeApps } from '../components/persistent-iframe/persistent-iframe.constants';
import Feedback from './Feedback';
import PasswordChange from './PasswordChange';
import { FEEDBACK_INTERVAL } from 'library/globals';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { ReduxStore } from 'reducers/redux.types';

interface RoutingProps {
    bannerHeight: number;
    password_expiry: any;
    password_policy: any;
}

const Routing = (props: RoutingProps) => {
    const {
        bannerHeight,
        password_expiry,
        password_policy,
    } = props;

    const authenticated = AuthHelper.isAuthenticated();

    if (!authenticated) {
        return <UnauthenticatedPages />;
    }
    const storage = window.localStorage;
    const redirectionTime = storage.getItem('redirectionTime');
    return (
        <div style={{ width: '100%', height: `calc(calc(100%% - ${bannerHeight}px) - 10px)` }}>
            {/* handling some additional margin for browser based margin and padding */}
            <Authenticated />
            <PersistentIframe
                appName={IframeApps.CustomerPortal}
            />
            <Feedback
                landingTime={redirectionTime}
                interval={FEEDBACK_INTERVAL}
                moduleId="NEW_CUSTOMER_PORTAL"
                moduleName="Customer Portal V2"
            />
            <PasswordChange
                password_expiry={password_expiry}
                password_policy={password_policy}
            />
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        password_policy: state.master?.config?.password_policy || {},
        password_expiry: state.master?.config?.password_expiry || {},
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
};

export default GenericHoc(hocConfig)(Routing);
