/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const Consignments = (props: any) => (
    <svg fill="current" width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>noun_parcel box_1485418</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="Nav-Bar---Dashboard" transform="translate(-25.000000, -145.000000)" fillRule="nonzero">
                <g id="noun_parcel-box_1485418" transform="translate(25.000000, 145.000000)">
                    <path d="M14.77,12 L14.77,4 L7.385,0 L0,4 L0,12 L7.385,16 L14.77,12 Z M7.385,7.62769231 L4.80640417,6.22769231 L10.8528729,2.94461538 L13.4345458,4.34461538 L7.385,7.62769231 Z M7.8465625,14.7046154 L7.8465625,8.42769231 L13.846875,5.16923077 L13.846875,11.4646154 L7.8465625,14.7046154 Z M9.88666875,2.39692308 L3.8402,5.70153846 L1.33545417,4.34153846 L7.385,1.06461538 L9.88666875,2.39692308 Z M0.923125,5.16923077 L6.9234375,8.42769231 L6.9234375,14.7169231 L0.923125,11.4646154 L0.923125,5.16923077 Z" id="Shape" />
                </g>
            </g>
        </g>
    </svg>
);

const ConsignmentsIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return Consignments({ ...innerProps, ...props });
    }}
    />
);

export default ConsignmentsIcon;
