const WalletPageOptions = {
    WALLET_LEDGER: 'Wallet Ledger Statement',
    WEIGHT_MISMATCH: 'Weight Mismatch',
    BLOCKED_LEDGER: 'Blocked Amount Ledger',
};

const weightMismatchColumns = {
    date: 'Date',
    reference_number: 'CONSIGNMENT NO.',
    softdata_weight: 'ACTUAL WT. AS PER SOFT DATA/PRS',
    actual_weight: 'ACTUAL WT. AT BOOKING LOCATION',
    softdata_volumetric_weight: 'VOL. WT. AS PER SOFTDARA PRS | VOL. WT. AT BOOKING LOCATION',
    softdata_chargeable_weight: 'CHARGEABLE WT. AS PER SOFT DATA/PRS',
    actual_chargeable_weight: 'CHARGEABLE WT. AT BOOKING LOCATION',
    differential_chargeable_weight: 'DIFFERENTIAL CHARGED WT',
    amount_pending: 'DIFFERENTIAL CHARGED AMNT.',
};

const walletLedgerColumns = {
    date: 'Date',
    utr: 'Transaction No.',
    reference_number: 'Consignment No. / Transaction ID',
    description: 'Description',
    credit_debit_flag: 'Type',
    amount: 'Amount',
    balance: 'Balance',
};

const blockedLedgerColumns = {
    reference_number: 'CN Number',
    blocked_amount: 'Blocked Amount',
    created_at: 'Date & Time',
    creation_source: 'Source',
    relation_type: 'Remarks',
};

const walletColumns = {
    WALLET_LEDGER: walletLedgerColumns,
    WEIGHT_MISMATCH: weightMismatchColumns,
    BLOCKED_LEDGER: blockedLedgerColumns,
};

export {
    walletColumns,
    WalletPageOptions,
};
