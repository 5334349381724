import moment from 'moment';
import { ViewData } from './view.types';

export type PageDataResponseType = {
    id: string,
    name: string,
    type: string,
    metadata: {
        sectionList: {
            id: string,
            name: string,
            objectType: string,
            isDefault: boolean,
        }[][],
        metricList: {
            id: string,
            name: string,
        }[],
        globalFilters: {
            id: string,
            name: string,
            isDefault: boolean,
        }[],
        analyticsDashboardId?: string,
    },
    description: string,
    extra_detail: { [key: string]: any },
};

export class PageData {
    id: string;

    name: string;

    type: string;

    metadata: {
        sectionList: ViewData[][],
        metricList: {
            id: string,
            name: string,
        }[],
        globalFilters: {
            id: string,
            name: string,
            isDefault: boolean,
        }[],
        analyticsDashboardId?: string,
    };

    description: string;

    extra_detail: { [key: string]: any };

    activeViews: (string | undefined)[];

    appliedGlobalTimeFilter: { columnName: string; fromDateString: string; toDateString: string; } | undefined;

    constructor(pageData: PageDataResponseType) {
        this.id = pageData.id;
        this.name = pageData.name;
        this.type = pageData.type;
        this.metadata = {
            sectionList: pageData.metadata.sectionList.map(
                (section) => section.map((view) => new ViewData(view)),
            ),
            metricList: pageData.metadata.metricList,
            globalFilters: pageData.metadata.globalFilters,
            analyticsDashboardId: pageData.metadata.analyticsDashboardId,
        };
        this.description = pageData.description;
        this.extra_detail = pageData.extra_detail;
        this.activeViews = this.metadata.sectionList.map((section) => {
            const defaultView = section.find((view) => view.isDefault);
            return defaultView ? defaultView.id : undefined;
        });
        if (pageData.metadata.globalFilters?.length) {
            const defaultGlobalFilter = pageData.metadata.globalFilters.find((g) => g.isDefault)
            || pageData.metadata.globalFilters[0];
            this.appliedGlobalTimeFilter = {
                columnName: defaultGlobalFilter.id,
                fromDateString: moment().format('YYYY-MM-DD'),
                toDateString: moment().format('YYYY-MM-DD'),
            };
        }
    }

    getPageApiResponse(): PageDataResponseType {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            metadata: {
                sectionList: this.metadata.sectionList.map((section) => section.map((view) => ({
                    id: view.id,
                    objectType: view.object_type,
                    name: view.name,
                    isDefault: view.isDefault,
                }))),
                metricList: this.metadata.metricList,
                globalFilters: this.metadata.globalFilters,
            },
            description: this.description,
            extra_detail: this.extra_detail,
        };
    }

    clone() {
        const copy = new PageData(this.getPageApiResponse());
        copy.metadata.sectionList = this.metadata.sectionList.map((section) => section.map((view) => view.clone()));
        return copy;
    }

    getTabData(
        sectionIndex: number,
        renderComponent: (viewId: string, viewIndex: number, sectionIndex: number) => JSX.Element,
    ) {
        return this.metadata.sectionList[sectionIndex].map((view, viewIndex) => ({
            key: view.id,
            header: {
                title: view.name,
            },
            Component: renderComponent(view.id, viewIndex, sectionIndex),
        }));
    }

    hasViews(): boolean {
        return !!(this.metadata.sectionList?.length && this.metadata.sectionList[0].length);
    }

    getViewData(viewId: string) {
        return this.metadata.sectionList.flat().find((v) => v.id === viewId);
    }
}

export enum PAGE_NAMES {
    CONSIGNMENTS = 'Consignments',
}
