import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
// import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    Row,
    FormInstance,
} from 'antd';
import { getPieceLevelFieldFromMapping } from '../generic-sub-layout-renderer';
import { uniqueId } from 'lodash';

const styles = (theme: ThemeType) => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any;
}

const PieceField = (props: IProps) => {
    // const { t } = useTranslation();
    const {
        form,
        params,
        pieceLayout,
        // allowMultiplePieceDimensions,
    } = props;

    const { ItemType } = formFields;

    // TODO: check this key
    // const PIECES_FORM_KEY = 'pieces';
    // const pieces = Form.useWatch(PIECES_FORM_KEY, form);
    const itemType = Form.useWatch(ItemType.key, form);

    if (itemType && itemType !== 'non-document') {
        return null;
    }

    // const addPiece = () => {
    //     const id = uniqueId('prefixPieceId');
    //     form.setFieldsValue({
    //         [PIECES_FORM_KEY]: [
    //             ...pieces,
    //             {
    //                 key: id,
    //                 weightUnit: 'kg',
    //                 dimensionsUnit: 'cm',
    //                 isNewPiece: true,
    //             },
    //         ],
    //     });
    // };

    // const removePiece = (index: number) => {
    //     const newPieces = [...pieces];
    //     newPieces.splice(index, 1);
    //     form.setFieldsValue({
    //         [PIECES_FORM_KEY]: newPieces,
    //     });
    // };

    // const renderAddMoreButton = () => {
    //     if (allowMultiplePieceDimensions) {
    //         return (
    //             <Button
    //                 type="primary"
    //                 onClick={addPiece}
    //                 style={{ margin: '10px 0' }}
    //             >
    //                 {t('add_more_text')}
    //             </Button>
    //         );
    //     }
    //     return <></>;
    // };


    // const renderRemovePiece = (index: number) => {
    //     return (
    //         <Button danger type="link" onClick={() => removePiece(index)}>{t('remove')}</Button>
    //     );
    // };

    const pieceComponent = (
        <>
            {
                pieceLayout.map(
                    (fieldKey: any) => getPieceLevelFieldFromMapping(fieldKey, form, params),
                )
            }
        </>
    );
    // if (!allowMultiplePieceDimensions) {
    // } else {
    //     pieceComponent = (
    //         <>
    //             {
    //                 Array.isArray(pieces) && pieces.length
    //                     ? pieces.map((_pieceData, pieceIndex) => {
    //                         const pieceForm = pieceLayout.map(
    //                             (fieldKey: any) => getPieceLevelFieldFromMapping(fieldKey, form, {
    //                                 ...params,
    //                                 pieceIndex,
    //                             }),
    //                         );
    //                         return (
    //                             <>
    //                                 <div
    //                                     style={{
    //                                         display: 'flex',
    //                                         flexDirection: 'row',
    //                                         justifyContent: 'flex-end',
    //                                     }}
    //                                 >
    //                                     {renderRemovePiece(pieceIndex)}
    //                                 </div>
    //                                 <Row gutter={10}>
    //                                     {pieceForm}
    //                                 </Row>
    //                             </>
    //                         );
    //                     })
    //                     : <></>
    //             }
    //         </>
    //     );
    // }

    return (
        <Col span={24}>
            <div
                key={uniqueId() + (+new Date())}
            >
                <Row gutter={10}>
                    {pieceComponent}
                </Row>
            </div>
            {/* {renderAddMoreButton()} */}
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    // const consignmentConfig = config?.customer_portal_consignment_config;
    // const allowMultiplePieceDimensions = consignmentConfig?.allow_multiple_piece_dimensions;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const pieceLayout = formSubLayout?.non_document || [];
    return {
        // allowMultiplePieceDimensions,
        pieceLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PieceField);
