/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const UnhappyColored = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Unhappy</title>
        <defs>
            <polygon id="path-1" points="0 0 36.00006 0 36.00006 36 0 36" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Artboard" transform="translate(-138.000000, -293.000000)">
                <g id="Group-10" transform="translate(138.000000, 293.000000)">
                    <g id="Group-3">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g id="Clip-2" />
                        <path d="M36.00006,19.220312 C36.00006,29.9614099 28.7411279,36.00006 18.00003,36.00006 C7.2589321,36.00006 0,29.9614099 0,19.220312 C0,8.07241345 6.85885143,0 18.00003,0 C29.1412086,0 36.00006,8.07241345 36.00006,19.220312" id="Fill-1" fill="#FED132" mask="url(#mask-2)" />
                    </g>
                    <path d="M11.0038983,26.3063358 C12.4116207,25.1156939 14.1413036,23.7122915 17.9689499,23.8110517 C21.5262359,23.9029718 23.0498784,24.602813 24.9988017,26.3062158 C25.2804421,26.5524563 25.6664828,26.5032562 25.8829631,26.2554558 C26.0327234,26.0839755 26.0708835,25.8524951 26.0040433,25.6508948 C24.8749615,22.2756491 21.576396,20.8622868 18.0319501,20.8186067 C13.9932233,20.7689266 11.0606584,22.79621 9.99865666,25.6510148 C9.88033647,25.9688953 10.0422167,26.3225359 10.3602173,26.4408561 C10.5667376,26.5176562 10.788258,26.4886161 11.0038983,26.3063358" id="Fill-4" fill="#802D21" />
                    <path d="M13.3270422,15.8614344 C12.627921,17.5543973 10.171997,17.5543973 9.47287579,15.8614344 C9.20371534,15.2095933 9.10171517,14.5044722 9.17071528,13.804991 C9.42667571,11.2084267 13.3732423,11.2084267 13.6292027,13.804991 C13.6980828,14.5038722 13.5965627,15.2087533 13.3270422,15.8614344" id="Fill-6" fill="#802D21" />
                    <path d="M22.6730178,15.8614344 C23.372139,17.5543973 25.828063,17.5543973 26.5271842,15.8614344 C26.7963447,15.2095933 26.8983448,14.5044722 26.8293447,13.804991 C26.5733843,11.2084267 22.6268177,11.2084267 22.3708573,13.804991 C22.3019772,14.5038722 22.4034973,15.2087533 22.6730178,15.8614344" id="Fill-8" fill="#802D21" />
                </g>
            </g>
        </g>
    </svg>
);

const Unhappy = (props: any) => (
    <Icon component={(innerProps: any) => {
        return UnhappyColored({ ...innerProps, ...props });
    }}
    />
);

export default Unhappy;
