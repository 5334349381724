import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Select,
} from 'antd';
import { ModeType } from 'types/master-data-types';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    amount: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        '& .ant-input-group > .ant-input:first-child': {
            marginRight: 0,
        },
        '& .ant-select-selection-item, .ant-select-single.ant-select-show-arrow': {
            color: '#111111',
            fontSize: 12,
            width: 65,
            fontWeight: 600,
        },
    },
    label: {
        color: '#333333',
        fontSize: 12,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
}

const CODModeField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        form,
        modeTypes,
    } = props;
    const {
        ConsignmentCategory,
        IsCOD,
        Mode,
    } = formFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const isCodValue = Form.useWatch(IsCOD.key, form);

    if (consignmentCategory === 'international') return <></>;
    if (!isCodValue) return <></>;

    const options = modeTypes.map((mode) => {
        return (
            <Select.Option key={mode} value={mode}>
                {t(mode)}
            </Select.Option>
        );
    });
    return (
        <Col span={12}>
            <div className={classes.amount}>
                <span className={classes.label}>{t('codCollectionMode')}</span>
                <Form.Item
                    name={Mode.key}
                    initialValue={Mode.defaultValue}
                    valuePropName={Mode.valuePropName}
                    className={classes.amount}
                >
                    <Select
                        allowClear
                        placeholder={t('mode_placeholder')}
                    >
                        {options}
                    </Select>
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const modeInfo: ModeType[] = config.customer_portal_config?.cod_collection_mode_list || [];
    const modeTypes: string[] = modeInfo.map((mode) => {
        return mode.name;
    });
    return {
        modeTypes: modeTypes.length ? modeTypes : ['Cash'],
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CODModeField);
