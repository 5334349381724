import * as React from 'react';
import { additionalServicesStyles } from './additional-services.styles';
import Paginator from '../common/paginator';
import {
    Button,
    Table,
    Select,
    DatePicker,
    Dropdown,
    Menu,
    message,
    Input,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { additionalServicesColumns, serviceListColumns } from './additional-services.constants';
import { ReduxStore } from '../../reducers/redux.types';
import { bindActionCreators } from 'redux';
import {
    applyFilters,
    setFilters,
    setPagination,
} from '../../actions/generic-action';
import { Buckets, NextOrPrev, Pagination } from '../../library/Types';
import GenericHoc from '../common/generic-hoc';
import { RouteChildrenProps } from 'react-router';
import { NAVBAR_HEIGHT } from 'library/globals';
import Helper from '../../library/Helper';
import { Master } from 'types/master-data-types';
import { useTranslation } from 'react-i18next';
import { loadSuppServices } from 'actions/supplimentary-services-action';
import AddConsignmentRequest from './add-request-consignment';
import moment from 'moment';
import { updateSupplementaryRequest } from 'network/supp-services.api';
import CrossIcon from 'assets/cross';

interface AdditionalServiceProps
    extends StylesProps<ReturnType<typeof additionalServicesStyles>>,
    RouteChildrenProps {
    filters: Record<string, any>;
    loading: boolean;
    uiTheme: ThemeType;
    loadAdditionalServices: () => void;
    pagination: Pagination,
    SuppServicesData: any,
    master: Master,
    setfilters: (bucketId: Buckets.SUPP_SERVICES, filters: any) => void;
    applyfilters: (bucketId: Buckets.SUPP_SERVICES) => void;
    setpagination: (bucketId: Buckets.SUPP_SERVICES, newPagination: Partial<Pagination>) => void;
}


const AdditionalServicesPage = (props: AdditionalServiceProps) => {
    const {
        classes,
        loadAdditionalServices,
        filters,
        setfilters,
        applyfilters,
        setpagination,
        loading,
        pagination,
        SuppServicesData,
        master,
        uiTheme,
    } = props;

    const [toggle, setToggle] = React.useState<boolean>(true);
    const [cnReferenceNumberCreated, setCnReferenceNumberCreated] = React.useState<any>('');
    const { t } = useTranslation();
    const [addTypeForm, setAddTypeForm] = React.useState<'consignment'|'general'|null>(null);
    const [editFormData, setEditFormData] = React.useState<Record<any, any>|null>(null);
    const [selectedRow, setSelectedRow] = React.useState<any>(null);

    const bannerHeight = master?.config?.is_using_new_customer_portal ? 0 : 50;

    React.useEffect(() => {
        if (localStorage.getItem('supplementaryServiceConsignmentReferenceNumber')) {
            setCnReferenceNumberCreated(localStorage.getItem('supplementaryServiceConsignmentReferenceNumber'));
            setAddTypeForm('consignment');
        }
        localStorage.setItem('supplementaryServiceConsignmentReferenceNumber', '');
    }, []);

    React.useEffect(() => {
        loadAdditionalServices();
    }, [toggle, filters]);

    const handleFilterChange = (filter: string, value: any) => {
        const newFilters = {
            ...filters,
            [filter]: value,
        };
        setfilters(Buckets.SUPP_SERVICES, newFilters);
        applyfilters(Buckets.SUPP_SERVICES);
    };

    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const handleEdit = (row: any) => {
        console.log('impt', row);
        setEditFormData(row);
        setAddTypeForm(row?.request_type?.toLowerCase());
    };

    const handleCancel = async (row: any) => {
        const response = await updateSupplementaryRequest({
            supplementary_service_request_id: row?.id,
            supplementary_service_list: row?.supplementary_service_list,
            action_type: 'CANCELLED',
        });
        if (response?.isSuccess) {
            message.success(t('Request Cancelled Successfully!'));
            if (selectedRow) setSelectedRow(null);
            loadAdditionalServices();
        } else {
            message.error(response?.message || t('Something went wrong!'));
        }
    };

    const renderActionsMenu = (row: any) => {
        return (
            <Menu>
                <Menu.Item
                    key="edit"
                    disabled={!(['CREATED'].includes(row?.status))}
                    onClick={() => {
                        handleEdit(row);
                    }}
                >
                    {t('Edit')}
                </Menu.Item>
                <Menu.Item
                    key="cancel"
                    disabled={!(['CREATED'].includes(row?.status))}
                    onClick={() => {
                        handleCancel(row);
                    }}
                >
                    {t('Cancel')}
                </Menu.Item>
            </Menu>
        );
    };

    const renderEditIcon = (row: any, theme: ThemeType) => {
        return (
            <Dropdown overlay={() => renderActionsMenu(row)} placement="bottom">
                <MoreOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: theme.primaryColor, marginRight: 8 }}
                />
            </Dropdown>
        );
    };

    const renderActions = (row: any) => {
        return (
            <div onClick={(e: any) => Helper.stops(e)}>
                {renderEditIcon(row, uiTheme)}
            </div>
        );
    };

    const renderColumn = (text: any, row: any, column: string) => {
        if (column === 'actions') {
            return renderActions(row);
        }
        if (typeof text === 'boolean') {
            return renderText(text ? 'Yes' : 'No');
        }
        if (column === 'created_at' && text) {
            return renderText(moment(text).format('LLL'));
        }
        if (!text || text === null) {
            return <div className={classes.cellNa}>—na—</div>;
        }
        return renderText(text);
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'name':
            case 'phone':
            case 'alternatePhone': return 120;
            case 'serialNumber': return 60;
            default: return 80;
        }
    };

    const getFixed = (column: string) => {
        switch (column) {
            case 'actions': return 'right';
            default: return undefined;
        }
    };

    const getColumns = (): any[] => {
        const columns: any = Object.keys(additionalServicesColumns).map((column: string) => {
            return {
                key: column,
                title: t(additionalServicesColumns[column]),
                dataIndex: column,
                width: getWidth(column),
                ellipsis: true,
                fixed: getFixed(column),
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const getColumnsSuppList = (): any[] => {
        const columns: any = Object.keys(serviceListColumns).map((column: string) => {
            return {
                key: column,
                title: t(serviceListColumns?.[column]),
                dataIndex: column,
                width: getWidth(column),
                ellipsis: true,
                fixed: getFixed(column),
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const renderTable = () => {
        if (selectedRow) return null;
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                rowKey={(row) => row.id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                onRow={(row) => {
                    return {
                        onClick: () => {
                            setSelectedRow(row);
                        },
                    };
                }}
                className={classes.table}
                dataSource={SuppServicesData?.page_data || []}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    const renderStatusFilter = () => {
        return (
            <div className={classes.filter}>
                <Select
                    className={classes.searchInput}
                    allowClear
                    onChange={(value: any) => handleFilterChange('status', value)}
                    placeholder={t('status')}
                    options={[
                        {
                            label: t('Created'),
                            value: 'CREATED',
                        },
                        {
                            label: t('Accepted'),
                            value: 'ACCEPTED',
                        },
                        {
                            label: t('Rejected'),
                            value: 'REJECTED',
                        },
                        {
                            label: t('Canceled'),
                            value: 'CANCELED',
                        },
                        {
                            label: t('Fulfilled'),
                            value: 'FULFILLED',
                        },
                        {
                            label: t('Partially Fulfilled'),
                            value: 'PARTIALLY_FULFILLED',
                        },
                    ]}
                />
            </div>
        );
    };

    const renderReferenceNumberSearchFilter = () => {
        return (
            <div className={classes.filter}>
                <Select
                    onChange={(value: any) => handleFilterChange('referenceNumberType', value)}
                    placeholder={t('Search Type')}
                    allowClear
                    defaultValue={filters.referenceNumberType}
                    options={[
                        {
                            label: t('Reference #'),
                            value: 'supplementary_request_reference_number',
                        },
                        {
                            label: t('Consignment #'),
                            value: 'consignment_reference_number',
                        },
                    ]}
                />
                <Input
                    type="text"
                    placeholder={t('Search')}
                    onChange={(e: any) => handleFilterChange('referenceNumber', e.target.value)}
                />
            </div>
        );
    };

    const renderObjectTypeFilter = () => {
        return (
            <div className={classes.filter}>
                <Select
                    className={classes.searchInput}
                    onChange={(value: any) => handleFilterChange('objectType', value)}
                    placeholder={t('Request Type')}
                    allowClear
                    options={[
                        {
                            label: t('Consignment'),
                            value: 'CONSIGNMENT',
                        },
                        {
                            label: t('General'),
                            value: 'GENERAL',
                        },
                    ]}
                />
            </div>
        );
    };

    const handleDateChange = (value: any) => {
        if (!(value && value[0] && value[1])) return;
        const newFilters = {
            ...filters,
        };
        if (value[1].isSame(filters.toDate)) {
            if (moment(filters.toDate).diff(value[0], 'days') > 30) {
                const endDate = moment(value[0]).add(30, 'd');
                newFilters.toDate = endDate.unix() * 1000;
                newFilters.fromDate = moment(value[0]).unix() * 1000;
            } else {
                newFilters.toDate = moment(value[1]).unix() * 1000;
                newFilters.fromDate = moment(value[0]).unix() * 1000;
            }
        } else if (value[0].isSame(filters.startDate)) {
            if (moment(value[1]).diff(value[0], 'days') > 30) {
                const startDate = moment(value[1]).subtract(30, 'd');
                newFilters.fromDate = startDate.unix() * 1000;
                newFilters.toDate = moment(value[1]).unix() * 1000;
            } else {
                newFilters.toDate = moment(value[1]).unix() * 1000;
                newFilters.fromDate = moment(value[0]).unix() * 1000;
            }
        }
        setfilters(Buckets.SUPP_SERVICES, newFilters);
        applyfilters(Buckets.SUPP_SERVICES);
    };

    const renderCreatedAtFilter = () => {
        return (
            <div className={classes.filter}>
                <DatePicker.RangePicker
                    className={classes.searchInput}
                    onChange={handleDateChange}
                    allowEmpty={[false, false]}
                    allowClear={false}
                    value={[
                        moment(filters.fromDate), moment(filters.toDate),
                    ]}
                    placeholder={[t('From'), t('To')]}
                />
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderReferenceNumberSearchFilter()}
                {renderStatusFilter()}
                {renderObjectTypeFilter()}
                {renderCreatedAtFilter()}
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.SUPP_SERVICES, newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <Paginator
                currentPageNumber={pagination.currentPageNumber}
                isNextPresent={SuppServicesData?.is_next_present}
                onNextClick={() => handlePagination('next')}
                onPrevClick={() => handlePagination('prev')}
            />
        );
    };

    const renderAddOptions = () => {
        return (
            <Menu>
                <Menu.Item key="consignment" onClick={() => setAddTypeForm('consignment')}>
                    {t('Consignment')}
                </Menu.Item>
                <Menu.Item key="general" onClick={() => setAddTypeForm('general')}>
                    {t('General')}
                </Menu.Item>
            </Menu>
        );
    };

    const renderAddButton = () => {
        return (
            <Dropdown overlay={renderAddOptions} placement="bottom">
                <Button
                    type="primary"
                    className={classes.newAddressBtn}
                >
                    {t('Add New Request')}
                </Button>
            </Dropdown>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderAddButton()}
                {renderPaginator()}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        if (selectedRow) return null;
        return (
            <div className={classes.filters}>
                {renderExtraFilters()}
            </div>
        );
    };

    const renderDetailsTitle = (text: string) => {
        return (
            <>
                <div className={classes.detailsTitle}>
                    {t(text)}
                </div>
            </>
        );
    };

    const renderDetail = (label: string, key: string) => {
        const dateCols = ['request_time', 'created_at'];
        return (
            <div className={classes.flexColumn} style={{ margin: '10px 40px 10px 10px' }}>
                <div className={classes.detailLabel}>
                    {t(label)}
                </div>
                <div className={classes.detailValue}>
                    { dateCols.includes(key)
                        ? moment(selectedRow?.[key]).format('LLL')
                        : selectedRow?.[key]}
                </div>
            </div>
        );
    };

    const renderDetailedView = () => {
        if (!selectedRow) return null;
        return (
            <div className={classes.detailedView}>
                {renderDetailsTitle('Basic Details')}
                <div className={classes.flexRowDetails}>
                    {renderDetail('Reference Number', 'reference_number')}
                    {renderDetail('Status', 'status')}
                    {/* {renderDetail('Request Id', 'request_name')} */}
                    {renderDetail('Service Date & Time', 'request_time')}
                    {renderDetail('Consignment #', 'consignment_reference_number')}
                    {renderDetail('Request Type', 'request_type')}
                    {renderDetail('Created At', 'created_at')}
                    {/* {renderDetail('Address Type', 'address_type')} */}
                    {/* {renderDetail('Requested By', 'requested_by')} */}
                    {/* {renderDetail('Is Fulfilled', 'is_fulfilled')} */}
                </div>
                {renderDetailsTitle('Address Details')}
                <div className={classes.flexRowDetails}>
                    {renderDetail('Name', 'address_name')}
                    {renderDetail('Address Line 1', 'address_line_1')}
                    {renderDetail('Address Line 2', 'address_line_2')}
                    {renderDetail('State', 'address_state')}
                    {renderDetail('City', 'address_city')}
                    {renderDetail('Pincode', 'address_pincode')}
                    {renderDetail('Country', 'address_country')}
                    {renderDetail('Phone', 'address_phone')}
                    {renderDetail('Alternate Phone', 'address_alternate_phone')}
                </div>
                {renderDetailsTitle('Supplementary Services')}
                <Table
                    bordered={false}
                    pagination={false}
                    loading={loading}
                    rowKey={(row) => row.id}
                    columns={getColumnsSuppList()}
                    locale={{
                        emptyText: <div className={classes.cellNa}>-NA-</div>,
                    }}
                    className={classes.table}
                    dataSource={selectedRow?.supplementary_service_list || []}
                />
            </div>
        );
    };

    const renderDetailedViewHeader = () => {
        if (!selectedRow) return null;
        return (
            <div className={classes.referenceBox}>
                <div>
                    <CrossIcon onClick={() => setSelectedRow(null)} />
                    <span className={classes.detailsTitle}>
                        {t('Request Details')}
                    </span>
                </div>
                <Dropdown overlay={() => renderActionsMenu(selectedRow)} placement="bottom">
                    <Button
                        type="primary"
                    >
                        {t('Actions')}
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {renderDetailedViewHeader()}
            {renderDetailedView()}
            {addTypeForm && (
                <AddConsignmentRequest
                    onClose={(isSuccess: boolean = false) => {
                        if (selectedRow) setSelectedRow(null);
                        setAddTypeForm(null);
                        if (isSuccess) {
                            loadAdditionalServices();
                        }
                        setEditFormData(null);
                    }}
                    requestType={addTypeForm}
                    cnReferenceNumberCreated={cnReferenceNumberCreated}
                    editFormData={editFormData}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const { SUPP_SERVICES } = generic;
    const {
        loading,
        data,
        pagination,
        appliedFilters,
    } = SUPP_SERVICES;
    return {
        loading,
        SuppServicesData: data,
        uiTheme: state.uiTheme,
        pagination,
        filters: appliedFilters,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadAdditionalServices: loadSuppServices,
        setpagination: setPagination,
        setfilters: setFilters,
        applyfilters: applyFilters,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: additionalServicesStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const AdditionalServices = GenericHoc(hocConfig)(AdditionalServicesPage);
export default AdditionalServices;
