import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
    },
    quantity: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    label: {
        color: '#333333',
        fontSize: 12,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
    params: any,
}

const QuantityField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        params,
        form,
    } = props;
    const {
        ConsignmentCategory,
        Quantity,
    } = formFields;

    const { key } = params;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    if (consignmentCategory === 'international') return <></>;


    return (
        <Col span={12}>
            <div className={classes.quantity}>
                <span className={classes.label}>{t(Quantity.key)}</span>
                <Form.Item
                    name={key}
                    initialValue={Quantity.defaultValue}
                    valuePropName={Quantity.valuePropName}
                    className={classes.quantity}
                    rules={[{
                        validator: (rule, value, cb) => {
                            if (value < 0) {
                                return cb(t('negative_quantity_error'));
                            }
                            return cb();
                        },
                    }]}
                >
                    <Input
                        type="number"
                        placeholder={t('cod_amount_placeholder')}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};


const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(QuantityField);
