/* eslint-disable max-len */
import * as React from 'react';
import Icon from '@ant-design/icons';

const FTLHyperlocal = ({ style }: any) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...(style || {}) }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.8081 22.2889H1.64171C1.28736 22.2889 1 22.0011 1 21.6462V8.45932C1 8.10442 1.28736 7.81661 1.64171 7.81661H14.8081C15.1625 7.81661 15.4498 8.10442 15.4498 8.45932V21.6462C15.4498 22.0011 15.1625 22.2889 14.8081 22.2889Z" fill="#DDA986" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.8081 22.2889H2.63829C2.28388 22.2889 1.99658 22.0011 1.99658 21.6462V8.45932C1.99658 8.10442 2.28388 7.81661 2.63829 7.81661H14.8081C15.1625 7.81661 15.4498 8.10442 15.4498 8.45932V21.6462C15.4498 22.0011 15.1625 22.2889 14.8081 22.2889Z" fill="#EDBA94" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.49481 7.81662C2.45714 7.81662 2.42056 7.82119 2.38527 7.82909C2.64452 11.025 5.31596 13.5379 8.57367 13.5379C11.8357 13.5379 14.51 11.0185 14.7633 7.81662H2.49481Z" fill="#DDA986" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.41336 14.3459L2.90431 16.2352H3.8475V18.5022H4.97922V16.2352H5.92241L4.41336 14.3459Z" fill="#F2F6F7" />
        <path d="M14.9515 7.81662C15.2267 7.81662 15.4498 8.04009 15.4498 8.31568V21.7898C15.4498 22.0654 15.2267 22.2889 14.9515 22.2889H1.4983C1.22307 22.2889 1.00002 22.0654 1.00002 21.7898V8.31568C1.00002 8.04009 1.22307 7.81662 1.4983 7.81662" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4594 18.5022H14.1543V17.2502H10.4594V18.5022Z" fill="#F76725" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4594 18.5022H14.1543V17.2502H10.4594V18.5022Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.4594 16.1105H14.1543" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.97925 16.2351H5.92238L4.41339 14.3458L2.90428 16.2351H3.84747" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.84749 16.2351V18.5021H4.97927V16.2351" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.032 6.8161C14.032 3.60392 11.4321 1 8.22491 1C5.0177 1 2.41788 3.60392 2.41788 6.8161C2.41788 10.0282 5.0177 12.6321 8.22491 12.6321C11.4321 12.6321 14.032 10.0282 14.032 6.8161Z" fill="#FAD42B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.032 6.8161C14.032 3.60392 11.7091 1 8.84357 1C5.97808 1 3.65521 3.60392 3.65521 6.8161C3.65521 10.0282 5.97808 12.6321 8.84357 12.6321C11.7091 12.6321 14.032 10.0282 14.032 6.8161Z" fill="#FFE261" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.032 6.8161C14.032 3.60392 11.4321 1 8.22491 1C5.0177 1 2.41788 3.60392 2.41788 6.8161C2.41788 10.0282 5.0177 12.6321 8.22491 12.6321C11.4321 12.6321 14.032 10.0282 14.032 6.8161Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4392 6.83775C12.4392 4.51244 10.5554 2.62752 8.23156 2.62752C5.90767 2.62752 4.0238 4.51244 4.0238 6.83775C4.0238 9.16293 5.90767 11.0479 8.23156 11.0479C10.5554 11.0479 12.4392 9.16293 12.4392 6.83775Z" fill="#FEF0AF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4392 6.83775C12.4392 4.51244 10.8057 2.62752 8.79075 2.62752C6.77572 2.62752 5.14224 4.51244 5.14224 6.83775C5.14224 9.16293 6.77572 11.0479 8.79075 11.0479C10.8057 11.0479 12.4392 9.16293 12.4392 6.83775Z" fill="#FFF9DF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4107 6.83775C12.4107 4.5226 10.5396 2.64577 8.23154 2.64577C5.92337 2.64577 4.05234 4.5226 4.05234 6.83775C4.05234 9.15284 5.92337 11.0297 8.23154 11.0297C10.5396 11.0297 12.4107 9.15284 12.4107 6.83775Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.3208 6.83775C11.3208 5.12892 9.9377 3.74364 8.23152 3.74364C6.52534 3.74364 5.14226 5.12892 5.14226 6.83775C5.14226 8.54657 6.52534 9.9318 8.23152 9.9318C9.9377 9.9318 11.3208 8.54657 11.3208 6.83775Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 3.3" />
        <path d="M9.6475 6.85695H8.23157" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.242 4.94786L8.25264 6.83774" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.00002 2.79229H2.44502" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.00002 4.47349H1.79728" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.00002 6.15469H1.44844" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.00002 7.83583H1.29899" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.9192 22.3606H15.4521V10.2338H20.2309L24.9192 15.3764V22.3606Z" fill="#D51323" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.9192 22.3606H16.6977V10.2338H20.2309L24.9192 15.3764V22.3606Z" fill="#F12D3D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.9192 22.3606H15.4521V10.2338H20.2309L24.9192 15.3764V22.3606Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.2397 16.2872H16.848V12.0573H19.2695L22.4765 15.7679C22.6523 15.9712 22.5081 16.2872 22.2397 16.2872Z" fill="#6695E1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.1621 16.2872H16.848V12.0573H19.2695L22.4258 15.7092C22.6214 15.9354 22.4609 16.2872 22.1621 16.2872Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.00002 22.4972H25V20.8176H1.00002V22.4972Z" fill="#93BDCB" />
        <path d="M12.3909 20.8176H24.8933V22.4973H1V20.8176H10.6684" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.35765 22.5048C7.35765 23.8829 6.24222 25 4.86627 25C3.49031 25 2.37495 23.8829 2.37495 22.5048C2.37495 21.1268 3.49031 20.0097 4.86627 20.0097C6.24222 20.0097 7.35765 21.1268 7.35765 22.5048Z" fill="#5A6464" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.35765 22.5048C7.35765 23.8829 6.24222 25 4.86627 25C3.49031 25 2.37495 23.8829 2.37495 22.5048C2.37495 21.1268 3.49031 20.0097 4.86627 20.0097C6.24222 20.0097 7.35765 21.1268 7.35765 22.5048Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.81297 22.5048C5.81297 23.0284 5.38919 23.453 4.86626 23.453C4.34345 23.453 3.91954 23.0284 3.91954 22.5048C3.91954 21.9812 4.34345 21.5566 4.86626 21.5566C5.38919 21.5566 5.81297 21.9812 5.81297 22.5048Z" fill="#F2F6F7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.81297 22.5048C5.81297 23.0284 5.38919 23.453 4.86626 23.453C4.34345 23.453 3.91954 23.0284 3.91954 22.5048C3.91954 21.9812 4.34345 21.5566 4.86626 21.5566C5.38919 21.5566 5.81297 21.9812 5.81297 22.5048Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4573 22.5048C22.4573 23.8829 21.3419 25 19.966 25C18.59 25 17.4747 23.8829 17.4747 22.5048C17.4747 21.1268 18.59 20.0097 19.966 20.0097C21.3419 20.0097 22.4573 21.1268 22.4573 22.5048Z" fill="#5A6464" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4573 22.5048C22.4573 23.8829 21.3419 25 19.966 25C18.59 25 17.4747 23.8829 17.4747 22.5048C17.4747 21.1268 18.59 20.0097 19.966 20.0097C21.3419 20.0097 22.4573 21.1268 22.4573 22.5048Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.9127 22.5048C20.9127 23.0284 20.4889 23.453 19.9659 23.453C19.4431 23.453 19.0192 23.0284 19.0192 22.5048C19.0192 21.9812 19.4431 21.5566 19.9659 21.5566C20.4889 21.5566 20.9127 21.9812 20.9127 22.5048Z" fill="#F2F6F7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.9127 22.5048C20.9127 23.0284 20.4889 23.453 19.9659 23.453C19.4431 23.453 19.0192 23.0284 19.0192 22.5048C19.0192 21.9812 19.4431 21.5566 19.9659 21.5566C20.4889 21.5566 20.9127 21.9812 20.9127 22.5048Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.8191 19.1285H24.9195V17.7312H22.8191V19.1285Z" fill="#FCDE8B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.8191 19.1285H24.9195V17.7312H22.8191V19.1285Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.7249 17.8183H17.7214" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const FTLHyperlocalIcon = (props: any) => (
    <Icon
        component={(innerProps: any) => {
            return FTLHyperlocal({ ...innerProps, ...props });
        }}
    />
);

export default FTLHyperlocalIcon;
