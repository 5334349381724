/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const APISvg = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor" d="M7 7H5A2 2 0 0 0 3 9V17H5V13H7V17H9V9A2 2 0 0 0 7 7M7 11H5V9H7M14 7H10V17H12V13H14A2 2 0 0 0 16 11V9A2 2 0 0 0 14 7M14 11H12V9H14M20 9V15H21V17H17V15H18V9H17V7H21V9Z" />
    </svg>
);

const APIIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return APISvg({ ...innerProps, ...props });
    }}
    />
);

export default APIIcon;
