import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

export const trackingStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 16,
            position: 'fixed',
            width: '100%',
            zIndex: 10,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-btn-link': {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            fontSize: 12,
            color: theme.primaryColor,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    inputs: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F7F1E0',
        padding: '16px 8%',
        marginTop: 50,
    },
    input: {
        width: '36%',
        margin: '0 2px',
    },
    availableText: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 11,
        letterSpacing: 0,
    },
    trackText: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
    },
    reference: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: 0,
        marginLeft: 8,
        marginRight: 16,
    },
    body: {
        padding: 24,
        paddingBottom: 8,
    },
    hr: {
        width: '92%',
        height: 1,
        marginLeft: '4%',
        backgroundColor: '#CCCCCC',
    },
    pincodeTitle: {
        marginLeft: '1.5%',
    },
});
