import * as React from 'react';
import PersistentIframeController from 'components/persistent-iframe/persistent-iframe-controller';
import { IframeApps } from 'components/persistent-iframe/persistent-iframe.constants';
import { useLocation } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

const ANALYTICS_BASE_PATH = '/api-playground';

const APIPlayground = () => {
    const location = useLocation();
    const route = location.pathname.substring(ANALYTICS_BASE_PATH.length) + location.search;

    return (
        <>
            <DocumentTitle title="API Playground" />
            <PersistentIframeController
                route={route}
                appName={IframeApps.APIPlayground}
            />
        </>
    );
};

export default APIPlayground;
