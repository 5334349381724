/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const AdditionalServicesSVG = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <path d="M5.83333 14.1667C5.83333 14.6087 5.65774 15.0326 5.34518 15.3452C5.03262 15.6577 4.60869 15.8333 4.16667 15.8333C3.72464 15.8333 3.30072 15.6577 2.98816 15.3452C2.67559 15.0326 2.5 14.6087 2.5 14.1667C2.5 13.7246 2.67559 13.3007 2.98816 12.9882C3.30072 12.6756 3.72464 12.5 4.16667 12.5C4.60869 12.5 5.03262 12.6756 5.34518 12.9882C5.65774 13.3007 5.83333 13.7246 5.83333 14.1667ZM5.83333 14.1667H10M10 14.1667C10 14.6087 10.1756 15.0326 10.4882 15.3452C10.8007 15.6577 11.2246 15.8333 11.6667 15.8333C12.1087 15.8333 12.5326 15.6577 12.8452 15.3452C13.1577 15.0326 13.3333 14.6087 13.3333 14.1667C13.3333 13.7246 13.1577 13.3007 12.8452 12.9882C12.5326 12.6756 12.1087 12.5 11.6667 12.5C11.2246 12.5 10.8007 12.6756 10.4882 12.9882C10.1756 13.3007 10 13.7246 10 14.1667Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 14.1667V9.16675H13.3333M13.3333 9.16675V14.1667M13.3333 9.16675L10.8333 4.16675H7.5V9.16675M4.16667 9.16675V5.83341H7.5M18.3333 12.5001H15.8333V4.16675M13.3333 10.8334H15.8333" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const AdditionalServiceIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return AdditionalServicesSVG({ ...innerProps, ...props });
    }}
    />
);

export default AdditionalServiceIcon;
