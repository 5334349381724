/* eslint-disable max-len */
import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from '../../theme/jss-types';

export const addRequestStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        paddingBottom: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        '& .ant-input, .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-input-number-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-checkbox-group-item:last-child': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            // marginLeft: 24,
        },
        '& .ant-form-item-control-input': {
            minHeight: 24,
        },
        '& .ant-form-item': {
            marginBottom: 0,
        },
        // '& .ant-radio-wrapper': {
        //     color: '#111111',
        //     fontFamily: 'Open Sans',
        //     fontSize: 14,
        //     fontWeight: 600,
        //     letterSpacing: 0,
        //     margin: 4,
        // },
        '& .ant-picker': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-form-item-control-input-content': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .ant-col-12': {
            marginTop: 10,
        },
    },
    input: {
        boxSizing: 'border-box',
        height: 32,
        border: '1px solid #999999',
        borderRadius: 4,
        marginRight: 2,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    content: {
        marginLeft: 10,
        marginRight: 10,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    searchAddress: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'flex-end',
        margin: 0,
    },
    consigneeDetailsInput: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        margin: 0,
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
        },
    },
    searchText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    addressList: {
        ...commonStyleSheet(theme).flexColumn,
        marginBottom: 12,
        maxHeight: 350,
        overflow: 'auto',
    },
    addressLine: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        width: '98%',
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
        margin: '4px 0px',
        cursor: 'pointer',
    },
    addressLineSelected: {
        backgroundColor: '#E7F5FF',
    },
    addressDetails: {
        ...commonStyleSheet(theme).flexRow,
        // justifyContent: 'space-between',
        alignItems: 'top',
        width: '100%',
        marginTop: 16,
    },
    consigneeDetails: {
        alignItems: 'top',
        width: '100%',
        margin: '16px 0 0 16px',
    },
    addressLineDetails: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        alignItems: 'top',
        width: '100%',
        padding: '0px 10px 0px 25px',
    },
    addressMenu: {
        ...commonStyleSheet(theme).flexColumn,
        marginTop: 16,
        minWidth: 188,
        boxSizing: 'border-box',
        width: 1,
        borderRight: '1px solid #EDEDED',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        main: {
            '& .ant-radio-wrapper': {
                backgroundColor: 'blue',
            },
        },
    },
    addressItem: {
        ...commonStyleSheet(theme).flexRow,
        height: 42,
        width: 188,
        alignItems: 'center',
        cursor: 'pointer',
    },
    addressItemSelected: {
        ...commonStyleSheet(theme).flexRow,
        height: 42,
        width: 188,
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        borderRight: `4px solid ${theme.secondryColor}`,
    },
    tabIcon: {
        height: 24,
        width: 24,
        borderRadius: 12,
        margin: '0px 10px',
    },
    filledCircle: {
        height: 20,
        width: 20,
        borderRadius: 10,
        border: '2px solid #FFF',
    },
});
