import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';


export const bulkConsignmentStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 24,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-btn-link': {
            marginLeft: 6,
            marginRight: 6,
            fontSize: 16,
            fontWeight: 600,
            color: theme.primaryColor,
            padding: 0,
            letterSpacing: 0,
            fontFamily: 'Open Sans',
        },
        '&  .ant-btn-link:hover': {
            color: theme.secondryColor,
        },
        '& .ant-upload-list-item-name': {
            fontSize: 14,
            fontWeight: 600,
            color: '#111',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#333333',
        fontSize: 20,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
    },
    newButton: {
        backgroundColor: `${theme.primaryColor} !important`,
    },
    newButtonDisabled: {
        backgroundColor: '#EDEDED !important',
    },
    uploadBox: {
        align: 'center',
        boxSizing: 'border-box',
        height: 275,
        border: `2px dashed ${theme.primaryColor}`,
        borderRadius: 2,
        backgroundColor: '#F7F7F7',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        width: '100%',
    },
    uploadText: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 32,
    },
    uploadHint: {
        fontSize: 16,
        color: '#666666',
    },
    downloadBtn: {
        marginTop: 12,
        height: 90,
        width: '100%',
        padding: 0,
    },
    consignmentCategory: {
        ...(commonStyleSheet(theme)).justifySpaceBetween,
        width: '100%',
        fontWeight: 'bold',
        padding: 0,
        marginBottom: 20,
    },
});
