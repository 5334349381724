import { CloseOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Radio,
    Drawer,
    Input,
    message,
    Select,
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import Helper from 'library/Helper';
import { uniq } from 'library/lodash-helper';
import { uniqueId } from 'lodash';
import {
    cancelConsignments,
    revokeCancellation,
    getCancellationReason,
} from 'network/consignments.api';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { cancellationStyles } from './consignment-cancel.styles';
import Cross from '../../assets/cross';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

const prettyNameMapping: any = {
    BULK: 'bulk_upload',
    CANCEL: 'mark_cancelled',
    REVOKE: 'release_cancelled',
};

interface CancellationProps extends StylesProps<ReturnType<typeof cancellationStyles>> {
    onClose: (reload: boolean, params?: any) => void;
    action: string;
    consignments: string[];
    uiTheme: ThemeType;
}

const CancellationModal = (props: CancellationProps) => {
    const {
        classes,
        onClose,
        action,
        consignments,
        uiTheme,
    } = props;

    const [cancelling, setCancelling] = React.useState<boolean>(false);
    const [reasonCode, setReasonCode] = React.useState<string>('PCC');
    const [inputValue, setInputValue] = React.useState<string | undefined>();
    const [actualReason, setActualReason] = React.useState<string | undefined>();
    const [actionType, setActionType] = React.useState<string>('CANCEL');
    const [list, setList] = React.useState<{ id: string, cn: string }[]>([]);
    const [reasonList, setReasonList] = React.useState<any>({});
    const [consignmentList, setConsignmentList] = React.useState<string[]>([]);
    const { t } = useTranslation();

    const getReasons = async () => {
        const response = await getCancellationReason();
        setReasonList(response?.data || {});
    };

    React.useEffect(() => {
        getReasons();
        setList(consignments.map((item: string) => ({
            id: uniqueId(),
            cn: item,
        })));
    }, []);

    const handleCancellation = async () => {
        setCancelling(true);

        const apiBody: any = {
            consignments: action === 'BULK' ? consignmentList : list.map((item) => (item.cn)),
        };

        let response: any;
        let successMsg = '';
        if (action === 'CANCEL' || (action === 'BULK' && actionType === 'CANCEL')) {
            apiBody.reason = reasonCode ? reasonList[reasonCode] : reasonCode;
            apiBody.reasonCode = reasonCode;
            if (reasonCode === 'OOT') {
                apiBody.reason = actualReason;
            }
            response = await cancelConsignments(apiBody);
            successMsg = 'Consignments Cancelled Successfully';
        } else {
            response = await revokeCancellation(apiBody);
            successMsg = 'Consignments Cancellation Revoked Successfully';
        }

        setCancelling(false);

        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else if (response?.data?.failures?.length) {
            onClose(true, { failures: response?.data?.failures });
        } else {
            message.success(successMsg);
            onClose(true);
        }
    };

    const renderSerial = (serial: number) => {
        return (
            <span className={classes.serial}>
                {serial + 1}
            </span>
        );
    };

    const renderReferenceNumber = (item: { cn: string, id: string }) => {
        return (
            <span className={classes.reference}>
                {item.cn}
            </span>
        );
    };

    const renderActions = (item: { cn: string, id: string }) => {
        return (
            <CloseOutlined
                style={{ color: 'red' }}
                onClick={() => {
                    const newList = list.filter((listItem) => listItem.id !== item.id);
                    setList(newList);
                }}
                className={classes.icon}
            />
        );
    };

    const renderListItem = (item: { cn: string, id: string }, idx: number) => {
        return (
            <div className={classes.itemBox}>
                {renderSerial(idx)}
                {renderReferenceNumber(item)}
                {renderActions(item)}
            </div>
        );
    };

    const renderList = () => {
        return (
            <div className={classes.list}>
                {list.map((item, idx) => {
                    return renderListItem(item, idx);
                })}
            </div>
        );
    };

    const renderReason = () => {
        if ((actionType !== 'CANCEL' && action === 'BULK') || action === 'REVOKE') {
            return null;
        }
        return (
            <div className={classes.column}>
                <span className={classes.inputTitle}>
                    {t('reason')}
                    *
                </span>
                <Select
                    value={reasonCode}
                    placeholder={t('select_reason')}
                    onChange={(value: string) => {
                        setReasonCode(value);
                    }}
                    className={classes.input}
                >
                    {Object.keys(reasonList).map((item) => {
                        return (
                            <Select.Option key={item} value={item}>
                                {reasonList[item]}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        );
    };

    const isDesabled = () => {
        if ((!consignmentList || !consignmentList?.length) && (!list || !list.length)) {
            return true;
        }
        if (action === 'CANCEL' || (action === 'BULK' && actionType === 'CANCEL')) {
            if (!reasonCode) {
                return true;
            }
            if (reasonCode === 'OOT' && !actualReason) {
                return true;
            }
        }
        return false;
    };

    const renderInput = () => {
        return (
            <div className={classes.row}>
                <span className={classes.fieldTitle}>{t('cn_number')}</span>
                <Input
                    style={{ width: '70%' }}
                    placeholder={t('reference_number_input')}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={(e: any) => {
                        const newReference = {
                            id: uniqueId(),
                            cn: e.target.value,
                        };
                        setList([...list, newReference]);
                        setInputValue('');
                    }}
                    className={classes.input}
                />
                <Button
                    type="ghost"
                    style={{
                        boxSizing: 'border-box',
                        border: `1px solid ${uiTheme.primaryColor}`,
                        borderRadius: 3,
                        color: uiTheme.primaryColor,
                        fontSize: 12,
                        fontWeight: 'bold',
                    }}
                    disabled={Boolean(!inputValue)}
                    onClick={() => {
                        if (!inputValue) {
                            return;
                        }
                        const newReference = {
                            id: uniqueId(),
                            cn: inputValue,
                        };
                        setList([...list, newReference]);
                        setInputValue('');
                    }}
                >
                    {t('add')}
                </Button>
            </div>
        );
    };

    const renderReasonInput = () => {
        if ((actionType !== 'CANCEL' && action === 'BULK') || action === 'REVOKE') {
            return null;
        }
        if (reasonCode !== 'OOT') {
            return null;
        }
        return (
            <div style={{ marginTop: 12 }} className={classes.column}>
                <span className={classes.inputTitle}>
                    {t('actual_reason')}
                    *
                </span>
                <Input
                    placeholder={t('enter_reason')}
                    value={actualReason}
                    onChange={(e) => setActualReason(e.target.value)}
                    onPressEnter={(e: any) => {
                        setActualReason(e.target.value);
                    }}
                    className={classes.input}
                />
            </div>
        );
    };

    const renderSerialTitle = () => {
        return (
            <span className={classes.serialTitle}>
                {t('serialNumber')}
            </span>
        );
    };

    const renderReferenceTitle = () => {
        return (
            <span className={classes.referenceTitle}>
                {t('ref_number')}
            </span>
        );
    };

    const renderActionTitle = () => {
        return (
            <span className={classes.actionTitle}>
                {t('action')}
            </span>
        );
    };

    const renderColumns = () => {
        return (
            <div className={classes.columnRow}>
                {renderSerialTitle()}
                {renderReferenceTitle()}
                {renderActionTitle()}
            </div>
        );
    };

    const renderTitle = () => {
        return (
            <div className={classes.title}>
                {t('consignments')}
            </div>
        );
    };

    const renderBody = () => {
        if (action === 'BULK') {
            return null;
        }

        return (
            <div>
                {renderReason()}
                {renderReasonInput()}
                {renderTitle()}
                {renderInput()}
                {renderColumns()}
                {renderList()}
            </div>
        );
    };

    const renderAction = () => {
        if (action !== 'BULK') {
            return null;
        }

        return (
            <div className={classes.actions}>
                <span className={classes.fieldTitle}>{t('action')}</span>
                <div className={classes.row}>
                    <Radio
                        onClick={() => setActionType('CANCEL')}
                        checked={actionType === 'CANCEL'}
                    >
                        {t('mark_cancelled')}
                    </Radio>
                    <Radio
                        onClick={() => setActionType('REVOKE')}
                        checked={actionType === 'REVOKE'}
                    >
                        {t('release_cancelled')}
                    </Radio>
                </div>
            </div>
        );
    };

    const getConsignmentArray = (data: any[]) => {
        const slicedData = data.slice(1);
        let consignmentsToSet = slicedData.map((item) => {
            return item ? item[0] : '';
        });
        consignmentsToSet = uniq(consignmentsToSet);
        return consignmentsToSet;
    };

    const handleParsedData = async (data: any[]) => {
        if (!data || !data.length) return;
        const slicedData = getConsignmentArray(data[0]);
        setConsignmentList(slicedData);
    };

    const mapping = ['reference_number'];

    const onFileRecieved = async (file: any) => {
        const consignmentArr: any[] = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
        handleParsedData(consignmentArr);
    };

    const handleUploadChange = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            onFileRecieved(info.file.originFileObj);
        }
    };

    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };

    const renderUpload = () => {
        return (
            <div
                className={classes.uploadBox}
            >
                <Dragger
                    multiple={false}
                    accept=".xlsx, .xls, .csv"
                    name="file"
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                    showUploadList={false}
                >
                    <div>
                        <div className={classes.uploadText}>
                            <UploadOutlined className={classes.closeIcon} />
                            {t('upload_excel_text')}
                        </div>
                        <p className={classes.uploadHint}>
                            {t('drag_and_drop_text')}
                        </p>
                        <p className={classes.uploadHint}>
                            or
                        </p>
                        <p className={classes.uploadHint}>
                            <a type="link" style={{ marginRight: 6 }}>Browse</a>
                            to choose file
                        </p>
                    </div>
                </Dragger>
            </div>
        );
    };

    const renderSampleDownload = () => {
        return (
            <a
                className={classes.downloadSample}
                // eslint-disable-next-line max-len
                href="https://shipsy-public-assets.s3-us-west-2.amazonaws.com/shipsy/sample-downloads/ops/bulk_scan_sample.xlsx"
            >
                <DownloadOutlined
                    size={14}
                    style={{
                        marginRight: 8,
                        fontWeight: 'bold',
                    }}
                />
                {t('download_sample_file')}
            </a>
        );
    };

    const renderBulk = () => {
        if (action !== 'BULK') {
            return null;
        }

        return (
            <div className={classes.bulkOps}>
                {renderAction()}
                {renderReason()}
                {renderReasonInput()}
                {renderUpload()}
                {renderSampleDownload()}
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross
                        onClick={() => onClose(false)}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>
                        {t(prettyNameMapping[action])}
                    </span>
                </div>
                <Button
                    type="primary"
                    loading={cancelling}
                    onClick={() => handleCancellation()}
                    disabled={isDesabled()}
                >
                    {t('submit')}
                </Button>
            </div>
        );
    };

    return (
        <Drawer
            title={renderHeader()}
            visible
            width="30%"
            className={classes.main}
            onClose={() => onClose(false)}
            closable={false}
        >
            {renderBody()}
            {renderBulk()}
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(cancellationStyles)(GenericHoc(hocConfig)(CancellationModal));
