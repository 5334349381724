import * as React from 'react';
import Cross from '../../assets/cross';
import { Drawer } from 'antd';
import withStyles from 'react-jss';
import { commonStyleSheet } from '../../library/common-styles';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { fetchConsignmentTracking } from '../../network/consignments.api';
import { useTranslation } from 'react-i18next';
import GalleryModal from '../common/gallery-modal';

const styles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 16,
            position: 'fixed',
            width: '100%',
            zIndex: 10,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-btn-link': {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            fontSize: 12,
            color: `${theme.primaryColor} !important`,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        fontSize: 16,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    availableText: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 11,
        letterSpacing: 0,
    },
    trackText: {
        marginTop: 50,
        padding: '8px 16px',
        backgroundColor: '#E6FFE5',
        justifyContent: 'space-between',
        color: '#111111',
        fontSize: 12,
        fontStyle: 'italic',
        fontWeight: 600,
        letterSpacing: 0,
    },
    body: {
        padding: 16,
        paddingBottom: 8,
    },
    hr: {
        width: '92%',
        height: 1,
        marginLeft: '4%',
        backgroundColor: '#CCCCCC',
    },
    name: {
        color: '#666666',
        fontSize: 10,
        letterSpacing: 0,
        width: '25%',
    },
    value: {
        color: '#111111',
        fontSize: 12,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    pair: {
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 16,
    },
});
interface TrackConsignmentProps extends StylesProps<ReturnType<typeof styles>> {
    onClose: () => void;
    podConsignment?: any;
}

interface GalleryModalData {
    images: string[];
    videos: string[];
}

const PodDetails = (props: TrackConsignmentProps) => {
    const [tracking, setTracking] = React.useState<any>({});
    const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
    const [galleryModalData, setGalleryModalData] = React.useState<GalleryModalData>({ images: [], videos: [] });
    const {
        classes,
        onClose,
        podConsignment,
    } = props;

    const referenceNumber = podConsignment.reference_number;
    const loadTracking = async () => {
        const trackingResponse = await fetchConsignmentTracking({ referenceNumber });
        const trackingData = (trackingResponse?.data?.events || []);
        setTracking(trackingData || []);
    };

    const { t } = useTranslation();

    let imageList: any[] = [];

    for (let i = 0; i < tracking.length; i += 1) {
        if (tracking[i].type === 'delivered') {
            let deliveredTaskImages = [];
            if (Array.isArray(tracking[i].event_proof_images) && tracking[i].event_proof_images.length) {
                deliveredTaskImages = tracking[i].event_proof_images;
            } else if (tracking[i].premise_image) {
                deliveredTaskImages = [tracking[i].premise_image];
            }
            imageList = deliveredTaskImages;
            break;
        }
    }

    React.useEffect(() => {
        loadTracking();
    }, []);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('proof_of_delivery')}
                    </span>
                </div>
            </div>
        );
    };

    const renderDeliveredText = () => {
        return (
            <div className={classes.trackText}>
                {t('consignment')}
                &nbsp;
                {podConsignment.reference_number}
                &nbsp;
                {t('customer_portal_delivered')}
            </div>
        );
    };

    const renderPair = (name: string, value: string) => {
        return (
            <div
                className={classes.pair}
            >
                <span className={classes.name}>{name}</span>
                <span className={classes.value}>{value || '-'}</span>
            </div>
        );
    };

    const renderImagePair = (name: string, value: any[]) => {
        return (
            <div
                className={classes.pair}
            >
                <span className={classes.name}>{name}</span>
                <div>
                    {value ? (
                        <a onClick={() => {
                            setIsGalleryModalVisible(true);
                            setGalleryModalData({
                                images: value || [],
                                videos: [],
                            });
                        }}
                        >
                            View Image
                        </a>
                    ) : 'Not Available'}
                </div>
            </div>
        );
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                {renderPair(t('runsheet_number'), podConsignment.trip_id)}
                {renderPair(t('receipent_name'), podConsignment.receiver_name)}
                {renderPair(t('delivered_to'), podConsignment.receiver_relation)}
                {renderPair(t('kyc_type'), podConsignment.delivery_kyc_type)}
                {renderPair(t('kyc_number'), podConsignment.delivery_kyc_number)}
                {renderPair(t('receipent_phone'), podConsignment.receiver_phone)}
                {renderPair(t('address_type'), podConsignment.receiver_phone)}
                {renderPair(t('comments'), podConsignment.comments)}
                {renderImagePair(t('attachment'), imageList)}
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="30%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderDeliveredText()}
            {renderBody()}
            {isGalleryModalVisible && (
                <GalleryModal
                    isVisible={isGalleryModalVisible}
                    imageVideoGalleryData={galleryModalData}
                    handleModalClose={() => { setIsGalleryModalVisible(false); }}
                />
            )}
        </Drawer>
    );
};

const ProofOfDelivery = withStyles(styles)(PodDetails);
export default ProofOfDelivery;
