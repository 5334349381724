/* eslint-disable max-len */
import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from '../../theme/jss-types';

const consignemtStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        padding: '0px 8px',
        '& .ant-table-container table > thead > tr:first-child th:first-child': {
            padding: '8px 0px',
        },
        '& .ant-table-thead > tr > th': {
            padding: '8px 8px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
            lineHeight: '12px',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 8px',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 100,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 12,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-select': {
            marginRight: 24,
            marginLeft: 24,
        },
        '& .ant-table-selection': {
            marginLeft: 8,
            marginRight: 8,
        },
        '& .ant-picker-range-separator, .ant-picker-input': {
            margin: 0,
            padding: 0,
        },
        backgroundColor: theme.backgroundColor,
    },
    pageFilter: {
        display: 'flex',
        marginBottom: 8,
        borderBottom: '1px solid #D9D9D9',
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        color: '#666666',
        padding: '12px 50px',
        cursor: 'pointer',
    },
    selectedPage: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: theme.primaryColor,
        padding: '12px 50px',
        borderBottom: `4px solid ${theme.primaryColor}`,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 180,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    actionButton: {
        marginRight: 8,
    },
    count: {
        height: 18,
        padding: 10,
        borderRadius: 9,
        backgroundColor: '#E9E9E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        color: '#333',
        fontWeight: 600,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    countSelected: {
        backgroundColor: theme.primaryColor,
        color: '#FFF',
        fontWeight: 'bold',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    notesCellValue: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    tableRowColor: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
        '&& tbody > tr:hover > td': {
            background: '#E7F5FF',
        },
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    ellipsisIcon: {
        fontSize: 20,
    },
    tabsContainer: {
        '& .ant-tabs-bar': {
            margin: '0px',
        },
        '& .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab': {
            padding: 4,
        },
        '& .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav': {
            margin: 0,
        },
        '& .ant-tabs-tab': {
            color: '#666666',
            minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            borderRadius: '4px 4px 0 0',
            backgroundColor: '#F5F5F5',
            cursor: 'pointer',
        },
        '& .ant-tabs-dropdown-menu-item': {
            color: '#666666',
            minWidth: 175,
            fontFamily: 'Open Sans',
            fontSize: 14,
            letterSpacing: 0,
            padding: '2px 8px',
            marginRight: 2,
            backgroundColor: '#F5F5F5',
            cursor: 'pointer',
        },
        '& .ant-tabs-tab.ant-tabs-tab-active': {
            color: '#111111',
            fontWeight: 'bold',
            backgroundColor: '#FFF',
        },
        '& .ant-tabs-tab-btn': {
            display: 'flex',
            width: '100%',
            padding: '0px 6px',
            justifyContent: 'space-between',
        },
        '& .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab': {
            marginRight: 0,
        },
        '& .ant-tabs-nav-more span': {
            transform: 'rotate(90deg)',
        },
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 12px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    moreFilter: {
        fontFamily: 'Open Sans',
        marginLeft: 1,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
        fontFamily: 'Open Sans',
    },
    bucketRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'scroll',
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        flexDirection: 'row',
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor} `,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
    },
    downloadBtn: {
        marginRight: 8,
    },
    settingIcon: {
        color: '#333333',
        fontSize: 20,
        cursor: 'pointer',
        marginLeft: 8,
    },
    menu: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    modalContainer: {
        padding: '1rem 0',
        paddingBottom: '3rem',
        minWidth: '40%',
    },
    carouselContainer: {
        padding: '1.5rem',
        maxWidth: '100%',
        '& span': {
            color: '#222021 !important',
        },
        '& span > svg': {
            width: '120%',
            height: '120%',
        },
    },
    popupbox: {
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '4px',
    },
    popupheader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#10477C',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '19px',
        padding: '14px',
    },
    modal: {
        '& .ant-modal-mask': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        '& .ant-modal-header': {
            backgroundColor: '#082E78',
            borderRadius: '4px 4px 0 0',
        },
        '& .ant-modal-title, .ant-modal-close-x': {
            color: '#FFFFFF',
        },
        '& .ant-modal-content': {
            borderRadius: 4,
            boxShadow: 'none !important',
        },
        '& .ant-modal-body': {
            padding: '0px !important',
        },
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    horizontalline: {
        height: '1px',
        backgroundColor: '#CCCCCC',
    },
    modalBody: {
        padding: '8px',
        fontSize: '13px !important',
        minWidth: 450,
    },
    uploadText: {
        color: '#111111',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 32,
    },
    closeIcon: {
        lineHeight: '24px',
        margin: '0px 6px',
        padding: '8px',
        width: 40,
        cursor: 'pointer',
        color: '#666666',
        fontWeight: 'bold',
    },
    uploadHint: {
        fontSize: 16,
        color: '#666666',
    },
    modalfooter: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mdbutton: {
        borderRadius: '2px',
        border: '0 !important',
        margin: '6px 8px',
        fontWeight: 500,
        minHeight: '36px',
        minWidth: '88px',
        textAlign: 'center',
        verticalAlign: 'middle',
        padidng: '0 8px',
    },
    dropdown: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap-reverse',
        alignItems: 'center',
    },
    grayCircle: {
        width: '32px',
        height: '32px',
        fontSize: '14px',
        backgroundColor: '#F5F5F5',
        borderRadius: '64px',
        marginRight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    customFlex: {
        display: 'flex', alignItems: 'center',
    },
});

export {
    consignemtStyles,
};
