import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { setIframeLoaded } from '../../actions/iframe-actions';
import { useHistory } from 'react-router-dom';
import { Store } from 'antd/lib/form/interface';
import { settingsStyles } from './persistent-iframe.style';
import GenericHoc from '../common/generic-hoc';
import { HocOptions } from '../common/generic-hoc.types';
import { IncomingActions } from './persistent-iframe.types';
import { flushMessageQueue, setAppLoaded } from './persistent-iframe.helper';
import { AppMetadata } from './persistent-iframe.constants';
import { StylesProps } from '../../theme/jss-types';

interface IFrameProps extends StylesProps<typeof settingsStyles> {
    iframeInfo: any
    appName: string;
    setiframeLoaded: (name: string, loaded: boolean) => void;
}

const PersistentIframeInjector = (props: IFrameProps) => {
    const {
        classes,
        iframeInfo = {},
        appName,
        setiframeLoaded,
    } = props;

    const { dimensions = {}, isRendered } = iframeInfo;
    const metadata = AppMetadata[appName];
    const history = useHistory();

    const onApplicationLoad = (event: any) => {
        if (event.origin !== metadata.origin) {
            return;
        }
        let action: any = {};
        try {
            action = JSON.parse(event.data);
        } catch (e) {
            return;
        }
        if (!(action.type === IncomingActions.Loaded && action.applicationId === appName)) {
            return;
        }
        setiframeLoaded(appName, true);
        setAppLoaded(appName);
        flushMessageQueue(appName);
    };

    const replaceIncomingRouteChange = (incomingRoute: any) => {
        const currentRoute = window.location.pathname.replace(metadata.basePath, '');
        if (incomingRoute !== currentRoute) {
            history.push(`${metadata.basePath}${incomingRoute}`);
        }
    };

    const onIncomingMessage = (event: any) => {
        if (event.origin !== metadata.origin) {
            return;
        }
        let action: any = {};
        try {
            action = JSON.parse(event.data);
        } catch (e) {
            return;
        }
        if (action.type === IncomingActions.ROUTING && action.applicationId === appName) {
            replaceIncomingRouteChange(action.pathname);
        }
    };

    React.useEffect(() => {
        window.addEventListener('message', onApplicationLoad);
        window.addEventListener('message', onIncomingMessage);
    }, []);

    return ReactDOM.createPortal(
        <div
            className={classes.appModal}
            style={{
                display: iframeInfo.visibility ? 'block' : 'none',
                top: dimensions.top,
                left: dimensions.left,
                width: `${dimensions.width || 0}px`,
                height: dimensions.height ? `${dimensions.height}px` : 'calc(100% - 64px)',
            }}
        >
            {isRendered && (
                <iframe
                    id={metadata.iframeId}
                    className={classes.customModalIframe}
                    src={metadata.url}
                    height="100%"
                    width="100%"
                    title={metadata.iframeId}
                />
            )}
        </div>,
        document.body,
    );
};

const mapStateToProps = (state: Store, ownProps: any) => {
    const { iframeReducer } = state;
    const { appName } = ownProps;
    return {
        iframeInfo: iframeReducer[appName],
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        setiframeLoaded: setIframeLoaded,
    }, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: settingsStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const PersistentIframe = GenericHoc(hocConfig)(PersistentIframeInjector);
export default PersistentIframe;
