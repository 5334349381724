import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Select,
} from 'antd';
import { FormField } from 'components/create-consignment/create-consignment.types';
import { useTranslation } from 'react-i18next';

const styles = () => ({
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '50%',
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    field: FormField;
    unitField: FormField;
}

const GenericInternationalCurrencyField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        fieldsToShowInternational,
        form,
        field,
        unitField,
        params,
    } = props;
    const {
        ConsignmentCategory,
        IncoTerms,
        InvoiceType,
        FOB_Value,
        TotalGSTPaidAmnt,
    } = formFields;

    const { currencyList } = params;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const incoTermsValue = Form.useWatch(IncoTerms.key, form);
    const invoiceTypeValue = Form.useWatch(InvoiceType.key, form);

    const isInternational = consignmentCategory === 'international';

    if (!isInternational) {
        return null;
    }

    if (!fieldsToShowInternational[field.key]) return null;

    const renderCurrencyUnit = () => {
        let options = unitField.options || [];
        if (currencyList.length > 0) {
            options = currencyList.map((currency: any) => {
                return (
                    {
                        // label: currency.currency_name,
                        label: currency.currency_code,
                        value: currency.currency_code,
                    }
                );
            });
        }
        options.sort((a, b) => ((a.label > b.label) ? 1 : -1));
        return (
            <Form.Item
                name={unitField.key}
                key={unitField.key}
                initialValue={unitField.defaultValue}
            >
                <Select
                    disabled={unitField.disabled}
                    placeholder={unitField.placeholder}
                    options={options}
                    showSearch
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                />
            </Form.Item>
        );
    };

    let required = false;
    if (field === FOB_Value && incoTermsValue === 'FOB') required = true;
    if (field === TotalGSTPaidAmnt && invoiceTypeValue === 'GST') required = true;
    return (
        <Col span={12}>
            <div>
                <div
                    className={classes.title}
                    style={{ width: '100%', margin: '5px 0' }}
                >
                    {field.label}
                    {required ? '*' : null}
                </div>
                <Form.Item
                    name={field.key}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    rules={[
                        {
                            required,
                            message: t('required'),
                        },
                    ]}
                >
                    <Input
                        type="number"
                        min={0}
                        placeholder={field.placeholder}
                        addonAfter={renderCurrencyUnit()}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const fieldsToShowInternational = customerPortalConfig?.fields_to_show_international || {};
    return {
        fieldsToShowInternational,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(GenericInternationalCurrencyField);
