/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const VeryUnhappy = (props: any) => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>Very unhappy</title>
        <defs>
            <polygon id="path-1" points="0 0 24.00004 0 24.00004 24 0 24" />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="CP-V2-Like" transform="translate(-36.000000, -80.000000)">
                <g id="Group-5" transform="translate(12.000000, 12.000000)">
                    <g id="Group-2" transform="translate(24.000000, 68.000000)">
                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="36" height="36" rx="4" />
                        <g id="Group-14" transform="translate(6.000000, 6.000000)">
                            <g id="Group-3">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                </mask>
                                <g id="Clip-2" />
                                <path d="M24.00004,12.8135414 C24.00004,19.9742733 19.1607519,24.00004 12.00002,24.00004 C4.83928807,24.00004 0,19.9742733 0,12.8135414 C0,5.38160897 4.57256762,0 12.00002,0 C19.4274724,0 24.00004,5.38160897 24.00004,12.8135414" id="Fill-1" fill="#999999" mask="url(#mask-2)" />
                            </g>
                            <path d="M6.37392262,9.48406381 C6.37392262,9.93470456 6.4190427,10.3751053 6.50520284,10.801266 C6.82512338,12.3846286 9.1227272,12.3846286 9.44272774,10.801266 C9.52880788,10.3751053 9.57392796,9.93470456 9.57392796,9.48406381 C9.57392796,8.85982277 9.48736781,8.25518176 9.32520754,7.6809408 C8.9399269,6.31677853 7.00800368,6.31677853 6.62272304,7.6809408 C6.46056277,8.25518176 6.37392262,8.85982277 6.37392262,9.48406381" id="Fill-4" fill="#FFFFFF" />
                            <path d="M17.5740293,9.48406381 C17.5740293,9.93470456 17.5289092,10.3751053 17.4427491,10.801266 C17.1228285,12.3846286 14.8252247,12.3846286 14.5052242,10.801266 C14.419144,10.3751053 14.374024,9.93470456 14.374024,9.48406381 C14.374024,8.85982277 14.4605841,8.25518176 14.6227444,7.6809408 C15.008025,6.31677853 16.9400282,6.31677853 17.3252289,7.6809408 C17.4873891,8.25518176 17.5740293,8.85982277 17.5740293,9.48406381" id="Fill-6" fill="#FFFFFF" />
                            <path d="M4.65160775,17.7762216 C5.15136859,16.3764593 6.1210502,15.0589371 7.47313246,14.1760556 C8.82785471,13.2914942 10.4775375,12.9422136 12.02314,12.9778136 C13.4553024,13.0107737 15.3040255,13.3615743 16.5385876,14.1462956 C17.9327499,15.0324571 18.8620314,16.3824593 19.3501923,17.7761416 C19.4243524,17.987742 19.3128322,18.2193424 19.1012318,18.2935025 C18.9369916,18.3510226 18.7607513,18.2967825 18.6553911,18.1707823 L18.6517111,18.1661423 C17.7605096,17.0951805 16.799948,16.182459 15.6946662,15.6783781 C14.6569044,15.2050973 13.0184217,15.005497 11.97314,14.973577 C10.6893778,14.9345369 9.44697574,15.1325372 8.32537388,15.6667781 C7.202252,16.201819 6.22649038,17.0918205 5.35008892,18.1663023 C5.20848868,18.3399026 4.95064825,18.3687026 4.77704796,18.2271824 C4.64048773,18.1158222 4.59552766,17.9331819 4.65160775,17.7762216" id="Fill-8" fill="#FFFFFF" />
                            <path d="M19.6206887,4.50678351 C18.7887673,4.03566273 17.7410856,4.17118295 16.820124,4.57718363 C15.8998825,4.9828643 15.1255612,5.69054548 14.4880401,6.34070657 C14.0564394,6.7808673 13.4235584,6.74518724 13.0436377,6.37270662 C12.6457171,5.98254597 12.6393971,5.34366491 13.0294777,4.94574424 C13.3685983,4.59990367 15.1052412,3.6579821 16.4836435,3.42534171 C17.6961255,3.22062137 19.0138877,3.37486162 19.8668491,4.19150299 C19.9470092,4.26822311 19.9497292,4.39542333 19.8730091,4.47550346 C19.812129,4.53910357 19.7154889,4.5604636 19.6206887,4.50678351" id="Fill-10" fill="#FFFFFF" />
                            <path d="M4.08798281,4.50678351 C4.9199842,4.03566273 5.96758595,4.17118295 6.88854748,4.57718363 C7.80878901,4.9828643 8.58311031,5.69054548 9.22063137,6.34070657 C9.65223209,6.7808673 10.2851131,6.74518724 10.6650338,6.37270662 C11.0629544,5.98254597 11.0692744,5.34366491 10.6791938,4.94574424 C10.3401532,4.59990367 8.60343034,3.6579821 7.22502804,3.42534171 C6.01254602,3.22062137 4.69478382,3.37486162 3.8418224,4.19150299 C3.76166227,4.26822311 3.75894226,4.39542333 3.83566239,4.47550346 C3.89654249,4.53910357 3.99318266,4.5604636 4.08798281,4.50678351" id="Fill-12" fill="#FFFFFF" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const VeryUnhappyGrey = (props: any) => (
    <Icon component={(innerProps: any) => {
        return VeryUnhappy({ ...innerProps, ...props });
    }}
    />
);

export default VeryUnhappyGrey;
