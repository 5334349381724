import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps } from '../../theme/jss-types';
import { consignmentDetailsIntStyles } from './consignment-details.styles';
import GenericHoc from '../common/generic-hoc';
import { snakeToPretty } from 'library/lodash-helper';
import {
    Button,
    Collapse,
    Image,
    Modal,
    Tooltip,
} from 'antd';
import { DetailsToShowKeysDomestic, DetailsToShowKeysIntl } from './consignment-details.constants';
import { useTranslation } from 'react-i18next';
import DocumentDetailsModal from './document-details-modal';
import {
    InfoCircleOutlined,
} from '@ant-design/icons';
import TableModal from '../table-modal/tableModal';
import { RouterProps } from 'library/Types';
import { PAGE_NAMES } from 'types/page.types';
import { ReduxStore } from 'reducers/redux.types';
import { ViewData } from 'types/view.types';
import { isEmpty } from 'lodash';

interface ConsignmentDetailedInternationalProps extends StylesProps<ReturnType<typeof consignmentDetailsIntStyles>>,
    RouterProps {
    consignment: Record<string, any>;
    consignmentSectionList: ViewData[][];
}

const detailsTabs = [
    'Primary Details',
    'Consignee Details',
    'Consignor Details',
    'Service Details',
    'Other Details',
];

const ConsignmentDetailedInternational = (props: ConsignmentDetailedInternationalProps) => {
    const {
        classes,
        consignment,
        consignmentSectionList,
        location,
    } = props;
    const [selectedTab, setSelectedTab] = React.useState('Primary Details');
    const [itemDetails, setItemDetails] = React.useState<any>(null);
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [modalRow, setModalRow] = React.useState<any>(null);
    const [isPriceDetailsModalVisible, setIsPriceDetailsModalVisible] = React.useState<boolean>(false);
    const [priceDataList, setPriceDataList] = React.useState<any>([]);
    // eslint-disable-next-line no-spaced-func
    const [viewDataKeyList, setViewDataKeyList] = React.useState<Record<string, (string | undefined)[]>>({});
    const [viewDataKeyLabelMap, setViewDataKeyLabelMap] = React.useState<Record<string, string>>({});

    const getDetailsToShowKeyList = () => {
        if (!isEmpty(viewDataKeyList)) return viewDataKeyList;

        return consignment?.is_international ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic;
    };

    React.useEffect(() => {
        const viewId = new URLSearchParams(location.search)?.get('view');

        if (viewId) {
            const detailsToShowKeysList: Record<string, (string | undefined)[]> = getDetailsToShowKeyList();
            const viewData = consignmentSectionList?.flat().find((view) => view.id === viewId);
            const consignmentFieldList: Record<string, string> = viewData?.metadata?.fieldList?.reduce(
                (obj: Record<string, string>, item) => {
                    if (item?.enable) {
                        return { ...obj, [item.id]: item.name };
                    }
                    return obj;
                }, {},
            ) || {};
            const viewDataKeys: Record<string, (string | undefined)[]> = {};

            if (!isEmpty(consignmentFieldList)) {
                Object.keys(detailsToShowKeysList).forEach((key) => {
                    viewDataKeys[key] = detailsToShowKeysList[key]?.filter(
                        (item) => item && consignmentFieldList?.[item],
                    ) || [];
                });
                setViewDataKeyList(viewDataKeys);
                setViewDataKeyLabelMap(consignmentFieldList);
            }
        }
    }, [consignmentSectionList]);

    const renderSingleTab = () => {
        return detailsTabs.map((tab) => {
            let bucketClass: any = classes.bucket;
            if (tab === selectedTab) {
                bucketClass = [classes.bucketSelected, classes.bucket].join(' ');
            }
            return (
                <div
                    key={tab}
                    className={bucketClass}
                    onClick={() => setSelectedTab(tab)}
                >
                    <span>
                        {t(tab)}
                    </span>
                </div>
            );
        });
    };

    const labelValuePair = (key: string) => {
        const data = {
            label: snakeToPretty(key),
            value: consignment[key],
        };

        if (viewDataKeyLabelMap[key]) {
            data.label = viewDataKeyLabelMap[key];
        } else {
            switch (key) {
                case 'load_type':
                    data.label = 'Item Type';
                    break;
                case 'num_pieces':
                    data.label = 'No. of Pieces';
                    break;
                case 'meis':
                    data.label = 'MEIS';
                    break;
                case 'is_risk_surcharge_applicable':
                    data.label = 'Risk Surcharge';
                    data.value = data.value !== null && data.value !== undefined ? data.value.toString() : '';
                    break;
                case 'length':
                case 'width':
                case 'height':
                    data.value = data.value ? `${consignment[key]}  cm` : '';
                    break;
                case 'weight':
                    data.value = data.value ? `${consignment[key]}  Kg` : '';
                    break;
                case 'given_length':
                case 'given_width':
                case 'given_height':
                    data.value = data.value ? `${consignment[key]}  cm` : '';
                    break;
                case 'given_weight':
                    data.value = data.value ? `${consignment[key]}  Kg` : '';
                    break;
                case 'fob_value':
                case 'freight_cost':
                    data.value = consignment[key]
                        ? `${consignment[key]}  ${consignment[`${key}_currency`] || ''}`
                        : '';
                    break;
                case 'total_gst_paid_amount':
                    data.value = consignment[key]
                        ? `${consignment[key]}  ${consignment.total_gst_paid_currency || ''}`
                        : '';
                    break;
                case 'consignor_kyc_front_image':
                case 'consignor_kyc_back_image':
                    if (data.value) {
                        data.value = (
                            <Button
                                type="link"
                                onClick={() => {
                                    setImageUrl(consignment[key]);
                                }}
                            >
                                View
                            </Button>
                        );
                    }
                    break;
                case 'sender_name':
                case 'sender_phone':
                case 'sender_address_line_1':
                case 'sender_address_line_2':
                case 'sender_country':
                case 'sender_state':
                case 'sender_city':
                case 'sender_pincode':
                    data.label = data.label.replace('Sender', 'Origin');
                    break;
                case 'origin_type':
                    data.label = data.label.replace('Origin', 'Sender');
                    break;
                case 'against_bond_lut':
                    data.label = 'Against Bond LUT';
                    if (data.value === 'B') data.value = 'Against Bond';
                    else if (data.value === 'U') data.value = 'Undertaking';
                    break;
                case 'international_detail_shipment_purpose':
                    data.label = 'Shipment Purpose';
                    break;
                case 'delivery_terms':
                    data.label = 'Shipment Terms';
                    break;
                case 'consignor_pan':
                    data.label = 'Consignor Pan Id';
                    break;
                case 'booking_service_type_id':
                    data.label = 'Service Type';
                    break;
                case 'courier_partner_reference_number':
                    data.label = 'Carrier AWB No.';
                    break;
                case 'courier_partner':
                    data.label = 'Carrier';
                    break;
                case 'transport_mode':
                    data.label = 'Transport Mode';
                    break;
                case 'document_list':
                    data.label = 'document_list';
                    break;
                case 'price_details':
                    data.label = 'Customer Charges';
                    break;
                default:
                    break;
            }
        }

        if (data.value !== false && !data.value) {
            data.value = '-';
        }
        if (!viewDataKeyLabelMap[key] && i18n.exists(key)) data.label = t(key);
        return data;
    };

    const renderLinkModal = (row: any) => {
        return (
            <DocumentDetailsModal
                isVisible={isVisible}
                onModalClose={() => setIsVisible(false)}
                documentDetails={row?.document_details}
            />
        );
    };
    const renderDocumentList = () => {
        return (
            <div className={classes.documentGroup}>
                <div style={{ fontSize: '12px' }}>Document List</div>
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            setModalRow(consignment);
                            setIsVisible(true);
                        }}
                        style={{ fontSize: '12px', marginLeft: 16 }}
                    >
                        View
                    </Button>
                </div>
            </div>
        );
    };
    const renderCustomValue = (key: string) => {
        switch (key) {
            case 'document_list':
                return (
                    <>
                        {renderDocumentList()}
                        {renderLinkModal(modalRow)}
                    </>
                );
            default:
                return null;
        }
    };

    const priceDetailsModalColumns = [
        {
            column_id: 'name',
            pretty_name: 'Charge Name',
            width: 50,
        },
        {
            column_id: 'value',
            pretty_name: 'Charge Value',
            width: 50,
        },
    ];


    const handlePriceDetailsModal = (priceDetailsList: any[]) => {
        setIsPriceDetailsModalVisible(true);
        setPriceDataList(priceDetailsList);
    };

    const renderFinalPrice = (finalPrice: string, priceDetailsList: any[]) => {
        return (
            <span>
                {(finalPrice || 0)?.toString()}
                <Tooltip title="">
                    <a>
                        <InfoCircleOutlined
                            style={{ marginLeft: 5 }}
                            onClick={() => handlePriceDetailsModal(priceDetailsList)}
                        />
                    </a>
                </Tooltip>
            </span>
        );
    };


    const renderPriceDetailsData = (label: string, priceDetails: any, width: string, chargeKey: string | undefined) => {
        let finalPriceValue = '';
        let priceDetailsList = [];
        if (Array.isArray(priceDetails) && priceDetails.length) {
            const finalPriceObject: any = priceDetails.find((price) => (price.id === 'finalPrice'));
            if (finalPriceObject) {
                finalPriceValue = finalPriceObject.value;
                priceDetailsList = priceDetails.filter((price) => (price.id !== 'finalPrice'));
            }
        }
        return (
            <span className={classes.fieldGroup} key={chargeKey} style={width ? { width } : undefined}>
                <div className={classes.fieldTitle}>
                    {label}
                </div>
                <div className={classes.fieldValue}>
                    {finalPriceValue ? renderFinalPrice(finalPriceValue, priceDetailsList) : '-'}
                </div>
            </span>
        );
    };

    const renderField = (key: string | undefined, width: string) => {
        if (key === undefined) {
            return (
                <div className={classes.fieldGroup} style={width ? { width } : undefined} />
            );
        }
        const { label, value } = labelValuePair(key);
        if (key === 'document_list') {
            return renderCustomValue(key);
        }

        if (key === 'price_details') {
            return renderPriceDetailsData(label, value, width, key);
        }
        return (
            <span className={classes.fieldGroup} key={key} style={width ? { width } : undefined}>
                <div className={classes.fieldTitle}>
                    {label}
                </div>
                <div className={classes.fieldValue}>
                    {value}
                </div>
            </span>
        );
    };

    const renderPieceField = (idx: any, key: string|undefined) => {
        if (!key) return null;
        const data = {
            label: key,
            value: consignment.pieces_detail[idx][key] ?? '-',
        };
        switch (key) {
            case 'length':
            case 'width':
            case 'height':
                data.value = `${data.value}  ${consignment.pieces_detail[idx]?.dimension_unit || 'cm'}`;
                break;
            case 'declared_value':
                data.value = `${data.value}  ${consignment.pieces_detail[idx]?.declared_currency || ''}`;
                break;
            case 'weight':
                data.value = `${data.value}  ${consignment.pieces_detail[idx]?.weight_unit || ''}`;
                break;
            case 'reference_number': {
                const refNumber = consignment.pieces_detail[idx][key];
                data.label = 'pieceReferenceNumber';
                data.value = (
                    <Tooltip title={refNumber}>
                        {refNumber?.length > 12 ? `${refNumber.slice(0, 12)}...` : refNumber}
                    </Tooltip>
                );
                break;
            }
            default:
                break;
        }
        return (
            <div className={classes.fieldGroup} key={data.label}>
                <div className={classes.fieldTitle}>
                    {i18n.exists(data.label) ? t(data.label) : snakeToPretty(data.label)}
                </div>
                <div className={classes.fieldValue}>
                    {data.value}
                </div>
            </div>
        );
    };

    const renderItemField = (item: any, key: string | undefined) => {
        if (!key) return <div className={classes.fieldGroup} key={key} style={{ width: '50%' }} />;
        let name = snakeToPretty(key);
        switch (key) {
            case 'duty_value': name = 'Total Value';
                break;
            case 'item_value': name = 'Item Rate';
                break;
            default:
                break;
        }
        return (
            <div className={classes.fieldGroup} key={key} style={{ width: '50%' }}>
                <div className={classes.fieldTitle}>
                    {name}
                </div>
                <div className={classes.fieldValue}>
                    {item[key]}
                </div>
            </div>
        );
    };

    const renderTabs = () => {
        return (
            <div className={classes.buckets}>
                {renderSingleTab()}
            </div>
        );
    };

    const renderPrimaryDetails = () => {
        const keys = getDetailsToShowKeyList().primaryDetails;
        return keys.map((key) => {
            return renderField(key, '33.3%');
        });
    };

    const renderOtherDetails = () => {
        const keys = getDetailsToShowKeyList().otherDetails;
        return keys.map((key) => {
            return renderField(key, '50%');
        });
    };

    const renderPieceDetails = (piece: any, idx: number) => {
        const pieceKeys = getDetailsToShowKeyList().pieceDetails;
        return (
            <div className={classes.box}>
                <Collapse expandIconPosition="right" bordered={false} ghost>
                    <Collapse.Panel
                        key={piece.id}
                        header={`${consignment.is_international ? 'Box' : t('Piece')} ${idx + 1}`}
                    >
                        <div className={classes.hr} style={{ margin: '0 0 16px 0' }} />
                        <div className={classes.tabDetails}>
                            {
                                pieceKeys.map((key) => renderPieceField(idx, key))
                            }
                        </div>
                        {

                            piece?.item_details ? (
                                <>
                                    <div className={classes.hr} style={{ margin: '16px 0' }} />
                                    Item Details:
                                    {
                                        piece?.item_details?.map((item: any) => (
                                            <Button
                                                type="primary"
                                                style={{ margin: '0 8px' }}
                                                onClick={() => { setItemDetails(item); }}
                                            >
                                                Item&nbsp;
                                                {piece.item_details.indexOf(item) + 1}
                                            </Button>
                                        ))
                                    }
                                </>
                            ) : null
                        }
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    };

    const renderPiecesDetails = () => {
        return consignment?.pieces_detail?.map((piece: any, idx: number) => {
            return renderPieceDetails(piece, idx);
        });
    };

    const renderItemDetailModal = () => {
        if (!itemDetails) return null;
        const keys = getDetailsToShowKeyList().itemDetails;
        return (
            <Modal
                title="Item Details"
                closable
                onCancel={() => setItemDetails(null)}
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                }}
                visible={itemDetails}
                footer={null}
            >
                <div className={classes.tabDetails}>
                    {
                        keys.map((key: string|undefined) => (renderItemField(itemDetails, key)))
                    }
                </div>
            </Modal>
        );
    };

    const renderDetails = () => {
        const consignorDetailsKeys = getDetailsToShowKeyList().consignorDetails;
        const consigneeDetailsKeys = getDetailsToShowKeyList().consigneeDetails;
        switch (selectedTab) {
            case 'Primary Details':
                return (
                    <>
                        <div className={classes.tabDetails}>
                            {renderPrimaryDetails()}
                        </div>
                        <div className={classes.hr} style={{ margin: '16px 0' }} />
                        {renderPiecesDetails()}
                    </>
                );
            case 'Consignee Details':
                return (
                    <div className={classes.tabDetails}>
                        {
                            consigneeDetailsKeys.map((key) => {
                                return renderField(key, '60%');
                            })
                        }
                    </div>
                );
            case 'Consignor Details':
                return (
                    <div className={classes.tabDetails}>
                        {
                            consignorDetailsKeys.map((key) => {
                                return renderField(key, consignment.is_international ? '50%' : '60%');
                            })
                        }
                    </div>
                );
            case 'Service Details':
                return (
                    <div className={classes.tabDetails}>
                        {renderField(
                            consignment.is_international ? 'booking_service_type_id' : 'service_type',
                            '100%',
                        )}
                    </div>
                );
            case 'Other Details':
                return (
                    <div className={classes.tabDetails}>
                        {renderOtherDetails()}
                    </div>
                );
            default:
                return (<h3>Tab details not handled yet</h3>);
        }
    };

    const renderImagePreview = () => {
        if (!imageUrl) return null;
        return (
            <Image
                src={imageUrl}
                width={0}
                preview={{
                    visible: true,
                    src: imageUrl,
                    onVisibleChange: (value) => {
                        if (!value) setImageUrl(null);
                    },
                }}
            />
        );
    };

    return (
        <>
            <div className={classes.mainDiv}>
                <div className={classes.itemDetails}>
                    {renderTabs()}
                    {renderDetails()}
                    {renderImagePreview()}
                </div>
            </div>
            {renderItemDetailModal()}
            {isPriceDetailsModalVisible && (
                <TableModal
                    width={500}
                    header="Details"
                    isVisible={isPriceDetailsModalVisible}
                    handleModalClose={() => {
                        setIsPriceDetailsModalVisible(false);
                        setPriceDataList([]);
                    }}
                    dataSource={priceDataList}
                    columnsToShow={priceDetailsModalColumns}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        consignmentSectionList: state.genericPage?.[PAGE_NAMES.CONSIGNMENTS]?.metadata?.sectionList,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: consignmentDetailsIntStyles,
    },
    connectRouter: true,
};

export default GenericHoc(hocConfig)(ConsignmentDetailedInternational);
