import * as React from 'react';
import SortableList from './sortable-list';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { uniq, uniqBy } from 'lodash';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { PageSelected, settingOptions } from './pickup-settings.constants';
import { updateColumns } from '../../network/pickup.api';
import { settingStyles } from './settings.styles';
import { SearchOutlined } from '@ant-design/icons';
import Cross from '../../assets/cross';
import {
    PickupBucketKeys,
    PickupColumn,
    MasterConfig,
} from '../../types/master-data-types';
import {
    Button,
    Drawer,
    Input,
    Checkbox,
    message,
} from 'antd';
import { LabelValue } from '../../library/Types';
import { bindActionCreators } from 'redux';
import { loadInitialData } from '../../actions/load-initial-data';
import GenericHoc from '../common/generic-hoc';
import { useTranslation } from 'react-i18next';

interface SettingsProps extends StylesProps<ReturnType<typeof settingStyles>> {
    onClose: (refresh?: boolean) => void;
    config: MasterConfig;
    bucketSelected: PickupBucketKeys;
    filterObject: {
        columns: Record<string, PickupColumn>;
    };
    loadInitial: () => void;
    uiTheme: ThemeType;
}

type Selected = Record<PageSelected, CheckboxValueType[]>;
type SelectedList = Record<PageSelected, PickupColumn[]>;
type VisibleList = Record<PageSelected, LabelValue[]>;

const {
    useEffect,
    useState,
} = React;

const PickupSettings = (props: SettingsProps) => {
    const {
        classes,
        onClose,
        config,
        filterObject,
        bucketSelected,
        loadInitial,
        uiTheme,
    } = props;

    const { t } = useTranslation();

    const [loading, setLoading] = React.useState<boolean>(false);

    const getSelectedColumns = () => {
        let columns = config?.pickup_column_list_by_bucket[bucketSelected] || [];
        columns = uniqBy(columns, 'column_id');
        return columns;
    };

    const [query, setQuery] = useState<string>();
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [pageSelected, setPageSelected] = useState<PageSelected>('columns');
    const [columnsSelected, setColumnsSelected] = useState<Selected>({
        columns: getSelectedColumns().map((item) => (item.column_id)),
    });
    const [selectedList, setSelectedList] = useState<SelectedList>({
        columns: getSelectedColumns(),
    });
    const [visibleList, setVisibleList] = useState<VisibleList>({
        columns: [],
    });

    const getColumnsList = () => {
        let options = uniqBy(config?.full_pickup_column_list || [], 'column_id');
        if (query) {
            options = options.filter((item) => {
                const name = item.pretty_name.toUpperCase();
                return name.includes(query.toUpperCase());
            });
        }
        const allOptions = options.map((item) => ({
            label: item.pretty_name,
            value: item.column_id,
        }));
        const selectedColumns = allOptions.filter((item) => {
            return columnsSelected[pageSelected].includes(item.value);
        });
        const unselectedColumns = allOptions.filter((item) => {
            return !columnsSelected[pageSelected].includes(item.value);
        });
        return [...selectedColumns, ...unselectedColumns];
    };

    useEffect(() => {
        setVisibleList({
            columns: getColumnsList(),
        });
    }, [columnsSelected, query]);

    const handleUpdateColumns = async () => {
        const apiBody = {
            bucket: bucketSelected,
            pickup_column_list: selectedList.columns,
        };
        const response = await updateColumns(apiBody);
        if (response.isSuccess) {
            message.success('Columns updated successfully');
            onClose(true);
        } else {
            message.error(response.errorMessage);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (pageSelected === 'columns') {
            await handleUpdateColumns();
        }
        loadInitial();
        setLoading(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButtonDisabled;
        if (!btnDisabled) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                className={btnClass}
                disabled={btnDisabled}
                loading={loading}
                style={{
                    border: `1px solid ${btnDisabled ? '#EDEDED'
                        : uiTheme.primaryColor}`,
                }}
                onClick={handleSubmit}
            >
                {t('submit')}
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('customize')}
                    </span>
                </div>
                {renderButton()}
            </div>
        );
    };

    const handlePageChange = (page: PageSelected) => {
        if (page === pageSelected) {
            return;
        }
        setPageSelected(page);
    };

    const renderSettingOptions = () => {
        return (
            <div className={classes.row}>
                {settingOptions.map((item) => (
                    <div
                        key={item.key}
                        onClick={() => handlePageChange(item.key)}
                        className={pageSelected === item.key ? classes.selectedPage : classes.pageName}
                    >
                        {t(item.key)}
                    </div>
                ))}
            </div>
        );
    };

    const renderSearchBox = () => {
        return (
            <Input
                type="text"
                value={query}
                onChange={(e: React.ChangeEvent<any>) => setQuery(e.target.value)}
                placeholder={t('search_columns')}
                className={classes.searchBox}
                suffix={<SearchOutlined />}
            />
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const getUpdatedSelection = (checked: any[], useExisting: boolean) => {
        let current: any[] = checked;
        if (useExisting) {
            current = [...columnsSelected[pageSelected]];
            if (checked.length > current.length) {
                const newAddition = checked.filter((item) => !current.includes(item));
                current.push(...newAddition);
            } else {
                current = current.filter((item) => checked.includes(item));
            }
        }
        current = uniq(current);
        const updatedList = current.map((item) => {
            return filterObject[pageSelected][item];
        });
        return {
            current,
            updatedList,
        };
    };

    const updateSelection = (checked: any[], useExisting = false) => {
        const { current, updatedList } = getUpdatedSelection(checked, useExisting);

        setColumnsSelected({
            ...columnsSelected,
            [pageSelected]: current,
        });

        setSelectedList({
            ...selectedList,
            [pageSelected]: updatedList,
        });
        setBtnDisabled(false);
    };

    const handleCheckboxChange = (checked: boolean, item: LabelValue) => {
        let newList = [...columnsSelected[pageSelected]];
        if (checked) {
            newList.push(item.value);
        } else {
            newList = newList.filter((column) => column !== item.value);
        }
        updateSelection(newList);
    };

    const renderColumns = () => {
        return (
            <div className={classes.columnList}>
                {visibleList[pageSelected].map((item) => {
                    return (
                        <Checkbox
                            onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                            key={item.value}
                            value={item.value}
                            checked={columnsSelected[pageSelected].includes(item.value)}
                        >
                            {item.label}
                        </Checkbox>
                    );
                })}
            </div>
        );
    };

    const renderAllColumns = () => {
        return (
            <div className={classes.columns}>
                {renderSearchBox()}
                {renderLine()}
                {renderColumns()}
            </div>
        );
    };

    const renderFilterColumns = () => {
        return (
            <div className={classes.filterColumns}>
                <div className={classes.filterName}>
                    {t(`${pageSelected}_to_show`)}
                </div>
            </div>
        );
    };

    const updateShowOnDashboard = () => {
        setSelectedList({
            ...selectedList,
        });
        setBtnDisabled(false);
    };

    const renderFilterOptions = () => {
        return (
            <div className={classes.filters}>
                {renderFilterColumns()}
                {renderLine()}
                <SortableList
                    updateShowOnDashboard={updateShowOnDashboard}
                    pageSelected={pageSelected}
                    updateSelection={updateSelection}
                    selectedList={selectedList[pageSelected]}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.contentRow}>
                {renderAllColumns()}
                {renderFilterOptions()}
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="55%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderSettingOptions()}
            {renderContent()}
        </Drawer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        config: state.master.config,
        uiTheme: state.uiTheme,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        loadInitial: loadInitialData,
    }, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: settingStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};

export default GenericHoc(hocConfig)(PickupSettings);
