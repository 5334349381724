import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col, Form, Radio } from 'antd';
import { formRules } from 'library/constants';

const styles = () => ({
    referenceItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    formItem: {
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const ConsignmentCategoryField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        allowInternationalBooking,
        hideConsignmentCategory,
        classes,
        params,
    } = props;
    const { isRequired, isFTL } = params;
    const { ConsignmentCategory } = formFields;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    let options = ConsignmentCategory.options;
    if (!allowInternationalBooking) {
        options = options?.filter((option: any) => option.value !== 'international');
    }
    if (isFTL) {
        options = options?.filter((option) => option.value === 'domestic');
    }
    if (Array.isArray(options) && options.length > 0) {
        options.forEach((item: any) => {
            /* eslint-disable no-param-reassign */
            item.label = t(item.value);
        });
    }
    if (hideConsignmentCategory && !isFTL) return null;

    return (
        <Col span={12}>
            <div className={classes.referenceItem}>
                <div className={classes.referenceTitle}>
                    {t(ConsignmentCategory.key)}
                </div>
                <Form.Item
                    name={ConsignmentCategory.key}
                    initialValue={ConsignmentCategory.defaultValue}
                    valuePropName={ConsignmentCategory.valuePropName}
                    className={classes.formItem}
                    rules={isRequired(false, ConsignmentCategory.key) ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={options}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show, config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    return {
        allowInternationalBooking: get(parts_to_show, 'allow_international_booking', false),
        hideConsignmentCategory: customerPortalConfig?.hide_consignment_category,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(ConsignmentCategoryField);
