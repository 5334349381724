import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import Helper from 'library/Helper';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    shouldNotMakeItemTypeCheck: boolean;
}

const CommodityNameField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        useCategoryInCommodityData,
        consignmentConfig,
        form,
        shouldNotMakeItemTypeCheck,
    } = props;
    const {
        ContentTypes,
        ConsignmentCategory,
        ContentNameForOthers,
        ItemType,
    } = formFields;
    const {
        categoryList,
        contentList,
    } = params;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);
    const useCategory = useCategoryInCommodityData && consignmentCategory === 'domestic';
    const commodity = Form.useWatch(ContentTypes.key, form);

    if (!shouldNotMakeItemTypeCheck && itemType !== 'non-document') {
        return <></>;
    }

    if (consignmentConfig?.allow_multiple_piece_dimensions) {
        return <></>;
    }

    const categories: { value: string; label: string; children: { value: string; label: string; }[] }[] = [];
    const captureOtherCommodityIdList: string[] = [];
    categoryList.forEach((category: any) => {
        const commodities: { value: string; label: string; }[] = [];
        category.commodityList?.forEach((item: any) => {
            commodities.push({
                value: item.COMMODITY_ID,
                label: item.COMMODITY_NAME,
            });
            if (item.captureActualCommodity) {
                captureOtherCommodityIdList.push(item.COMMODITY_ID as string);
            }
        });
        categories.push({
            value: category.categoryId,
            label: i18n.exists(category.categoryName.toUpperCase())
                ? t(category.categoryName.toUpperCase()) : category.categoryName,
            children: commodities,
        });
    });

    const id = (Helper.isValidArray(commodity) ? last(commodity) : '') as string;
    let isOtherReq = false;
    if (useCategory) {
        if (captureOtherCommodityIdList.includes(id)) {
            isOtherReq = true;
        }
    } else {
        contentList?.forEach((content: any) => {
            if (content?.COMMODITY_ID === id && content?.captureActualCommodity) {
                isOtherReq = true;
            }
        });
    }

    if (!isOtherReq) {
        return <></>;
    }

    // const optionList = useCategory ? categories : contentList.map((item: any) => {
    //     return {
    //         value: item.COMMODITY_ID,
    //         label: i18n.exists(item.COMMODITY_NAME.toUpperCase())
    //             ? t(item.COMMODITY_NAME.toUpperCase()) : item.COMMODITY_NAME,
    //     };
    // });
    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div className={classes.title} style={{ width: '100%' }}>
                    {ContentNameForOthers.label}
                </div>
                <Form.Item
                    name={ContentNameForOthers.key}
                    className={classes.contentType}
                    // initialValue={ContentNameForOthers.defaultValue}
                    valuePropName={ContentNameForOthers.valuePropName}
                >
                    <Input
                        placeholder={ContentNameForOthers.placeholder}
                        style={{ width: ContentTypes.width }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    return {
        useCategoryInCommodityData: get(config, 'use_category_in_commodity_data', false),
        consignmentConfig: get(config, 'customer_portal_consignment_config', {}),
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CommodityNameField);
