/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const ConsignorIconUtil = (props: any) => (
    <svg
        {...props}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 363.7 504"
    // style="enable-background:new 0 0 363.7 504;"
    >
        <path d="M254.7,222.8c-0.5,21.9-32.9,39.5-72.9,39.5c-39.9,0-72.4-17.7-72.8-39.5c-61.1,5.6-109,0-109,119.5
            c0,59.1,42.8,62.6,99,62.7v61.6c0,20.6,16.8,37.4,37.4,37.4h90.7c20.6,0,37.4-16.8,37.4-37.4V405c56.3-0.1,99-3.6,99-62.7
            C363.6,222.8,315.8,228.4,254.7,222.8L254.7,222.8z M246.6,466.6c0,10.7-8.7,19.4-19.4,19.4h-90.7c-10.7,0-19.4-8.7-19.4-19.4V343.4
            c0-10.7,8.7-19.4,19.4-19.4h90.7c10.7,0,19.4,8.7,19.4,19.4L246.6,466.6L246.6,466.6z M181.8,238.5c55.3,0,100.1-83.1,100.1-138.4
            C281.9,44.8,237.1,0,181.8,0C126.5,0,81.7,44.8,81.7,100.1C81.7,155.3,126.6,238.5,181.8,238.5L181.8,238.5z M221.4,351h-79.1
            c-5,0-9,4-9,9s4,9,9,9h79.1c5,0,9-4,9-9C230.4,355,226.3,351,221.4,351L221.4,351z M221.4,396h-79.1c-5,0-9,4-9,9s4,9,9,9h79.1
            c5,0,9-4,9-9S226.3,396,221.4,396L221.4,396z M181.8,441h-39.6c-5,0-9,4-9,9s4,9,9,9h39.6c5,0,9-4,9-9
            C190.8,445,186.8,441,181.8,441z"
        />
    </svg>
);

const ConsignorIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return ConsignorIconUtil({ ...innerProps, ...props });
    }}
    />
);

export default ConsignorIcon;
