/* eslint-disable max-len */
import { ThemeType } from '../../theme/jss-types';

const advanceShippingNoteStyle = (theme: ThemeType) => ({
    main: {
        width: '100%',
        padding: '0px 8px',
        '& .ant-table-thead > tr > th': {
            padding: '8px 0px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
            paddingRight: 8,
            lineHeight: '12px',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 0',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 130,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 12,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-select': {
            marginRight: 24,
        },
        backgroundColor: theme.backgroundColor,
    },
    pageName: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        color: '#666666',
        padding: '12px 50px',
        cursor: 'pointer',
    },
    pageFilter: {
        display: 'flex',
        marginBottom: 8,
        borderBottom: '1px solid #D9D9D9',
    },
    selectedPage: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: theme.primaryColor,
        padding: '12px 50px',
        borderBottom: `4px solid ${theme.primaryColor}`,
    },
    count: {
        height: 18,
        padding: 10,
        borderRadius: 9,
        backgroundColor: '#E9E9E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 12,
        color: '#333',
        fontWeight: 600,
    },
    countSelected: {
        backgroundColor: theme.primaryColor,
        color: '#FFF',
        fontWeight: 'bold',
    },
    bucketSelected: {
        color: '#111111',
        fontWeight: 'bold',
        backgroundColor: '#FFF',
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
        fontFamily: 'Open Sans',
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 16px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
    },
    bucketRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    bucketName: {
        marginRight: 12,
    },
    tableRowColor: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
        '&& tbody > tr:hover > td': {
            background: '#E7F5FF',
        },
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    datePicker: {
        '& .ant-picker': {
            borderRadius: '16px',
            width: '350px',
        },
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-picker': {
            width: 180,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor}`,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
        '& .ant-dropdown-menu-submenu-title': {
            padding: '12px 4px',
        },
    },
    moreFilter: {
        fontFamily: 'Open Sans',
        marginLeft: 1,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterButton: {
        borderRadius: '4px',
        border: `1px solid ${theme.primaryColor}`,
        color: theme.primaryColor,
        marginRight: '7px',
        fontFamily: 'Open Sans',
    },
});

export {
    advanceShippingNoteStyle,
};
