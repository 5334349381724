import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from 'library/common-styles';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { Col, DatePicker, Form } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const EWBDateField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const { classes, showEwb, params } = props;
    const { isRequired } = params;
    const { EwbDate } = formFields;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    if (!showEwb) {
        return null;
    }

    const getDisabled = (current: Moment) => {
        return (
            moment(current) >= moment()
        );
    };

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div className={classes.referenceTitle}>
                    {EwbDate.label}
                    {isRequired(false, EwbDate.key) ? '*' : null}
                </div>
                <Form.Item
                    name={EwbDate.key}
                    className={classes.contentType}
                    initialValue={EwbDate.defaultValue}
                    valuePropName={EwbDate.valuePropName}
                    rules={isRequired(false, EwbDate.key) ? [formRuleRequired] : undefined}
                >
                    <DatePicker
                        disabledDate={(current) => getDisabled(current)}
                        placeholder={EwbDate.placeholder}
                        style={{ width: EwbDate.width }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;

    return {
        showEwb: get(config, 'customer_portal_config.show_ewb_fields', false),
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(EWBDateField);
