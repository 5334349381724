import * as React from 'react';
import lodash from 'lodash';
import { generateJWTSSOResponseCallback } from 'network/idp-sso-response';


function FreshdeskJWT() {
    const [previewValue, setPreviewValue] = React.useState('Loading...');

    const takeAction = async (sp_integration_id: string, protocol: string, payload: any) => {
        // make and POST call with above payload, user details in headers ( accessed from cookies )
        // if response.isSuccess is success -> redirect to data.rediredUrl
        // if !response.isSuccecc -> show error message
        const notFoundURl = `${window.location.pathname}?showErrorMessage=no%20redirect%20url%20found`;
        const response = await generateJWTSSOResponseCallback(sp_integration_id, protocol, payload);
        if (response.isSuccess) {
            window.location.href = response.data.redirectUrl ?? notFoundURl;
        } else {
            setPreviewValue(`Error: ${response?.errorMessage}`);
        }
    };

    // use effect on component mount
    React.useEffect(() => {
        try {
            const sp_integration_id = 'freshdesk';
            const protocol = 'jwt';

            const urlParams = window.location.search ?? '?';

            // query params as json
            const queryParams = lodash.fromPairs(urlParams.slice(1).split('&').map((pair) => pair.split('=')));

            // decodeUriComponent on each value
            lodash.forOwn(queryParams, (value, key) => {
                queryParams[key] = decodeURIComponent(value);
            });

            if (queryParams?.showErrorMessage) {
                setPreviewValue(`Error: ${queryParams?.showErrorMessage}`);
                return;
            }

            const payload = { sp_integration_id, ...queryParams };
            takeAction(sp_integration_id, protocol, payload);
        } catch (err) {
            setPreviewValue(`Error: ${err}`);
        }
    }, []);

    return (
        <div>
            {previewValue}
        </div>
    );
}

export default FreshdeskJWT;
