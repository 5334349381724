import * as React from 'react';
import withStyles from 'react-jss';
import { Modal, Table } from 'antd';

import { CssType } from '../../theme/jss-types';

const styles = (): CssType => ({
    tableContainer: {
        flex: '1 1 0',
        overflow: 'auto',
        height: 300,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
        '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
            padding: 9,
        },
    },
    image: {
        maxHeight: '450px',
        maxWidth: '450px',
    },
});

const TableModal = (props: any) => {
    const {
        columnsToShow,
        dataSource,
        isVisible,
        customGenerateDataColumns,
        useCustomGenerateDataColumns = false,
        classes,
        header,
        handleModalClose,
        width = 900,
    } = props;
    const generateDataColumns = () => {
        const columnData: any = [];
        const indexList = columnsToShow;
        indexList.forEach((item: any) => {
            const itemToPush :any = {};
            itemToPush.title = item.pretty_name;
            itemToPush.dataIndex = item.column_id;
            itemToPush.width = item?.width || 50;
            itemToPush.key = item.column_id;
            columnData.push(itemToPush);
        });
        return columnData;
    };
    return (
        <Modal
            title={header}
            width={width}
            visible={isVisible}
            footer={null}
            onCancel={() => handleModalClose(false)}
            destroyOnClose
        >
            <div className={classes.tableContainer}>
                <Table
                    locale={{ emptyText: `No ${header}` }}
                    pagination={false}
                    dataSource={dataSource}
                    columns={useCustomGenerateDataColumns ? customGenerateDataColumns(columnsToShow) : generateDataColumns()}
                />
            </div>
        </Modal>
    );
};

const StyledTableModal = withStyles(styles, { injectTheme: true })(TableModal);
export default StyledTableModal;
