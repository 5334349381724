/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const BulkHyperlocal = (props: any) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8081 19.4722H2.64171C2.28736 19.4722 2 19.1844 2 18.8295V5.64271C2 5.2878 2.28736 5 2.64171 5H15.8081C16.1625 5 16.4498 5.2878 16.4498 5.64271V18.8295C16.4498 19.1844 16.1625 19.4722 15.8081 19.4722" fill="#DDA986" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8081 19.4722H3.63829C3.28388 19.4722 2.99658 19.1844 2.99658 18.8295V5.64271C2.99658 5.2878 3.28388 5 3.63829 5H15.8081C16.1625 5 16.4498 5.2878 16.4498 5.64271V18.8295C16.4498 19.1844 16.1625 19.4722 15.8081 19.4722" fill="#EDBA94" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.11504 5.18341C3.07548 5.18341 3.03707 5.18421 3 5.18559C3.27228 5.7442 6.07796 6.18341 9.49936 6.18341C12.9253 6.18341 15.734 5.74305 16 5.18341H3.11504Z" fill="#DDA986" />
        <path d="M15.9515 19.4723C16.2268 19.4723 16.4498 19.2488 16.4498 18.9732V5.4991C16.4498 5.22351 16.2268 5.00004 15.9515 5.00004H2.49832C2.22309 5.00004 2.00003 5.22351 2.00003 5.4991V18.9732C2.00003 19.2488 2.22309 19.4723 2.49832 19.4723" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.4594 15.6855H15.1543V14.4336H11.4594V15.6855Z" fill="#F76725" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.4594 15.6855H15.1543V14.4336H11.4594V15.6855Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.4594 13.294H15.1543" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.9192 19.544H16.4521V7.41724H21.2309L25.9192 12.5598V19.544Z" fill="#D51323" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.9192 19.544H17.6978V7.41724H21.2309L25.9192 12.5598V19.544Z" fill="#F12D3D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.9192 19.544H16.4521V7.41724H21.2309L25.9192 12.5598V19.544Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.2397 13.4706H17.848V9.24072H20.2695L23.4766 12.9513C23.6523 13.1546 23.5081 13.4706 23.2397 13.4706" fill="#6695E1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.1621 13.4706H17.848V9.24072H20.2695L23.4258 12.8926C23.6214 13.1188 23.4609 13.4706 23.1621 13.4706Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.00003 19.6806H26V18.001H2.00003V19.6806Z" fill="#93BDCB" />
        <path d="M13.3909 18.001H25.8933V19.6806H2V18.001H11.6684" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.35766 19.6882C8.35766 21.0663 7.24224 22.1834 5.86628 22.1834C4.49033 22.1834 3.37497 21.0663 3.37497 19.6882C3.37497 18.3102 4.49033 17.1931 5.86628 17.1931C7.24224 17.1931 8.35766 18.3102 8.35766 19.6882" fill="#5A6464" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.35766 19.6882C8.35766 21.0663 7.24224 22.1834 5.86628 22.1834C4.49033 22.1834 3.37497 21.0663 3.37497 19.6882C3.37497 18.3102 4.49033 17.1931 5.86628 17.1931C7.24224 17.1931 8.35766 18.3102 8.35766 19.6882Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.81296 19.6882C6.81296 20.2118 6.38917 20.6364 5.86624 20.6364C5.34344 20.6364 4.91953 20.2118 4.91953 19.6882C4.91953 19.1646 5.34344 18.7401 5.86624 18.7401C6.38917 18.7401 6.81296 19.1646 6.81296 19.6882" fill="#F2F6F7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.81296 19.6882C6.81296 20.2118 6.38917 20.6364 5.86624 20.6364C5.34344 20.6364 4.91953 20.2118 4.91953 19.6882C4.91953 19.1646 5.34344 18.7401 5.86624 18.7401C6.38917 18.7401 6.81296 19.1646 6.81296 19.6882Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.4573 19.6882C23.4573 21.0663 22.3418 22.1834 20.966 22.1834C19.59 22.1834 18.4746 21.0663 18.4746 19.6882C18.4746 18.3102 19.59 17.1931 20.966 17.1931C22.3418 17.1931 23.4573 18.3102 23.4573 19.6882" fill="#5A6464" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.4573 19.6882C23.4573 21.0663 22.3418 22.1834 20.966 22.1834C19.59 22.1834 18.4746 21.0663 18.4746 19.6882C18.4746 18.3102 19.59 17.1931 20.966 17.1931C22.3418 17.1931 23.4573 18.3102 23.4573 19.6882Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9127 19.6882C21.9127 20.2118 21.4889 20.6364 20.9659 20.6364C20.4431 20.6364 20.0192 20.2118 20.0192 19.6882C20.0192 19.1646 20.4431 18.7401 20.9659 18.7401C21.4889 18.7401 21.9127 19.1646 21.9127 19.6882" fill="#F2F6F7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9127 19.6882C21.9127 20.2118 21.4889 20.6364 20.9659 20.6364C20.4431 20.6364 20.0192 20.2118 20.0192 19.6882C20.0192 19.1646 20.4431 18.7401 20.9659 18.7401C21.4889 18.7401 21.9127 19.1646 21.9127 19.6882Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.8191 16.3119H25.9195V14.9146H23.8191V16.3119Z" fill="#FCDE8B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.8191 16.3119H25.9195V14.9146H23.8191V16.3119Z" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.7249 15.0017H18.7214" stroke="black" strokeWidth="0.653786" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const BulkHyperlocalIcon = (props: any) => (
    <Icon component={() => {
        return BulkHyperlocal({ ...props });
    }}
    />
);

export default BulkHyperlocalIcon;
