import * as React from 'react';
import {
    Map,
    MapProvider,
    NavigationControl,
    FullscreenControl,
} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { IUIMap } from './utils/types';
import {
    // applySpiderfyToLayer,
    loadImages,
} from './utils/utils';
import { MAP_DEFAULT_CONFIG } from './utils/constants';

const UIMap = (props: IUIMap) => {
    const {
        mapRef,
        children,
        interactiveLayerIds,
        id = MAP_DEFAULT_CONFIG.id,
        mapStyle = MAP_DEFAULT_CONFIG.mapStyle,
        imagesToLoad = MAP_DEFAULT_CONFIG.images,
        initialViewState = MAP_DEFAULT_CONFIG.initialViewState,
        onClick = () => {},
        onMouseLeave = () => {},
        onMouseEnter = () => {},
        onMouseMove = () => {},
        onZoom = () => {},
        onMapLoad = () => {},
        ...rest
    } = props;

    const onLoad = (e: any) => {
        if (mapRef?.current) {
            loadImages(mapRef.current.getMap() as any, imagesToLoad);
        }
        onMapLoad(e);
    };

    return (
        <MapProvider>
            <Map
                id={id}
                ref={mapRef}
                mapLib={maplibregl as any}
                interactiveLayerIds={interactiveLayerIds}
                initialViewState={initialViewState}
                style={{ width: '100% !important', height: '100% !important' }}
                mapStyle={mapStyle}
                onLoad={onLoad}
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
                onZoom={onZoom}
                {...rest}
            >
                <NavigationControl />
                <FullscreenControl position="top-left" />
                {children}
            </Map>
        </MapProvider>
    );
};

export default UIMap;
